import gql from 'graphql-tag';

import type { Option } from '@atlaskit/editor-common/extensions';

import { getApolloClient } from '@confluence/graphql';

const labelSearchQuery = gql`
	query LabelSearch($searchTerm: String!) {
		labelSearch(searchText: $searchTerm, limit: 25) {
			otherLabels {
				label
			}
			suggestedLabels {
				label
			}
		}
	}
`;

const extractResults = (labelResults: { label: string }[]) =>
	labelResults.map((res) => ({
		label: res.label,
		value: res.label,
	}));

const search = async (searchTerm?: string): Promise<Option[]> => {
	const client = getApolloClient();

	const {
		data: { labelSearch },
	} = await client.query({
		query: labelSearchQuery,
		variables: {
			searchTerm,
		},
	});

	return [
		...extractResults(labelSearch.suggestedLabels),
		...extractResults(labelSearch.otherLabels),
	];
};

export const labelResolver = async (searchTerm?: string, selectedValue?: string | string[]) => {
	const searchers: Array<Promise<Option[]>> = [];

	if (searchTerm) {
		searchers.push(search(searchTerm));
	}

	if (selectedValue) {
		if (Array.isArray(selectedValue)) {
			searchers.push(
				Promise.resolve(
					selectedValue.map((label) => ({
						label,
						value: label,
					})),
				),
			);
		} else {
			searchers.push(
				Promise.resolve([
					{
						label: selectedValue,
						value: selectedValue,
					},
				]),
			);
		}
	}

	return (await Promise.all(searchers)).flat();
};
