import FeatureGates from '@atlaskit/feature-gate-js-client';

import { preloadPageTitleContentProperties } from '@confluence/custom-sites-extensions/entry-points/preloadPageTitleContentProperties';
import { preloadContentSmartLinks } from '@confluence/content-smartlinks/entry-points/preloadContentSmartLinks';
import { CONTENT_SMART_LINKS_BEST_EFFORT_TASK_NAME } from '@confluence/content-smartlinks/entry-points/ContentSmartLinksBestEffortTaskName';
import { preloadUserPreferencesForSSR } from '@confluence/storage-manager/entry-points/preloadUserPreferencesForSSR';
import { preloadSpaceOverviewQuery } from '@confluence/space-overview/entry-points/preloadSpaceOverview';
import {
	preloadPageTree,
	PAGE_TREE_QUERY_BEST_EFFORT_TASK_NAME,
} from '@confluence/page-tree/entry-points/preloadPageTree';
import { VIEW_SPACE_EXTERNAL_COLLABORATORS_DIALOG_BEST_EFFORT_TASK_NAME } from '@confluence/space-guest-list/entry-points/queryConstants';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import { SHORTCUTS_SMART_LINKS_BEST_EFFORT_TASK_NAME } from '@confluence/space-shortcuts/entry-points/ShortcutsSmartLinksBestEffortTaskName';
import { preloadSideNavigation } from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { preloadCustomHeaderAndFooter } from '@confluence/custom-header-footer/entry-points/preloadCustomHeaderAndFooter';
import { RENDERER_ANNOTATION_PROVIDER_SSR_FF } from '@confluence/annotation-provider/entry-points/featureFlags';
import { preloadInlineComments } from '@confluence/inline-comments-queries/entry-points/preloadInlineComments';
import { preloadWebPanelLocation } from '@confluence/web-panel-location/entry-points/preloadWebPanelLocation';
import { ATL_PAGE_CONTENT_FOOTER_ACTIONS } from '@confluence/web-item-location/entry-points/ATL_PAGE_CONTENT_FOOTER_ACTIONS';
import { REACTIONS_PRELOAD_SSR_FF } from '@confluence/reactions/entry-points/constants';
import { preloadPageReactions } from '@confluence/reactions/entry-points/preloadPageReactions';
import { preloadContent } from '@confluence/content-body/entry-points/preloadContent';
import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { preloadContentHeader } from '@confluence/content-header/entry-points/preloadContentHeader';
import { preloadLegacyBridge } from '@confluence/view-page/entry-points/preloadLegacyBridge';
import { preloadEditorData } from '@confluence/load-edit-page/entry-points/preloadEditorData';
import { preloadFocusedInlineComment } from '@confluence/inline-comments-queries/entry-points/preloadFocusedInlineComment';
import { INLINE_HIGHLIGHTS_BEST_EFFORT_TASK_NAME } from '@confluence/inline-highlights-query/entry-points/constants';
import { preloadInlineHighlights } from '@confluence/inline-highlights-query/entry-points/preloadInlineHighlights';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';
import { preloadMacrosSSR } from '@confluence/fabric-extension-handlers/entry-points/preloadMacrosSSR';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { bestEffortTask, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { getSSRFeatureFlag, getSSRRenderInputs } from '@confluence/ssr-utilities';
import { EDIT_BLOG_V2, EDIT_PAGE_V2 } from '@confluence/named-routes';
import { preloadPageStatusQuery } from '@confluence/view-page-common/entry-points/preloadPageStatusQuery';
import { preloadEndOfPageRecommendation } from '@confluence/page-recommendations/entry-points/preloadEndOfPageRecommendation';

import {
	getIsEmbeddedConfluence,
	hasEmbeddedAllowedFeatures,
	getUsername,
	shouldPreloadNavigationForTransition,
} from './matchHelpers';
import { matchBlogPage } from './matchRoutes';

/*
 * preloadViewPageRoute preload queries are currently arranged in order of latency, with slowest
 * queries pushed to the tasks array first.
 */
export const preloadViewPageRoute = async (
	match: RouteMatch,
	url: string,
	isTransition: boolean = false,
	previousMatch: RouteMatch | null = null,
) => {
	const { featureFlags, isLicensed, userId, edition, cloudId } = await getPreloaderFnContext();
	const { isLivePage } = getSSRRenderInputs();
	const tasks: Promise<any>[] = [];
	const { spaceKey, contentId } = match.params;
	const username = getUsername(match);
	const isEmbeddedConfluence = getIsEmbeddedConfluence(match);
	const isBlog = Boolean(matchBlogPage(url));
	const isInitialLoad = !isTransition;
	const shouldPreloadNavigation =
		!isEmbeddedConfluence && (isInitialLoad || shouldPreloadNavigationForTransition(previousMatch));

	// Don't preload queries that will be updated by editor if previous route is edit_page
	const isPrevEditRoute =
		previousMatch?.name === EDIT_PAGE_V2.name || previousMatch?.name === EDIT_BLOG_V2.name;

	if (isPrevEditRoute && isTransition) {
		// Preload for edit -> view transition
		return preloadContentHeader({
			spaceKey,
			contentId,
			bestEffortTask,
			isLicensed,
			userId,
			username,
			edition,
			isPrevEditRoute,
			useNewContentTopper: Boolean(
				featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
			),
			isSpaceAliasFFEnabled: Boolean(featureFlags['confluence.frontend.space.alias']),
		});
	}

	// 1. Content + Content Header
	tasks.push(
		preloadMacrosSSR({
			contentId,
			featureFlags,
			useMultipleMacrosQuery: true,
		}),
		preloadContent({
			contentId,
			isBlog,
			spaceKey,
			featureFlags,
			cloudId,
		}).then(({ result, hasErrors }): Promise<any> | void => {
			// In case of errors on preloading content the content data may not make it to the
			// Apollo cache. To ensure we can try handling errors or missing cintent data cases
			// on SSR we preload content prerequisites here.
			// We don't want to preload content dependencies as in case of errors and/or missing
			// content this may result in unnecessary queries.
			if (hasErrors) {
				return preloadContentPrerequisites(
					contentId,
					spaceKey,
					Boolean(featureFlags['confluence.frontend.space.alias']),
				);
			} else if (process.env.REACT_SSR) {
				return preloadMacrosSSR({
					contentId,
					featureFlags,
					useMultipleMacrosQuery: false,
					contentNodes: result?.data?.content?.nodes,
				});
			}
		}),
		preloadContentHeader({
			spaceKey,
			contentId,
			bestEffortTask,
			isLicensed,
			userId,
			username,
			edition,
			isPrevEditRoute,
			useNewContentTopper: Boolean(
				featureFlags['confluence.frontend.custom-sites.page-header-and-title'],
			),
			isSpaceAliasFFEnabled: Boolean(featureFlags['confluence.frontend.space.alias']),
		}),
		preloadPageStatusQuery(contentId),
		preloadLegacyBridge({ contentId, isBlog }),
	);

	// 2. Conditional page title
	const isCustomSitesPageTitleFFOn = featureFlags[CUSTOM_SITES_PAGE_TITLE_FF];
	if (isCustomSitesPageTitleFFOn) {
		tasks.push(preloadPageTitleContentProperties({ contentId, spaceKey }));
	}

	// 3. Conditional space details
	const shouldPreloadSpaceDetails = featureFlags['confluence.frontend.ecosystem.access.narrrowing'];
	const isSpaceAliasFFEnabled = Boolean(featureFlags['confluence.frontend.space.alias']);
	if (shouldPreloadSpaceDetails) {
		tasks.push(preloadSpaceDetail(spaceKey, isSpaceAliasFFEnabled));
	}

	// 4. Content Smart Links
	const shouldRemoveSmartlinksBestEffort = getSSRFeatureFlag(
		'confluence.ssr.remove.preload.smartlinks.best.effort',
	);

	if (shouldRemoveSmartlinksBestEffort) {
		tasks.push(preloadContentSmartLinks(contentId));
	} else {
		tasks.push(
			bestEffortTask(CONTENT_SMART_LINKS_BEST_EFFORT_TASK_NAME, () =>
				preloadContentSmartLinks(contentId),
			),
		);
	}

	// 5. Navigation
	if (shouldPreloadNavigation) {
		tasks.push(
			bestEffortTask(VIEW_SPACE_EXTERNAL_COLLABORATORS_DIALOG_BEST_EFFORT_TASK_NAME, () =>
				preloadSpaceGuestList(spaceKey),
			),
			bestEffortTask(SHORTCUTS_SMART_LINKS_BEST_EFFORT_TASK_NAME, () =>
				preloadShortcutsSmartLinks(spaceKey),
			),
			preloadSideNavigation(spaceKey, isLicensed),
			preloadSpaceViews(spaceKey, isLicensed),
		);

		const shouldDisablePageTreeBestEffort = FeatureGates.checkGate(
			'confluence_remove_page_tree_best_effort',
		);

		// 6. Page tree for blog post or pages
		if (isBlog) {
			const shouldPreloadUsingSpaceKey = getSSRFeatureFlag(
				'confluence.ssr.remove.preload.space.overview.query',
			);

			if (shouldPreloadUsingSpaceKey) {
				tasks.push(
					bestEffortTask(
						PAGE_TREE_QUERY_BEST_EFFORT_TASK_NAME,
						() =>
							preloadPageTree({
								spaceKey,
								statuses: PAGE_TREE_STATUSES,
								isSuperAdmin: false,
							}),
						shouldDisablePageTreeBestEffort,
					),
				);
			} else {
				tasks.push(
					preloadSpaceOverviewQuery(spaceKey).then(({ data }) => {
						const homepageId = data?.space?.homepage?.id;
						if (homepageId) {
							return bestEffortTask(
								PAGE_TREE_QUERY_BEST_EFFORT_TASK_NAME,
								() =>
									preloadPageTree({
										spaceKey,
										contentId: homepageId,
										statuses: PAGE_TREE_STATUSES,
										isSuperAdmin: false,
									}),
								shouldDisablePageTreeBestEffort,
							);
						}
					}),
				);
			}
		} else {
			tasks.push(
				bestEffortTask(
					PAGE_TREE_QUERY_BEST_EFFORT_TASK_NAME,
					() =>
						preloadPageTree({
							spaceKey,
							contentId,
							statuses: PAGE_TREE_STATUSES,
							isSuperAdmin: false,
						}),
					shouldDisablePageTreeBestEffort,
				),
			);
		}
	}

	// 7. Inline Comments
	if (match?.query.focusedCommentId) {
		if (!featureFlags[RENDERER_ANNOTATION_PROVIDER_SSR_FF]) {
			tasks.push(preloadFocusedInlineComment(match?.query.focusedCommentId as string, contentId));
		}
	}
	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('inline-comments', match)) {
		// when rendering an embedded confluence page, we do not want to preload inline comments if they are not requested via the allowlist features
		tasks.push(
			bestEffortTask(INLINE_HIGHLIGHTS_BEST_EFFORT_TASK_NAME, () =>
				preloadInlineHighlights(contentId),
			),
		);
		if (!featureFlags[RENDERER_ANNOTATION_PROVIDER_SSR_FF]) {
			// it's best effort preloading so if it preloads then we have the data, otherwise SPA can load it
			tasks.push(
				bestEffortTask('InlineCommentsQuery', () => preloadInlineComments({ pageId: contentId })),
			);
		}
	}

	// 8. Reactions
	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('page-reactions', match)) {
		// Preload whether the server has enabled reactions at all to show placeholder
		tasks.push(
			preloadWebPanelLocation({
				contentId,
				location: ATL_PAGE_CONTENT_FOOTER_ACTIONS,
			}),
		);
		// Preload reactions asynchronously so SSR won't wait for it
		// it's best effort preloading so if it preloads then we have the data, otherwise SPA can load it
		if (featureFlags[REACTIONS_PRELOAD_SSR_FF]) {
			tasks.push(bestEffortTask('ReactionsContentQuery', () => preloadPageReactions(contentId)));
		}
	}

	// 9. Recommendations
	if (getSSRFeatureFlag('confluence.ssr.end.of.page.recommendation')) {
		tasks.push(preloadEndOfPageRecommendation({ contentId, spaceKey }));
	}

	// 10. Stuff only needed for initial load
	if (isInitialLoad) {
		tasks.push(
			// We only refresh token when it is about to expire.
			preloadMediaToken(contentId),
			preloadCustomHeaderAndFooter(spaceKey),
			preloadUserPreferencesForSSR({
				isLicensed,
				spaceKey,
				themeState: match.query?.themeState as string,
			}),
		);
	}

	// TODO: This is a temporary solution to preload editor data for live pages
	// This is gated by FF and `isLivePage` flag and, as we know the experience
	// user is loading, later it will be used to gate the content preloading.
	// 10. Live page editor data
	if (process.env.REACT_SSR && featureFlags['confluence.frontend.editor.ssr'] && isLivePage) {
		tasks.push(
			preloadEditorData({
				userId,
				contentId,
				preloadNativeCollabData: true,
				shouldPreloadNativeEditorData: false,
				isCustomSitesPageTitleFFOn: !!isCustomSitesPageTitleFFOn,
				shouldLoadDraftFromGql: Boolean(
					featureFlags['confluence.frontend.load-document-draft-from-graphql'],
				),
				cloudId,
				isBlog,
				isTransition,
				isLivePage: true,
			}),
		);
	}

	return Promise.all(tasks);
};
