import { type ExperimentalVCDevToolsOptions } from '../../types';

/**
 * @deprecated
 * Please use UFO dev tool Chrome extension instead
 * @link https://chromewebstore.google.com/detail/ufo-dev-tool/admhmchkganpobmhjggadkihkkfmpmon
 */
export const initDevTools = () => {
	window.VCDevTools = (opts?: ExperimentalVCDevToolsOptions) =>
		import(/* webpackChunkName: "vc-dev-tools" */ './dev-tools').then(({ startVCDevTools }) => {
			// eslint-disable-next-line no-console
			console.warn(
				'%cDEPRECATION WARNING: Direct invoke of "window.VCDevTools" is deprecated. \n%cPlease use UFO dev tool Chrome extension instead: %chttps://chromewebstore.google.com/detail/ufo-dev-tool/admhmchkganpobmhjggadkihkkfmpmon',
				'color: #FF5722; font-weight: bold;',
				'color: #000000; font-weight: normal;',
				'color: #0080FF; font-weight: normal; text-decoration: underline;',
			);
			startVCDevTools?.(opts);
		});
};
