import { preloadTemplatesCollectionOnboardingState } from '@confluence/onboarding-helpers/entry-points/preloadTemplatesCollectionOnboardingState';
import { preloadUserPersonalizationQuery } from '@confluence/onboarding-helpers/entry-points/preloadUserPersonalizationQuery';
import { preloadProductsTeamAndSpaceQuery } from '@confluence/onboarding-helpers/entry-points/preloadProductsTeamAndSpaceQuery';

export const CREATE_FROM_TEMPLATE_QUERY = 'CreateFromTemplateQuery';

export const preloadCreateFromTemplate = ({ userId }: { userId: string | null }) => {
	if (!userId) {
		return Promise.resolve();
	}
	return Promise.all([
		preloadUserPersonalizationQuery(userId),
		preloadTemplatesCollectionOnboardingState(),
		preloadProductsTeamAndSpaceQuery(),
	]);
};
