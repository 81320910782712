import { parse } from 'url';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { Cookies } from '@confluence/cookies';
import { getTaskManager, LoadingPriority } from '@confluence/loadable';
import { enableGraphqlRetries } from '@confluence/graphql';
import { getBuildInfo } from '@confluence/build-info';
import { getEventFilterOptions } from '@confluence/filtered-awc';
import {
	getMonitoringClient,
	enableNewSentry,
	enableSendErrorsToTelemetryService,
} from '@confluence/monitoring';
import { initializeAnalyticsClient } from '@confluence/analytics-web-client';
import { initMetalClient } from '@confluence/metal-client';
import { patchLocalStorage } from '@confluence/storage-manager';
import { enableAnalyticsDebugger } from '@confluence/analytics-debugger/entry-points/AnalyticsDebugger';

import { setFrontendBranch } from './set-frontend-branch';
import { setBrowserMetricsUniqueIdentifier } from './set-browser-metrics-unique-identifier';

export function initialization(sessionData) {
	const { featureFlags, cloudId, environment, shard, activationId, edition } = sessionData;

	patchLocalStorage();

	if (featureFlags['confluence.frontend.cookie.based.branch.deployment']) {
		setFrontendBranch();
	}

	if (featureFlags['confluence.frontend.bm.unique-identifier']) {
		setBrowserMetricsUniqueIdentifier();
	}

	enableGraphqlRetries();

	// enable new sentry integration
	if (featureFlags['confluence.frontend.new-sentry-client']) {
		Error.stackTraceLimit = 50;
		// https://app.launchdarkly.com/confluence/production/features/confluence.frontend.new-sentry-client/targeting
		enableNewSentry();
	}

	if (featureFlags['confluence.frontend.monitoring.send-errors-to-telemetry-service']) {
		enableSendErrorsToTelemetryService();
	}

	const isEmbeddedConfluence = isEmbeddedConfluence_DO_NOT_USE();
	const product = isEmbeddedConfluence ? 'embeddedConfluence' : 'confluence';
	//Grabbing parentProduct and setting it to subproduct
	const subproduct = isEmbeddedConfluence
		? parse(location.search, true).query.parentProduct
		: undefined;

	// Monitoring context initialization
	getMonitoringClient().updateContext({
		tenantId: cloudId,
		shard,
		product,
		edition,
	});

	// initialize metal client
	initMetalClient({
		environment: environment || 'DEVELOPMENT',
		synthetic: Boolean(featureFlags['confluence.frontend.pollinator.synthetic.check.tenants']),
	});

	// Before initializing the Analytics Web Client (AWC), set the
	// `atlassian_analytics_debug` cookie to indicate that CC Scale bot users (load testing)
	// can use `window.testAnalytics` object to detect whether a page is loaded
	// (AWC saves all the events that are fired under `window.testAnalytics`)
	const analyticsDebuggingEnabled = featureFlags['confluence.frontend.atlassian-analytics-debug'];
	if (analyticsDebuggingEnabled) {
		Cookies.set('atlassian_analytics_debug', true);
	}

	// GASv3 analytics client
	// ActivationId: sessionData.activationId needs to be added after https://experimentation-platform.atlassian.net/browse/MEP-2924
	initializeAnalyticsClient({
		cloudId,
		activationId,
		userId: sessionData.userIdForAnalytics,
		locale: sessionData.locale,
		environment: featureFlags['confluence.frontend.pollinator.synthetic.check.tenants']
			? 'DEVELOPMENT'
			: environment,
		product,
		subproduct,
		eventFilterOptions: getEventFilterOptions(featureFlags),
		callback: analyticsDebuggingEnabled ? enableAnalyticsDebugger : undefined,
	});

	// Please put stuff that can be delayed here
	getTaskManager().push({
		id: 'BackgroundInitialization',
		priority: LoadingPriority.BACKGROUND,
		task: () => {
			// Global function available for support engineer to get all environmental data for troubleshooting.
			window.__CONFLUENCE_SUPPORT = () => {
				const supportData = {
					version: getBuildInfo().FRONTEND_VERSION,
					tinymce: window.tinymce
						? `${window.tinymce.majorVersion}.${window.tinymce.minorVersion}`
						: undefined,
					activeEditor: window.tinymce && window.tinymce.activeEditor ? true : undefined,
				};
				return JSON.stringify(supportData);
			};

			// Register service worker
			import(
				/* webpackChunkName: "loadable-service-workerclient" */ './service-worker/client'
			).then(({ unregister: unregisterServiceWorker }) => {
				unregisterServiceWorker();
			});

			// Message queue for sending GASv3 event from legacy pages
			import(/* webpackChunkName: "loadable-legacy-message-queue" */ './legacy-message-queue').then(
				({ initializeLegacyMessageQueue }) => {
					initializeLegacyMessageQueue();
				},
			);

			// https://developer.mozilla.org/en-US/docs/Glossary/Domain_sharding
			// We roughly has 8% of users still on http/1.1 https://redash.data.internal.atlassian.com/queries/126773/source#242723
			// Http/1.1 is 2x slower than http/2 https://redash.data.internal.atlassian.com/queries/126772/source#242719
			import(/* webpackChunkName: "loadable-domain-sharding" */ './domain-sharding').then(
				({ initializeFlag }) => initializeFlag(),
			);

			if (process.env.PACKAGE_STATS) {
				import(
					/* webpackChunkName: "loadable-confluenceperformanceentry-pointspackageStats" */ '@confluence/performance/entry-points/packageStats'
				).then((packageReports) => {
					window.__poReports = packageReports;
				});
			}
		},
	});
}
