import { createStore, createSubscriber, createContainer, createStateHook } from 'react-sweet-state';
import type { Action } from 'react-sweet-state';

import { getApolloClient } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';

import type { MacrosQueryTypes } from '.';

import { MacrosQuery } from './Macros/MacrosQuery.graphql';
import { getMultiMacroQueryBlocklistFF, getMultiMacrosQueryFF } from './useMultiMacroQuery';

type StoreState = {
	contentId?: string;
	featureFlags?: Record<string, any>;
	revision?: string;
	complete: boolean;
	data: any;
	cursor?: string;
	macroData: Record<string, any>; // macroId -> data
	error?: boolean;
};

type ContainerParams = {
	contentId: string;
	featureFlags: Record<string, any>;
	cursor?: string;
	revision?: string;
};

const DEFAULT_PRELOAD_PAGE_SIZE = 8;
const DEFAULT_PAGE_SIZE = 10;

const actions = {
	queryMacros:
		({ contentId, featureFlags, cursor, revision }): Action<StoreState> =>
		async ({ getState, setState, dispatch }) => {
			if (!getMultiMacrosQueryFF(featureFlags)) {
				return;
			}

			// avoid querying if already in progress
			if (
				getState().contentId === contentId &&
				getState().revision === revision &&
				getState().cursor === cursor
			) {
				return;
			}

			setState({
				contentId,
				revision,
				cursor,
				complete: false,
			});

			const queryOptions = {
				query: MacrosQuery,
				variables: {
					contentId,
					blocklist: getMultiMacroQueryBlocklistFF(featureFlags),
					first: !cursor ? DEFAULT_PRELOAD_PAGE_SIZE : DEFAULT_PAGE_SIZE,
					after: cursor,
				},
			};

			let data;
			if (process.env.REACT_SSR && !getState().cursor) {
				data = getApolloClient().readQuery<MacrosQueryTypes>(queryOptions);
			} else {
				const result = await getApolloClient().query<MacrosQueryTypes>(queryOptions);

				if (result.errors) {
					getMonitoringClient().submitError(result.errors, {
						attribution: 'backbone',
					});

					setState({
						error: true,
					});
				}

				data = result.data;
			}

			const { nodes, pageInfo } = data.macros;
			const macros = nodes?.reduce((nodeMap, node) => {
				if (node) {
					const key = getMacrosCacheKey(node.contentId || '', node.macroId || '');
					nodeMap[key] = node?.renderedMacro;
				}
				return nodeMap;
			}, {});

			setState({
				macroData: {
					...getState().macroData,
					...macros,
				},
			});

			if (pageInfo.hasNextPage) {
				void dispatch(
					actions.queryMacros({ contentId, featureFlags, cursor: pageInfo.endCursor, revision }),
				);
			} else {
				setState({
					complete: true,
				});
			}
		},
};

const store = createStore<StoreState, typeof actions>({
	initialState: { complete: false, data: {}, macroData: {} },
	actions,
	name: 'MultiMacroQueryStore',
});

export const MultiMacroQueryContainer = createContainer(store, {
	onInit:
		() =>
		({ dispatch }, { contentId, featureFlags, cursor, revision }: ContainerParams) => {
			void dispatch(actions.queryMacros({ contentId, featureFlags, cursor, revision }));
		},
	onUpdate:
		() =>
		({ dispatch }, { contentId, featureFlags, cursor, revision }: ContainerParams) => {
			void dispatch(actions.queryMacros({ contentId, featureFlags, cursor, revision }));
		},
});

function getMacrosCacheKey(contentId: string, macroId: string) {
	return `${contentId}::${macroId ?? 0}`;
}

function multiMacroQuerySelector(
	state: StoreState,
	params: { macroId: string; contentId: string },
) {
	const key = getMacrosCacheKey(params.contentId, params.macroId);
	const macroData = state.macroData?.[key];

	return {
		loading: !Boolean(macroData) && !state.complete,
		error: !Boolean(macroData) && state.error,
		renderedMacro: macroData || null,
		complete: state.complete,
	};
}

export const useMultiMacroQuery = createStateHook(store, {
	selector: multiMacroQuerySelector,
});

export const MultiMacroQuerySubscriber = createSubscriber(store, {
	selector: multiMacroQuerySelector,
});
