import { MarkdownSerializerState as PMMarkdownSerializerState } from '@atlaskit/editor-prosemirror/markdown';
import { Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import type { Fragment, Mark } from '@atlaskit/editor-prosemirror/model';

import type { FeatureToggles } from '../../feature-keys';

import tableNodes from './tableSerializer';
import { getOrderFromMaybeOrderedListNode } from './utils/list';
import { escapeMarkdown, stringRepeat } from './utils/utils';

export type NodeSerializer = (
	state: MarkdownSerializerState,
	node: PMNode,
	parent?: PMNode | Fragment,
	index?: number,
	options?: Record<string, unknown>,
) => void;

export type NodeSerializerSpec = {
	[key: string]: NodeSerializer;
};

/**
 * Look for series of backticks in a string, find length of the longest one, then
 * generate a backtick chain of a length longer by one. This is the only proven way
 * to escape backticks inside code block and inline code (for python-markdown)
 */
export const generateOuterBacktickChain: (text: string, minLength?: number) => string = (() => {
	function getMaxLength(text: string): number {
		const matches: RegExpMatchArray | Array<string> = text.match(/`+/g) || [];
		return matches.reduce(
			(prev: string, val: string) => (val.length > prev.length ? val : prev),
			'',
		).length;
	}

	return function (text: string, minLength = 1): string {
		const length = Math.max(minLength, getMaxLength(text) + 1);
		return stringRepeat('`', length);
	};
})();

/**
 * This is a map where the key is the node type name and the value is the attributes of the node.
 *
 * The nodes are rendered with an id attribute so that we can reference them in the markdown document.
 *
 * When converting back from markdown to prosemirror, we use the id to reapply the attributes to the node.
 */

export type IDMap = {
	[key: string]: {
		/**
		 * Node type name
		 */
		type: string;
		/**
		 * Node attributes
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		attributes: any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		marks?: any;
	};
};

export type FallbackState = {
	[nodeTypeName: string]: {
		[errorMessage: string]: number;
	};
};

export class MarkdownSerializerState extends PMMarkdownSerializerState {
	// Typing this as a string -- to override the PMMarkdownSerializerState type of any
	// In the confluence build -- this results in the out property being set to undefined in the constructor
	// whereas in the dev server this is stripped when the code is transpiled.
	// When it is used in transpilation -- "undefined" is added to the start of the generated text
	// out: string;
	idMap: IDMap = {};
	idCounter = 0;

	nodes: NodeSerializerSpec;
	featureToggles: FeatureToggles = {};
	mentionMap: { [id: string]: string | undefined } = {};

	/**
	 * This is used to track the number of times a node has failed to render
	 * This is useful for debugging purposes
	 * - the key is the node type name
	 * - the value is an object with
	 *   - the error message as the key
	 *   - and the number of times it has failed to render with that message as the value.
	 */
	fallbackState: FallbackState = {};

	constructor(featureToggles?: FeatureToggles, mentionMap?: { [id: string]: string | undefined }) {
		// ignoring as generated types for node serializer is inaccurate (typings/prosemirror-markdown.d.ts)
		// parent and index in node serializers should be optional

		// if markdownPlusMarks feature toggle is enabled, we don't want to pass marks to the super constructor
		// to prevent markdown mark syntax from being rendered
		// @ts-ignore-next-line
		super(nodes, featureToggles?.markdownPlusMarks ? {} : marks, {});
		// ignoring as generated types for node serializer is inaccurate (typings/prosemirror-markdown.d.ts)
		// parent and index in node serializers should be optional
		this.nodes = nodes as unknown as NodeSerializerSpec;
		this.featureToggles = featureToggles || {};
		this.mentionMap = mentionMap || {};
	}

	context = { insideTable: false };

	updateFallbackState(error: unknown, child: PMNode) {
		if (error instanceof Error) {
			const childTypeName = child.type.name;
			const errorMessage = error.message;
			const childFallbackState = this.fallbackState?.[childTypeName];

			Object.assign(this.fallbackState, {
				[childTypeName]: {
					...childFallbackState,
					[errorMessage]: (childFallbackState?.[errorMessage] || 0) + 1,
				},
			});
		} else {
			throw error;
		}
	}

	/**
	 * This method overide adds support for "options"
	 * options is an object that can be used to pass additional information to the node serializer
	 *
	 * This is useful when a grandparent node has information that needs to be passed to a grandchild node
	 *
	 * For example, a table node has information about whether or not the table has a header row
	 * This information needs to be passed to the tableRow and tableCell node serializers so that it can render the correct markdown
	 * @see node_modules/prosemirror-markdown/src/to_markdown.js
	 * @see MarkdownSerializerState.render()
	 */
	render(
		node: PMNode,
		parent: PMNode | Fragment,
		index: number,
		options?: Record<string, unknown>,
	): void {
		if (!this.nodes[node.type.name]) {
			throw new Error(`Token type \`${node.type.name}\` not supported by Markdown renderer`);
		}

		if (options?.skipFallback) {
			this.nodes[node.type.name](this, node, parent, index, options);
		} else {
			try {
				this.nodes[node.type.name](this, node, parent, index, options);
			} catch (error) {
				this.updateFallbackState(error, node);
				return this.nodes.fallback(this, node);
			}
		}
	}

	renderContent(parent: PMNode | Fragment): void {
		parent.forEach((child: PMNode, _offset: number, index: number) => {
			if (
				// If child is an empty Textblock we need to insert a zwnj-character in order to preserve that line in markdown
				child.isTextblock &&
				!child.textContent &&
				// If child is a Codeblock we need to handle this separately as we want to preserve empty code blocks
				!(child.type.name === 'codeBlock') &&
				!(child.content && child.content.size > 0)
			) {
				return this.nodes.empty_line(this, child);
			}
			try {
				return this.render(child, parent, index);
			} catch (error) {
				this.updateFallbackState(error, child);
				return this.nodes.fallback(this, child);
			}
		});
	}

	/**
	 * This method override will properly escape backticks in text nodes with "code" mark enabled.
	 * Bitbucket uses python-markdown which does not honor escaped backtick escape sequences \`
	 * inside a backtick fence.
	 *
	 * @see node_modules/prosemirror-markdown/src/to_markdown.js
	 * @see MarkdownSerializerState.renderInline()
	 */
	renderInline(parent: PMNode | Fragment, options?: any): void {
		const active: Mark[] = [];
		let trailing = '';

		const progress = (node: PMNode | null, _?: any, index?: number) => {
			let marks = node ? node.marks.filter((mark) => this.marks[mark.type.name as any]) : [];

			let leading = trailing;
			trailing = '';
			// If whitespace has to be expelled from the node, adjust
			// leading and trailing accordingly.
			if (
				node &&
				node.isText &&
				marks.some((mark) => {
					let info = this.marks[mark.type.name as any];
					return info && (info as any).expelEnclosingWhitespace;
				})
			) {
				let [, lead, inner, trail] = /^(\s*)(.*?)(\s*)$/m.exec(node.text!)!;
				leading += lead;
				trailing = trail;
				if (lead || trail) {
					node = inner ? (node as any).withText(inner) : null;
					if (!node) {
						marks = active;
					}
				}
			}

			const code =
				marks.length && marks[marks.length - 1].type.name === 'code' && marks[marks.length - 1];
			const len = marks.length - (code ? 1 : 0);

			// Try to reorder 'mixable' marks, such as em and strong, which
			// in Markdown may be opened and closed in different order, so
			// that order of the marks for the token matches the order in
			// active.
			outer: for (let i = 0; i < len; i++) {
				const mark: Mark = marks[i];
				if (!(this.marks[mark.type.name as any] as any).mixable) {
					break;
				}
				for (let j = 0; j < active.length; j++) {
					const other = active[j];
					if (!(this.marks[other.type.name as any] as any).mixable) {
						break;
					}
					if (mark.eq(other)) {
						if (i > j) {
							marks = marks
								.slice(0, j)
								.concat(mark)
								.concat(marks.slice(j, i))
								.concat(marks.slice(i + 1, len));
						} else if (j > i) {
							marks = marks
								.slice(0, i)
								.concat(marks.slice(i + 1, j))
								.concat(mark)
								.concat(marks.slice(j, len));
						}
						continue outer;
					}
				}
			}

			// Find the prefix of the mark set that didn't change
			let keep = 0;
			while (keep < Math.min(active.length, len) && marks[keep].eq(active[keep])) {
				++keep;
			}

			// Close the marks that need to be closed
			while (keep < active.length) {
				this.text(this.markString(active.pop()!, false), false);
			}

			// Output any previously expelled trailing whitespace outside the marks
			if (leading) {
				this.text(leading);
			}

			// Open the marks that need to be opened
			while (active.length < len) {
				const add = marks[active.length];
				active.push(add);
				this.text(this.markString(add, true), false);
			}

			if (node) {
				if (!code || !node.isText) {
					this.render(node, parent, index!);
				} else if (node.text) {
					// Generate valid monospace, fenced with series of backticks longer that backtick series inside it.
					let text = node.text;
					const backticks = generateOuterBacktickChain(node.text as string, 1);

					// Make sure there is a space between fences, otherwise python-markdown renderer will get confused
					if (text.match(/^`/)) {
						text = ' ' + text;
					}

					if (text.match(/`$/)) {
						text += ' ';
					}

					this.text(backticks + text + backticks, false);
				}
			}
		};

		parent.forEach((child: PMNode, _offset: number, index: number) => {
			if (options?.skipFallback) {
				progress(child, parent, index);
			} else {
				try {
					progress(child, parent, index);
				} catch (error) {
					this.updateFallbackState(error as Error, child);
				}
			}
		});

		progress(null);
	}

	addBlockMarks(state: MarkdownSerializerState, node: PMNode, renderFunction: () => void): void {
		const closingTags: string[] = [];
		// This is temporary, only adding the marks that we support, once we get full support this will be removed
		const markFilters = ['alignment', 'breakout', 'border'];
		const marks = node.marks.filter((mark) => markFilters.includes(mark.type.name));

		if (marks.length === 0) {
			renderFunction();
			return;
		}

		marks.forEach((mark) => {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: mark.type.name,
				attributes: mark.attrs,
			};
			state.write(`<custom data-type="${mark.type.name}_open" data-id="${id}" />\n\n`);
			closingTags.unshift(`<custom data-type="${mark.type.name}_close" />\n\n`);
		});
		renderFunction();
		state.write(closingTags.join(''));
	}
}

const editorNodes = {
	blockquote(state: MarkdownSerializerState, node: PMNode) {
		state.wrapBlock('> ', undefined, node, () => state.renderContent(node));
	},
	codeBlock(state: MarkdownSerializerState, node: PMNode) {
		const backticks = generateOuterBacktickChain(node.textContent, 3);
		state.write(backticks + (node.attrs.language || '') + '\n');
		state.text(node.textContent ? node.textContent : '\u200c', false);
		state.ensureNewLine();
		state.write(backticks);
		state.closeBlock(node);
	},
	heading(state: MarkdownSerializerState, node: PMNode) {
		state.write(state.repeat('#', node.attrs.level) + ' ');
		state.renderInline(node);
		state.closeBlock(node);
	},
	rule(state: MarkdownSerializerState, node: PMNode) {
		state.write(node.attrs.markup || '---');
		state.closeBlock(node);
	},
	bulletList(state: MarkdownSerializerState, node: PMNode) {
		for (let i = 0; i < node.childCount; i++) {
			const child = node.child(i);
			state.render(child, node, i);
		}
	},
	orderedList(state: MarkdownSerializerState, node: PMNode) {
		for (let i = 0; i < node.childCount; i++) {
			const child = node.child(i);
			state.render(child, node, i);
		}
	},
	listItem(state: MarkdownSerializerState, node: PMNode, parent: PMNode, index: number) {
		const order = getOrderFromMaybeOrderedListNode(parent);
		const delimiter = parent.type.name === 'bulletList' ? '* ' : `${order + index}. `;
		for (let i = 0; i < node.childCount; i++) {
			const child = node.child(i);
			// if  at second child or more of list item, add a newline
			if (i > 0) {
				state.write('\n');
			}
			// if at first child of list item, add delimiter (e.g "1.").
			// if at second child or more of list item, only add spacing (not delimiter)
			if (i === 0) {
				state.wrapBlock('  ', delimiter, node, () => state.render(child, parent, i));
			} else {
				state.wrapBlock('    ', undefined, node, () => state.render(child, parent, i));
			}
			if (child.type.name === 'paragraph' && i > 0) {
				state.write('\n');
			}
			state.flushClose(1);
		}
		// if we're at the final list item, add a final closing newline
		if (index === parent.childCount - 1) {
			state.write('\n');
		}
	},
	taskList(state: MarkdownSerializerState, node: PMNode) {
		for (let i = 0; i < node.childCount; i++) {
			const child = node.child(i);
			state.render(child, node, i);
			state.write('\n');
		}
		state.write('\n');
	},
	taskItem(state: MarkdownSerializerState, node: PMNode, parent: PMNode) {
		const taskItemState = node.attrs.state;
		state.write(taskItemState === 'TODO' ? '- [ ] ' : '- [x] ');
		state.renderInline(node);
	},
	paragraph(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusMarks) {
			state.addBlockMarks(state, node, () => {
				state.renderInline(node);
				if (node.marks.length > 0) {
					state.write('\n\n');
				} else {
					state.closeBlock(node);
				}
			});
		} else {
			state.renderInline(node);
			state.closeBlock(node);
		}
	},
	mediaGroup(state: MarkdownSerializerState, node: PMNode) {
		for (let i = 0; i < node.childCount; i++) {
			const child = node.child(i);
			state.render(child, node, i);
		}
	},
	mediaInline(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusMedia) {
			state.addBlockMarks(state, node, () => {
				const id = `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'mediaInline',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="mediaInline" data-id="${id}"></custom>`);
			});
		}
	},
	mediaSingle(state: MarkdownSerializerState, node: PMNode, parent: PMNode | Fragment) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusMedia) {
			state.addBlockMarks(state, node, () => {
				const id = `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'mediaSingle',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="media_single_open" data-id="${id}" />\n\n`);

				node.content.forEach((child, i) => {
					try {
						state.render(child, node, i, { skipFallback: true });
					} catch (error) {
						state.updateFallbackState(error, node);
						state.nodes.fallback(state, node);
					}
				});

				state.write(`<custom data-type="media_single_close" />\n\n`);
			});
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}

			/**
			 * Currently, if the parent is a node we assume that mediaSingle is nested (e.g. inside a
			 * table or list). In these cases we only output \n as there is often already new lines added
			 * by the parent node.
			 * Otherwise, if mediaSingle is not nested we add an extra new line.
			 */
			if (parent instanceof PMNode) {
				state.write('\n');
			} else {
				state.write('\n\n');
			}
		}
	},
	caption(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusMedia) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'caption',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="caption_open" data-id="${id}" />\n\n`);

			try {
				state.renderInline(node, { skipFallback: true });
			} catch (error) {
				state.updateFallbackState(error, node);
				state.nodes.fallback(state, node);
			}

			// captions do not contain paragraphs, so we need to add a newline after the content
			state.write(`\n\n<custom data-type="caption_close" />\n\n`);
		} else {
			state.write(node.textContent);
		}
	},
	media(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusMedia) {
			state.addBlockMarks(state, node, () => {
				const id = `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'media',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="media" data-id="${id}" />\n\n`);
			});
		} else {
			state.write('![](' + node.attrs.url + ')');
		}
	},
	image(state: MarkdownSerializerState, node: PMNode) {
		// Note: the 'title' is not escaped in this flavor of markdown.
		state.write(
			'![' +
				escapeMarkdown(node.attrs.alt) +
				'](' +
				node.attrs.src +
				(node.attrs.title ? ` '${escapeMarkdown(node.attrs.title)}'` : '') +
				')',
		);
	},
	hardBreak(state: MarkdownSerializerState) {
		state.write('  \n');
	},
	text(state: MarkdownSerializerState, node: PMNode, parent: PMNode | Fragment, index: number) {
		const previousNode = index === 0 ? null : parent.child(index - 1);
		let text = node.textContent;

		// BB converts 4 spaces at the beginning of the line to code block
		// that's why we escape 4 spaces with zero-width-non-joiner
		const fourSpaces = '    ';
		if (!previousNode && /^\s{4}/.test(node.textContent)) {
			text = node.textContent.replace(fourSpaces, '\u200c' + fourSpaces);
		}

		if (
			state.featureToggles.markdownPlus &&
			state.featureToggles.markdownPlusMarks &&
			node.marks.length
		) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'text',
				attributes: node.attrs,
				marks: node.marks,
			};

			text = `<custom data-type="text" data-id="${id}">${text}</custom>`;
		}

		const lines = text.split('\n');
		for (let i = 0; i < lines.length; i++) {
			const startOfLine = state.atBlank() || !!state.closed;
			state.write();
			state.out += escapeMarkdown(lines[i], startOfLine, state.context.insideTable);
			if (i !== lines.length - 1) {
				if (lines[i] && lines[i].length && lines[i + 1] && lines[i + 1].length) {
					state.out += '  ';
				}
				state.out += '\n';
			}
		}
	},
	empty_line(state: MarkdownSerializerState, node: PMNode) {
		state.write('\u200c'); // zero-width-non-joiner
		state.closeBlock(node);
	},
	placeholder(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusPlaceholder) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'placeholder',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="placeholder" data-id="${id}">${node.attrs.text}</custom>`);
		}
	},
	mention(state: MarkdownSerializerState, node: PMNode, parent: PMNode | Fragment, index: number) {
		const isLastNode = parent.childCount === index + 1;
		let delimiter = '';
		if (!isLastNode) {
			const nextNode = parent.child(index + 1);
			const nextNodeHasLeadingSpace = nextNode.textContent.indexOf(' ') === 0;
			delimiter = nextNodeHasLeadingSpace ? '' : ' ';
		}
		// Get the name from the map
		const name = state.mentionMap[node.attrs.id] || '';

		if (state.featureToggles.markdownPlus) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'mention',
				// Update the attributes to include resolved name
				attributes: {
					...node.attrs,
					text: `@${name}`,
				},
			};
			state.write(`<custom data-type="mention" data-id="${id}">@${name}</custom>`);
		} else {
			state.write(`${name}${delimiter}`);
		}
	},
	date(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'date',
				attributes: node.attrs,
			};
			state.write(
				// TODO -- pass intl through
				`<custom data-type="date" data-id="${id}">${new Intl.DateTimeFormat().format(
					Number(node.attrs.timestamp),
				)}</custom>`,
			);
		} else {
			state.write(new Intl.DateTimeFormat().format(Number(node.attrs.timestamp)));
		}
	},
	emoji(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'emoji',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="emoji" data-id="${id}">${node.attrs.shortName}</custom>`);
		} else {
			state.write(node.attrs.shortName);
		}
	},
	inlineCard(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'inlineCard',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="smartlink" data-id="${id}">${node.attrs.url}</custom>`);
		} else {
			state.write(`[${node.attrs.url}](${node.attrs.url})`);
		}
	},
	status(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'status',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="status" data-id="${id}">${node.attrs.text}</custom>`);
		} else {
			// this will trigger the fallback conversion (text)
			throw new Error('Token type `status` not supported by Markdown renderer');
		}
	},
	layoutSection(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusLayouts) {
			state.addBlockMarks(state, node, () => {
				const id = `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'layoutSection',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="layoutSection_open" data-id="${id}" />\n\n`);
				for (let i = 0; i < node.childCount; i++) {
					const child = node.child(i);
					try {
						state.render(child, node, i, { skipFallback: true });
					} catch (error) {
						state.updateFallbackState(error, node);
						state.nodes.fallback(state, node);
					}
				}
				state.write(`<custom data-type="layoutSection_close" />\n\n`);
			});
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
		}
	},
	layoutColumn(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusLayouts) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'layoutColumn',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="layoutColumn_open" data-id="${id}" />\n\n`);
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				try {
					state.render(child, node, i, { skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			}
			state.write(`<custom data-type="layoutColumn_close" />\n\n`);
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
		}
	},
	expand(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusExpands) {
			state.addBlockMarks(state, node, () => {
				const id = `id-${state.idCounter++}`;
				state.idMap[id] = {
					type: 'expand',
					attributes: node.attrs,
				};
				state.write(`<custom data-type="expand_open" data-id="${id}" />\n\n`);
				for (let i = 0; i < node.childCount; i++) {
					const child = node.child(i);
					try {
						state.render(child, node, i, { skipFallback: true });
					} catch (error) {
						state.updateFallbackState(error, node);
						state.nodes.fallback(state, node);
					}
				}
				state.write(`<custom data-type="expand_close" />\n\n`);
			});
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
		}
	},
	panel(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusPanels) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'panel',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="panel_open" data-id="${id}" />\n\n`);
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				try {
					state.render(child, node, i, { skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			}
			state.write(`<custom data-type="panel_close" />\n\n`);
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
		}
	},
	nestedExpand(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusNestedExpands) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'nestedExpand',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="nestedExpand_open" data-id="${id}" />\n\n`);
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				try {
					state.render(child, node, i, { skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			}
			state.write(`<custom data-type="nestedExpand_close" />\n\n`);
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
		}
	},
	blockCard(state: MarkdownSerializerState, node: PMNode) {
		state.write(`[${node.attrs.url}](${node.attrs.url})`);
	},
	embedCard(state: MarkdownSerializerState, node: PMNode) {
		state.write(`[${node.attrs.url}](${node.attrs.url})`);
	},
	decisionList(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusDecisions) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'decisionList',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="decisionList_open" data-id="${id}" />\n\n`);
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
			state.write(`<custom data-type="decisionList_close" />\n\n`);
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
			state.write(`\n\n`);
		}
	},
	decisionItem(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusDecisions) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'decisionItem',
				attributes: node.attrs,
			};

			state.write(`<custom data-type="decisionItem_open" data-id="${id}" />\n\n`);
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				try {
					state.render(child, node, i, { skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			}
			state.write(`\n\n<custom data-type="decisionItem_close" />\n\n`);
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
		}
	},
	extension(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusExtensions) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'extension',
				attributes: node.attrs,
			};

			state.write(`<custom data-type="extension" data-id="${id}" />\n\n`);
		}
	},
	inlineExtension(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusExtensions) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'inlineExtension',
				attributes: node.attrs,
			};

			state.write(`<custom data-type="inlineExtension" data-id="${id}"></custom>`);
		}
	},
	bodiedExtension(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusExtensions) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'bodiedExtension',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="bodiedExtension_open" data-id="${id}" />\n\n`);
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				try {
					state.render(child, node, i, { skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			}
			state.write(`<custom data-type="bodiedExtension_close" />\n\n`);
		} else {
			for (let i = 0; i < node.childCount; i++) {
				const child = node.child(i);
				state.render(child, node, i);
			}
		}
	},
	fallback(state: MarkdownSerializerState, node: PMNode) {
		// If PMNode is an unhandled block node, we need to add a newline
		// after the content to prevent the next line from being rendered as
		// part of the block node.

		if (node.isBlock) {
			state.write(`${node.textContent || node.attrs.text || ''}\n\n`);
		} else {
			state.write(node.textContent || node.attrs.text || '');
		}
	},
};

export const nodes = { ...editorNodes, ...tableNodes };

export const marks = {
	em: { open: '_', close: '_', mixable: true, expelEnclosingWhitespace: true },
	strong: {
		open: '**',
		close: '**',
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	strike: {
		open: '~~',
		close: '~~',
		mixable: true,
		expelEnclosingWhitespace: true,
	},
	link: {
		open: '[',
		close(_state: MarkdownSerializerState, mark: any) {
			return '](' + mark.attrs['href'] + ')';
		},
	},
	code: { open: '`', close: '`' },
};
