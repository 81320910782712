/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessStatus {
  ANONYMOUS_ACCESS = "ANONYMOUS_ACCESS",
  EXTERNAL_COLLABORATOR_ACCESS = "EXTERNAL_COLLABORATOR_ACCESS",
  EXTERNAL_SHARE_ACCESS = "EXTERNAL_SHARE_ACCESS",
  LICENSED_ADMIN_ACCESS = "LICENSED_ADMIN_ACCESS",
  LICENSED_USE_ACCESS = "LICENSED_USE_ACCESS",
  NOT_PERMITTED = "NOT_PERMITTED",
  UNLICENSED_AUTHENTICATED_ACCESS = "UNLICENSED_AUTHENTICATED_ACCESS",
}

export enum ConfluenceEdition {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  STANDARD = "STANDARD",
}

export enum Environment {
  DEVELOPMENT = "DEVELOPMENT",
  PRODUCTION = "PRODUCTION",
  STAGING = "STAGING",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SessionDataQuery
// ====================================================

export interface SessionDataQuery_tenant_editions {
  edition: ConfluenceEdition;
}

export interface SessionDataQuery_tenant {
  shard: string;
  cloudId: string;
  environment: Environment;
  activationId: string | null;
  editions: SessionDataQuery_tenant_editions | null;
}

export interface SessionDataQuery_organization {
  orgId: string | null;
}

export interface SessionDataQuery_userForAnalytics {
  id: string | null;
}

export interface SessionDataQuery_user_confluence {
  accessStatus: AccessStatus;
  accountId: string | null;
  userKey: string | null;
  locale: string;
}

export interface SessionDataQuery_user {
  id: string | null;
  displayName: string | null;
  confluence: SessionDataQuery_user_confluence | null;
  timeZone: string | null;
}

export interface SessionDataQuery_featureFlags_nodes_explanation {
  ruleIndex: number | null;
  ruleId: string | null;
  kind: string | null;
}

export interface SessionDataQuery_featureFlags_nodes {
  id: string;
  value: string;
  explanation: SessionDataQuery_featureFlags_nodes_explanation | null;
}

export interface SessionDataQuery_featureFlags {
  nodes: (SessionDataQuery_featureFlags_nodes | null)[] | null;
}

export interface SessionDataQuery_platformFeatureFlags_nodes_explanation {
  ruleIndex: number | null;
  ruleId: string | null;
  kind: string | null;
}

export interface SessionDataQuery_platformFeatureFlags_nodes {
  id: string;
  value: string;
  explanation: SessionDataQuery_platformFeatureFlags_nodes_explanation | null;
}

export interface SessionDataQuery_platformFeatureFlags {
  nodes: (SessionDataQuery_platformFeatureFlags_nodes | null)[] | null;
}

export interface SessionDataQuery_getAIConfig {
  isEnabled: boolean;
  isRovoEnabled: boolean | null;
}

export interface SessionDataQuery {
  tenant: SessionDataQuery_tenant;
  organization: SessionDataQuery_organization | null;
  userForAnalytics: SessionDataQuery_userForAnalytics | null;
  user: SessionDataQuery_user | null;
  featureFlags: SessionDataQuery_featureFlags | null;
  platformFeatureFlags: SessionDataQuery_platformFeatureFlags | null;
  getAIConfig: SessionDataQuery_getAIConfig | null;
}
