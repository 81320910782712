import type { VFC } from 'react';
import React from 'react';

import type { AnnotationProviders } from '@atlaskit/editor-common/types';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import {
	getExperienceName,
	MacroExperienceSuccess,
	getMacroAttributesFromADFNode,
} from '@confluence/macro-tracker';
import { MustPreloadLoadableNames, mustPreloadWhenRenderingSPA } from '@confluence/loadable';
import type {
	ExtensionHandlerProps,
	RendererExtensionHandlers,
} from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';

import { convertRendererProps, getMacroId } from './utils';

export type RendererExtensionHandlerProps = ExtensionHandlerProps & {
	appearance?: string;

	// WARN: These 3 attributes below added to allow for seamless transition from server-side rendering
	// where ADFRenderer wrapper is used, to client-side rendering where classic (read: legacy) Renderer
	// is used. In the future, legacy Renderer has to be replaced with ADFRenderer to keep things uniform.
	Renderer: any;
	isLegacyRenderer: boolean;
	getExtensionHandlers?: (options: any) => RendererExtensionHandlers;

	mediaToken?: string;
	annotationProvider?: AnnotationProviders;
};

export const ExcerptExtensionHandlerComponent: VFC<RendererExtensionHandlerProps> = ({
	contentId,
	extensionKey,
	mode,
	node,
	spaceKey,
	appearance,
	showTemplateVariablesInPreview,
	showTemplateInputInPreview,
	Renderer,
	isLegacyRenderer,
	getExtensionHandlers,
	mediaToken,
	annotationProvider,
}) => {
	mustPreloadWhenRenderingSPA([MustPreloadLoadableNames.ClassicRendererLoader]);

	const hiddenMacroParamValue = node?.parameters?.macroParams?.hidden?.value;
	const isHidden = hiddenMacroParamValue === 'false' ? false : Boolean(hiddenMacroParamValue);
	const attributes = getMacroAttributesFromADFNode(node);
	const document = { type: 'doc', version: 1, content: node?.content };

	// ADFRenderer (next-based) requires extension handlers during rendering on server side,
	// for extensions like this as they use renderer internally. Handlers aren't needed with
	// legacy renderer because legacy component cooks them internally.
	const extensionHandlers =
		getExtensionHandlers && !isLegacyRenderer
			? getExtensionHandlers({
					adf: document,
					contentId,
					appearance,
					showTemplateInputInPreview,
					showTemplateVariablesInPreview,
				})
			: undefined;

	return (
		<div data-fabric-macro={getMacroId(node)}>
			{isHidden ? null : (
				<Renderer
					{...convertRendererProps(isLegacyRenderer, { contentId, document })}
					spaceKey={spaceKey}
					appearance={appearance || 'default'}
					allowStickyHeaders={false}
					extensionHandlers={extensionHandlers}
					showTemplateInputInPreview={showTemplateInputInPreview}
					showTemplateVariablesInPreview={showTemplateVariablesInPreview}
					mediaToken={mediaToken}
					annotationProvider={annotationProvider}
					allowAnnotations={!!annotationProvider}
				/>
			)}
			<MacroExperienceSuccess
				name={getExperienceName(mode, node)}
				extensionKey={extensionKey}
				mode={mode}
				attributes={attributes}
				contentId={contentId}
			/>
		</div>
	);
};

export const ExcerptExtensionHandler = (props: RendererExtensionHandlerProps) => (
	<ErrorBoundary attribution={Attribution.PAGE_EXTENSIONS}>
		<ExcerptExtensionHandlerComponent {...props} />
	</ErrorBoundary>
);
