/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import type ApolloClient from 'apollo-client';
import { type Extension } from '@atlassian/forge-ui/ui';

import { type CoreData, type ExtensionData } from '@atlassian/forge-ui-types';
import { useInvokeAdfExportExtension } from '../../utils/useInvokeAdfExportExtension';
import { ReactRenderer } from '@atlaskit/renderer';
import { type ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { RendererActionsContext } from '@atlaskit/renderer/actions';

interface AdfExportRendererProps {
	accountId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	coreData: CoreData;
	dataProviders: ProviderFactory;
	extension: Extension;
	extensionData: ExtensionData;
	extensionId: string;
	extensionTitle?: string;
}

export const AdfExportRenderer = ({
	accountId,
	apolloClient,
	contextIds,
	coreData,
	dataProviders,
	extension,
	extensionData,
	extensionId,
	extensionTitle,
}: AdfExportRendererProps) => {
	const { adfDoc, error, isLoading } = useInvokeAdfExportExtension({
		apolloClient,
		contextIds,
		extensionId,
		coreData,
	});

	if (isLoading || error || !adfDoc) {
		return null;
	}

	return (
		<RendererActionsContext>
			<ReactRenderer dataProviders={dataProviders} document={adfDoc} />
		</RendererActionsContext>
	);
};
