import { ConfluencePageAri, ConfluenceSpaceAri } from '@atlassian/ari/confluence';

import { PageUnifiedQueryV2Type, ContentUnifiedQueryType, ContentBlogUnifiedQueryType } from '.';

// Query fields mapping: https://hello.atlassian.net/wiki/spaces/CCG/pages/3308530106/ContentUnifiedQuery#Field-Mapping
export function convertToContentUnifiedQueryResult(
	data: PageUnifiedQueryV2Type | null | undefined,
): ContentUnifiedQueryType | ContentBlogUnifiedQueryType | undefined {
	if (data === null || data === undefined) {
		return undefined;
	}
	return {
		content: {
			__typename: 'PaginatedContentListWithChild',
			nodes: data?.pageV2
				? [
						{
							// @ts-ignore
							__typename: 'Content',
							id: ConfluencePageAri.parse(data.pageV2.id).pageId,
							status: data.pageV2.status || null,
							type: 'page',
							subType: data.pageV2.internalProperties?.subType || null,
							title: data.pageV2.title || null,
							space: {
								// @ts-ignore
								__typename: 'Space',
								id: data?.spaceV2ForPage?.id
									? ConfluenceSpaceAri.parse(data.spaceV2ForPage.id).spaceId
									: null,
								key: data?.spaceV2ForPage?.key || null,
								alias: data?.spaceV2ForPage?.alias || null,
							},
							version: {
								// @ts-ignore
								__typename: 'Version',
								confRev: data.pageV2.internalProperties?.confRev || null,
								number: data.pageV2.version?.number || null,
							},
							properties: {
								// @ts-ignore
								__typename: 'PaginatedJsonContentPropertyList',
								nodes:
									data?.contentPropertiesV2ForPage
										?.filter((p: any) => ['cover-picture-id-published', 'editor'].includes(p?.key))
										.map((p: any) => {
											return {
												...p,
												id: p?.key || null,
												version: {
													// @ts-ignore
													__typename: 'Version',
													number: p?.version?.number || null,
												},
												value: JSON.stringify(p?.value),
												__typename: 'JsonContentProperty',
											};
										}) || null,
							},
							metadata: {
								__typename: 'ContentMetadata',
								lastModifiedDate: data?.content?.nodes?.[0]?.metadata?.lastModifiedDate || null,
								frontend: {
									// @ts-ignore
									__typename: 'ContentMetadata_SpaFriendlyMetadataProvider_frontend',
									fabricEditorSupported:
										data?.content?.nodes?.[0]?.metadata?.frontend?.fabricEditorSupported || null,
									coverPictureWidth:
										data?.content?.nodes?.[0]?.metadata?.frontend?.coverPictureWidth || null,
								},
							},
							macroRenderedOutput: data?.content?.nodes?.[0]?.macroRenderedOutput || null,
							body: {
								__typename: 'ContentBodyPerRepresentation',
								dynamic: {
									...data.pageV2.body?.atlas_doc_format,
									__typename: 'ContentBody',
									webresource: {
										__typename: 'WebResourceDependencies',
										superbatch: {
											__typename: 'SuperBatchWebResources',
											tags: {
												// @ts-ignore
												__typename: 'WebResourceTags',
												js: '',
												css: '',
											},
										},
										tags: {
											// @ts-ignore
											__typename: 'WebResourceTags',
											js: '',
											css: '',
										},
									},
								},
							},
							appearancePublished: {
								// @ts-ignore
								__typename: 'PaginatedJsonContentPropertyList',
								nodes:
									data?.contentPropertiesV2ForPage
										?.filter((p) => p?.key === 'content-appearance-published')
										.map((p) => {
											return {
												...p,
												id: p?.key || null,
												key: p?.key || null,
												value: JSON.stringify(p?.value),
												version: {
													// @ts-ignore
													__typename: 'Version',
													number: p?.version?.number || null,
												},
												__typename: 'JsonContentProperty',
											};
										}) || null,
							},
						},
					]
				: [],
		},
		space: data?.spaceV2ForPage
			? {
					// @ts-ignore
					__typename: 'Space',
					id: ConfluenceSpaceAri.parse(data.spaceV2ForPage.id).spaceId || null,
					theme: data.spaceV2ForPage.theme || null,
					lookAndFeel: data.spaceV2ForPage.lookAndFeel || null,
					settings: data.spaceV2ForPage.settings || null,
				}
			: null,
	};
}

// Query fields mapping: https://hello.atlassian.net/wiki/spaces/CCG/pages/3308530106/ContentUnifiedQuery#Field-Mapping
export function convertToPageUnifiedQueryV2Result(
	data: ContentUnifiedQueryType | null | undefined,
	cloudId: string,
): PageUnifiedQueryV2Type | undefined {
	if (data === null || data === undefined) {
		return undefined;
	}

	const pageAri = data?.content?.nodes?.[0]?.id
		? ConfluencePageAri.create({
				siteId: cloudId,
				pageId: data.content?.nodes[0].id,
			}).toString()
		: '';

	const spaceAri = data?.space?.id
		? ConfluenceSpaceAri.create({
				siteId: cloudId,
				spaceId: data.space.id,
			}).toString()
		: '';

	return {
		pageV2: {
			__typename: 'PageV2',
			id: pageAri,
			// @ts-ignore
			status: data?.content?.nodes?.[0]?.status || '',
			title: data?.content?.nodes?.[0]?.title || '',
			version: {
				__typename: 'VersionV2',
				// @ts-ignore
				number: data?.content?.nodes?.[0]?.version?.number || null,
				createdAt: data?.content?.nodes?.[0]?.metadata?.lastModifiedDate || null,
			},
			internalProperties: {
				// @ts-ignore
				__typename: 'InternalProperties',
				subType: data?.content?.nodes?.[0]?.subType || null,
				confRev: data?.content?.nodes?.[0]?.version?.confRev || null,
			},
			body: {
				__typename: 'BodyV2',
				atlas_doc_format: {
					__typename: 'FormattedBodyV2',
					// @ts-ignore
					representation: 'atlas_doc_format',
					value: data?.content?.nodes?.[0]?.body?.dynamic?.value || '',
				},
			},
		},
		spaceV2ForPage: {
			id: spaceAri,
			key: data?.content?.nodes?.[0]?.space?.key || '',
			alias: data?.content?.nodes?.[0]?.space?.alias || '',
			lookAndFeel: data?.space?.lookAndFeel || null,
			settings: data?.space?.settings || null,
			theme: data?.space?.theme || null,
			// @ts-ignore
			__typename: 'SpacePageV2',
		},
		// @ts-ignore
		contentPropertiesV2ForPage: [
			...(data?.content?.nodes?.[0]?.properties?.nodes || []),
			...(data?.content?.nodes?.[0]?.appearancePublished?.nodes || []),
		].map((p) => {
			return {
				...p,
				value: p?.value ? JSON.parse(p.value) : null,
				version: {
					// @ts-ignore
					__typename: 'VersionV2',
					number: p?.version?.number || null,
				},
				__typename: 'ContentPropertyV2',
			};
		}),
		content: {
			__typename: 'PaginatedContentListWithChild',
			nodes: [
				{
					id: data?.content?.nodes?.[0]?.id || '',
					type: 'page',
					subType: data?.content?.nodes?.[0]?.subType || null,
					macroRenderedOutput: data?.content?.nodes?.[0]?.macroRenderedOutput || [],
					metadata: {
						lastModifiedDate: data?.content?.nodes?.[0]?.metadata?.lastModifiedDate || null,
						frontend: {
							fabricEditorSupported:
								data?.content?.nodes?.[0]?.metadata?.frontend?.fabricEditorSupported || null,
							coverPictureWidth:
								data?.content?.nodes?.[0]?.metadata?.frontend?.coverPictureWidth || null,
							// @ts-ignore
							__typename: 'ContentMetadata_SpaFriendlyMetadataProvider_frontend',
						},
						__typename: 'ContentMetadata',
					},
					__typename: 'Content',
				},
			],
		},
	};
}

export function canUsePageUnifiedQueryResult({
	pageUnifiedQueryResult,
	isMigratingContentUnifiedQuery,
	pageUnifiedQuerySkipped,
}: {
	pageUnifiedQueryResult: {
		data: PageUnifiedQueryV2Type | undefined;
		loading: boolean | null;
		error?: any;
	};
	isMigratingContentUnifiedQuery: boolean;
	pageUnifiedQuerySkipped: boolean;
}) {
	if (!isMigratingContentUnifiedQuery || pageUnifiedQuerySkipped) {
		return false;
	} else if (pageUnifiedQueryResult?.loading || pageUnifiedQueryResult?.error) {
		return true;
	} else {
		// no error and not loading
		const contentType = pageUnifiedQueryResult?.data?.content?.nodes?.[0]?.type;
		const contentSubType = pageUnifiedQueryResult?.data?.content?.nodes?.[0]?.subType;
		const fabricEditorSupported =
			pageUnifiedQueryResult?.data?.content?.nodes?.[0]?.metadata?.frontend?.fabricEditorSupported;

		if (contentType && fabricEditorSupported !== undefined) {
			// if there is contentType value and fabricEditorSupported value, only this condition can use the result
			return Boolean(
				contentType === 'page' && contentSubType !== 'embed' && fabricEditorSupported === true,
			);
		}

		// if contentType or fabricEditorSupported is emoty, it's the NOT FOUND case
		// and we can still use the result
		return true;
	}
}
