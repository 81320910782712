import type React from 'react';
import { useState, useEffect, useRef } from 'react';
import deepEqual from 'fast-deep-equal';
import type { History } from 'history';
import { iframeResizer } from 'iframe-resizer';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { getGlobalTheme, type ThemeState } from '@atlaskit/tokens';
import { createIframeBridge } from '@atlassian/bridge-core';
import {
	type ProductEnvironment,
	type ForgeUIExtensionType,
	type ForgeDoc,
} from '@atlassian/forge-ui-types';

import type { ViewContext, HTMLCustomIFrameElement } from '../iframe/types';
import { withMetricsAndAnalytics } from '../metrics';
import { reload } from './methods/reload';
import type { FlagOptions, FlagAction } from './types';

import { emit, on, type Subscription } from './methods/events';
import { captureAndReportError, useTracingContext } from '../../error-reporting';
import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { FORGE_UI_ANALYTICS_CHANNEL } from '../../analytics';
import { useMetricsContext } from '../../context';

// Type of these methods could be shared with @forge/bridge

export type NavigatePayload =
	| {
			type: 'same-tab';
			url: string;
	  }
	| {
			type: 'new-tab';
			url: string;
	  };

interface FetchProductPayload {
	restPath: string;
	product: 'jira' | 'confluence';
	fetchRequestInit: Omit<RequestInit, 'body'> & { body?: string | null };
	isMultipartFormData: boolean;
}

interface FetchProductResponse {
	body?: string;
	headers: { [key: string]: string };
	status: number;
	statusText: string;
	isAttachment?: boolean;
}

interface BridgeProvider {
	theme?: Partial<ThemeState> | null;
	surfaceColor?: string | null;
	api: {
		createHistory: () => History<any> | undefined;
		onInvoke: (payload: {
			functionKey: string;
			payload: object;
		}) => Promise<
			| { type: 'ok'; response: object }
			| { type: '3lo'; authUrl: string }
			| { type: 'err'; message: string }
		>;
		onNavigate: (payload: NavigatePayload) => Promise<void>;
		onThreeLO: (authUrl: string, retry: () => Promise<void>) => Promise<void>;
		getContext: () => Promise<ViewContext>;
		openModal: (opts: any) => void;
		refresh: () => Promise<boolean | void>;
		submit: (payload?: any) => void;
		close: (payload?: any) => void;
		fetchProduct: (payload: FetchProductPayload) => Promise<FetchProductResponse>;
		showFlag: (options: FlagOptions) => Promise<boolean | void>;
		closeFlag: (options: Pick<FlagOptions, 'id'>) => Promise<boolean | void>;
		reconcile: (options: { forgeDoc: ForgeDoc }) => Promise<boolean | void>;
		onError: (options: { error: Error }) => Promise<boolean | void>;
		changeWindowTitle: (title: string) => void;
		getFrameId: () => string | null;
	};
	customBridgeMethods?: Record<string, (...args: any[]) => any>;
	origin: string;
	onLoad?: () => void;
	isResizable?: boolean;
	height?: string;
	environment: ProductEnvironment;
	extension: ForgeUIExtensionType;
	iframeRef: React.RefObject<HTMLCustomIFrameElement>;
}

type Result = {
	loading: boolean;
	iframeProps: React.DetailedHTMLProps<
		React.IframeHTMLAttributes<HTMLIFrameElement>,
		HTMLIFrameElement
	>;
};

const mapCustomBridgeMethods = (customBridgeMethods?: Record<string, (...args: any[]) => any>) => {
	if (!customBridgeMethods) {
		return {};
	}
	return Object.keys(customBridgeMethods).reduce((acc, key) => {
		return {
			...acc,
			[key]: (payload: any) => {
				return customBridgeMethods[key](payload?.data);
			},
		};
	}, {});
};

/**
 * BridgeClientError represents an error that will reject to the user.
 * It is a "normal" error that should not be included in our error metrics/tracking.
 */
export class BridgeClientError extends Error {
	constructor(cause: string) {
		super(cause);
	}
}

export const useBridge = ({
	theme,
	surfaceColor,
	origin,
	api,
	customBridgeMethods,
	onLoad,
	isResizable,
	height,
	environment,
	extension,
	iframeRef,
}: BridgeProvider): Result => {
	const [loading, setLoading] = useState(true);
	const [loadedAt, setLoadedAt] = useState<number | null>(null);
	const { page } = useMetricsContext();
	// This ref avoids the api object ending up in the dep array
	// of the effect that calls createIframeBridge. It means that
	// people can pass anonymous functions as the api without the
	// bridge being re-created every render.
	const apiRef = useRef(api);
	useEffect(() => {
		apiRef.current = api;
	}, [api]);

	const enableThemingRef = useRef<CallableFunction>();

	const setThemeRef = useRef<CallableFunction>();
	useEffect(() => {
		if (!setThemeRef.current) {
			return;
		}
		setThemeRef.current(theme, surfaceColor, environment);
	}, [theme, surfaceColor, loading, environment]);

	// same as above, but for customBridgeMethods
	const customBridgeMethodsRef = useRef(customBridgeMethods);
	useEffect(() => {
		customBridgeMethodsRef.current = customBridgeMethods;
	}, [customBridgeMethods]);

	const [errorExtensionDetails, setErrorExtensionDetails] = useState({
		properties: extension.properties,
		type: extension.type,
		environmentType: extension.environmentType,
		appOwnerAccountId: extension.appOwner?.accountId,
	});

	const tracing = useTracingContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const { type, environmentType, properties, appOwnerAccountId } = errorExtensionDetails;

		if (
			extension.type !== type ||
			extension.environmentType !== environmentType ||
			extension.appOwner?.accountId !== appOwnerAccountId ||
			!deepEqual(extension.properties, properties)
		) {
			setErrorExtensionDetails({
				properties: extension.properties,
				type: extension.type,
				environmentType: extension.environmentType,
				appOwnerAccountId: extension.appOwner?.accountId,
			});
		}
	}, [extension, errorExtensionDetails]);

	const { id, installationId } = extension;

	useEffect(() => {
		const iframeEl = iframeRef.current;

		if (iframeEl?.contentWindow && !loading && typeof loadedAt === 'number') {
			const eventCallbacks: Subscription[] = [];
			const extensionDetails = {
				id: id.split('/').slice(0, 3).join('/'),
				installationId,
			};

			const sendHandshakeMetric = (page: string): ((success: boolean, error?: Error) => void) => {
				return (success: boolean, error?: Error): void => {
					if (success) {
						createAnalyticsEvent({
							eventType: OPERATIONAL_EVENT_TYPE,
							data: {
								action: 'succeeded',
								actionSubject: 'forge.ui.bridge',
								attributes: {
									target: 'handshake',
								},
								source: page,
								tags: ['forge'],
							},
						}).fire(FORGE_UI_ANALYTICS_CHANNEL);
					} else {
						createAnalyticsEvent({
							eventType: OPERATIONAL_EVENT_TYPE,
							data: {
								action: 'failed',
								actionSubject: 'forge.ui.bridge',
								attributes: {
									target: 'handshake',
								},
								source: page,
								tags: ['forge'],
							},
						}).fire(FORGE_UI_ANALYTICS_CHANNEL);

						const handshakeError =
							error instanceof Error
								? new Error(`Bridge handshake error: ${error.message}`)
								: new Error('Unknown bridge handshake error');

						captureAndReportError({
							error: handshakeError,
							environment,
							errorExtensionDetails,
							page,
							tracing,
						});
					}
				};
			};

			const { open } = createIframeBridge({
				theme,
				surfaceColor,
				withDomain: origin,
				withWindow: iframeEl.contentWindow,
				metadata: {
					env: environment,
					module: extension.type!,
					product: extension.type!.split(':')[0],
				},
				features: withMetricsAndAnalytics({
					features: {
						invoke: async ({ data }) => {
							const { onInvoke, onThreeLO } = apiRef.current;
							const result = await onInvoke(data);
							if (result.type === '3lo') {
								return new Promise((resolve, reject) => {
									onThreeLO(result.authUrl, () =>
										onInvoke(data).then((res) => {
											if (res.type === 'ok') {
												resolve(res.response);
											}
											// Unexpected case where 3LO consent requested again after consenting
											reject();
										}, reject),
									);
								});
							}
							if (result.type === 'err') {
								throw new Error(result.message);
							}
							return result.response;
						},
						navigate: async ({ data }: { data: NavigatePayload }) => {
							return apiRef.current.onNavigate(data);
						},
						reconcile: async ({ data }) => {
							return apiRef.current.reconcile(data);
						},
						onError: async ({ data }) => {
							return apiRef.current.onError(data);
						},
						reload: async () => {
							reload();
						},
						refresh: async () => {
							return apiRef.current.refresh();
						},
						submit: async (payload?: any) => {
							return apiRef.current.submit(payload);
						},
						close: async (payload?: any) => {
							return apiRef.current.close(payload);
						},
						getContext: () => {
							return apiRef.current.getContext();
						},
						openModal: async (payload: any) => {
							return apiRef.current.openModal(payload);
						},
						createHistory: async () => {
							return apiRef.current.createHistory();
						},
						fetchProduct: async ({ data }): Promise<FetchProductResponse> => {
							return apiRef.current.fetchProduct(data);
						},
						showFlag: async ({ data }) => {
							const flagActions = data.actions?.map((action: FlagAction) => {
								const { onClick } = action;
								return {
									...action,
									onClick: onClick ? () => onClick() : () => {},
								};
							});

							return apiRef.current.showFlag({
								...data,
								actions: flagActions,
							});
						},
						closeFlag: async ({ data }) => {
							return apiRef.current.closeFlag(data);
						},
						on: async (payload: {
							data: {
								event: string;
								callback: (payload?: any) => Promise<any>;
							};
						}) => {
							const { event, callback } = payload.data;
							const subscription = on(event, callback, extensionDetails);
							eventCallbacks.push(subscription);
							return {
								unsubscribe: () => {
									eventCallbacks.splice(eventCallbacks.indexOf(subscription), 1);
									subscription.unsubscribe();
								},
							};
						},
						emit: async (payload: { data: { event: string; payload?: any } }) => {
							return emit(payload.data.event, payload.data.payload, extensionDetails);
						},
						enableTheming: async (): Promise<void> => {
							await enableThemingRef.current?.();
							const currentTheme = getGlobalTheme();
							const surfaceColor = null;
							setThemeRef.current?.(currentTheme, surfaceColor, environment);
						},
						changeWindowTitle: async ({ data }) => {
							apiRef.current.changeWindowTitle(data);
						},
						getFrameId: async () => {
							return apiRef.current.getFrameId();
						},
						...mapCustomBridgeMethods(customBridgeMethodsRef.current),
					},

					page,
					environment,
					errorExtensionDetails,
					createAnalyticsEvent,
					tracing,
				}),
				sendHandshakeMetric: sendHandshakeMetric(page),
			});

			if (isResizable) {
				iframeResizer(
					{
						heightCalculationMethod: 'bodyScroll',
						scrolling: true,
						// @ts-ignore minHeight can be a string
						minHeight: height,
						initCallback: (iframe) => {
							iframe?.iFrameResizer?.resize();
						},
					},
					iframeEl,
				);
			}

			const { close, enableTheming, setTheme } = open();
			setThemeRef.current = setTheme;
			enableThemingRef.current = enableTheming;

			return () => {
				close?.(); // close may be undefined here if createIframeBridge call fails
				for (const subscription of eventCallbacks) {
					subscription.unsubscribe();
				}
				iframeEl?.iFrameResizer && iframeEl?.iFrameResizer.removeListeners();
			};
		}
	}, [
		iframeRef,
		origin,
		loading,
		page,
		isResizable,
		height,
		environment,
		errorExtensionDetails,
		id,
		extension.type,
		installationId,
		tracing,
		theme,
		surfaceColor,
		createAnalyticsEvent,
		loadedAt,
	]);

	return {
		loading,
		iframeProps: {
			ref: iframeRef,
			onLoad: () => {
				onLoad && onLoad();
				setLoading(false);
				setLoadedAt(Date.now());
			},
		},
	};
};
