import React from 'react';

import { useBooleanFeatureFlag } from '@confluence/session-data';
import {
	ADMIN_MIGRATION_MACRO_USAGE_FF,
	ADMIN_MACRO_USAGE_ANALYTICS_SOURCE,
	LazyMacroUsagePage,
} from '@confluence/site-settings';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { ADMIN_MACRO_USAGE_LEGACY } from '@confluence/named-routes';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const AdminMacroUsageRoute = () => {
	const isSPAPageEnabled = useBooleanFeatureFlag(ADMIN_MIGRATION_MACRO_USAGE_FF);

	if (!isSPAPageEnabled) {
		return <Redirection name={ADMIN_MACRO_USAGE_LEGACY.name} />;
	}

	return <LazyMacroUsagePage />;
};

AdminMacroUsageRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: ADMIN_MACRO_USAGE_ANALYTICS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
