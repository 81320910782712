import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';

import type { MarkdownSerializerState } from './serializer';

const isHeaderRow = (row: PMNode): boolean => row.child(0).type.name === 'tableHeader';

const isHeaderRowPresent = (table: PMNode): boolean => {
	let headerRowPresent = false;
	table.content.forEach((row: PMNode) => {
		if (isHeaderRow(row)) {
			headerRowPresent = true;
		}
	});
	return headerRowPresent;
};

const renderNode = (state: MarkdownSerializerState, node: PMNode, index: number): void => {
	if (index > 0) {
		state.write(' ');
	}
	node.content.forEach((child: PMNode, i: number) => {
		if (child.isTextblock || child.type.name === 'mediaSingle') {
			if (i > 0) {
				state.write(' ');
			}
			(state as any).context.insideTable = true;
			state.renderInline(child);
			(state as any).context.insideTable = false;
		} else {
			renderNode(state, child, i);
		}
	});
};

const renderInlineContent = (
	state: MarkdownSerializerState,
	node: PMNode,
	_parent: PMNode,
	_index: number,
	options: { headerRowPresent: boolean },
) => {
	options.headerRowPresent && state.write('| ');
	renderNode(state, node, 0);
	state.write(' ');
};

export default {
	table(state: MarkdownSerializerState, node: PMNode) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusTables) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'table',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="table_open" data-id="${id}" />\n\n`);

			node.content.forEach((child, i) => state.render(child, node, i));

			state.write(`<custom data-type="table_close" />\n\n`);
		} else {
			const headerRowPresent = isHeaderRowPresent(node);
			node.content.forEach((child, i) => {
				try {
					state.render(child, node, i, {
						headerRowPresent,
						skipFallback: true,
					});
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			});
			state.closeBlock(node);
		}
	},
	tableRow(
		state: MarkdownSerializerState,
		node: PMNode,
		_parent: PMNode,
		_index: number,
		options: { headerRowPresent: boolean },
	) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusTables) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'table',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="tr_open" data-id="${id}" />\n\n`);
			node.content.forEach((child, i) => {
				try {
					state.render(child, node, i, { skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			});
			state.write(`<custom data-type="tr_close" />\n\n`);
		} else {
			node.content.forEach((child, i) => {
				try {
					state.render(child, node, i, { ...options, skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			});
			if (options.headerRowPresent) {
				state.write('|');
				state.ensureNewLine();
				if (isHeaderRow(node)) {
					for (let i = 0; i < node.childCount; i++) {
						state.write('| --- ');
					}
					state.write('|');
					state.ensureNewLine();
				}
			}
		}
	},
	tableHeader(
		state: MarkdownSerializerState,
		node: PMNode,
		_parent: PMNode,
		_index: number,
		options: {
			headerRowPresent: boolean;
		},
	) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusTables) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'table',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="th_open" data-id="${id}" />\n\n`);
			node.content.forEach((child, i) => {
				try {
					state.render(child, node, i, { skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			});
			state.write(`<custom data-type="th_close" />\n\n`);
		} else {
			renderInlineContent(state, node, _parent, _index, options);
		}
	},
	tableCell(
		state: MarkdownSerializerState,
		node: PMNode,
		_parent: PMNode,
		_index: number,
		options: {
			headerRowPresent: boolean;
		},
	) {
		if (state.featureToggles.markdownPlus && state.featureToggles.markdownPlusTables) {
			const id = `id-${state.idCounter++}`;
			state.idMap[id] = {
				type: 'table',
				attributes: node.attrs,
			};
			state.write(`<custom data-type="td_open" data-id="${id}" />\n\n`);
			node.content.forEach((child, i) => {
				try {
					state.render(child, node, i, { skipFallback: true });
				} catch (error) {
					state.updateFallbackState(error, node);
					state.nodes.fallback(state, node);
				}
			});
			state.write(`<custom data-type="td_close" />\n\n`);
		} else {
			renderInlineContent(state, node, _parent, _index, options);
		}
	},
};
