import React from 'react';

import { LoadablePaint } from '@confluence/loadable';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { COMPANY_HUB_ANALYTICS } from '@confluence/named-routes';

const CompanyHubAnalytics = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CompanyHubAnalytics" */ '@confluence/company-hub/entry-points/CompanyHubAnalyticsPage'
			)
		).CompanyHubAnalyticsPage,
});

export const CompanyHubAnalyticsRoute = () => {
	const spaceKey = getCompanyHubSpaceKey() || '';
	return <CompanyHubAnalytics spaceKey={spaceKey} />;
};

CompanyHubAnalyticsRoute.NAVIGATION_PARAMS = () => {
	const spaceKey = getCompanyHubSpaceKey() || '';
	return {
		Screen: {
			screenEvent: {
				name: 'companyHubAnalytics',
			},
			pageState: {
				routeName: COMPANY_HUB_ANALYTICS.name,
				spaceKey,
			},
		},
		MainLayout: {
			navView: PRODUCT_HOME_ID,
		},
	};
};
