import type { Action } from 'react-sweet-state';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';

import type { LockScreenServiceResponse } from './types';

export type EntitlementDetails = {
	billingSourceSystem: 'HAMS' | 'CCP';
	entitlementId: string;
	trialEndTime: number | null;
};

export type LockScreenStoreState = {
	isLocked: boolean;
	wasDismissed: boolean;
	entitlementDetails?: EntitlementDetails;
	lockScreenDetails?: LockScreenServiceResponse;
};

const initialState = {
	isLocked: false,
	wasDismissed: false,
	entitlementDetails: undefined,
	lockScreenDetails: undefined,
};

const actions = {
	lockScreen:
		({
			entitlementDetails,
			lockScreenDetails,
		}: {
			entitlementDetails: EntitlementDetails;
			lockScreenDetails: LockScreenServiceResponse;
		}): Action<LockScreenStoreState, void, void> =>
		({ setState }) => {
			setState({
				isLocked: true,
				entitlementDetails,
				lockScreenDetails,
			});
		},

	unlockScreen:
		(dismissed?: boolean): Action<LockScreenStoreState, void, void> =>
		({ setState }) => {
			if (dismissed) {
				setState({
					isLocked: false,
					wasDismissed: true,
				});
			} else {
				setState({
					isLocked: false,
				});
			}
		},
};

export const LockScreenStore = createStore<LockScreenStoreState, typeof actions>({
	initialState,
	actions,
	name: 'LockScreenStore',
});

export const useLockScreenState = createStateHook(LockScreenStore);
export const useLockScreenActions = createActionsHook(LockScreenStore);
