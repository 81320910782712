import React from 'react';
import { getEditorPreloader } from '@confluence-classic/confluence-fabric-editor/src/lib';

import type { RouteMatch } from '@confluence/route';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { GoToHomeLoader } from '@confluence/home/entry-points/HomeRedirects';
import { START_TOUCH } from '@confluence/navdex';

export const HomeRoute = () => <GoToHomeLoader />;

HomeRoute.NAVIGATION_PARAMS = ({ name }: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'homeScreen',
			attributes: {
				navdexPointType: START_TOUCH,
			},
		},
		pageState: { routeName: name },
	},
	MainLayout: {
		navView: PRODUCT_HOME_ID,
		editorPreloader: getEditorPreloader,
	},
});
