// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const Outline = styled.div<{ color: string }>`
	outline: 2px dashed ${(props) => props.color};
	position: relative;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.2;
		background-color: ${(props) => props.color};
	}
`;

export const withOutline = (color: string, element: any) => (
	<Outline color={color}>{element}</Outline>
);
