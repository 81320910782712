import type { ComponentProps, FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';

import { useIsDisabledContext } from './IsDisabledContext';

type ToggleFieldProps = {
	Icon: React.ElementType;
	label: string;
} & ComponentProps<typeof Toggle>;

const toggleFieldContainerStyles = xcss({
	alignItems: 'center',
});

const toggleFieldContainerDisabledStyles = xcss({
	alignItems: 'center',
	color: 'color.text.disabled',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled.label({
	flexGrow: 1,
});

const iconContainerStyles = xcss({
	paddingRight: 'space.100',
});

export const ToggleField: FC<ToggleFieldProps> = ({ Icon, id, label, ...toggleProps }) => {
	const isDisabled = useIsDisabledContext();

	if (!id) id = `toggle-${label.replace(/\s+/g, '-').toLowerCase()}-property`;

	return (
		<Flex xcss={isDisabled ? toggleFieldContainerDisabledStyles : toggleFieldContainerStyles}>
			<Box xcss={iconContainerStyles}>
				<Icon />
			</Box>
			<Label htmlFor={id}>{label}</Label>
			<Toggle {...toggleProps} isDisabled={isDisabled} id={id} />
		</Flex>
	);
};
