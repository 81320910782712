import React from 'react';

import { perfMark, WATERFALL_INITIAL_MEASURES } from '@confluence/action-measures';
import { SSRScriptsInit } from '@confluence/browser-metrics';
import { SSRInlineScriptsInit } from '@confluence/ssr-utilities';

export const SSRAppScripts = () => {
	const nonceValue = window.__SSR_TRACE_ID__;
	return (
		<>
			{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_SSR_SCRIPTS}.start`)}
			<SSRScriptsInit />
			<SSRInlineScriptsInit />
			{perfMark(`${WATERFALL_INITIAL_MEASURES.HTML_SSR_SCRIPTS}.end`)}

			<script
				{...(nonceValue ? { nonce: nonceValue } : {})}
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					// Suppress error when performance API failed.
					// Note: in MicrosoftEdge version 18 and prior, performance.measure(name, startMark, endMark) API will throw SyntaxError if startMark is undefined.
					// That's why wrap with try catch statement.
					__html:
						'if(window["__SSR_RENDERED__"]){try{window.performance.mark("CFP-63.ssr-ttr");window.performance.measure("browser-metric:fmp",undefined,"CFP-63.ssr-ttr")}catch(e){}}',
				}}
			/>
		</>
	);
};
