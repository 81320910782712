import { fg } from '@atlaskit/platform-feature-flags';

import { ATLASSIAN_CONSENT_COOKIE_KEY, LEGACY_ATLASSIAN_GDPR_COOKIE_KEY } from '../../../constants';
import type { ConsentPreference } from '../../../types';
import { getConsentToken } from '../../cookie-controls/get-consent-token';
import { getCookie } from '../../cookie-controls/get-cookie';
import { unpackUserPreferencesCookie } from '../../cookie-controls/transformer';

export async function getPreferences(): Promise<ConsentPreference | undefined> {
	// Use V001 pattern without the V2 FF enabled
	if (!fg('platform.moonjelly.browser-storage-controls-v2')) {
		const packedPrefs = getCookie(LEGACY_ATLASSIAN_GDPR_COOKIE_KEY);

		if (!packedPrefs) {
			return undefined;
		}

		return unpackUserPreferencesCookie(packedPrefs);
	}

	let packedLocalPrefs = getCookie(ATLASSIAN_CONSENT_COOKIE_KEY);

	if (!packedLocalPrefs) {
		const consentToken = await getConsentToken();
		return consentToken ? unpackUserPreferencesCookie(consentToken) : undefined;
	}

	return unpackUserPreferencesCookie(packedLocalPrefs);
}
