import { getSessionData } from '@confluence/session-data';
import type { SessionDataType } from '@confluence/session-data';
import { initializeClientFromValues } from '@confluence/statsig-client/entry-points/initializeClientFromValues';
import { getStatsigBootstrap } from '@confluence/statsig-client/entry-points/getStatsigBootstrap';

// Preload function context is a subset of session data
// It's a subset because we can't provide everything on the server-side.
export type PreloadFnContext = {
	isLoggedIn: SessionDataType['isLoggedIn'];
	isLicensed: SessionDataType['isLicensed'];
	accessStatus: SessionDataType['accessStatus'];
	userId: SessionDataType['userId'];
	cloudId: SessionDataType['cloudId'];
	orgId: SessionDataType['orgId'];
	featureFlags: SessionDataType['featureFlags'];
	edition: SessionDataType['edition'];
	activationId: SessionDataType['activationId'];
	environment: SessionDataType['environment'];
};

export type getPreloadFnContextType = () => Promise<PreloadFnContext>;

let resolveContext = (_: PreloadFnContext) => {};
const contextPromise: Promise<PreloadFnContext> = new Promise(
	(resolve) => (resolveContext = resolve),
);

export async function initializePreloaderFnContext(
	contextCreatedFromSSRApp: PreloadFnContext | null = null,
): Promise<void> {
	if (contextCreatedFromSSRApp) {
		return resolveContext(contextCreatedFromSSRApp);
	}

	const [sessionData, statsigBootstrapData] = await Promise.all([
		getSessionData(),
		getStatsigBootstrap(),
	]);

	const {
		isLoggedIn,
		isLicensed,
		accessStatus,
		userId,
		cloudId,
		orgId,
		featureFlags,
		edition,
		activationId,
		environment,
	} = sessionData;
	const { clientSdkKey, experimentValues } = statsigBootstrapData || {};

	await initializeClientFromValues({
		clientSdkKey,
		experimentValues,
		identifiers: {
			userId,
			tenantId: cloudId,
		},
		environment,
	});

	// Please do NOT add random field to the return result of context object.
	// Because when rendering on the server-side.
	// cc-frontend-ssr will provide these values so we can avoid a round trip before requesting the actual queries.
	// It is VERY critical that every value returned by the getContext callback can be derived from cc-frontend-ssr service without querying graphql.
	// Please consult developers in #cc-fe-performance when changing this function
	// Also, when updating this function you MUST update the preloadQueryBasedOnRoute call in @confluence/ssr-app
	resolveContext({
		isLoggedIn,
		isLicensed,
		accessStatus,
		userId,
		cloudId,
		orgId,
		featureFlags,
		edition,
		activationId,
		environment,
	});
}

export async function getPreloaderFnContext(): Promise<PreloadFnContext> {
	return contextPromise;
}
