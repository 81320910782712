import type { ReactElement } from 'react';
import { elementToForgeDoc } from '../../../../utils/elementToForgeDoc';

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::9883730d5fd3d3fb1e1205e29b443a6d>>
 * @codegenId #banner
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen banner
 * @codegenParams { "props": { "appearance": true, "children": true, "icon": {"value": "icon ? props.render(elementToForgeDoc(icon)) as ReactElement : undefined"}, "ref": false, "testId": true } } 
 * @codegenDependency ../../../../../../../design-system/banner/src/components/banner.tsx <<SignedSource::cb8a40798e1d424af86eec59fb1aec76>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformBanner from '@atlaskit/banner';

type PlatformBannerProps = React.ComponentProps<typeof PlatformBanner>;

export type BannerProps = Pick<
  PlatformBannerProps,
  'appearance' | 'children' | 'icon' | 'testId'
>;

export const Banner = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    icon,
    testId,
  } = props.forgeDoc.props as BannerProps;
  return (
    <PlatformBanner
      appearance={appearance}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      icon={icon ? props.render(elementToForgeDoc(icon)) as ReactElement : undefined}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */