import { preloadDraft } from '@confluence/fabric-providers/entry-points/preloadDraft';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';

export async function preloadEditPageRoutePostSSR(match: RouteMatch, _url: string) {
	const { contentId } = match.params;
	const { cloudId } = await getPreloaderFnContext();

	await preloadDraft(contentId, cloudId);
}
