import type { FC } from 'react';
import { useContext, useEffect } from 'react';

import {
	ContentUnifiedQuery,
	PageUnifiedQueryV2,
	ContentBlogUnifiedQuery,
	convertToContentUnifiedQueryResult,
	canUsePageUnifiedQueryResult,
	type ContentUnifiedQueryType,
	type ContentBlogUnifiedQueryType,
	type PageUnifiedQueryV2Type,
	type ContentUnifiedQueryVariablesType,
	type ContentBlogUnifiedQueryVariablesType,
	type PageUnifiedQueryV2VariablesType,
} from '@confluence/content-unified-query';
import { useSpaceKey } from '@confluence/space-utils';
import { getApolloClient } from '@confluence/graphql';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { useContentType } from '@confluence/page-context';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { ConfluencePageAri } from '@atlassian/ari/confluence/page';

type ContentRefetchHandlerProps = {
	contentId?: string;
	shouldRefetch: boolean;
	refetchImmediately?: boolean;
	onRefetchComplete?: (versionNumber?: number | null) => void;
};

type ChooseContentUnifiedQueryType =
	| ContentUnifiedQueryType
	| ContentBlogUnifiedQueryType
	| PageUnifiedQueryV2Type;
type ChooseContentUnifiedQueryVariablesType =
	| ContentUnifiedQueryVariablesType
	| ContentBlogUnifiedQueryVariablesType
	| PageUnifiedQueryV2VariablesType;

export const ContentRefetchHandler: FC<ContentRefetchHandlerProps> = ({
	contentId,
	shouldRefetch,
	refetchImmediately,
	onRefetchComplete,
}) => {
	const { history } = useContext(RoutesContext);
	const [contentType] = useContentType();
	const spaceKey = useSpaceKey();
	const isSSR = Boolean(process.env.REACT_SSR);
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);

	const isMigratingContentUnifiedQuery =
		useBooleanFeatureFlag('confluence.frontend.content.unified.query.migration') && isSSR;

	const { cloudId } = useSessionData();

	useEffect(() => {
		let unlisten;
		if (shouldRefetch && contentId) {
			let chooseContentUnifiedQuery = ContentUnifiedQuery;
			const ari = ConfluencePageAri.create({
				siteId: cloudId,
				pageId: contentId,
			}).toString();

			let queryVariable: ChooseContentUnifiedQueryVariablesType = {
				contentId,
				spaceKey,
				includeAlias: isSpaceAliasFFEnabled,
				useNewContentTopper: isNewContentTopperFFEnabled,
			};

			if (contentType === 'blogpost') {
				chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
			} else if (isMigratingContentUnifiedQuery) {
				chooseContentUnifiedQuery = PageUnifiedQueryV2;
				queryVariable = {
					contentId: ari,
					includeAlias: isSpaceAliasFFEnabled,
					useNewContentTopper: isNewContentTopperFFEnabled,
				};
			}

			const refetchFn = async () => {
				let versionNumber: number | null | undefined;
				try {
					let queryResult = await getApolloClient().query<
						ChooseContentUnifiedQueryType,
						ChooseContentUnifiedQueryVariablesType
					>({
						query: chooseContentUnifiedQuery,
						context: { single: true },
						fetchPolicy: 'network-only',
						variables: queryVariable,
					});

					// no need to to make request again for blogpost here because when choosing chooseContentUnifiedQuery,
					// the above query will always be ContentBlogUnifiedQuery for blogpost
					if (isMigratingContentUnifiedQuery && contentType !== 'blogpost') {
						const canUsePageQuery = canUsePageUnifiedQueryResult({
							pageUnifiedQueryResult: queryResult as any,
							isMigratingContentUnifiedQuery,
							pageUnifiedQuerySkipped: false,
						});

						if (canUsePageQuery) {
							queryResult = {
								...queryResult,
								data: convertToContentUnifiedQueryResult(
									queryResult.data as PageUnifiedQueryV2Type,
								) as ChooseContentUnifiedQueryType,
							};
						} else {
							queryResult = await getApolloClient().query<
								ChooseContentUnifiedQueryType,
								ChooseContentUnifiedQueryVariablesType
							>({
								query: ContentUnifiedQuery,
								context: { single: true },
								fetchPolicy: 'network-only',
								variables: {
									contentId,
									spaceKey,
									includeAlias: isSpaceAliasFFEnabled,
									useNewContentTopper: isNewContentTopperFFEnabled,
								},
							});
						}
					}

					// at this time, queryResult.data is always ContentUnifiedQueryType or ContentBlogUnifiedQueryType format
					// @ts-ignore
					versionNumber = queryResult.data.content?.nodes?.[0]?.version?.number;
				} catch (error) {
					// NOTE: We intentionally swallow any possible error here as this query is only aimed to
					// populate the cache with just published content. Should any error occur here after
					// successful publishing the page, the Apollo cache for the query will be invalidated
					// anyway and View Page will fetch the page and handle possible errors.
				}

				// If a callback is supplied, call it regardless
				onRefetchComplete && onRefetchComplete(versionNumber);
			};

			if (refetchImmediately) {
				void refetchFn();
			} else {
				unlisten = history?.listen(refetchFn);
			}
		}

		return () => {
			// Call the unlistener when this component unmounts
			unlisten && unlisten();
		};
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [shouldRefetch, isSpaceAliasFFEnabled]);

	return null;
};
