/**
 * Type for date picker had to be defined here because the type could not be imported from the datepicker module.
 * It is being declared in typings/atlaskit.d.ts which is preventing imports. If removed, type errors are introduced in components 
 * outside of this package.
 * 
 */
import type { FieldProps } from '@atlassian/forge-ui-types';
import type {SelectProps} from '@atlaskit/select';

// Type copied from packages/design-system/datetime-picker/src/components/date-picker.tsx
type Appearance = 'default' | 'subtle' | 'none';
type Spacing = 'compact' | 'default';

export type DatePickerProps = {
   appearance?: Appearance;
   autoFocus?: boolean;
   defaultIsOpen?: boolean;
   defaultValue?: string;
   disabled?: string[];
   maxDate?: string;
   minDate?: string;
   isDisabled?: boolean;
   isOpen?: boolean;
   nextMonthLabel?: string;
   previousMonthLabel?: string;
   spacing?: Spacing;
   value?: string;
   isInvalid?: boolean;
   dateFormat?: string;
   placeholder?: string;
   locale?: string;
   testId?: string;
   weekStartDay?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
   onChange?:(value: string) => void;
   selectProps?: SelectProps<any | false>;
} & Partial<Omit<FieldProps, 'onChange' | 'isRequired' >>;

/**
 * Excluded props:
 * - icon - we are not allowing custom icons until Icon is introduced in UI Kit 2
 * - hideIcon - not supported until Icon is introduced in UI Kit 2
 * - innerProps - not supporting HTML attributes at this time
 * - selectProps - not supporting to simplify the API
 * 
 * Excluded function props:
 * - disabledDateFilter - workaround is to use disabled array prop
 * - parseInputValue
 * - formatDisplayLabel
 * - Reason: these functions are being passed by Post Robot, where the response can be resolved but the function cannot be
 * - Possible fix: add custom logic in @forge/react package, or the original component is modified to cater for async functions
 * 
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::7db747d310a81dd63a64436ff18b4cd3>>
 * @codegenId #date-picker
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen date-picker
 * @codegenParams { "defineOwnType": true, "hasFieldProps": { "value": false, "isRequired": false, "onChange": false}, "props": { "appearance": true, "autoFocus": true, "defaultIsOpen": true, "defaultValue": true, "disabled": true, "disabledDateFilter": false, "maxDate": true, "minDate": true, "icon": false, "innerProps": false, "isOpen": {"replaceLine": true, "value": "{...(isOpen ? { isOpen }: undefined)}", "comment": "undefined is evaluated as true when isOpen is being explicitly set" }, "nextMonthLabel": true, "parseInputValue": false, "formatDisplayLabel": false, "previousMonthLabel": true, "selectProps": true, "spacing": true, "hideIcon": false, "dateFormat": true, "placeholder": true, "locale": true, "testId": true, "weekStartDay": true, "onChange": true, "value": {"replaceLine": true, "value": "{...(value !== undefined ? { value }: undefined)}", "comment": "Explicitly setting value to undefined is causing issues"} } }
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/components/date-picker.tsx <<SignedSource::6bf4c8568ef4dff312e2e6534692e302>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/types.tsx <<SignedSource::01e56ab6293a125750006fe63951c923>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsContext.tsx <<SignedSource::fe8cfa3b5df7c0625832b02a14245144>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/index.tsx <<SignedSource::ccce7ae2ef1a6f54ee790977cd70ce4b>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { DatePicker as PlatformDatePicker } from '@atlaskit/datetime-picker';
import { adaptEventHandler } from "../../../utils";

// Define the type for DatePicker as DatePickerProps at the top of this file

export const DatePicker = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    autoFocus,
    defaultIsOpen,
    defaultValue,
    disabled,
    maxDate,
    minDate,
    isOpen,
    nextMonthLabel,
    previousMonthLabel,
    selectProps,
    spacing,
    dateFormat,
    placeholder,
    locale,
    testId,
    weekStartDay,
    onChange,
    value,
    // Field props
    id,
    isDisabled,
    isInvalid,
    onBlur,
    onFocus,
    "aria-invalid": ariaInvalid,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as DatePickerProps;
  return (
    <PlatformDatePicker
      appearance={appearance}
      autoFocus={autoFocus}
      defaultIsOpen={defaultIsOpen}
      defaultValue={defaultValue}
      disabled={disabled}
      maxDate={maxDate}
      minDate={minDate}
      // undefined is evaluated as true when isOpen is being explicitly set
      {...(isOpen ? { isOpen }: undefined)}
      nextMonthLabel={nextMonthLabel}
      previousMonthLabel={previousMonthLabel}
      selectProps={selectProps}
      spacing={spacing}
      dateFormat={dateFormat}
      placeholder={placeholder}
      locale={locale}
      testId={testId}
      weekStartDay={weekStartDay}
      onChange={onChange}
      // Explicitly setting value to undefined is causing issues
      {...(value !== undefined ? { value }: undefined)}
      // Field props
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      aria-invalid={ariaInvalid}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */
