import React from 'react';
import { styled } from '@compiled/react';

import FadeIn from '@atlaskit/motion/fade-in';
import { N20A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { SkeletonWrapper } from './emotionStyled';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiSkeleton = styled.span<{ size: string; margin?: string }>({
	display: 'inline-block',
	background: token('color.background.neutral', N20A),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => props.size,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => props.size,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: (props) => props.size,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	lineHeight: (props) => props.size,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: (props) => (props.margin ? props.margin : token('space.0', '0px')),
});

export const EmojiPlaceholder = (props: { height: number; loadingMargin?: string }) => {
	return (
		<FadeIn entranceDirection="left">
			{(animationProps) => (
				<SkeletonWrapper
					{...animationProps}
					data-testid="emoji-placeholder"
					data-css-target="emoji-placeholder"
				>
					<EmojiSkeleton size={`${props.height || 26}px`} margin={props.loadingMargin} />
				</SkeletonWrapper>
			)}
		</FadeIn>
	);
};
