import { query } from '@confluence/query-preloader-tools';
import { ConfluenceDraftQuery } from '@confluence/editor-features/entry-points/ConfluenceDraftQuery';
import type {
	ConfluenceDraftQueryResponse,
	ConfluenceDraftQueryVariables,
} from '@confluence/editor-features/entry-points/ConfluenceDraftQuery';
import { GetCollabServiceWithMigrationQuery } from '@confluence/native-collab/entry-points/CollabServiceWithMigrationQuery';
import type {
	getCollabServicePropertyQueryRes,
	getCollabServicePropertyQueryVariables,
} from '@confluence/native-collab/entry-points/CollabServiceWithMigrationQuery';
import { NativeCollabQuery } from '@confluence/native-collab/entry-points/NativeCollabQuery';
import type {
	NativeCollabQueryRes,
	NativeCollabQueryVariables,
} from '@confluence/native-collab/entry-points/NativeCollabQuery';
import { NativeCollabProviderQuery } from '@confluence/fabric-providers/entry-points/NativeCollabProviderQuery';
import type {
	NativeCollabProviderQueryRes,
	NativeCollabProviderQueryVariables,
} from '@confluence/fabric-providers/entry-points/NativeCollabProviderQuery';
import { CollabDraftQuery } from '@confluence/fabric-providers/entry-points/CollabDraftQuery';
import type {
	CollabDraftQueryRes,
	CollabDraftQueryVariables,
} from '@confluence/fabric-providers/entry-points/CollabDraftQuery';

/**
 * Preload draft data including adf body that is used in NativeCollabComponent to inject
 * initial content into the editor, before Collab Service loads
 */
export function preloadNativeEditorContent(
	contentId: string,
	shouldPreloadNativeEditorData: boolean,
) {
	return query<ConfluenceDraftQueryResponse, ConfluenceDraftQueryVariables>({
		query: ConfluenceDraftQuery,
		variables: {
			contentId,
			status: ['draft'],
		},
		fetchPolicy: shouldPreloadNativeEditorData ? 'cache-first' : 'network-only',
	});
}

export function preloadCollabDraftContent(
	documentAri: string,
	token: string | null,
	shouldPreloadNativeEditorData: boolean,
) {
	return query<CollabDraftQueryRes, CollabDraftQueryVariables>({
		query: CollabDraftQuery,
		variables: {
			id: documentAri,
			token: token || null,
		},
		fetchPolicy: shouldPreloadNativeEditorData ? 'cache-first' : 'network-only',
	});
}

export function preloadNativeCollab(
	contentId: string,
	draftShareId: string | undefined,
	shouldPreloadNativeEditorData: boolean,
	status?: [string],
) {
	return query<NativeCollabQueryRes, NativeCollabQueryVariables>({
		query: NativeCollabQuery,
		variables: {
			contentId,
			...(draftShareId && { draftShareId }),
			status: status ?? ['draft', 'current'],
		},
		fetchPolicy: shouldPreloadNativeEditorData ? 'cache-first' : 'network-only',
		context: { single: 'true' },
	});
}

export function preloadCollabServiceProperty(
	contentId: string,
	draftShareId: string | undefined,
	shouldPreloadNativeEditorData: boolean,
) {
	return query<getCollabServicePropertyQueryRes, getCollabServicePropertyQueryVariables>({
		query: GetCollabServiceWithMigrationQuery,
		variables: {
			contentId,
			...(draftShareId && { draftShareId }),
		},
		fetchPolicy: shouldPreloadNativeEditorData ? 'cache-first' : 'network-only',
		context: { single: 'true' },
	});
}

export function preloadNativeCollabProvider(
	accountId: string | null,
	shouldPreloadNativeEditorData: boolean,
) {
	return query<NativeCollabProviderQueryRes, NativeCollabProviderQueryVariables>({
		query: NativeCollabProviderQuery,
		variables: {
			accountId,
		},
		fetchPolicy: shouldPreloadNativeEditorData ? 'cache-first' : 'network-only',
		context: { single: 'true' },
	});
}
