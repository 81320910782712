/* eslint-disable @atlassian/tangerine/import/no-restricted-paths */
import { SERVICE_UNAVAILABLE } from '../../controllers/coordination/utils/status-codes';
import ALLOW_CORS_WHITELIST from '../allow-cors-whitelist';

export const MAXIMUM_WAIT_TIME_MINUTES = 60;

export async function errorIfNotOk(response: Response): Promise<Response> {
	if (!response.ok) {
		// We use the response body for two reasons:
		// 1. To ensure that we consume the response body, so that it gets reported on in Chrome DevTools
		// 2. It could have some useful information relevant to the error
		throw Error(`${response.status}: ${await response.text()}`);
	}

	return response;
}

export async function pullResponseTextIfNotOk(response: Response): Promise<Response> {
	if (!response.ok) {
		await response.text();
	}

	return response;
}

function basicStargateRetryCondition(response: Response): boolean {
	const failureCategory = response.headers.get('X-Failure-Category');
	const failureCategoryIsThrottled =
		!!failureCategory && failureCategory.toLowerCase() === 'throttled';

	return response.status === SERVICE_UNAVAILABLE && failureCategoryIsThrottled;
}

export function stargateFetch(
	input: RequestInfo,
	reqOptions?: RequestInit,
	retryCondition: (response: Response) => boolean = basicStargateRetryCondition,
): Promise<Response> {
	return fetchWithRetry(input, retryCondition, augmentOptionsWithSameOriginCredentials(reqOptions));
}

export function stargateFetchRetryOn5xx(
	input: RequestInfo,
	reqOptions?: RequestInit,
): Promise<Response> {
	return stargateFetch(
		input,
		reqOptions,
		(response) => response.status >= 500 && response.status <= 599,
	);
}

export function augmentOptionsWithSameOriginCredentials(reqOptions?: RequestInit): RequestInit {
	return {
		...reqOptions,
		credentials: ALLOW_CORS_WHITELIST.includes(location.host) ? 'include' : 'same-origin',
	};
}

function handleFetchResultAndRetryIfNeeded(
	fetchResult: Promise<Response>,
	retryCondition: (response: Response) => boolean,
	retry: () => Promise<Response>,
): Promise<Response> {
	return fetchResult
		.then((r: Response) => (retryCondition(r) ? retry() : Promise.resolve(r)))
		.catch(retry);
}

function fetchWithRetry(
	input: RequestInfo,
	retryCondition: (response: Response) => boolean,
	reqOptions?: RequestInit,
): Promise<Response> {
	return handleFetchResultAndRetryIfNeeded(fetch(input, reqOptions), retryCondition, () =>
		retryFetchWithBackoff(input, retryCondition, reqOptions),
	);
}

function retryFetchWithBackoff(
	input: RequestInfo,
	retryCondition: (response: Response) => boolean,
	reqOptions?: RequestInit,
	waitTimeMinutes = 0,
): Promise<Response> {
	return handleFetchResultAndRetryIfNeeded(
		fetchAfter(input, waitTimeMinutes, reqOptions),
		retryCondition,
		() =>
			retryFetchWithBackoff(
				input,
				retryCondition,
				reqOptions,
				Math.min(waitTimeMinutes === 0 ? 1 : waitTimeMinutes * 2, MAXIMUM_WAIT_TIME_MINUTES),
			),
	);
}

export const REQUEST_STAGGER_DELAY_MAXIMUM_MS = 5000;
function fetchAfter(
	input: RequestInfo,
	waitTimeMinutes: number,
	reqOptions?: RequestInit,
): Promise<Response> {
	const staggerDelay = Math.round(Math.random() * REQUEST_STAGGER_DELAY_MAXIMUM_MS);
	const delay = waitTimeMinutes ? waitTimeMinutes * 60 * 1000 + staggerDelay : 0;

	return new Promise((resolve, reject) => {
		setTimeout(() => {
			fetch(input, reqOptions).then(resolve).catch(reject);
		}, delay);
	});
}
