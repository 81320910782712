import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { LoadablePaint } from '@confluence/loadable';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';

import SpaceBase from '../../SpaceBase';

const AdminCenterSpacePage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluenceadmin-center" */ '@confluence/admin-center'
			)
		).AdminCenterSpacePage,
});

export const AdminCenterSpaceRoute = ({ params: { spaceKey } }: RouteMatch) => {
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');

	return isSpaceAliasFFEnabled ? (
		<SpaceBase>
			<AdminCenterSpacePage spaceKey={spaceKey} />
		</SpaceBase>
	) : (
		<AdminCenterSpacePage spaceKey={spaceKey} />
	);
};

AdminCenterSpaceRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { edition } = useSessionData();
	return {
		Screen: {
			screenEvent: { name: 'adminCenterSpace', id: spaceKey, attributes: { edition } },
			pageState: {
				routeName: name,
				spaceKey,
			},
		},
		MainLayout: {
			navView: CONTAINER_HOME_ID,
		},
	};
};
