import partition from 'lodash/partition';

import { type ParagraphChunk } from '../../utils/diff-match-patch/utils';

import {
	MAX_PARTS,
	PART_MAX_SIZE,
	QUEUE_MAX_ADDITIONAL_ATTEMPTS,
	TOTAL_PARTS_MAX_SIZE,
} from './constants';
import type { Suggestion } from './states';

type SuggestionEntry =
	| { state: 'parsed'; suggestions: Suggestion[] }
	| { state: 'cached'; suggestions: Suggestion[] }
	| {
			state: 'receivedChunkMetadata';
			chunkId: string;
			metadata: {
				editDistanceRatio?: number;
				wordCountRatio?: number;
				originalContentLength?: number;
			};
	  }
	| { state: 'trackedDuration'; duration: number }
	| {
			state: 'failed';
			reason: 'network' | 'backend' | 'aborted' | 'parsing' | 'unhandled';
			errors: string[];
			statusCode: number;
			failedChunkIds: string[];
	  }
	| {
			state: 'purged';
			totalParts: number;
			totalPurgedParts: number;
			purgedChunkIds: string[];
	  }
	| { state: 'done' };

export type SuggestionGenerator = AsyncGenerator<SuggestionEntry>;

/**
 * When updating this type, please update the corresponding type guard functions too
 */
type ValidStreamingResponse =
	| {
			id: string;
			part: string;
			metadata?: {
				edit_distance_ratio?: string;
				original_content_length?: string;
				word_count_ratio?: string;
			};
	  }
	| {
			id: string;
			error: string;
	  };

function isValidStreamingResponse(response: unknown): response is ValidStreamingResponse {
	return (
		typeof response === 'object' &&
		response !== null &&
		'id' in response &&
		('part' in response || 'error' in response)
	);
}

type ParseResponseReturnType =
	| {
			result: true;
			response: Suggestion;
	  }
	| {
			result: false;
			error: string;
			chunkId?: string;
	  };

function parseResponse(paragraphs: Array<ParagraphChunk>, response: any): ParseResponseReturnType {
	const chunk = paragraphs.find((p) => response.id === p.id);

	if (!chunk || !isValidStreamingResponse(response)) {
		return {
			result: false,
			error: 'Invalid streaming response',
			// It may be possible that id is in response but part or error does not exist.
			chunkId: response.id,
		};
	}

	if ('error' in response) {
		return {
			result: false,
			error: 'part was missing in reponse.',
			chunkId: chunk.id,
		};
	}

	return {
		result: true,
		response: {
			chunk,
			suggestion: response.part,
			...(response.metadata && {
				metadata: {
					editDistanceRatio: Number(response.metadata.edit_distance_ratio) || undefined,
					wordCountRatio: Number(response.metadata.word_count_ratio) || undefined,
					originalContentLength: Number(response.metadata.original_content_length) || undefined,
				},
			}),
		},
	};
}

/**
 * Only store 32 entries for now to prevent the cache from getting too large
 * This is primarily to make sure any Undo / Redo actions where the
 * state reverts to an original checked state won't request the same paragraph again
 */
const paragraphCache = new Map<string, string>();
const PARAGRAPH_CACHE_MAX_SIZE = 32;

export async function* suggestionGenerator({
	endpoint,
	paragraphs,
	abortController,
}: {
	endpoint: string;
	paragraphs: Array<ParagraphChunk>;
	abortController: AbortController;
}): SuggestionGenerator {
	const [cachedParagraphs, uncachedParagraphs] = partition(paragraphs, (p: ParagraphChunk) =>
		paragraphCache.has(p.text),
	);

	if (cachedParagraphs.length > 0) {
		const suggestions: Suggestion[] = [];
		for (const cachedParagraph of cachedParagraphs) {
			const suggestion = paragraphCache.get(cachedParagraph.text);
			if (suggestion) {
				// Re-add the key / value to keep it on top of the Map iter order
				paragraphCache.delete(cachedParagraph.text);
				paragraphCache.set(cachedParagraph.text, suggestion);
				suggestions.push({
					chunk: cachedParagraph,
					suggestion,
				});
			}
		}
		yield {
			state: 'cached',
			suggestions,
		};
	}

	if (!uncachedParagraphs.length) {
		yield { state: 'done' };
		return;
	}

	/**
	 * QUEUE CONSTRAINTS:
	 * Input size sum (all parts): 10,000 chars
	 * Input size per part: 5,000 chars
	 * Max parts: 50
	 */

	const purgedChunkIds: Array<ParagraphChunk['id']> = [];

	const totalParts = uncachedParagraphs.length;
	let attemptCount = 0;

	// Partition into parts that are within the part size limit and parts that exceed it
	// This is done due to backend limitations
	const [uncachedFilteredParagraphChunks, uncachedUnfilteredParagraphChunks] = partition(
		uncachedParagraphs,
		(p) => p.text.length <= PART_MAX_SIZE,
	);

	// For all parts that exceed the parts limit, add them to the purged list
	purgedChunkIds.push(...uncachedUnfilteredParagraphChunks.map((p) => p.id));

	while (uncachedFilteredParagraphChunks.length && attemptCount <= QUEUE_MAX_ADDITIONAL_ATTEMPTS) {
		attemptCount++;
		const queue: ParagraphChunk[] = [];
		// const queue = new Map<ParagraphChunk['id'], ParagraphChunk['text']>();
		let queueTotalPartsSize = 0;
		/**
		 * Fill the queue with parts up until the constraints have been met or until all parts have been added
		 */
		while (
			uncachedFilteredParagraphChunks.length &&
			queueTotalPartsSize + uncachedFilteredParagraphChunks[0].text.length <=
				TOTAL_PARTS_MAX_SIZE &&
			queue.length <= MAX_PARTS
		) {
			const uncachedParagraph = uncachedFilteredParagraphChunks.shift();
			if (!uncachedParagraph) {
				break;
			}
			queueTotalPartsSize += uncachedParagraph.text.length;
			queue.push(uncachedParagraph);
		}

		if (!queue.length) {
			break;
		}
		const queuedChunkIds = queue.map((p) => p.id);

		try {
			/**
			 * API Spec documented at https://hello.atlassian.net/wiki/spaces/CA3/pages/3776161653/Bulk+Spelling+and+Grammar+Endpoint
			 */
			const requests: {
				[id: string]: string;
			} = {};

			// Only add uncached paragraphs to requests, since cached ones have already been yielded
			queue.forEach(({ id, text }) => {
				requests[id] = text;
			});

			const payload = { requests };

			// track durations
			const startTime = performance.now();

			const response = await fetch(endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=UTF-8',
				},
				body: JSON.stringify(payload),
				credentials: 'include',
				signal: abortController.signal,
				mode: 'cors',
			});

			yield {
				state: 'trackedDuration',
				duration: performance.now() - startTime,
			};

			if (!response.ok) {
				yield {
					state: 'failed',
					reason: 'backend',
					errors: [`unexpected response status: ${response.status}`],
					statusCode: response.status,
					failedChunkIds: queuedChunkIds,
				};
				break;
			}

			if (!response.body) {
				yield {
					state: 'failed',
					reason: 'network',
					errors: ['response.body missing'],
					statusCode: response.status,
					failedChunkIds: queuedChunkIds,
				};
				break;
			}

			const processedChunkIds: string[] = [];

			try {
				const reader = response.body.getReader();
				const decoder = new TextDecoder('utf-8');
				let lineBuffer = '';
				let done = false;

				while (!done) {
					const { value, done: doneReading } = await reader.read();
					done = doneReading;
					const chunkValue = decoder.decode(value);
					lineBuffer = lineBuffer + chunkValue;
					// Split the lineBuffer by line breaks
					const lines = lineBuffer.split('\n');
					// Process all complete lines, except for the last one (which might be incomplete)

					const suggestions: Suggestion[] = [];
					const errors: string[] = [];
					const failedChunkIds: string[] = [];

					while (lines.length > 1) {
						const line = lines.shift()!;
						const parsedData = JSON.parse(line);
						const parsedResponse = parseResponse(queue, parsedData);
						if (parsedResponse.result) {
							const { suggestion, chunk, metadata } = parsedResponse.response;
							if (paragraphCache.size >= PARAGRAPH_CACHE_MAX_SIZE) {
								// .keys().next() will return the oldest created key in the map
								paragraphCache.delete(paragraphCache.keys().next().value);
							}
							paragraphCache.set(chunk.text, suggestion);
							suggestions.push(parsedResponse.response);
							processedChunkIds.push(chunk.id);
							if (metadata) {
								yield {
									state: 'receivedChunkMetadata',
									chunkId: chunk.id,
									metadata,
								};
							}
						} else {
							errors.push(parsedResponse.error);
							if (parsedResponse.chunkId) {
								failedChunkIds.push(parsedResponse.chunkId);
							}
						}
					}
					if (suggestions.length) {
						yield { state: 'parsed', suggestions: suggestions };
					}
					if (errors.length) {
						yield {
							state: 'failed',
							reason: 'backend',
							errors,
							statusCode: response.status,
							failedChunkIds,
						};
					}

					// Keep the last (potentially incomplete) line in the lineBuffer
					lineBuffer = lines[0];
				}
			} catch (parsingError) {
				yield {
					state: 'failed',
					reason: 'parsing',
					errors: ['parsingError'],
					statusCode: response.status,
					failedChunkIds: queuedChunkIds.filter((id) => !processedChunkIds.includes(id)),
				};
			}
		} catch (error: any) {
			if (error.name === 'AbortError') {
				yield {
					state: 'failed',
					reason: 'aborted',
					errors: ['Streaming aborted'],
					statusCode: error.status,
					failedChunkIds: queuedChunkIds,
				};
			} else {
				yield {
					state: 'failed',
					reason: 'unhandled',
					errors:
						error instanceof Error &&
						['RangeError', 'TypeError', 'TransformError'].includes(error.name)
							? [error.message]
							: ['unhandled'],
					statusCode: error.status,
					failedChunkIds: queuedChunkIds,
				};
			}
		}
	}

	/**
	 * Purge any remaining uncached paragraphs
	 */
	if (uncachedFilteredParagraphChunks.length) {
		purgedChunkIds.push(...uncachedFilteredParagraphChunks.map((p) => p.id));
	}

	if (purgedChunkIds.length) {
		yield {
			state: 'purged',
			totalParts,
			totalPurgedParts: purgedChunkIds.length,
			purgedChunkIds,
		};
	}

	yield { state: 'done' };
	return;
}
