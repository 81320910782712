// Maximum number of characters in a single part
export const PART_MAX_SIZE = 5000;
// Maximum number of characters in all parts in a queue
export const TOTAL_PARTS_MAX_SIZE = 10000;
// Maximum number of parts in a queue
export const MAX_PARTS = 50;
// Maximium number of additional queues to request
export const QUEUE_MAX_ADDITIONAL_ATTEMPTS = 2;
// Limit until we stop tracking count of doc changes
export const DOC_CHANGE_COUNT_LIMIT = 8;
// Minimum size of document's text content to be eligible for proactive AI suggestions
export const DOC_MINIMUM_SIZE_THRESHOLD = 8;
