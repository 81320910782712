import gql from 'graphql-tag';

export const MacrosQuery = gql`
	query MacrosQuery($contentId: ID!, $blocklist: [String!], $first: Int, $after: String) {
		macros(contentId: $contentId, blocklist: $blocklist, first: $first, after: $after) {
			pageInfo {
				hasNextPage
				endCursor
			}

			nodes {
				macroId
				contentId
				renderedMacro {
					value
					macroRenderedRepresentation
					macroBodyStorage
					webResource {
						contexts
						keys
						superbatch {
							metatags
						}
						tags {
							css
							data
							js
						}
						uris {
							css
							data
							js
						}
					}
					mediaToken {
						token
					}
				}
			}
		}
	}
`;
