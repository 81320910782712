import { SSRMeasures } from '@confluence/action-measures';

import { getPreloaderFnContext } from './getPreloaderFnContext';

// Best effort tasks are not pushed to the main tasks array because we don't wait for these queries to finish.
// If the query does not finish in time, it will not be ready in time for SSR rendering, and the SPA will execute it instead.
// When the wrapper is invoked, we mark it as timed out. If it finishes in time, we mark it as completed.
export const bestEffortTimeouts: { [key: string]: boolean } = {};

export const bestEffortTask = async (
	queryName: string,
	task: () => Promise<any>,
	force = false,
): Promise<any> => {
	const { featureFlags } = await getPreloaderFnContext();
	const disableBestEffortQuery = featureFlags['confluence.frontend.remove.best.effort'];
	// We are not doing best effort on staging as it can cause flakiness with some elements that we should always test for in Cypress.
	// We will however allow best effort logic to run on jest tests for testing purposes.
	// We are trying to remove the best effort logic from the codebase, so we are adding a feature flag to test it
	if (
		(process.env.CLOUD_ENV === 'staging' || force || disableBestEffortQuery) &&
		!process.env.JEST_WORKER_ID
	) {
		return task();
	} else {
		bestEffortTimeouts[queryName] = true;
		void SSRMeasures.run(`ssr-app/prepare/preloadQuery/bestEffort:${queryName}`, task).then(() => {
			delete bestEffortTimeouts[queryName];
		});
	}
};
