/* eslint react/prop-types:0 */
import React from 'react';

import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const SpaceSettingsForgeAppsRouteComponent = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-SpaceSettingsForgeAppsRouteComponent" */ './SpaceSettingsForgeAppsRouteComponent'
		),
});

export const SpaceSettingsForgeAppsRoute = ({
	params: { appId, forgeManifestRoute, envId, spaceKey },
}) => (
	<SpaceSettingsForgeAppsRouteComponent
		spaceKey={spaceKey}
		appId={appId}
		envId={envId}
		forgeManifestRoute={forgeManifestRoute}
	/>
);

SpaceSettingsForgeAppsRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey, appId, envId } }) => ({
	Screen: {
		screenEvent: { name: 'spaceSettingsForgeApps', id: spaceKey, attributes: { appId, envId } },
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		disableMinWidth: true,
	},
});
