import { ApolloClient } from 'apollo-client';
import type { ApolloLink } from 'apollo-link';
import { from, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { UFOLoggerLink } from '@atlassian/ufo-apollo-log/link';

import { appendHeader, cfetch, NoNetworkError, causedByAbortError } from '@confluence/network';
import { SSRMeasures, WaterfallMeasures } from '@confluence/action-measures';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';

import { deleteExtensionsLink } from './links/DeleteExtensionsLink';
import { NetworkStatus, networkStatusLink, writeNetworkStatus } from './links/NetworkStatusLink';
import { externalShareLink } from './links/ExternalShareLink';
import { addSuperAdminDirectiveLink } from './links/SuperAdminLink';
import { queryOverridesLink } from './links/QueryOverridesLink';
import { networkErrorRetryLink } from './links/NetworkErrorRetryLink';
import { createOnErrorLink } from './links/OnErrorLink';
import { statusCodeRetryLink } from './links/StatusCodeRetryLink';
import { graphqlSyntheticErrorGenerator } from './links/DevOnlyGraphqlErrorGenerator';
import { ExperimentalLink } from './links/ExperimentalLink';
import { webSocketLink } from './links/WebSocketLink';
import { SSRLink } from './links/SSRLink';
import { SSRTestLink } from './links/SSRTestLink';
import { fragmentMatcher } from './fragmentMatcher';
import { dataIdFromObject } from './dataIdFromObject';
import { cacheRedirects } from './cacheRedirects';
import { isExperimental, isWS } from './graphqlAssertions';
import {
	createExperienceAwareFetch,
	isExperienceAwareFetchAlwaysEnabled,
} from './createExperienceAwareFetch';
import { ClientSchema } from './types/ClientSchema.graphqls';

const experienceAwareFetch = createExperienceAwareFetch();
const URI = '/cgraphql';
const MAX_CONCURRENCY_FOR_THROTTLED = 8;

export function resetApolloStats() {
	window.__APOLLO_STATS__ = {
		// Used in node_modules/apollo-client/bundle.esm.js
		broadcast: {},
	};
}

const createCache = () => {
	return new InMemoryCache({
		fragmentMatcher,
		dataIdFromObject,
		cacheRedirects,
	});
};

// appends the operation names as query params, and
// includes them as headers as well
const wrappedFetch = (uri: string, options: RequestInit) => {
	// On client side the uri is /cgraphql
	// However when running on the server side we want to dynamic change the URI
	// So we can route the request to the graphql deployment that closer to the data.

	uri = window.GLOBAL_APOLLO_CLIENT_URI || uri;

	const payload: Array<{
		operationName: string;
		query: string;
		variables: { [key: string]: any };
	}> = [].concat(typeof options.body === 'string' ? JSON.parse(options.body) : []); // concat normalizes into an array

	const operationNames = payload.map(({ operationName, variables }) => {
		// There are various webitem location we want to distinguish them
		if (operationName === 'WebItemLocationQuery' || operationName === 'WebPanelLocationQuery') {
			return `${operationName}:${
				variables?.location || (variables?.locations || []).join(',') || ''
			}`;
		}
		return operationName;
	});

	// pass as headers for rate-limiting compatibility
	const operationNamesList = operationNames.join(',');
	if (!options.headers) {
		options.headers = {};
	}
	appendHeader(options.headers, 'X-APOLLO-OPERATION-NAME', operationNamesList);
	options.referrerPolicy = options.referrerPolicy || 'same-origin';

	// At this point the query has lost the structure and formatted back to string
	// However it is different in experimental queries. The AST is always accessible
	// Refer to next/packages/graphql/src/links/ExperimentalLink/schemaLink.ts
	const executeFetch = () => {
		return (
			isExperienceAwareFetchAlwaysEnabled() || payload.some((item) => item.query.includes('@SLA'))
				? experienceAwareFetch
				: cfetch
		)(`${uri}?q=${operationNamesList}`, options);
	};

	const markingPrefix = process.env.REACT_SSR
		? 'ssr-app/prepare/preloadQuery/fetch:'
		: 'wf/client-graphql/';
	const markingNames = operationNames.map(
		(name) => `${markingPrefix}${name?.replace(/[^\-\.\w]/g, '_')}`,
	);
	if (process.env.REACT_SSR) {
		return SSRMeasures.run(markingNames, executeFetch);
	}
	// Ignore batch queries because they are not deterministic. Don't make sense to show on performance waterfall.
	return operationNames.length > 1
		? executeFetch()
		: WaterfallMeasures.run(markingNames, executeFetch);
};

/**
 * Override fetch function used in terminating (http batch) link, to throttle network requests.
 * Concurrent network requests are limited based on MAX_CONCURRENCY_FOR_THROTTLED constant.
 *
 * Realistically, this will be used when there are a large volume of queries are triggered together, upon page load. Therefore,
 * resolving requests in approximate order is sufficient.
 */
const requestPool: Promise<Response>[] = [];
const throttledFetch = (uri: string, options: RequestInit) => {
	if (requestPool.length >= MAX_CONCURRENCY_FOR_THROTTLED) {
		// pool is full, so queue up next request behind the first request in the pool
		const queuedFetchFn = async () => {
			await requestPool.shift();
			return wrappedFetch(uri, options);
		};

		const queuedFetch: Promise<Response> = queuedFetchFn();
		requestPool.push(queuedFetch);
		return queuedFetch;
	}

	// pool has available slot - add new request to pool and begin request immediately
	const nextFetch = wrappedFetch(uri, options);
	requestPool.push(nextFetch);
	return nextFetch;
};

const createLink = ({
	experimentalSchemaLinkOverride,
	ccGraphqlSchemaLinkOverride,
}: {
	experimentalSchemaLinkOverride?: ApolloLink;
	ccGraphqlSchemaLinkOverride?: ApolloLink;
}) => {
	const httpLinkOptions = {
		uri: URI,
		credentials: 'same-origin',
		fetch: wrappedFetch,
	};

	if (process.env.REACT_SSR) {
		const SSRLinks = [addSuperAdminDirectiveLink, SSRLink()];
		if (process.env.CLOUD_ENV === 'staging' || process.env.CLOUD_ENV === 'branch') {
			SSRLinks.push(SSRTestLink());
		}
		return from(SSRLinks).concat(
			ccGraphqlSchemaLinkOverride ||
				split(
					({ getContext }) => {
						const httpBatchConfig = getSSRFeatureFlag('confluence.ssr.http.batch') || {};
						return getContext().single || !httpBatchConfig.enabled;
					},
					new HttpLink(httpLinkOptions),
					new BatchHttpLink({
						...httpLinkOptions,
						batchMax: 4,
						batchInterval: 0,
					}),
				),
		);
	} else {
		const httpLink = split(
			({ getContext }) => {
				const disableBatching = FeatureGates.checkGate('confluence_disable_spa_batching');
				return (
					getContext().single ||
					disableBatching ||
					// In integration tests we deliberately block some queries in order to test the loading state
					// Randomly batching other queries with them will make UI fail to load
					// @ts-ignore TODO FIXME
					window.Cypress ||
					// @ts-ignore Property 'isPlaywright' does not exist on type 'Window & typeof globalThis'
					!!window.isPlaywright
				);
			},
			new HttpLink(httpLinkOptions),
			split(
				({ getContext }) => !!getContext().throttle,
				new HttpLink({
					...httpLinkOptions,
					fetch: throttledFetch,
				}),
				new BatchHttpLink(httpLinkOptions),
			),
		);

		const processingLinks = [
			deleteExtensionsLink,
			addSuperAdminDirectiveLink,
			networkStatusLink(),
			statusCodeRetryLink,
			networkErrorRetryLink,
			externalShareLink,
			UFOLoggerLink,
		];

		let ccGraphLink: ApolloLink;
		if (process.env.NODE_ENV === 'testing') {
			// SSR and test env don't support websocket
			ccGraphLink = from(processingLinks).concat(httpLink);
		} else {
			ccGraphLink = from(processingLinks).split(isWS, webSocketLink(), httpLink);
		}

		const links = [createOnErrorLink(), graphqlSyntheticErrorGenerator()];

		// prevents adding bundle size for prod customers
		if (
			process.env.CLOUD_ENV === 'hello' ||
			process.env.CLOUD_ENV === 'staging' ||
			process.env.CLOUD_ENV === 'branch' ||
			(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'testing')
		) {
			links.unshift(queryOverridesLink());
		}

		return from(links).split(
			// Condition
			(operation) => isExperimental(operation),
			// If query contains @experimental directive
			experimentalSchemaLinkOverride || ExperimentalLink(),
			// Else
			ccGraphqlSchemaLinkOverride || ccGraphLink,
		);
	}
};

export const apolloCache = createCache();

export const createClient = (
	options: {
		experimentalSchemaLinkOverride?: ApolloLink;
		ccGraphqlSchemaLinkOverride?: ApolloLink;
		initializeNewCache?: boolean;
	} = {},
) => {
	// Creates window.__APOLLO_STATS__ call before creating the client
	resetApolloStats();

	const cache = options.initializeNewCache ? createCache() : apolloCache;
	// @ts-ignore TODO FIXME
	if (typeof window['__APOLLO_STATE__'] === 'object') {
		// @ts-ignore TODO FIXME
		cache.restore(window['__APOLLO_STATE__']);
	}
	const client = new ApolloClient({
		// Prevents refresh. Should only be enabled on the server-side thus process.env.REACT_SSR
		ssrMode: Boolean(process.env.REACT_SSR),
		link: createLink(options),
		typeDefs: ClientSchema,
		cache,
	});

	if (!process.env.REACT_SSR) {
		// Don't initialize the client cache for tests so we can mock it
		if (!options.ccGraphqlSchemaLinkOverride) {
			writeNetworkStatus(client.cache, NetworkStatus.ONLINE);
		}

		cfetch.subscribe((response, error) => {
			if (response) {
				// It's NetworkStatusLink's responsibility/concern to interpret
				// cc-graphql errors (which may be carried in an OK HTTP response).
				if (response.url?.indexOf(URI) === -1) {
					writeNetworkStatus(client.cache, NetworkStatus.ONLINE);
				}
			} else if (error instanceof NoNetworkError && !error.ignore && !causedByAbortError(error)) {
				writeNetworkStatus(client.cache, NetworkStatus.OFFLINE);
			}
		});
	}

	return client;
};
