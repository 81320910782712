import React from 'react';
import { useQuery } from 'react-apollo';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import isEqual from 'lodash/isEqual';

import { QuickReloadContextProvider } from '@confluence/quick-reload/entry-points/subscription';
import { initialPageState, pageStateShape, PageStateContainer } from '@confluence/page-context';
import { PageRestrictionsContextProvider } from '@confluence/page-restrictions-context';
import { ScreenEvent } from '@confluence/analytics';
import { PremiumAdminEvent } from '@confluence/super-admin';
import { PremiumOnboarding } from '@confluence/premium-onboarding';
import { CreateContentLegacyQueryParamRedirect } from '@confluence/create-blank-fabric-page/entry-points/CreateContentLegacyQueryParamRedirect';
import { CreateContentDataPreloader } from '@confluence/create-blank-fabric-page/entry-points/CreateContentDataPreloader';
import { EXTERNAL_SHARE, CREATE_CONTENT } from '@confluence/named-routes';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { EditPageLoadingListener } from '@confluence/load-edit-page';
import {
	SearchSessionStateContainer,
	useSearchSessionIdFromQueryParam,
} from '@confluence/search-session';

import { SPACE_OVERVIEW } from './constants';
import { ScreenQuery } from './ScreenQuery.graphql';

/**
 * Screen ensures that the elements it renders are always in the same
 * position, to avoid remounts.
 *
 * For example, going from this tree:
 *
 *   <Layout>
 *     <ScreenEvent />
 *     {children}
 *   </Layout>
 *
 * To this tree:
 *
 *   <Layout>
 *     {children}
 *   </Layout>
 *
 * Would normally cause any components in `children` to remount.
 *
 * But going to the following does not:
 *
 *   <Layout>
 *     {null}
 *     {children}
 *   </Layout>
 */

const newRefIfChanged = memoizeOne((obj) => ({ ...obj }), isEqual);

export function Screen({ screenEvent, pageState = {}, showPremiumOnboarding = true, children }) {
	const pageStateValues = {
		// setState does a shallow merge this will cause transition to read stale data
		...initialPageState,
		// Making a new copy is important here because isEqual will bail if it is a same reference.
		// If we update pageState directly here it will be the same reference when fetchSpaceHomePage comes back.
		// newRefIfChanged won't pick the change up to create a new copy of page state thus not triggering the re-render of children.
		...pageState,
	};

	const missingContentId =
		!pageStateValues.contentId || pageStateValues.contentId === SPACE_OVERVIEW;

	const skipQuery = !(process.env.REACT_SSR || (pageStateValues.spaceKey && missingContentId));

	const { data: screenQueryData, loading: screenQueryLoading } = useQuery(ScreenQuery, {
		variables: {
			spaceKey: pageStateValues.spaceKey,
		},
		skip: skipQuery,
	});

	if (missingContentId) {
		if (screenQueryData && !screenQueryLoading) {
			pageStateValues.contentId = screenQueryData?.space?.homepage?.id;
			pageStateValues.contentIdLoading = false;
		} else {
			delete pageStateValues.contentId;
			pageStateValues.contentIdLoading = true;
		}
	}

	const isCreateContentRoute = pageStateValues.routeName === CREATE_CONTENT.name;
	if (
		// If query was skipped means we can never resolve the content id. Most likely there is no space key
		skipQuery ||
		// create flow does not require contentId so set this value to false
		// to prevent issue of blank white screen when redirected to viewing a content on its route
		isCreateContentRoute
	) {
		pageStateValues.contentIdLoading = false;
	}

	const { searchSessionId, additionalAnalyticsToParse } = useSearchSessionIdFromQueryParam({
		spaceKey: pageStateValues.spaceKey,
		contentId: pageStateValues.contentId,
	});

	//this captures if any page is inside a space when viewed, for the purpose of tracking space usage
	if (screenEvent && pageStateValues.spaceKey) {
		let { attributes } = screenEvent;
		if (attributes == null || typeof attributes !== 'object')
			screenEvent.attributes = attributes = {};
		// Allow the `Screen` consumer the final say. For example, Company Hub
		// hides its space from the user, doesn't refer to its page as such, is
		// rather presented to the user as "a new surface", and thus doesn't
		// constitute space usage from the perspective of the user.
		if (attributes.screenIsInSpace == null) attributes.screenIsInSpace = true;
	}

	const extra = [
		screenEvent && (
			<ScreenEvent
				name={screenEvent.name}
				id={screenEvent.id || 'none'}
				attributes={screenEvent.attributes}
				key="screen"
			/>
		),
		...(pageStateValues.routeName === EXTERNAL_SHARE.name
			? []
			: [
					<ErrorBoundary key="missionControlBoundary" attribution={Attribution.ADMIN_EXPERIENCE}>
						<PremiumAdminEvent key="premiumAdmin" />
						{showPremiumOnboarding && <PremiumOnboarding key="premiumOnboarding" />}
					</ErrorBoundary>,
					// CreateContentLegacyQueryParamRedirect & CreateContentDataPreloader are using PageState, it needs to be placed here.
					<CreateContentLegacyQueryParamRedirect key="CreateContentLegacyQueryParamRedirect" />,
					<CreateContentDataPreloader key="CreateContentDataPreloader" />,
					<EditPageLoadingListener key="editPageLoadingListener" />,
				]),
	];

	return (
		<PageStateContainer {...newRefIfChanged(pageStateValues)}>
			<SearchSessionStateContainer
				searchSessionId={searchSessionId}
				additionalAnalyticsToParse={additionalAnalyticsToParse}
			>
				<QuickReloadContextProvider contentId={pageStateValues.contentId}>
					<PageRestrictionsContextProvider contentId={pageStateValues.contentId}>
						{extra.concat(children)}
					</PageRestrictionsContextProvider>
				</QuickReloadContextProvider>
			</SearchSessionStateContainer>
		</PageStateContainer>
	);
}

Screen.propTypes = {
	children: PropTypes.any.isRequired,

	/**
	 * If provided, will send a "screen" analytics event.
	 */
	screenEvent: PropTypes.shape({
		name: PropTypes.string.isRequired,
		id: PropTypes.string,
		attributes: PropTypes.object,
		objectType: PropTypes.string,
		objectId: PropTypes.string,
	}).isRequired,

	/**
	 * If provided, will be used as PageState.
	 */
	pageState: pageStateShape,

	/**
	 * If false, the Premium onboarding flow is not rendered
	 * For now, this will be false on the editor routes to not intrude
	 * in the user's editing experience.
	 */
	showPremiumOnboarding: PropTypes.bool,
};
