import { getBitValueFromBool, getBoolFromBitValue } from '../../common/utils/helpers';
import {
	ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY,
	DEFAULT_PREFERENCES,
	V002_SCHEMA_KEY,
	V003_SCHEMA_KEY,
} from '../../constants';
// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths
import { getCookie } from '../../controllers/cookie-controls/get-cookie';
import { type ConsentPreference, ContextualConsentProperty, PreferenceCategory } from '../../types';

import {
	type ConsentDisplayedTextMap,
	ConsentHubKey,
	type ConsentHubResponse,
	type ConsentRecord,
	type ConsentToken,
	type DeserializedConsents,
} from './types';

const isLegacyGeoTargetingV2Token = (consentToken: string) => {
	const version = consentToken[0] + consentToken[1] + consentToken[2];
	const isV2Schema = version === V002_SCHEMA_KEY;
	return isV2Schema;
};

export const hasLegacyV2TokenConsentedPreviously = (consentToken: string) => {
	if (isLegacyGeoTargetingV2Token(consentToken)) {
		// If we're looking at a V2 token and they have ANY saved 'consentedUnderXX' bits,
		// we know it must be a set of valid consents instead of defaults
		const consentedUnderGDPR = consentToken[8] === '1';
		const consentedUnderCPRA = consentToken[9] === '1';
		const consentedUnderLGPD = consentToken[10] === '1';
		const consentedUnderFADP = consentToken[11] === '1';

		const hasConsentedPreviously =
			consentedUnderGDPR || consentedUnderCPRA || consentedUnderLGPD || consentedUnderFADP;
		return hasConsentedPreviously;
	}
	return false;
};

export const checkIfTokenIsDefault = (consentToken: string) => {
	const isLegacyV2Token = isLegacyGeoTargetingV2Token(consentToken);
	if (isLegacyV2Token) {
		const legacyTokenHasConsents = hasLegacyV2TokenConsentedPreviously(consentToken);
		return !legacyTokenHasConsents;
	}

	const isDefaultToken = getBoolFromBitValue(consentToken[7]);
	return isDefaultToken;
};

export const getDefaultBits = (consentsUnavailable?: boolean): ConsentToken => {
	const version = V003_SCHEMA_KEY;

	// DEFAULT_PREFERENCES now has the default boolean baked into it, so we don't need to set it here
	const serializedBits = Object.entries(DEFAULT_PREFERENCES).reduce((acc, [key, val]) => {
		// Skip the STRICTLY_NECESSARY as it's always allowed
		if (key === PreferenceCategory.StrictlyNecessary) {
			return acc;
		}

		if (key === ContextualConsentProperty.UserIsAuthenticated) {
			// If consents are missing when setting these defaults, the user will be logged out,
			// so pack this info into the consent token bits
			const userIsAuthed = !consentsUnavailable;
			return acc + getBitValueFromBool(userIsAuthed);
		}

		if (key === ContextualConsentProperty.ConsentDataUnavailable) {
			// If consents are missing when setting these defaults, the user will be logged out,
			// so pack this info into the consent token bits
			return acc + getBitValueFromBool(!!consentsUnavailable);
		}
		return acc + getBitValueFromBool(val as boolean);
	}, '');
	return `${version}${serializedBits}`;
};

export const getSavedConsentBits = (consents: ConsentRecord[]): ConsentToken => {
	const version = V003_SCHEMA_KEY;
	// As 'strictlyNecessaryConsent' would always be allowed, we don't store it in the cookie or use ConsentHub
	const functionalConsent = getBitValueFromBool(
		!!consents.find((key) => key.key === ConsentHubKey.Functional)?.granted,
	);
	const analyticsConsent = getBitValueFromBool(
		!!consents.find((key) => key.key === ConsentHubKey.Analytics)?.granted,
	);
	const marketingConsent = getBitValueFromBool(
		!!consents.find((key) => key.key === ConsentHubKey.Marketing)?.granted,
	);
	const unknownConsent = getBitValueFromBool(
		!!consents.find((key) => key.key === ConsentHubKey.Unknown)?.granted,
	);

	// As these consents were returned from the server, ConsentHub is obviously available, they're logged in, and aren't defaults
	const isDefaultToken = 0;
	const userIsAuthenticated = 1;
	const consentHubDataUnavailable = 0;

	return [
		version,
		functionalConsent,
		analyticsConsent,
		marketingConsent,
		unknownConsent,
		isDefaultToken,
		userIsAuthenticated,
		consentHubDataUnavailable,
	].join('');
};

export const serializeConsents = ({ consents }: ConsentHubResponse): ConsentToken => {
	if (!consents) {
		// When generating consents for an unauthenticated user, use the fallback token if it exists
		// This circumvents us setting defaults for them if their token has expired, but they have consented previously
		const existingFallbackToken = getCookie(ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY);
		if (existingFallbackToken) {
			return existingFallbackToken;
		}
		// User is unauthed, will only use local consenting, consent data will also be unavailable
		return getDefaultBits(true); // e.g. 0030000101
	} else if (consents.length === 0) {
		// User is authed but has no consents in the system, so use defaults in the interim, consentData must be available
		return getDefaultBits(); // e.g. 0030000110
	} else {
		// User is authed and has consents in the system
		return getSavedConsentBits(consents); // e.g. 0030110110
	}
};

export const deserializeConsents = (
	unpackedUserPreferences: ConsentPreference,
	displayedTextMap: ConsentDisplayedTextMap,
) => {
	const validConsentHubCookieConsents = [
		PreferenceCategory.StrictlyNecessary,
		PreferenceCategory.Functional,
		PreferenceCategory.Analytics,
		PreferenceCategory.Marketing,
		PreferenceCategory.Unknown,
	];
	const consentKeyMap = {
		[PreferenceCategory.StrictlyNecessary]: ConsentHubKey.StrictlyNecessary,
		[PreferenceCategory.Functional]: ConsentHubKey.Functional,
		[PreferenceCategory.Analytics]: ConsentHubKey.Analytics,
		[PreferenceCategory.Marketing]: ConsentHubKey.Marketing,
		[PreferenceCategory.Unknown]: ConsentHubKey.Unknown,
	};

	const consentRecords: DeserializedConsents = validConsentHubCookieConsents.map((consentKey) => {
		const key = consentKeyMap[consentKey];
		return {
			key,
			granted: !!unpackedUserPreferences[consentKey],
			gatheredTs: new Date().toISOString(),
			displayedText: displayedTextMap[consentKey],
		};
	});

	return consentRecords;
};

export const getConsentTimestamp = ({ consents }: ConsentHubResponse) => {
	if (consents && consents.length) {
		// As consents can be updated individually, we need to find the most recent timestamp to use as "most recent consent"

		const timestamps = consents.map((consent: ConsentRecord) => {
			return new Date(consent.updatedTs).getTime();
		});

		const maxTimestamp = Math.max(...timestamps);
		const mostRecentConsentTimestamp = new Date(maxTimestamp).toISOString();

		return mostRecentConsentTimestamp;
	}
};

export const checkIfConsentsAreStale = (consentTimestamp?: string) => {
	// Privacy has determined that we should re-prompt users for consent if they haven't
	// consented in over a year regardless, because they're legally stale/expired
	let consentedOverAYearAgo = false;
	if (consentTimestamp) {
		const oneYearAgo = new Date();
		oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

		consentedOverAYearAgo = new Date(consentTimestamp) < oneYearAgo;
	}
	return consentedOverAYearAgo;
};

export class StatusCodeError extends Error {
	public status: number;

	constructor(message: string, status: number) {
		super(message);
		this.status = status;

		Object.setPrototypeOf(this, StatusCodeError.prototype);

		// Captures the stack trace (for environments that support it)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, StatusCodeError);
		}

		this.name = 'StatusCodeError';
	}
}
