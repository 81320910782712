import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import { fg } from '@atlaskit/platform-feature-flags';
import { isFedRamp } from '@atlassian/atl-context';

import buildActionItemsPanelManifest from './manifests/action-items/manifest';
import buildSummarisePagePanelManifest from './manifests/summarise-page/manifest';
import type { AIManifest } from './types';

export const getAIPanelsProvider = ({ api, intl, providerFactory }: AIManifest) => {
	const summarisePage = buildSummarisePagePanelManifest({
		api,
		intl,
		providerFactory,
	});
	const findActionItems = buildActionItemsPanelManifest({
		api,
		intl,
		providerFactory,
	});
	const extensions = [];

	/**
	 * WARNING: Do not remove the isFedRamp check.
	 * This is to ensure that AI functionality is not enabled in FedRamp environments.
	 */
	if (!isFedRamp()) {
		if (fg('platform.editor.ai.enable-ai-summary-blocks_r040m')) {
			extensions.push(summarisePage);
		}

		if (fg('platform.editor.ai.enable-ai-action-items-block_vctyf')) {
			extensions.push(findActionItems);
		}
	}

	return new DefaultExtensionProvider<any>(extensions);
};
