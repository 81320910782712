export type ChildrenMacroParams = Partial<{
	all: Value;
	depth: Value;
	excerptType: Value;
	first: Value;
	page: Value;
	reverse: Value;
	sort: Value;
	style: Value;
}>;

type Value = {
	value: string;
};

export type ChildrenMacroSupportedParams = {
	depth?: {
		value: '1';
	};
};

export const childrenMacroParamsSupported = (
	params: unknown,
): params is ChildrenMacroSupportedParams => {
	if (!vaidateParams(params)) {
		return false;
	}

	if (
		params.all ||
		params.excerptType ||
		params.first ||
		params.page ||
		params.reverse ||
		params.sort ||
		params.style
	) {
		return false;
	}

	if (params.depth && params.depth.value !== '1') {
		return false;
	}

	return true;
};

const vaidateParams = (params: unknown): params is ChildrenMacroParams => Boolean(params);
