import gql from 'graphql-tag';

export const DatabaseUnifiedQuery = gql`
	query DatabaseUnifiedQuery($contentId: ID, $spaceKey: String, $includeAlias: Boolean = false) {
		content(id: $contentId) {
			nodes {
				id
				referenceId
				title
				operations {
					operation
					targetType
				}
				hasRestrictions
				hasViewRestrictions
				hasInheritedRestrictions
				space {
					id
					name
					key
					type
					alias @include(if: $includeAlias)
				}
				type
				status
				ancestors {
					id
					type
					title
					status
				}
			}
		}
		spaces(spaceKey: $spaceKey, first: 10) {
			nodes {
				id
				name
				homepage {
					id
				}
			}
		}
		user(current: true) {
			id
			displayName
			photos {
				value
				isPrimary
			}
			emails {
				value
				isPrimary
			}
			locale
			confluence {
				space {
					id
				}
			}
		}
	}
`;
