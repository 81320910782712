import { query } from '@confluence/query-preloader-tools';

import { SpaceNavigationQuery } from './SpaceNavigation/SpaceNavigationQuery.graphql';
import { AdvancedFeaturesInstanceIsInTrialQuery } from './SpaceNavigation/AdvancedFeaturesInstanceIsInTrialQuery.graphl';

export function preloadSideNavigation(spaceKey: string, isLicensed: boolean) {
	return Promise.all([
		query({
			query: SpaceNavigationQuery,
			variables: {
				spaceKey,
				isLicensed,
			},
		}),
		query({
			query: AdvancedFeaturesInstanceIsInTrialQuery,
		}),
	]);
}
