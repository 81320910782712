import gql from 'graphql-tag';

export const FavouriteSpaceQuery = gql`
	query FavouriteSpaceQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			currentUser {
				isFavourited
			}
		}
	}
`;

const SpaceDropdownSpaceFragment = gql`
	fragment SpaceDropdownSpaceFragment on Space {
		id
		name
		key
		icon {
			path
		}
	}
`;

export const SpaceOverviewStarredSpacesQuery = gql`
	query SpaceOverviewStarredSpacesQuery {
		spaces(favourite: true, first: 99, status: "current") {
			nodes {
				...SpaceDropdownSpaceFragment
			}
		}
	}
	${SpaceDropdownSpaceFragment}
`;

export const SpaceOverviewCurrentSpaceQuery = gql`
	query SpaceOverviewCurrentSpaceQuery($selectedSpaceKey: String) {
		space(key: $selectedSpaceKey) {
			...SpaceDropdownSpaceFragment
			currentUser {
				isFavourited
			}
		}
	}
	${SpaceDropdownSpaceFragment}
`;
