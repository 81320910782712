import { useIsLivePagesFeatureEnabled } from './useIsLivePagesFeatureEnabled';
import { useLivePagesStore } from './useLivePagesStore';

/**
 * Checks that current page is a live page and that live pages feature flag is enabled
 * @returns boolean
 */
export const useIsLivePage = () => {
	const isLivePagesEnabled = useIsLivePagesFeatureEnabled();
	const [globalLiveState] = useLivePagesStore();
	return isLivePagesEnabled && globalLiveState.isLive;
};
