import { query } from '@confluence/query-preloader-tools';

import { preloadOptions } from '../Options/preloadOptions';

import { getRecentQueryOptions } from './recentQueryHelpers';
import { HomeRecentUserPreferencesQuery } from './HomeRecentQueries.graphql';

export const preloadRecent = ({
	cloudId,
	isLoggedIn,
	isWhiteboardViewedEnabled,
	isWhiteboardUpdatedEnabled,
	bestEffortTask,
	isSpaceAliasFFEnabled,
}: {
	cloudId: string;
	isLoggedIn: boolean;
	bestEffortTask: Function;
	isWhiteboardViewedEnabled?: boolean;
	isWhiteboardUpdatedEnabled?: boolean;
	isSpaceAliasFFEnabled: boolean;
}) => {
	return Promise.all([
		bestEffortTask('HomeRecentQueries', () =>
			query<unknown, {}>(
				getRecentQueryOptions({
					pill: 'all',
					cloudId,
					endCursor: '',
					isSpaceAliasFFEnabled,
					isWhiteboardViewedEnabled,
					isWhiteboardUpdatedEnabled,
				}),
			),
		),
		preloadOptions({ isLoggedIn }),
		...(isLoggedIn ? [query({ query: HomeRecentUserPreferencesQuery })] : []),
	]);
};
