import { ConfluencePageAri } from '@atlassian/ari/confluence';
import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';

import { LegacyBridgeQuery } from '../src/LegacyBridgeQuery.graphql';
import { LegacyBridgeQueryV2 } from '../src/LegacyBridgeQueryV2.graphql';
import type {
	LegacyBridgeQuery as LegacyBridgeQueryType,
	LegacyBridgeQueryVariables,
} from '../src/__types__/LegacyBridgeQuery';
import type {
	LegacyBridgeQueryV2 as LegacyBridgeQueryV2Type,
	LegacyBridgeQueryV2Variables,
} from '../src/__types__/LegacyBridgeQueryV2';

export async function preloadLegacyBridge({ contentId, isBlog }) {
	if (!contentId) {
		return;
	}
	const { cloudId, featureFlags } = await getPreloaderFnContext();
	const ari = ConfluencePageAri.create({
		siteId: cloudId,
		pageId: contentId,
	}).toString();
	const canUseLegacyBridgeQueryV2 = Boolean(
		featureFlags['confluence.frontend.legacy.bridge.query.migration_0f25n'] && !isBlog && ari,
	);

	if (canUseLegacyBridgeQueryV2) {
		return query<LegacyBridgeQueryV2Type, LegacyBridgeQueryV2Variables>({
			query: LegacyBridgeQueryV2,
			variables: { contentId: ari },
			errorPolicy: 'all',
		}).then(({ data }) => {
			if (
				data?.singleContent?.type !== 'page' ||
				(data?.pageV2?.parentType !== 'page' && data?.pageV2?.parentType !== null)
			) {
				return query<LegacyBridgeQueryType, LegacyBridgeQueryVariables>({
					query: LegacyBridgeQuery,
					variables: { contentId, isBlog },
					errorPolicy: 'all',
				});
			}
		});
	}

	return query<LegacyBridgeQueryType, LegacyBridgeQueryVariables>({
		query: LegacyBridgeQuery,
		variables: { contentId, isBlog },
		errorPolicy: 'all',
	});
}
