import type { ADNode } from "@atlaskit/editor-common/validator";
import type { MacroAttributes, ExtensionType } from "@atlaskit/editor-core";

import { TemplateVariableDialogLoader } from "@confluence/template-variable";

import {
  updateParams,
} from "../../extensions-common";
import type { MacroConfig } from "@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types";
import {
  EXTENSION_TYPE,
  EXTENSION_KEY,
  BODIED_EXTENSION_TYPE,
  INLINE_EXTENSION_TYPE,
  REGULAR_EXTENSION_TYPE,
} from "@confluence/fabric-extension-lib/entry-points/extensionConstants";

// types
import type {
  TemplateVariableType,
  TemplateVariableParams,
} from "./types";

export const TEXT_TYPE: TemplateVariableType = "text";
export const MULTI_LINES_TYPE: TemplateVariableType = "textarea";
export const LIST_TYPE: TemplateVariableType = "select";

export const defaultVariableName = "variable_name";

export function createDefaultTemplateVariableADNode(
  name = defaultVariableName
): ADNode {
  return {
    type: INLINE_EXTENSION_TYPE,
    attrs: {
      extensionType: EXTENSION_TYPE.TEMPLATE,
      extensionKey: EXTENSION_KEY.TEMPLATE,
      parameters: {
        type: TEXT_TYPE,
        name
      } as TemplateVariableParams
    }
  };
}

export function isTemplateVariableADNode(node: ADNode): boolean {
  return node.attrs && node.attrs.extensionType === EXTENSION_TYPE.TEMPLATE;
}

export function openTemplateVariableDialog(
  selectedNode: ADNode,
  config: MacroConfig
): Promise<MacroAttributes> {
  return new Promise<MacroAttributes>((resolve, reject) => {
    if (!selectedNode.attrs || !config.setActiveModal) {
      reject(new Error("Cannot open editing template variable dialog"));
      return;
    }

    const params: TemplateVariableParams = selectedNode.attrs.parameters;

    config.setActiveModal(TemplateVariableDialogLoader, {
      defaultVariableType: params.type,
      defaultVariableName: params.name,
      defaultRows: params.params && params.params.rows,
      defaultColumns: params.params && params.params.columns,
      defaultListOptions: params.params && params.params.options,
      onSave: (updatedParams: TemplateVariableParams): void => {
        const adf = updateParams(selectedNode, updatedParams);

        // MacroAttributes and ADNode are pretty much the same type, but
        // MacroAttributes requires either "extension", "bodiedExtension", or
        // "inlineExtension". This check is so we can pass the ADNode as a
        // MacroAttributes, but will default to "extension" if given an
        // unexpected value.
        let extensionType: ExtensionType = REGULAR_EXTENSION_TYPE;

        if (adf.type === BODIED_EXTENSION_TYPE) {
          extensionType = BODIED_EXTENSION_TYPE;
        } else if (adf.type === INLINE_EXTENSION_TYPE) {
          extensionType = INLINE_EXTENSION_TYPE;
        }

        resolve({
          ...adf,
          type: extensionType,
          attrs: adf.attrs || {}
        });
      },
      onClose: () => {
        if (config.closeActiveModal) {
          config.closeActiveModal();
        }
      }
    });
  });
}

export {
  getTemplateExtensionHandler,
  getTemplateVariableInputExtensionHandler
} from "./handlers";
