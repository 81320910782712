import React from 'react';

import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<svg
				width="24"
				height="25"
				viewBox="0 0 24 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				role="presentation"
			>
				<path
					d="M18 16.5H6C5.44772 16.5 5 16.9477 5 17.5C5 18.0523 5.44772 18.5 6 18.5H18C18.5523 18.5 19 18.0523 19 17.5C19 16.9477 18.5523 16.5 18 16.5Z"
					fill={token('color.icon', '#44546F')}
				/>
				<path
					d="M18 6.5H14C13.4477 6.5 13 6.94772 13 7.5C13 8.05228 13.4477 8.5 14 8.5H18C18.5523 8.5 19 8.05228 19 7.5C19 6.94772 18.5523 6.5 18 6.5Z"
					fill={token('color.icon', '#44546F')}
				/>
				<path
					d="M18 11.5H6C5.44772 11.5 5 11.9477 5 12.5C5 13.0523 5.44772 13.5 6 13.5H18C18.5523 13.5 19 13.0523 19 12.5C19 11.9477 18.5523 11.5 18 11.5Z"
					fill={token('color.icon', '#44546F')}
				/>
				<path
					d="M5.375 7.661C5.375 8.14424 5.76675 8.536 6.25 8.536C6.73325 8.536 7.125 8.14424 7.125 7.661C7.125 7.17775 6.73325 6.786 6.25 6.786C5.76675 6.786 5.375 7.17775 5.375 7.661Z"
					fill={token('color.icon', '#44546F')}
					stroke={token('color.icon', '#44546F')}
					strokeWidth="1.75"
				/>
				<path
					d="M9.375 7.661C9.375 8.14424 9.76675 8.536 10.25 8.536C10.7332 8.536 11.125 8.14424 11.125 7.661C11.125 7.17775 10.7332 6.786 10.25 6.786C9.76675 6.786 9.375 7.17775 9.375 7.661Z"
					fill={token('color.icon', '#44546F')}
					stroke={token('color.icon', '#44546F')}
					strokeWidth="1.75"
				/>
				<path
					d="M5.07 7.989C4.682 6.493 5.481 5.164 6.77 4.955M9.07 7.989C8.682 6.493 9.481 5.164 10.77 4.955"
					fill={token('color.icon', '#44546F')}
					strokeLinecap="round"
				/>
			</svg>
		</RainbowBorder>
	);
};
