import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { SPACE_PERMISSIONS_SETTINGS } from '@confluence/named-routes';

const SpaceSettingsLandingPageRouteComponent = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-SpaceSettingsLandingPageRouteComponent" */ './SpaceSettingsLandingPageRouteComponent'
		),
});

export const SpaceSettingsLandingPageRoute = ({ params: { spaceKey } }: RouteMatch) => {
	if (isCompanyHubSpaceKey(spaceKey)) {
		return (
			<Redirection name={SPACE_PERMISSIONS_SETTINGS.name} params={{ spaceKey, tab: 'users' }} />
		);
	}

	return <SpaceSettingsLandingPageRouteComponent spaceKey={spaceKey} />;
};

SpaceSettingsLandingPageRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }) => ({
	Screen: {
		screenEvent: { name: 'spaceSettingsLandingPage', id: spaceKey },
		pageState: { routeName: name, spaceKey },
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
	},
});
