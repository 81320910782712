import { preloadIntentBasedOnboardingState } from '@confluence/onboarding-helpers/entry-points/preloadIntentBasedOnboardingState';
import { preloadUserPersonalizationQuery } from '@confluence/onboarding-helpers/entry-points/preloadUserPersonalizationQuery';
import { preloadGetLicensedProductsQuery } from '@confluence/onboarding-helpers/entry-points/preloadGetLicensedProductsQuery';

export const INTENT_BASED_ONBOARDING_QUERY = 'IntentBasedOnboardingQuery';

export const preloadIntentBasedOnboarding = ({ userId }: { userId: string | null }) => {
	if (!userId) {
		return Promise.resolve();
	}
	return Promise.all([
		preloadUserPersonalizationQuery(userId),
		preloadIntentBasedOnboardingState(),
		preloadGetLicensedProductsQuery(),
	]);
};
