import Cookies, { type CookieAttributes } from 'js-cookie';

import { fg } from '@atlaskit/platform-feature-flags';

import { sendPackageOperationalEvent } from '../../../common/utils';
import { loadStorageControlsData } from '../../../common/utils/load-storage-controls-data';
import { Logger } from '../../../common/utils/logger';
import { type CategorizedCookiesCache, PreferenceCategory } from '../../../types';
import { getPreferences } from '../../storage-preferences/get-preferences';

import { Status } from './types';

export type { CookieAttributes as JSCookieAttributes };

const findStartsWithPatternMatch = (
	cookieName: string,
	{ startsWith }: CategorizedCookiesCache['patterns'],
): number | null => {
	// Setup initial pattern step and position
	let currentStep = startsWith;
	let position = 0;

	// Iterate through cookie name symbol by symbol
	while (position < cookieName.length) {
		// The value of cookie character at the position
		const currentStepValue = currentStep[cookieName[position]];
		// If there is no match for cookie symbol in cache, this means there is no
		// pattern match for the cookie name
		if (currentStepValue === undefined) {
			return null;
		}
		// If current step value is a number, this means we reached the end of the pattern
		// and should return category number
		if (typeof currentStepValue === 'number') {
			return currentStepValue;
		}
		// Continue to going through pattern
		currentStep = currentStepValue;
		position++;
	}

	// No pattern match - all patterns longer or shorter than cookie name
	return null;
};

const getPreferenceCategory = async (cookieName: string): Promise<PreferenceCategory | null> => {
	const storageControlsData = await loadStorageControlsData();

	if (!storageControlsData) {
		return null;
	} else {
		return getPreferenceCategoryFromPassedCache(cookieName, storageControlsData);
	}
};

/**
 * Synchronously fetches a cookie category from a CategorizedCookiesCache that has already been fetched
 *
 * This allows synchronous parts of the code to access cached versions of the cache but still maintain
 * a synchronous signature for clients
 *
 */
export const getPreferenceCategoryFromPassedCache = (
	cookieName: string,
	storageControlsData: CategorizedCookiesCache,
) => {
	let cookieCategoryNumber: number | null = null;
	const { keys, categories, patterns } = storageControlsData;
	// Find direct match in keys
	if (cookieName in Object(keys)) {
		cookieCategoryNumber = keys[cookieName];
	} else {
		// Find pattern match or null
		cookieCategoryNumber = findStartsWithPatternMatch(cookieName, patterns);
	}

	const numToCategoryMap = {
		[categories.STRICTLY_NECESSARY]: PreferenceCategory.StrictlyNecessary,
		[categories.FUNCTIONAL]: PreferenceCategory.Functional,
		[categories.ANALYTICS]: PreferenceCategory.Analytics,
		[categories.MARKETING]: PreferenceCategory.Marketing,
		[categories.UNKNOWN]: PreferenceCategory.Unknown,
	};

	return cookieCategoryNumber !== null ? numToCategoryMap[cookieCategoryNumber] : null;
};

/**
 * Given a cookie key, fetches preferences and categories and determines whether the cookie can be set
 *
 * Allows an optional allowedCallback to be passed which is invoked in the case the cookie is allowed to be set.
 * This allows synchronous parts of the code to check a cookie's status (e.g. the document.cookie set override)
 *
 */
export const canSetCookie = async ({
	cookieKey,
	allowedCallback = () => {},
	blockedCallback = () => {},
}: {
	cookieKey: string;
	allowedCallback?: () => void;
	blockedCallback?: ({ cookieHasCategory }: { cookieHasCategory?: boolean }) => void;
}) => {
	let shouldSetCookie;

	const cookieCategory = await getPreferenceCategory(cookieKey);
	if (cookieCategory === PreferenceCategory.StrictlyNecessary) {
		shouldSetCookie = true;
	} else {
		const preferences = await getPreferences();
		let hasPreferencesForCategory = false;

		if (!!preferences) {
			if (!!cookieCategory) {
				hasPreferencesForCategory = preferences[cookieCategory];
			}
		}
		shouldSetCookie = hasPreferencesForCategory;
	}
	if (shouldSetCookie) {
		allowedCallback?.();
	} else {
		blockedCallback?.({ cookieHasCategory: cookieCategory != null });
	}
	return shouldSetCookie;
};

/**
 * Sets a cookie asynchronously. This queries user preferences before setting
 * the cookie, so it must be used for any cookies which are not strictly
 * necessary.
 */
export const setCookie = async (
	key: string,
	value: string,
	attributes?: CookieAttributes,
): Promise<string> => {
	// Flag disabled, allow all cookies.
	if (!fg('platform.moonjelly.browser-storage-controls')) {
		const eventAttributes = {
			wasRejected: false,
			cookieKey: key,
		};
		try {
			Cookies.set(key, value, attributes);
			return Status.SUCCESS;
		} catch (e: any) {
			Logger.errorWithOperationalEvent({
				action: 'usedSetCookieError',
				attributes: eventAttributes,
				message: `Failed to use set cookie. ${e.message || ''}`,
			});
			return Status.FAILED;
		}
	}

	// Flag enabled, user belongs to an org that opted into cookies management and we should respect their preferences
	const shouldSetCookie = await canSetCookie({ cookieKey: key });

	const eventAttributes = {
		wasRejected: !shouldSetCookie,
		cookieKey: key,
	};
	try {
		sendPackageOperationalEvent({
			action: 'usedSetCookie',
			attributes: eventAttributes,
		});

		if (shouldSetCookie) {
			Cookies.set(key, value, { ...attributes, 'atl-set-cookie': true });
			return Status.SUCCESS;
		} else {
			return Status.BLOCKED;
		}
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'usedSetCookieError',
			attributes: eventAttributes,
			message: `Failed to use set cookie. ${e.message || ''}`,
		});
		return Status.BLOCKED;
	}
};
