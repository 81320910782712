import { cfetch } from '@confluence/network';
import { SSRMeasures } from '@confluence/action-measures';

import { LocalStorageQuery } from './LocalStorageQueryRaw';
import { getKeysForLocalStorageQuery } from './helpers';
import type { LocalStorageQueryVariables } from './__types__/LocalStorageQuery';
import type { StorageManagerInitContext } from './StorageManagerInitContext';

export async function getRemoteLocalStorage({
	userId,
	isLicensed,
}: StorageManagerInitContext): Promise<any> {
	if (window['__REMOTE_LOCAL_STORAGE__']) {
		return window['__REMOTE_LOCAL_STORAGE__'];
	}

	const URI = window['GLOBAL_APOLLO_CLIENT_URI'] || '/cgraphql';
	return (window['__REMOTE_LOCAL_STORAGE__'] = SSRMeasures.run(
		'ssr-app/prepare/preloadQuery/fetch:LocalStorageQuery',
		async () => {
			let result = null;
			try {
				if (isLicensed && userId) {
					const variables: LocalStorageQueryVariables = {
						stringKeys: getKeysForLocalStorageQuery(userId),
					};
					const res = await cfetch(`${URI}?q=LocalStorageQuery`, {
						method: 'POST',
						credentials: 'include',
						headers: {
							'X-APOLLO-OPERATION-NAME': 'LocalStorageQuery',
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							query: LocalStorageQuery,
							variables,
						}),
					});
					if (res.ok) {
						const { data } = await res.json();
						result = data?.localStorage?.stringValues;
					}
				}
			} catch (e) {}
			return (window['__REMOTE_LOCAL_STORAGE__'] = result);
		},
	));
}
