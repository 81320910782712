import React from 'react';

import { getOverride } from '@confluence/meta';
import { PageTitle } from '@confluence/page-title';

export default function withDocumentTitle(getTitle, wrapperStyle) {
	if (typeof wrapperStyle === 'undefined') {
		wrapperStyle = {};
	}
	return (BaseComponent) => (props) => {
		const title = getTitle(props);
		const siteTitle = getOverride('site-title') || '';
		const titleDefinedOnWindow = window['__DOCUMENT_TITLE_TAG__'] || '';
		let titleProp;
		if (titleDefinedOnWindow) {
			titleProp = titleDefinedOnWindow;
		} else if (title && siteTitle) {
			titleProp = `${title} - ${siteTitle}`;
		} else {
			titleProp = title || siteTitle;
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<div style={wrapperStyle}>
				{!window['__SSR_RENDERED__'] && <PageTitle title={titleProp} />}
				<BaseComponent {...props} />
			</div>
		);
	};
}
