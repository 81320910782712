/* eslint-disable import/dynamic-import-chunkname */
import React, { useContext, useEffect, Suspense } from 'react';
import {
	type ForgeDoc,
	type Dispatch,
	type ForgeExtensionPoints,
	type ProductEnvironment,
} from '@atlassian/forge-ui-types';
import { IntlProvider } from 'react-intl-next';
import { fg } from '@atlaskit/platform-feature-flags';

import { UnknownComponentErrorBoundary, GenericErrorBoundary } from '../error-boundary';
import { PortalProvider } from '../context/portal';
import { WidthProvider, RendererContext } from '../context';
import { Loader } from '../web-runtime/loader';
import { type ComponentMap, type ModalExtension } from './util';
import StyleErrorBoundary from '../error-boundary/StyleErrorBoundary';
import { type Extension } from '../web-client';
import { RendererNextLegacy } from './RendererWithComponents';
import FrameCountProvider from '../provider/FrameCountProvider';
import { parseExtensionId } from '../utils';
import { EnvironmentContext } from '../context';
import { createSrcFromExtension } from '../custom-ui/iframe/utils';
import { useForgeUiAnalyticsEvent } from '../analytics/useForgeUiAnalyticsEvent';

export interface RendererProps {
	/* ForgeDoc to be rendered */
	forgeDoc?: ForgeDoc;
	/* Map of component types to render functions */
	components?: (defaults: ComponentMap) => ComponentMap;
	/* Function used by components to dispatch effects */
	dispatch?: Dispatch;
	/* Error message to show the user. Set when an unexpected client-side error happens. */
	error?: string;
	/* Whether a dispatched effect is pending. */
	loading?: boolean;
	/* Replace the default spinner with a custom loading component. */
	loadingComponent?: React.ReactNode;
	/* Indicates whether a Native UI app is being rendered*/
	isNative?: boolean;
	/* Object that stores all necessary properties for a Modal Extension */
	modalExtension?: ModalExtension;
	/* Contains the module type of the extension */
	extensionType?: ForgeExtensionPoints;
	/* Contains all the details for the extension*/
	extension: Extension;
}

const getLoadingComponent = (loadingComponent?: React.ReactNode) => {
	if (loadingComponent) {
		return loadingComponent;
	}
	return <Loader />;
};

const getAppDomainName = (extension: Extension, environment: ProductEnvironment) => {
	const parsedExtension = extension && extension?.id ? parseExtensionId(extension.id) : undefined;

	if (parsedExtension) {
		const iframeSrc = createSrcFromExtension(parsedExtension.appId, extension, environment, '');
		const iframeUrl = new URL(iframeSrc);

		return iframeUrl.host;
	}
	return;
};

const RendererNextLatest = React.lazy(() =>
	/* webpackChunkName: "forge-ui-renderer-with-components" */
	import('./RendererWithComponents').then((module) => ({
		default: module.RendererNextLatest,
	})),
);

// This extra component exists so errors from the "render" function are caught in the an error boundary
// Also the error boundary requires a suspense fallback
const RendererNextWithProviders = (props: RendererProps) => {
	const { extension, forgeDoc, dispatch } = props;

	const defaultDispatch = React.useCallback(async () => {}, []);

	const forgeReactMajorVersion = forgeDoc?.forgeReactMajorVersion;

	const environment = useContext(EnvironmentContext);
	const domainName = getAppDomainName(extension, environment);

	const { trackExtensionLoaded } = useForgeUiAnalyticsEvent();

	useEffect(() => {
		if (fg('platform.forge-ui.use-new-event-schema')) {
			trackExtensionLoaded({
				renderType: props.isNative ? 'UIKit' : 'UIKit1',
				forgeEnvironment: extension?.environmentType,
			});
		}
	}, [extension?.environmentType, extension?.type, props.isNative, trackExtensionLoaded]);

	return (
		<Suspense fallback={getLoadingComponent(props.loadingComponent)}>
			<GenericErrorBoundary dispatch={dispatch}>
				<UnknownComponentErrorBoundary dispatch={dispatch}>
					<StyleErrorBoundary>
						<WidthProvider>
							<IntlProvider locale="en" defaultLocale="en">
								<PortalProvider>
									<RendererContext.Provider
										value={{
											egress: extension?.egress,
											forgeEnvironment: extension?.environmentType,
											forgeReactMajorVersion: forgeReactMajorVersion,
											appDomainName: domainName,
											extensionType: extension?.type,
										}}
									>
										{props.isNative && forgeReactMajorVersion && forgeReactMajorVersion >= 10 ? (
											<FrameCountProvider>
												<RendererNextLatest {...props} dispatch={dispatch ?? defaultDispatch} />
											</FrameCountProvider>
										) : (
											<RendererNextLegacy {...props} dispatch={dispatch ?? defaultDispatch} />
										)}
									</RendererContext.Provider>
								</PortalProvider>
							</IntlProvider>
						</WidthProvider>
					</StyleErrorBoundary>
				</UnknownComponentErrorBoundary>
			</GenericErrorBoundary>
		</Suspense>
	);
};

export default (props: RendererProps) => {
	return <RendererNextWithProviders {...props} />;
};
