// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!./variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* anmiation for disappearing of component */
.OO_v7OBgRF {
	opacity: 1;
}

.f11tuBFhg7 {
	opacity: 0.01;
	transition: opacity var(--transition-time) var(--transition-easing);
}

.AITw5DL0AP {
	/* fade out and slide up */
	opacity: 0.01;
	transform: translateY(-50%);
	transition:
		opacity var(--transition-time) var(--transition-easing),
		transform var(--transition-time) var(--transition-easing);
}

/* anmiation for appearing of component */
.kqdSPTHHxy {
	opacity: 0.01;
}

.z4l5uNB8es {
	/* CONFDEV-49061: 'opacity: 1' make a bug in IE 11 */
	opacity: 0.99999;
	transition: opacity var(--transition-time) var(--transition-easing);
}

/* fade in animation and has delay time equalling transition time */
.wztvpLbHz9 {
	animation-name: ie1qKs4fIp;
	animation-duration: 0.4s; /*\$transition-time * 2*/
	animation-timing-function: var(--transition-easing);
}

@keyframes ie1qKs4fIp {
	/*hidden in half of duration time*/
	0%,
	50% {
		opacity: 0.01;
		visibility: hidden;
		width: 0;
		height: 0;
	}
	51% {
		opacity: 0.01;
		visibility: visible;
		width: auto;
		height: auto;
	}
	100% {
		opacity: 1;
		visibility: visible;
		width: auto;
		height: auto;
	}
}
`, "",{"version":3,"sources":["webpack://./src/helpers/animation.module.css"],"names":[],"mappings":"AAEA,4CAA4C;AAC5C;CACC,UAAU;AACX;;AAEA;CACC,aAAa;CACb,mEAAmE;AACpE;;AAEA;CACC,0BAA0B;CAC1B,aAAa;CACb,2BAA2B;CAC3B;;2DAE0D;AAC3D;;AAEA,yCAAyC;AACzC;CACC,aAAa;AACd;;AAEA;CACC,oDAAoD;CACpD,gBAAgB;CAChB,mEAAmE;AACpE;;AAEA,mEAAmE;AACnE;CACC,0BAAwC;CACxC,wBAAwB,EAAE,uBAAuB;CACjD,mDAAmD;AACpD;;AAEA;CACC,kCAAkC;CAClC;;EAEC,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,SAAS;CACV;CACA;EACC,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;CACb;CACA;EACC,UAAU;EACV,mBAAmB;EACnB,WAAW;EACX,YAAY;CACb;AACD","sourcesContent":["@import './variables.css';\n\n/* anmiation for disappearing of component */\n.leaveFadeOut {\n\topacity: 1;\n}\n\n.leaveActiveFadeOut {\n\topacity: 0.01;\n\ttransition: opacity var(--transition-time) var(--transition-easing);\n}\n\n.leaveActiveFadeOutAndSlideUp {\n\t/* fade out and slide up */\n\topacity: 0.01;\n\ttransform: translateY(-50%);\n\ttransition:\n\t\topacity var(--transition-time) var(--transition-easing),\n\t\ttransform var(--transition-time) var(--transition-easing);\n}\n\n/* anmiation for appearing of component */\n.enterFadeIn {\n\topacity: 0.01;\n}\n\n.enterActiveFadeIn {\n\t/* CONFDEV-49061: 'opacity: 1' make a bug in IE 11 */\n\topacity: 0.99999;\n\ttransition: opacity var(--transition-time) var(--transition-easing);\n}\n\n/* fade in animation and has delay time equalling transition time */\n.enterActiveFadeInWithDelay {\n\tanimation-name: hidden-first-and-fade-in;\n\tanimation-duration: 0.4s; /*$transition-time * 2*/\n\tanimation-timing-function: var(--transition-easing);\n}\n\n@keyframes hidden-first-and-fade-in {\n\t/*hidden in half of duration time*/\n\t0%,\n\t50% {\n\t\topacity: 0.01;\n\t\tvisibility: hidden;\n\t\twidth: 0;\n\t\theight: 0;\n\t}\n\t51% {\n\t\topacity: 0.01;\n\t\tvisibility: visible;\n\t\twidth: auto;\n\t\theight: auto;\n\t}\n\t100% {\n\t\topacity: 1;\n\t\tvisibility: visible;\n\t\twidth: auto;\n\t\theight: auto;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leaveFadeOut": `OO_v7OBgRF`,
	"leaveActiveFadeOut": `f11tuBFhg7`,
	"leaveActiveFadeOutAndSlideUp": `AITw5DL0AP`,
	"enterFadeIn": `kqdSPTHHxy`,
	"enterActiveFadeIn": `z4l5uNB8es`,
	"enterActiveFadeInWithDelay": `wztvpLbHz9`,
	"hidden-first-and-fade-in": `ie1qKs4fIp`
};
export default ___CSS_LOADER_EXPORT___;
