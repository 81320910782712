// XMLs or CSVs should be in a zip file
export const ALLOWED_FILE_EXTENSIONS = ['.zip'];
export const IMPORT_STATUS_CHECK_POLL_INTERVAL_MS = 1500;
export const ADMIN_IMPORT_SPACES_ANALYTICS_SOURCE = 'adminImportSpaces';

export const CLOUD_MIGRATION_ASSISTANT_LINK =
	'https://marketplace.atlassian.com/apps/1219672/cloud-migration-assistant-for-confluence';
export const EXPORT_HELP_LINK =
	'https://confluence.atlassian.com/doc/export-content-to-word-pdf-html-and-xml-139475.html#ExportContenttoWord,PDF,HTMLandXML-ExportmultiplepagestoHTML,XML,orPDF';
export const IMPORT_SPACE_HELP_LINK =
	'http://confluence.atlassian.com/display/ConfCloud/Import+a+Confluence+Space';
