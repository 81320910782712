import { LoadableAfterPaint, LoadableBackground } from '@confluence/loadable';

export const CopySpacePermissionsForExistingSpacePortal = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CopySpacePermissionsForExistingSpacePortal" */ './CopySpacePermissionsForExistingSpacePortal'
			)
		).CopySpacePermissionsForExistingSpacePortal,
	name: 'CopySpacePermissionsForExistingSpacePortal',
});

export const CopySpacePermissionsForLegacyNewSpace = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CopySpacePermissionsForLegacyNewSpace" */ './CopySpacePermissionsForLegacyNewSpace'
			)
		).CopySpacePermissionsForLegacyNewSpace,
	name: 'CopySpacePermissionsForLegacyNewSpace',
});
