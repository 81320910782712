import { useCallback } from 'react';
import { useApolloClient } from 'react-apollo';
import { isFuture } from 'date-fns';

import { AccessStatus, useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';
import type { NetworkError } from '@confluence/network';
import { BadStatusError, cfetch } from '@confluence/network';
import { markErrorAsHandled } from '@confluence/graphql';

import { useLockScreenActions, useLockScreenState } from './LockScreenStore';
import { isVariationForTrialEndLockScreen, useFeatureExposedEvent } from './utils';
import type { LockScreenQuery as LockScreenQueryType } from './__types__/LockScreenQuery';
import { LockScreenQuery } from './LockScreenQuery.graphql';
import type { LockScreenServiceError, LockScreenServiceResponse } from './types';

const LOCK_SCREEN_CLOSED_TRAIT_NAME = 'confluence_trial_end_lock_screen_closed_first';

const fetchLockScreenDetails = async (
	entitlementId: string,
): Promise<LockScreenServiceResponse> => {
	return cfetch(
		`/gateway/api/growth/trial-end-lock-screen/is-locked?entitlementId=${entitlementId}`,
	)
		.then((result) => result.json())
		.catch((error: NetworkError) => {
			const errorResponse: LockScreenServiceError = {
				name: error.name,
				message: error.message,
			};
			if (error instanceof BadStatusError) {
				errorResponse.status = error.response.status;
				errorResponse.traceId = error.response.headers.get('X-Trace-Id') || undefined;
			}

			return Promise.reject(errorResponse);
		});
};

const fetchPersonalization = async (cloudId: string, userId: string) => {
	return cfetch(`/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}/user/${userId}`)
		.then((result) => result.json())
		.catch((error) => {
			const errorResponse: LockScreenServiceError = {
				name: error.name,
				message: error.message,
			};
			if (error instanceof BadStatusError) {
				errorResponse.status = error.response.status;
				errorResponse.traceId = error.response.headers.get('X-Trace-Id') || undefined;
			}

			return Promise.reject(errorResponse);
		});
};

const isExpectedLockScreenQueryError = (error: any) => {
	return !!error.message && error.message.includes('User does not have required permissions');
};

export const useUpdateLockScreen = () => {
	const fireExposureEvent = useFeatureExposedEvent();
	const apolloClient = useApolloClient();
	const { cloudId, edition, userId, isLicensed, isLoggedIn, accessStatus } = useSessionData();
	const { lockScreen, unlockScreen } = useLockScreenActions();
	const { wasDismissed } = useLockScreenState();

	return useCallback(async () => {
		if (wasDismissed) {
			return;
		}

		if (edition === ConfluenceEdition.FREE) {
			unlockScreen();
			fireExposureEvent('invalidEdition', { edition });

			return;
		}

		if (!userId) {
			unlockScreen();
			fireExposureEvent('noUserId', {
				edition,
			});

			return;
		}

		if (!isLoggedIn) {
			unlockScreen();
			fireExposureEvent('notLoggedIn', {
				edition,
			});

			return;
		}

		if (!isLicensed) {
			unlockScreen();
			fireExposureEvent('notLicensed', {
				edition,
			});

			return;
		}

		if (
			!accessStatus ||
			![AccessStatus.LICENSED_ADMIN_ACCESS, AccessStatus.LICENSED_USE_ACCESS].includes(accessStatus)
		) {
			unlockScreen();
			fireExposureEvent('invalidAccessStatus', {
				edition,
				accessStatus,
			});

			return;
		}

		const [lockScreenQueryResponseOutcome, personalizationOutcome] = await Promise.allSettled([
			apolloClient.query<LockScreenQueryType>({
				query: LockScreenQuery,
			}),
			fetchPersonalization(cloudId, userId),
		]);

		const serviceOutcomes = {
			lockScreenQuery: lockScreenQueryResponseOutcome,
			personalization: personalizationOutcome,
		};

		if (lockScreenQueryResponseOutcome.status === 'rejected') {
			if (isExpectedLockScreenQueryError(lockScreenQueryResponseOutcome.reason)) {
				markErrorAsHandled(lockScreenQueryResponseOutcome.reason);
			}

			unlockScreen();
			fireExposureEvent('lockScreenQueryFetchError', {
				error: lockScreenQueryResponseOutcome.reason,
				serviceOutcomes,
				edition,
			});

			return;
		}

		if (personalizationOutcome.status === 'rejected') {
			unlockScreen();
			fireExposureEvent('personalizationApiFetchError', {
				error: personalizationOutcome.reason,
				serviceOutcomes,
				edition,
			});

			return;
		}

		const entitlementId =
			lockScreenQueryResponseOutcome.value.data.siteConfiguration.ccpEntitlementId;

		if (!entitlementId) {
			unlockScreen();
			fireExposureEvent('missingEntitlementId', { edition });

			return;
		}

		const billingSourceSystem =
			lockScreenQueryResponseOutcome.value.data.license?.billingSourceSystem;

		if (billingSourceSystem !== 'CCP') {
			unlockScreen();
			fireExposureEvent('notCCP', {
				entitlementDetails: {
					entitlementId,
					billingSourceSystem,
				},
				edition,
				serviceOutcomes,
			});

			return;
		}

		const trialEndDate = lockScreenQueryResponseOutcome.value.data.license?.trialEndDate;

		const trialEndTime = trialEndDate && Number(new Date(trialEndDate));

		const entitlementDetails = {
			billingSourceSystem,
			entitlementId,
			trialEndTime: trialEndTime || null,
		};

		if (trialEndTime && isFuture(trialEndTime)) {
			unlockScreen();
			fireExposureEvent('inTrial', {
				entitlementDetails,
				edition,
			});

			return;
		}

		const modalClosedTraitExists = !!personalizationOutcome.value.attributes?.find(
			(attribute: { name: string }) => attribute.name === LOCK_SCREEN_CLOSED_TRAIT_NAME,
		);

		if (modalClosedTraitExists) {
			unlockScreen(true);
			fireExposureEvent('dismissed', {
				entitlementDetails,
				edition,
			});

			return;
		}

		try {
			const lockScreenDetails = await fetchLockScreenDetails(entitlementId);

			if (lockScreenDetails.predunning === false) {
				unlockScreen();
				fireExposureEvent('notInPreDunning', {
					entitlementDetails,
					lockScreenDetails,
					edition,
				});

				return;
			}

			fireExposureEvent(null, {
				entitlementDetails,
				lockScreenDetails,
				edition,
			});

			if (isVariationForTrialEndLockScreen()) {
				lockScreen({
					entitlementDetails,
					lockScreenDetails,
				});
			} else {
				unlockScreen();
			}
		} catch (error) {
			unlockScreen();
			fireExposureEvent('fetchEligibilityFailed', {
				entitlementDetails,
				error,
				edition,
			});
		}
	}, [
		wasDismissed,
		edition,
		userId,
		isLoggedIn,
		isLicensed,
		accessStatus,
		apolloClient,
		cloudId,
		unlockScreen,
		fireExposureEvent,
		lockScreen,
	]);
};
