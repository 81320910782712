import type { FC } from 'react';
import React, { useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { SessionDataType } from '@confluence/session-data';

const DONT_CHECK = -1;

/**
 * Periodically compare a running time counter against the system time to determine
 * how much time JavaScript may have been inactive for.
 *
 * If the amount of time that JavaScript has been down for is above the specified threshold,
 * then send a GASv3 event to help in determining performance issues.
 *
 * Events that fire after an interval starts and end before the next interval are not detected,
 * just the amount of time that the interval is delayed.  This means that the more frequent
 * the interval, the more accurate the response, but more frequent checks slow down application execution.
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !! To not interfere with the overall application performance, the !!
 * !! checking should happen infrequently.                           !!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export const JavaScriptInactivityTracker: FC<{
	sessionData: SessionDataType;
}> = ({ sessionData }) => {
	const _totalMinimumInactivityTime = React.useRef(0);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const checkingFrequency: number = Number(
		sessionData?.featureFlags['confluence.frontend.javascript-inactivity-tracker.frequency'],
	);
	const singleEventThreshold: number = Number(
		sessionData?.featureFlags[
			'confluence.frontend.javascript-inactivity-tracker.single-event-threshold'
		],
	);
	const totalInactivityThreshold: number = Number(
		sessionData?.featureFlags['confluence.frontend.javascript-inactivity-tracker.total-threshold'],
	);

	useEffect(() => {
		// Allow the flag a way to disable the javascript inactivity checking functionality
		if (!checkingFrequency || checkingFrequency === DONT_CHECK) {
			return;
		}

		let lastTime = Date.now();
		let totalWarningSent = false;

		const notifyOfLongJSDowntime = (type: 'single' | 'total') => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'paused',
					actionSubject: 'JavaScript',
					source: 'JavaScriptInactivityTracker',
					attributes: {
						type,
						totalApproxInactivityTime: _totalMinimumInactivityTime.current,
					},
				},
			}).fire();
		};

		const checkTime = () => {
			const currentTime = Date.now();
			const elapsed = currentTime - lastTime;
			const minimumInactivityTime = Math.max(0, elapsed - checkingFrequency); // Minimum, because delays that happen after the interval fires and stop before it fires again are not noticed.
			_totalMinimumInactivityTime.current += minimumInactivityTime;

			if (minimumInactivityTime > singleEventThreshold) {
				notifyOfLongJSDowntime('single');
			}
			if (!totalWarningSent && _totalMinimumInactivityTime.current > totalInactivityThreshold) {
				totalWarningSent = true;
				notifyOfLongJSDowntime('total');
			}

			lastTime = currentTime;
		};

		const intervalId = setInterval(checkTime, checkingFrequency);
		return () => clearInterval(intervalId);
	}, [createAnalyticsEvent, checkingFrequency, singleEventThreshold, totalInactivityThreshold]);

	return null;
};
