/* eslint-disable @atlaskit/platform/no-invalid-feature-flag-usage */
import { fg } from '@atlaskit/platform-feature-flags';

const TIME_IN_MS = 1000;
const MAX_WAIT_TIME = 300 * TIME_IN_MS;
const INITIAL_WAIT_TIME = 2 * TIME_IN_MS;

export const pollForBrowserControlsFlag = async (
	waitTime = INITIAL_WAIT_TIME,
	totalElapsedTime = 0,
): Promise<boolean> => {
	return pollForFeatureFlag(
		'platform.moonjelly.browser-storage-controls',
		waitTime,
		totalElapsedTime,
	);
};

const pollForFeatureFlag = (
	featureFlag: string,
	waitTime = INITIAL_WAIT_TIME,
	totalElapsedTime = 0,
): Promise<boolean> => {
	/*
    We don't know exactly if/when the FF will be enabled, so we want to exponentially poll from 2s to a max of ~5min
    If it never comes back as 'true' then we assume that:
      a) it's correctly set to false and thus we don't override the document.cookie behavior
      b) the FF client failed to load in time, don't override the behavior by making false assumptions
  */
	const shouldCancelPolling = totalElapsedTime > MAX_WAIT_TIME;

	if (shouldCancelPolling) {
		return Promise.resolve(false);
	}

	// eslint-disable-next-line @atlaskit/platform/static-feature-flags
	if (!fg(featureFlag)) {
		const newWaitTime = waitTime * 2;

		// Use the max if new wait time exceeds it
		const cappedWaitTime = Math.min(newWaitTime, MAX_WAIT_TIME);

		return new Promise((resolve) => {
			setTimeout(async () => {
				const updatedElapsedTime = totalElapsedTime + cappedWaitTime;

				const newFlagState = await pollForBrowserControlsFlag(cappedWaitTime, updatedElapsedTime);

				resolve(newFlagState);
			}, cappedWaitTime);
		});
	}
	return Promise.resolve(true);
};
