/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import type ApolloClient from 'apollo-client';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';

import { Iframe, getModalDimensions } from '@atlassian/forge-ui/iframe';
import { emit, on } from '@atlassian/forge-ui/events';
import { EnvironmentContext, type Extension } from '@atlassian/forge-ui/ui';
import {
	type ProductEnvironment,
	type CoreData,
	type ExtensionData,
	type ForgeDoc,
} from '@atlassian/forge-ui-types';

import { N0, N30A, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { getAtlasKitModalWidth } from '../../utils/getAtlasKitModalWidth';
import { type FlagFunctions, getFlagProvider } from '../../utils/getFlagProvider';
import { areCommonPropertiesEqual } from '../../utils/areCommonPropertiesEqual';

interface IframeRendererProps {
	accountId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	environment: ProductEnvironment;
	extension: Extension;
	coreData: CoreData;
	extensionData: ExtensionData;
	locale: string;
	flags: FlagFunctions;
}

interface CustomUIBridge {
	onClose?: (payload?: any) => boolean | undefined;
}

type ModalIframeRendererProps = IframeRendererProps & {
	size?: ModalOptions['size'];
	closeOnEscape?: ModalOptions['closeOnEscape'];
	closeOnOverlayClick?: ModalOptions['closeOnOverlayClick'];
	onTearDown?: () => void;
	bridge?: CustomUIBridge;
};

interface ModalOptions {
	resource?: string | null;
	onClose?: (payload: any) => any;
	size?: 'small' | 'medium' | 'large';
	context?: any;
	closeOnEscape?: boolean;
	closeOnOverlayClick?: boolean;
}

interface ModalState {
	isOpen: boolean;
	opts: ModalOptions;
}

type ViewportSizeType = 'small' | 'medium' | 'large' | 'xlarge';
type ViewportSizeTypeWithDefault = ViewportSizeType | 'default';
type ViewportSizeObjectType = {
	[size in ViewportSizeTypeWithDefault]: string;
};

const macroHeights: ViewportSizeObjectType = {
	small: '112px',
	medium: '262px',
	default: '262px',
	large: '524px',
	xlarge: '1048px',
};

const calculateHeight = (size?: ViewportSizeType) => {
	return macroHeights[size ?? 'default'];
};

export const IframeRenderer = (props: IframeRendererProps) => {
	const { environment, extension, extensionData, coreData } = props;
	const [modalState, setModalState] = useState<ModalState>({
		isOpen: false,
		opts: {},
	});

	const [macroConfigForgeDocSubject$] = useState(() => new Subject());
	const configValueRef = useRef(undefined);
	// Stores the forgeDoc for the macro config schema. The ref is often a render behind
	// the actual schema, so should only be used on the initial load.
	const macroConfigForgeDocRef = useRef<ForgeDoc | undefined>(undefined);

	const { flags, ...restProps } = props;
	const { showFlag, closeFlag } = useMemo(() => getFlagProvider(flags), [flags]);

	const id = `${extension.id}-${coreData.localId}`;
	const reconcile = useCallback(
		({ forgeDoc }: { forgeDoc: ForgeDoc }) => {
			Object.freeze(forgeDoc);
			macroConfigForgeDocRef.current = forgeDoc;
			macroConfigForgeDocSubject$.next(forgeDoc);
			emit(`CONFIG_FORGE_DOC_UPDATED_${id}`);
		},
		[id, macroConfigForgeDocSubject$],
	);

	// This sets up an event listener to respond to Editor's requests for the macro config schema.
	// When the GET_CONFIG_FORGE_DOC_${id} event is emitted, the payload includes the current
	// config value, and the resolve and reject functions of a Promise. The listener should be
	// resolving the promise with the value of the macro config forgeDoc.
	useEffect(() => {
		const macroConfigForgeDocSubscription = on(
			`GET_CONFIG_FORGE_DOC_${id}`,
			async ({ resolve, reject, config }) => {
				// If the config value received in the event is different from the existing
				// stored value in configValueRef, then the forgeDoc will be updated.
				// We need to set a debounce time to wait for the new forgeDoc to be returned
				// from the app before we resolve the promise.
				if (configValueRef.current && !areCommonPropertiesEqual(configValueRef.current, config)) {
					const subscription = macroConfigForgeDocSubject$.pipe(debounceTime(200)).subscribe({
						next: (forgeDoc) => {
							resolve({
								type: 'Root',
								props: {},
								children: [forgeDoc],
							});
							subscription.unsubscribe();
						},
					});
				} else {
					if (!macroConfigForgeDocRef.current) {
						reject('MacroConfig forgeDoc is not defined.');
					}
					resolve({
						type: 'Root',
						props: {},
						children: [macroConfigForgeDocRef.current],
					});
				}
				configValueRef.current = config;
			},
		);

		return () => {
			macroConfigForgeDocSubscription.unsubscribe();
		};
	}, [id, macroConfigForgeDocSubject$]);

	return (
		<EnvironmentContext.Provider value={environment}>
			<Iframe
				{...restProps}
				bridge={{
					openModal: (opts: any) => {
						setModalState({ isOpen: true, opts: opts.data });
						return true;
					},
					showFlag,
					closeFlag,
					reconcile,
				}}
				height={
					extension.properties.viewportSize
						? calculateHeight(extension.properties.viewportSize)
						: undefined
				}
				isResizable={true}
			/>
			{modalState.isOpen && (
				<ModalIframeRenderer
					{...props}
					extension={{
						...extension,
						type: 'dynamic-modal',
						properties: {
							...extension.properties,
							resource: modalState.opts.resource ?? extension.properties.resource,
						},
					}}
					extensionData={{
						...extensionData,
						...(modalState.opts.context ? { modal: modalState.opts.context } : {}),
					}}
					onTearDown={() => {
						setModalState((prevState: ModalState) => ({
							...prevState,
							isOpen: false,
						}));
					}}
					bridge={{
						onClose: modalState.opts.onClose,
					}}
					size={modalState.opts.size}
					closeOnEscape={modalState.opts.closeOnEscape}
					closeOnOverlayClick={modalState.opts.closeOnOverlayClick}
				/>
			)}
		</EnvironmentContext.Provider>
	);
};

export const ModalIframeRenderer = ({
	size,
	onTearDown,
	bridge,
	extension,
	closeOnEscape,
	closeOnOverlayClick,
	flags,
	...props
}: ModalIframeRendererProps) => {
	const [isOpen, setOpen] = useState(true);

	const modalSize = size ?? extension.properties.viewportSize ?? 'medium';
	const { height, minHeight } = getModalDimensions(modalSize);
	const akModalSize = getAtlasKitModalWidth(modalSize);

	const { showFlag, closeFlag } = useMemo(() => getFlagProvider(flags), [flags]);

	return (
		<ModalTransition>
			{isOpen && (
				<Modal
					width={akModalSize}
					height={modalSize === 'max' ? height : undefined}
					onClose={() => {
						if (bridge?.onClose) {
							bridge.onClose();
						}
						setOpen(false);
					}}
					onCloseComplete={onTearDown}
					testId="custom-ui-modal-dialog"
					shouldCloseOnEscapePress={closeOnEscape}
					shouldCloseOnOverlayClick={closeOnOverlayClick}
				>
					<div
						// TODO Delete this comment after verifying space token -> previous value `border-radius: 3px`
						// TODO Delete this comment after verifying space token -> previous value `border-radius: 3px`
						// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						css={css({
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
							height: height,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
							minHeight: minHeight ?? height,
							background: token('elevation.surface.overlay', N0),
							borderRadius: token('border.radius', '3px'),
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
							iframe: {
								boxShadow: token(
									'elevation.shadow.overlay',
									`0 0 0 1px ${N30A}, 0 2px 1px ${N30A}, 0 0 20px -6px ${N60A}`,
								),
								borderRadius: token('border.radius', '3px'),
								height: '100%',
							},
						})}
					>
						<Iframe
							{...props}
							extension={extension}
							bridge={{
								...bridge,
								close: (payload?: any) => {
									if (bridge?.onClose) {
										bridge.onClose(payload?.data);
									}
									setOpen(false);
									return true;
								},
								showFlag,
								closeFlag,
							}}
							isResizable={false}
						/>
					</div>
				</Modal>
			)}
		</ModalTransition>
	);
};
