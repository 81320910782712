import gql from 'graphql-tag';

const SpaceDropdownSpaceFragment = gql`
	fragment SpaceDropdownSpaceFragment on Space {
		alias @include(if: $includeAlias)
		id
		name
		key
		icon {
			path
		}
	}
`;

export const StarredSpacesQuery = gql`
	query StarredSpacesQuery($includeAlias: Boolean = false) {
		spaces(favourite: true, first: 99, status: "current") {
			nodes {
				...SpaceDropdownSpaceFragment
			}
		}
	}
	${SpaceDropdownSpaceFragment}
`;

export const RecentSpacesQuery = gql`
	query RecentSpacesQuery($includeAlias: Boolean = false) {
		recentSpaces {
			...SpaceDropdownSpaceFragment
		}
	}
	${SpaceDropdownSpaceFragment}
`;

export const RecentSpacesWithCurrentSpaceQuery = gql`
	query RecentSpacesWithCurrentSpaceQuery(
		$selectedSpaceKey: String
		$includeAlias: Boolean = false
	) {
		space(key: $selectedSpaceKey) {
			...SpaceDropdownSpaceFragment
			currentUser {
				isFavourited
			}
		}
		recentSpaces {
			...SpaceDropdownSpaceFragment
		}
	}
	${SpaceDropdownSpaceFragment}
`;

export const MyVisitedSpacesQuery = gql`
	query MyVisitedSpacesQuery($includeAlias: Boolean = false) {
		myVisitedSpaces(limit: 26) {
			nodes {
				spaces {
					...SpaceDropdownSpaceFragment
				}
			}
		}
	}
	${SpaceDropdownSpaceFragment}
`;

export const MyVisitedSpacesWithCurrentSpaceQuery = gql`
	query MyVisitedSpacesWithCurrentSpaceQuery(
		$selectedSpaceKey: String
		$includeAlias: Boolean = false
	) {
		space(key: $selectedSpaceKey) {
			...SpaceDropdownSpaceFragment
			currentUser {
				isFavourited
			}
		}
		myVisitedSpaces(limit: 26) {
			nodes {
				spaces {
					...SpaceDropdownSpaceFragment
				}
			}
		}
	}
	${SpaceDropdownSpaceFragment}
`;

export const AnonymousSpacesQuery = gql`
	query AnonymousSpacesQuery($includeAlias: Boolean = false) {
		spaces(first: 10) {
			nodes {
				...SpaceDropdownSpaceFragment
			}
		}
	}
	${SpaceDropdownSpaceFragment}
`;

export const SpacesDropdownFavouriteSpaceQuery = gql`
	query SpacesDropdownFavouriteSpaceQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			currentUser {
				isFavourited
			}
		}
	}
`;
