import React, { createContext, useContext, useState, useMemo } from 'react';

import { withOutline } from './tooling/outline';
import { LoadingPriority } from './constants';

const PRIORITY_COLORS: { [key: number]: string } = {
	[LoadingPriority.PAINT]: 'red',
	[LoadingPriority.AFTER_PAINT]: 'yellow',
	[LoadingPriority.BACKGROUND]: 'blue',
	[LoadingPriority.LAZY]: 'green',
};

type LoadableSummary = {
	id?: string;
	placeholderReplaceId?: string;
	name?: string;
	priority: number;
	rootForServerPlaceholderId?: boolean;
	isReactLoadable?: boolean;
};

type LoadableAncestorsContextType = {
	ancestors: LoadableSummary[];
	placeholderReplaceId?: string;
};

export const LoadableAncestorsContext = createContext<LoadableAncestorsContextType>({
	ancestors: [],
});

export const useCheckCorrectPriority = ({
	id,
	name,
	priority,
	rootForServerPlaceholderId,
	isReactLoadable,
}: LoadableSummary): [boolean, LoadableSummary, LoadableSummary[], string?] => {
	// Returns an array of Loadable names that appear as ancestors,
	// including the current one if `self` is supplied.
	const { ancestors } = useContext(LoadableAncestorsContext);
	const parent = ancestors[0];

	// Loadable names should be static, so this array should never be
	// recomputed to avoid excessive re-renders
	const [ancestorsIncludingSelf] = useState(() =>
		[
			{
				id: id || name,
				name,
				priority,
				rootForServerPlaceholderId: rootForServerPlaceholderId ?? false,
				isReactLoadable: isReactLoadable ?? false,
			} as LoadableSummary,
		].concat(ancestors),
	);

	if (parent && priority === LoadingPriority.PAINT && priority < parent.priority) {
		return [false, parent, ancestorsIncludingSelf];
	}

	return [true, parent, ancestorsIncludingSelf];
};

export const useAutoCorrectPriorityWithAncestor = (priority: number): number => {
	const { ancestors } = useContext(LoadableAncestorsContext);
	const ancestor = ancestors.find((ancestor) => ancestor.isReactLoadable);
	// if ancestor is AFTER_PAINT and current is PAINT. Correct the current
	if (ancestor && ancestor.priority > priority) {
		return ancestor.priority;
	}
	return priority;
};

export const LoadableAncestorsProvider = (props: React.PropsWithChildren<LoadableSummary>) => {
	const [correct, parent, ancestorsIncludingSelf] = useCheckCorrectPriority(props);
	const { name, placeholderReplaceId, priority, children } = props;

	// The LoadableAncestorsProvider will alert developers if PAINT loadables
	// are descendants of lower-priority ones
	if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'testing') {
		if (!correct) {
			const loadablesHierarchy = ancestorsIncludingSelf
				.map(({ name, priority }) => `\t${name} [${LoadingPriority[priority]}] `)
				.reverse()
				.join('\n');

			// eslint-disable-next-line no-console
			console.error(`%cError!`, `color: #FF0000; font-size: 272px; font-weight: bold;`);

			throw new Error(`
❌ Loadables are prioritized incorrectly! ❌
The component ${name} [${
				LoadingPriority[priority]
			}] has a higher priority of it's parent component ${parent.name}[${
				LoadingPriority[parent.priority]
			}]!
This could introduce deadlocks and cause the page stuck until times out (5s in prod, 30s in other env).
See go/cc-loading-phases for more details on this error.\n
Current loadables hierarchy (loadable name [priority]):
${loadablesHierarchy}\n
`);
		}
	}

	// This setting allows to display the outline for Loadable components with a
	// specific priority, which helps to troubleshoot the page loading performance.
	let priorityToOutline = -1;
	try {
		// handle The "operation is insecure" when cookie is disabled
		priorityToOutline =
			typeof window !== 'undefined'
				? Number(window?.sessionStorage?.getItem('confluence.ssr-outline-priority') || '-1')
				: -1;
	} catch (e) {}

	const wrappedChildren =
		priorityToOutline === priority ? withOutline(PRIORITY_COLORS[priority], children) : children;

	return (
		<LoadableAncestorsContext.Provider
			value={useMemo(
				() => ({ ancestors: ancestorsIncludingSelf, placeholderReplaceId }),
				[ancestorsIncludingSelf, placeholderReplaceId],
			)}
		>
			{wrappedChildren}
		</LoadableAncestorsContext.Provider>
	);
};
