import type { FC } from "react";

import type { ADDoc, ADNode } from "@atlaskit/editor-common/validator";
import type { ExtensionParams, ReferenceEntity } from "@atlaskit/editor-common/extensions";
import type { DataSourceProvider as ReferentialityDataSourceProvider} from "@atlassian/editor-referentiality";
import { useReference } from "@atlassian/editor-referentiality";

type Props = {
  adf: ADNode | string | null;
  extensionParams: ExtensionParams<any>;
  referentialityDataSourceProvider: ReferentialityDataSourceProvider;
  children: (data: { references: ReferenceEntity[] }) => JSX.Element;
}

export const MacroReference: FC<Props> = ({
  adf,
  extensionParams,
  referentialityDataSourceProvider,
  children
}) => {
  let adfParsed = adf as ADDoc;

  if (typeof adf === "string") {
    try {
      adfParsed = JSON.parse(adf);
    } catch(e) {
      adfParsed = {
        version: 1,
        type: 'doc',
        content: [],
      }
    }
  }

  const { references } = useReference({
    adf: adfParsed,
    extensionParams,
    dataSourceProvider: referentialityDataSourceProvider
  });

  return (
    children({references})
  );
}
