import gql from 'graphql-tag';

import { contentAppearanceDraftFragment } from '@confluence/content-appearance/entry-points/fragments';

export const ConfluenceDraftQuery = gql`
	query ConfluenceDraftQuery($contentId: ID!, $status: [String]!) @SLA {
		content(id: $contentId, status: $status) {
			nodes {
				...contentAppearanceDraftFragment
				id
				type
				status
				title
				body {
					atlas_doc_format {
						value
					}
				}
			}
		}
	}

	${contentAppearanceDraftFragment}
`;
