import { query } from '@confluence/query-preloader-tools';
import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';

import { preloadSettingsComponentQuery } from './preloadSettingsComponentQuery';
import { AppNavigationUnifiedQuery } from './AppNavigationUnifiedQuery.graphql';

export function preloadAppNavigation() {
	const appNavigationPromises = [
		query({
			query: AppNavigationUnifiedQuery,
		}),
		preloadWebItemLocation({
			location: 'system.header/left',
		}),
	];

	if (process.env.REACT_SSR) {
		appNavigationPromises.push(preloadSettingsComponentQuery());
	}
	return Promise.all(appNavigationPromises);
}
