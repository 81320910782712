import { AnalyticsStep } from '@atlaskit/adf-schema/steps';
import { FabricChannel } from '@atlaskit/analytics-listeners';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { EditorState, Transaction } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';

import { getPackageInfo } from '../utils/version';

import {
	type FailedStep,
	type ServerErrorStep,
	type UserFlowStepAttributes,
} from './analytics-flow/analyticsFlowTypes';
import { EDITOR_AI_PLATFORM_FEATURE_FLAGS } from './platform-feature-flags';
import type { EditorAIAnalyticEventPayload } from './types';

export function getAIFeatureFlags() {
	const editorAiFeatureFlags: { [key: string]: boolean } = {};

	/**
	 * Note that we are not required to cleanup fg usage here.
	 * This is a helper function to get active Editor AI feature flags.
	 *
	 * We also trim flag keys in our events as a temporary measure against
	 * Statsig errors when metadata is too large.
	 */
	Object.keys(EDITOR_AI_PLATFORM_FEATURE_FLAGS).forEach((flagKey) => {
		const EDITOR_FLAG_PREFIX = 'platform.editor.';
		const trimmedFlagKey = flagKey.startsWith(EDITOR_FLAG_PREFIX)
			? flagKey.replace(EDITOR_FLAG_PREFIX, '')
			: flagKey;
		// eslint-disable-next-line @atlaskit/platform/static-feature-flags
		editorAiFeatureFlags[trimmedFlagKey] = fg(flagKey);
	});

	return editorAiFeatureFlags;
}

export function addAIPluginCommonInfoToPayload(payload: EditorAIAnalyticEventPayload) {
	const packageInfo = getPackageInfo();
	const attributes = payload.attributes || {};

	let isInternalOrSyntheticUser = false;
	if (fg('platform.editor.ai-tag-synthetic-and-internal-users_eb7tz')) {
		isInternalOrSyntheticUser = true;
	}

	return {
		...payload,
		attributes: {
			...attributes,
			aiPluginPackageName: packageInfo.packageName,
			aiPluginPackageVersion: packageInfo.packageVersion,
			aiPluginFeatureFlags: getAIFeatureFlags(),
			isInternalOrSyntheticUser,
		},
	};
}

// This utils is referenced from packages/editor/editor-core/src/analytics-api/attach-payload-into-transaction.ts
// Note: this adds extra step to a transaction which effectively marks it as docChanged and can cause editor onchange to fire on dispatch
function attachPayloadIntoTransaction({
	editorState,
	payload,
	channel = FabricChannel.editor,
}: {
	editorState: EditorState;
	payload: EditorAIAnalyticEventPayload;
	channel?: string;
}) {
	return (tr: Transaction) => {
		const { storedMarks } = tr;
		const pos = tr.mapping.map(editorState.selection.$from.pos, -1);
		tr.step(
			new AnalyticsStep(
				[
					{
						payload: addAIPluginCommonInfoToPayload(payload),
						channel,
					},
				],
				[],
				// We don't understand now why we need position in AnalyticsStep.
				// So we are keeping same as it is in original util.
				// Below comment is taken from original util from packages/editor/editor-core/src/analytics-api/attach-payload-into-transaction.ts
				// We need to create the step based on a position, this prevent split history for relative changes.
				pos,
			),
		);

		// We don't understand now why we need to store marks again in transaction.
		// So we are keeping same as it is in original util.
		// Below comment is taken from original util from packages/editor/editor-core/src/analytics-api/attach-payload-into-transaction.ts
		// When you add a new step all the storedMarks are removed it
		if (storedMarks) {
			tr.setStoredMarks(storedMarks);
		}

		return true;
	};
}

/**
 *  This will add extra step to the transaction with analytics info
 *  this information will be be picked up by the analytics plugin which will fire the event
 *  please note that this will cause the transaction to be marked as docChanged because of adding extra transaction step
 *  avoid creating and dispatching adhoc transaction, but rather reuse existing transactions that are already dispatched
 *
 * The `addToHistory` parameter controls whether this transaction should be recorded in the
 * editor's history. By default, `addToHistory` is set to `true`, meaning the transaction
 * will be added to the history stack, allowing for undo/redo operations. However, if
 * `addToHistory` is set to `false`, the transaction will not be recorded in the history,
 * which can be useful for operations that should not be undoable, such as logging or
 * non-destructive analytics events.
 */
export function addAnalytics({
	editorState,
	tr,
	payload,
	channel = FabricChannel.editor,
	addToHistory = true,
}: {
	editorState: EditorState;
	tr: Transaction;
	payload: EditorAIAnalyticEventPayload;
	channel?: string;
	addToHistory?: boolean;
}): Transaction {
	attachPayloadIntoTransaction({ editorState, payload, channel })(tr);
	if (!addToHistory) {
		tr.setMeta('addToHistory', false);
	}
	return tr;
}

// File copied from packages/editor/editor-core/src/types/command.ts
export type AnalyticsEventPayloadCallback = (
	state: EditorState,
) => EditorAIAnalyticEventPayload | undefined;
export type CommandDispatch = (tr: Transaction) => void;
export type Command = (
	state: EditorState,
	dispatch?: CommandDispatch,
	view?: EditorView,
) => boolean;
export type HigherOrderCommand = (command: Command) => Command;

// Below function is copied from packages/editor/editor-core/src/plugins/analytics/utils.ts
export function withAnalytics({
	payload,
	channel = FabricChannel.editor,
}: {
	payload: EditorAIAnalyticEventPayload | AnalyticsEventPayloadCallback;
	channel?: string;
}): HigherOrderCommand {
	return (command) => (state, dispatch, view) =>
		command(
			state,
			(tr) => {
				if (dispatch) {
					if (payload instanceof Function) {
						const dynamicPayload = payload(state);
						if (dynamicPayload) {
							dispatch(
								addAnalytics({
									editorState: state,
									tr,
									payload: dynamicPayload,
									channel,
								}),
							);
						}
					} else {
						dispatch(addAnalytics({ editorState: state, tr, payload, channel }));
					}
				}
			},
			view,
		);
}
export type FireAIAnalyticsEvent = {
	payload: EditorAIAnalyticEventPayload;
	channel?: string;
};

export type FireAIAnalyticsEventCallback = (args: FireAIAnalyticsEvent) => void;

export function createFireAIAnalyticsEvent(
	createAnalyticsEvent: CreateUIAnalyticsEvent,
): FireAIAnalyticsEventCallback {
	return ({ payload, channel = FabricChannel.editor }: FireAIAnalyticsEvent) => {
		if (!createAnalyticsEvent) {
			return;
		}

		const fireEvent = () =>
			createAnalyticsEvent(addAIPluginCommonInfoToPayload(payload))?.fire(channel);
		if ((window as any).requestIdleCallback) {
			(window as any).requestIdleCallback(fireEvent);
		} else if ((window as any).requestAnimationFrame) {
			(window as any).requestAnimationFrame(fireEvent);
		} else {
			setTimeout(fireEvent, 0);
		}
	};
}

/*
 * gets helper function to fire analytics for ai plugin
 */
export function useFireAIAnalyticsEvent() {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireAIAnalyticsEvent = createFireAIAnalyticsEvent(createAnalyticsEvent);
	return fireAIAnalyticsEvent;
}

/**
 * Gets the single instrumentation ID from the given channel ID and last AI interaction ID.
 */
export function getSingleInstrumentationID(channelId?: string, lastAiInteractionID?: string) {
	return channelId ?? lastAiInteractionID ?? '';
}

/**
 * Checks if the given step is an error step.
 */
export function isErrorStepAttributes(
	step: Partial<UserFlowStepAttributes> | undefined,
): step is Partial<ServerErrorStep['attributes'] | FailedStep['attributes']> {
	if (!step) {
		return false;
	}
	return 'errorKey' in step || 'statusCode' in step;
}
