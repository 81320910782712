import React from 'react';
import type { Dispatch, ForgeDoc, ForgeExtensionPoints, RenderFn } from '@atlassian/forge-ui-types';
import { uiKit1Components, uiKit2Components as uiKitLegacyComponents } from './defaultComponents';
import { Root, Fragment } from '../components/UIKit/internalComponents';
import { type ComponentMap, type ModalExtension } from './util';
import { RendererNext } from './RendererNextComponent';
import { importMapMemo } from '../components/UIKit/importMap';
import { importMapChartsMemo } from '../components/UIKit-charts/importMapCharts';
import { ThreeLOPromptRenderFn } from '../components/threeLOPrompt/threeLOPrompt';
import { UnknownComponentError } from '../error-boundary/UnknownComponentErrorBoundary';

const rendererNextComponents: ComponentMap = {
	ThreeLOPrompt: ThreeLOPromptRenderFn,
};

// This was extracted from RendererNext Component due to UnknownComponent Error being
// incompatible with running in a server-side context.
const customRenderErrorFn = (forgeDocType: string) => {
	throw new UnknownComponentError(
		`Error rendering app - encountered unknown component ${forgeDocType}.`,
		forgeDocType,
	);
};

type Props = {
	/* ForgeDoc to be rendered */
	forgeDoc?: ForgeDoc;
	/* Map of component types to render functions */
	components?: (defaults: ComponentMap) => ComponentMap;
	// defaultComponents: ComponentMap;
	/* Function used by components to dispatch effects */
	dispatch: Dispatch;
	/* Error message to show the user. Set when an unexpected client-side error happens. */
	error?: string;
	/* Whether a dispatched effect is pending. Not included in function due to server side render*/
	loading?: boolean;
	/* Replace the default spinner with a custom loading component. */
	loadingComponent?: React.ReactNode;
	/* Object that stores all necessary properties for a Modal Extension */
	modalExtension?: ModalExtension;
	/* Contains the module type of the extension */
	extensionType?: ForgeExtensionPoints;
	/* Indicates whether a CSUIKit app is being rendered*/
	isNative?: boolean;
};

export const RendererNextLatest = (props: Props) => {
	const uiKitComponents = Object.keys(importMapMemo).reduce((acc, key) => {
		const Component = importMapMemo[key];
		return {
			...acc,
			[key]: ((args) => <Component {...args} key={args.forgeDoc.key} />) as RenderFn,
		};
	}, {});

	const uiKitChartsComponents = Object.keys(importMapChartsMemo).reduce((acc, key) => {
		const Component = importMapChartsMemo[key];
		return {
			...acc,
			[key]: ((args) => <Component {...args} key={args.forgeDoc.key} />) as RenderFn,
		};
	}, {});

	const components = {
		...uiKitComponents,
		...uiKitChartsComponents,
		String: uiKit1Components.String,
		Text: uiKitLegacyComponents.Text,
		Image: uiKitLegacyComponents.Image,
		Link: uiKitLegacyComponents.Link,
		Em: uiKit1Components.Em,
		Strong: uiKit1Components.Strong,
		Strike: uiKit1Components.Strike,
		Root,
		Fragment,
	};

	const combinedComponents = {
		...components,
		...rendererNextComponents,
	};

	return (
		<RendererNext
			{...props}
			components={props.components ? props.components(combinedComponents) : combinedComponents}
			customRenderErrorFn={customRenderErrorFn}
		/>
	);
};

export const RendererNextLegacy = (props: Props) => {
	const components: ComponentMap = props.isNative
		? { ...uiKit1Components, ...uiKitLegacyComponents }
		: uiKit1Components;

	const combinedComponents = {
		...components,
		...rendererNextComponents,
	};

	return (
		<RendererNext
			{...props}
			components={props.components ? props.components(combinedComponents) : combinedComponents}
			customRenderErrorFn={customRenderErrorFn}
		/>
	);
};
