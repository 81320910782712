import { defineMessages } from 'react-intl-next';

export default defineMessages({
	aiUniversalLongCallToAction: {
		id: 'fabric.editor.ai.shared.aiUniversalLongCallToAction',
		defaultMessage: 'Let Atlassian Intelligence assist your writing',
		description:
			'Call-to-action text for activating the AI, used universally across UI elements like hover buttons, tooltips, and menu entries',
	},
	aiUniversalShortCallToAction: {
		id: 'fabric.editor.ai.shared.aiUniversalShortCallToAction',
		defaultMessage: 'Write',
		description:
			'Call-to-action text for activating the AI, used universally across UI elements like buttons and menu entries',
	},
	aiUniversalDescriptor: {
		id: 'fabric.editor.ai.shared.aiUniversalDescriptor',
		defaultMessage: 'Writing assistant',
		description:
			'Descriptive text for activating the AI, used universally across UI elements like hover buttons, tooltips, and menu entries',
	},
	aiButtonTitle: {
		id: 'fabric.editor.ai.shared.generateContent.aiButtonTitle',
		defaultMessage: 'AI (Atlassian Intelligence)',
		description:
			'Title for Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
	betaLabel: {
		id: 'fabric.editor.ai.shared.betaLabel',
		defaultMessage: 'Beta',
		description: 'The label describing the beta status of an Atlassian Intelligence prompt',
	},
});
