import { query, isUnauthorizedError } from '@confluence/query-preloader-tools';
import { WEB_ITEM_LOCATION_BUTTON_BEST_EFFORT_TASK_NAME } from '@confluence/web-item-location/entry-points/constants';
import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';
import { preloadBreadcrumbs } from '@confluence/breadcrumbs/entry-points/preloadBreadcrumbs';
import { preloadEditButton } from '@confluence/edit-button/entry-points/preloadEditButton';
import { EDIT_BUTTON_BEST_EFFORT_TASK_NAME } from '@confluence/edit-button/entry-points/constants';
import { READ_TIME_SMARTS_BEST_EFFORT_TASK_NAME } from '@confluence/read-time/entry-points/constants';
import { preloadReadTime } from '@confluence/read-time/entry-points/preloadReadTime';
import { preloadContentAnalyticsViewers } from '@confluence/confluence-analytics/entry-points/preloadContentAnalyticsViewers';
import { preloadPageRestrictionsQuery } from '@confluence/page-restrictions-context/entry-points/preloadPageRestrictionsQuery';
import { preloadGrantAccessDialogData } from '@confluence/grant-access-dialog/entry-points/preloadGrantAccessDialogData';
import { preloadRendererContentState } from '@confluence/content-state/entry-points/preloadRendererContentState';
import { preloadFavouriteButton } from '@confluence/favourite-button/entry-points/preloadFavouriteButton';
import { WATCH_DIALOG_BEST_EFFORT_TASK_NAME } from '@confluence/watch-dialog/entry-points/constants';
import { preloadWatchDialog } from '@confluence/watch-dialog/entry-points/preloadWatchDialog';
import { COMMENT_BUTTON_BEST_EFFORT_TASK_NAME } from '@confluence/comment-button/entry-points/constants';
import { preloadCommentButton } from '@confluence/comment-button/entry-points/preloadCommentButton';
import { CONTENT_ANALYTICS_BEST_EFFORT_TASK_NAME } from '@confluence/confluence-analytics/entry-points/constants';
import { ContentStatus } from '@confluence/data-classification/entry-points/contentStatus';
import {
	preloadContentDataClassificationQuery,
	preloadDataClassificationEnabledQuery,
	preloadDefaultAndSpaceClassificationLevelsQuery,
} from '@confluence/data-classification/entry-points/queries';
import {
	DATA_CLASSIFICATION_ENABLED_BEST_EFFORT_TASK_NAME,
	CONTENT_DATA_CLASSIFICATION_BEST_EFFORT_TASK_NAME,
	DEFAULT_AND_SPACE_CLASSIFICATION_BEST_EFFORT_TASK_NAME,
} from '@confluence/data-classification/entry-points/bestEffortTaskNames';
import type { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

import { ContentHeaderUnifiedQuery } from './ContentHeaderUnifiedQuery.graphql.js';
import { DEFAULT_CONTRIBUTORS_FETCH_LIMIT, CONFLUENCE_EDITION } from './defaultQueryVariables';

// We preload content header unconditionally but when page is restricted we don't
// render the corresponding components, neither we use the result of preloading.
// Hence mute the restriction errors on preloading to avoid unnecessary noise.
// Not needed on the SSR preloading, as similar errors from server doesn't produce
// noise in analytics.
const handlePreloadErrors = (result) => {
	const errorProcessor = window.__GRAPHQL_ERROR_PROCESSOR__;

	result?.errors?.forEach((error) => {
		if (isUnauthorizedError(error)) {
			errorProcessor?.markErrorAsHandled(error);
		}
	});

	return result;
};

export type preloadContentHeaderProps = {
	spaceKey?: string;
	contentId: string;
	bestEffortTask?: (name: string, task: () => Promise<any>) => Promise<any>;
	isLicensed: boolean;
	userId: string | null;
	username?: string;
	edition: ConfluenceEdition | null;
	isPrevEditRoute?: boolean;
	useNewContentTopper?: boolean;
	isSpaceAliasFFEnabled?: boolean;
};

export function preloadContentHeader({
	spaceKey,
	contentId,
	bestEffortTask,
	isLicensed,
	userId,
	username,
	edition,
	isPrevEditRoute = false,
	useNewContentTopper = false,
	isSpaceAliasFFEnabled = false,
}: preloadContentHeaderProps) {
	const queries: Promise<any>[] = [preloadFavouriteButton(contentId, userId)];

	// Don't preload queries that will be updated by editor if previous route is edit_page
	if (!isPrevEditRoute) {
		queries.push(
			query({
				query: ContentHeaderUnifiedQuery,
				variables: {
					contentId,
					spaceKey,
					versionOverride: null,
					embeddedContentRender: null,
					limit: DEFAULT_CONTRIBUTORS_FETCH_LIMIT,
					useNewContentTopper,
				},
			}).then(handlePreloadErrors),
			preloadBreadcrumbs(spaceKey, contentId, isSpaceAliasFFEnabled).then(handlePreloadErrors),
			preloadRendererContentState(contentId).then(handlePreloadErrors),
		);

		// SSR Grant Access Restrictions button
		// username is a legacy URL parameter that actually represents accountId in the grant access flow
		if (contentId && username) {
			queries.push(preloadGrantAccessDialogData(contentId, username));
		}

		//unlike SPA, no need to check the route since preloadContentHeader is fired only when its VIEW_PAGE.
		const includeAncestors = edition === CONFLUENCE_EDITION.FREE;
		queries.push(preloadPageRestrictionsQuery(contentId, includeAncestors));
	}

	if (process.env.REACT_SSR) {
		if (bestEffortTask) {
			queries.push(
				bestEffortTask(WATCH_DIALOG_BEST_EFFORT_TASK_NAME, () =>
					preloadWatchDialog({ contentId, isLicensed, userId }),
				),
				bestEffortTask(EDIT_BUTTON_BEST_EFFORT_TASK_NAME, () =>
					preloadEditButton({ spaceKey, contentId }),
				),
				bestEffortTask(CONTENT_ANALYTICS_BEST_EFFORT_TASK_NAME, () =>
					preloadContentAnalyticsViewers(contentId),
				),
				bestEffortTask(READ_TIME_SMARTS_BEST_EFFORT_TASK_NAME, () => preloadReadTime(contentId)),
				bestEffortTask(COMMENT_BUTTON_BEST_EFFORT_TASK_NAME, () => preloadCommentButton(contentId)),
				/**
				 * Inline comment button:
				 * next/packages/ssr-app/src/module-replacements/@confluence/comment-button.js
				 *
				 * "..." button:
				 * See next/packages/ssr-app/src/module-replacements/@confluence/content-tools.js
				 *
				 * Share button:
				 * See next/packages/ssr-app/src/module-replacements/@confluence/share.js
				 */

				// Determine whether to show restriction and share button
				bestEffortTask(WEB_ITEM_LOCATION_BUTTON_BEST_EFFORT_TASK_NAME, () =>
					preloadWebItemLocation({
						location: 'system.content.button',
						contentId,
					}),
				),
				bestEffortTask(DATA_CLASSIFICATION_ENABLED_BEST_EFFORT_TASK_NAME, () =>
					preloadDataClassificationEnabledQuery(),
				),

				bestEffortTask(CONTENT_DATA_CLASSIFICATION_BEST_EFFORT_TASK_NAME, () =>
					preloadContentDataClassificationQuery(contentId, ContentStatus.CURRENT),
				),
			);

			if (spaceKey) {
				queries.push(
					bestEffortTask(DEFAULT_AND_SPACE_CLASSIFICATION_BEST_EFFORT_TASK_NAME, () =>
						preloadDefaultAndSpaceClassificationLevelsQuery(spaceKey),
					),
				);
			}
		}
	} else {
		queries.push(preloadWatchDialog({ contentId, isLicensed, userId }));
	}

	return Promise.all(queries);
}
