import React from 'react';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { AdvancedSearch } from '@confluence/advanced-search';

export const AdvancedSearchRoute = () => <AdvancedSearch />;

AdvancedSearchRoute.NAVIGATION_PARAMS = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isFullPageSearchEnabled = useBooleanFeatureFlag(
		'platform.search-platform-experience.enable-unified-search',
	);

	return {
		Screen: isFullPageSearchEnabled ? null : { screenEvent: { name: 'advancedSearchScreen' } },
		MainLayout: { navView: PRODUCT_HOME_ID },
	};
};
