import { query } from '@confluence/query-preloader-tools';
import { preloadFeatureDiscovery } from '@confluence/feature-discovery/entry-points/preloadFeatureDiscovery';

import { FEED_CONTENT_QUERY_TASK_NAME, FEED_POPULAR_FEED_QUERY_TASK_NAME } from './feedConstants';
import { FeedContentQuery } from './FeedContentQuery.graphql';
import { FeedTypeSelectorRefreshQuery } from './FeedTypeSelectorRefreshQuery.graphql';
import { FeedPopularFeedQuery } from './FeedPopularFeedQuery.graphql';
import { getInitialPageSize } from './initialPageSizeConstant';

export const preloadFeed = ({ isLoggedIn, bestEffortTask, isSpaceAliasFFEnabled = false }) => {
	// If the user is not logged in we show the following feed by default
	// so only preload that feed. If the user is logged in preload both
	// feeds and the feed preference
	const tasks: Array<Promise<any>> = [
		bestEffortTask(FEED_CONTENT_QUERY_TASK_NAME, () =>
			query({
				query: FeedContentQuery,
				variables: { first: getInitialPageSize(), includeAlias: isSpaceAliasFFEnabled },
			}),
		),
	];

	if (isLoggedIn) {
		tasks.push(
			bestEffortTask(FEED_POPULAR_FEED_QUERY_TASK_NAME, () =>
				query({
					query: FeedPopularFeedQuery,
					variables: { first: getInitialPageSize(), includeAlias: isSpaceAliasFFEnabled },
				}),
			),
			query({
				query: FeedTypeSelectorRefreshQuery,
			}),
			preloadFeatureDiscovery(),
		);
	}
	return Promise.all(tasks);
};
