import { parse } from 'url';

import type { FC } from 'react';
import { useEffect, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import type { RoutesContextType } from './RoutesContext';
import { RoutesContext } from './RoutesContext';

type RedirectionProps = {
	href?: string;
	name?: string;
	params?: Parameters<RoutesContextType['toUrl']>[1];
	to?: string;
	usePush?: boolean;
	forceReload?: Parameters<RoutesContextType['push']>[1];
};

export const Redirection: FC<RedirectionProps> = ({
	href = null,
	name,
	params,
	to = null,
	usePush = false,
	forceReload = false,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { push, replace, toUrl, getHash, getQueryParams, location, matchRoute } =
		useContext(RoutesContext);

	const getUrl = () => {
		const customPath = href || to;

		let parsedUrl = {};
		if (customPath) {
			parsedUrl = parse(customPath, true);
		}

		return toUrl(name, {
			hash: getHash(),
			query: getQueryParams(),
			...params,
			...parsedUrl,
		});
	};

	// When it's in Embedded Confluence we want to disable SSR redirection optimization and let SPA redirection
	// otherwise iframe onload event will be triggered too early and cause Embedded Confluence to display login/storage access page
	if (process.env.REACT_SSR && !isEmbeddedConfluence_DO_NOT_USE()) {
		// This instructs cc-frontend-ssr to throw away the SSR output and write an HTML meta redirect instead
		window['__SSR_REDIRECT__'] = getUrl();
	}

	useEffect(() => {
		const gottenUrl = getUrl();

		const source = location?.pathname ? matchRoute(location.pathname)?.name : null;

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'redirect',
				actionSubject: source,
				actionSubjectId: name || matchRoute(gottenUrl)?.name,
				source,
			},
		}).fire();
		(usePush ? push : replace)(gottenUrl, forceReload);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
