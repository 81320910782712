import { type ExtensionPayload } from '@atlassian/forge-ui-types';
import { type ThemeState } from '@atlaskit/tokens';

import type { Extension } from '../../../web-client';
import type { ResolverContext, ViewContext } from '../types';

export const getViewContext = (
	resolverContext: ResolverContext,
	accountId: string | undefined,
	extension: Extension,
	timezone: string,
	locale: string,
	extensionPayload?: ExtensionPayload,
	theme?: Partial<ThemeState> | null,
	surfaceColor?: string | null,
): ViewContext => ({
	...resolverContext,
	extension: {
		...resolverContext.extension,
		...extensionPayload,
	},
	accountId,
	license: extension?.license || null,
	timezone,
	locale,
	theme,
	surfaceColor,
});
