import { SPACE_OVERVIEW, COMPANY_HUB } from '@confluence/named-routes';
import { preloadSpaceOverview } from '@confluence/space-overview/entry-points/preloadSpaceOverview';
import { preloadPageTitleContentProperties } from '@confluence/custom-sites-extensions/entry-points/preloadPageTitleContentProperties';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import { SHORTCUTS_SMART_LINKS_BEST_EFFORT_TASK_NAME } from '@confluence/space-shortcuts/entry-points/ShortcutsSmartLinksBestEffortTaskName';
import { preloadUserPreferencesForSSR } from '@confluence/storage-manager/entry-points/preloadUserPreferencesForSSR';
import { preloadSideNavigation } from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { bestEffortTask, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { getCompanyHubSpaceKeyForSSR } from '@confluence/route-manager/entry-points/getCompanyHubSpaceKeyForSSR';
import type { RouteMatch } from '@confluence/route';

export const preloadSpaceOverviewRoute = async (match: RouteMatch, _url: string) => {
	const { isLicensed, featureFlags } = await getPreloaderFnContext();
	const { spaceKey } = match.params;
	let homepageId: string | undefined;

	if (match.query) {
		const homepageIds = match.query[SPACE_OVERVIEW.HOMEPAGE_ID_QUERY_KEY];
		if (Array.isArray(homepageIds)) {
			homepageId = homepageIds[0];
			//sometimes it's a string and not an array
		} else if (typeof homepageIds === 'string') {
			homepageId = homepageIds;
		}
	}

	const tasks: Promise<any>[] = [];

	if (spaceKey) {
		if (process.env.REACT_SSR) {
			// Only load data for navigation in React SSR since we are delaying them in the SPA
			tasks.push(
				bestEffortTask(SHORTCUTS_SMART_LINKS_BEST_EFFORT_TASK_NAME, () =>
					preloadShortcutsSmartLinks(spaceKey),
				),
				preloadUserPreferencesForSSR({ isLicensed, spaceKey }),
				preloadSideNavigation(spaceKey, isLicensed),
				preloadSpaceGuestList(spaceKey),
			);
		}
	}

	const companyHubSpaceKey =
		match.name === COMPANY_HUB.name ? await getCompanyHubSpaceKeyForSSR() : undefined;

	tasks.push(
		preloadSpaceOverview({
			spaceKey: spaceKey ?? companyHubSpaceKey,
			includeAlias: Boolean(featureFlags['confluence.frontend.space.alias']),
			homepageId,
		}),
	);

	const isCustomSitesPageTitleFFOn = featureFlags[CUSTOM_SITES_PAGE_TITLE_FF];
	if (isCustomSitesPageTitleFFOn) {
		tasks.push(
			preloadPageTitleContentProperties({
				contentId: homepageId || '',
				spaceKey: spaceKey ?? companyHubSpaceKey,
			}),
		);
	}

	const shouldPreloadSpaceDetails = featureFlags['confluence.frontend.ecosystem.access.narrrowing'];
	const isSpaceAliasFFEnabled = Boolean(featureFlags['confluence.frontend.space.alias']);
	if (shouldPreloadSpaceDetails) {
		tasks.push(preloadSpaceDetail(spaceKey, isSpaceAliasFFEnabled));
	}

	return Promise.all(tasks);
};
