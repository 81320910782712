// HOT-102930 - Confluence FE reached the default 500 feature flag limit, overriding to 800 while we wait for FF cleanup.
// https://ops.internal.atlassian.com/jira/browse/HOT-102930

export const SessionDataQueryRaw = /* GraphQL */ `
	query SessionDataQuery @SLA {
		tenant {
			shard
			cloudId
			environment
			activationId
			editions {
				edition
			}
		}
		organization {
			orgId
		}
		userForAnalytics: user {
			id
		}
		user(current: true) {
			id
			displayName
			confluence {
				accessStatus
				accountId
				userKey
				locale
			}
			timeZone
		}
		featureFlags(extraTargeting: true, first: 800) {
			nodes {
				id
				value
				explanation {
					ruleIndex
					ruleId
					kind
				}
			}
		}
		platformFeatureFlags: featureFlags(prefix: PLATFORM, extraTargeting: true, first: 500) {
			nodes {
				id
				value
				explanation {
					ruleIndex
					ruleId
					kind
				}
			}
		}

		getAIConfig(product: CONFLUENCE) {
			isEnabled
			isRovoEnabled
		}
	}
`;
