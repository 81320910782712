import memoize from 'memoize-one';
import { useCallback, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import type { NavigationPolicy } from '@atlassian/embedded-confluence/navigationPolicy';

import { CONTEXT_PATH as CC_CONTEXT_PATH, isHashedOnlyURL } from '@confluence/route-manager-utils';

/**
 * If a shim URL is provided by the embedding parent product, any URL that navigates to Confluence Pages
 * will be converted to the URL of the parent product. 3 functions are provided: shimUrl(), shimAdfUrls(),
 * shimHtmlUrls(). Both absolute URLs and relative URLs are supported. Since we are applying shimming logic, all shimmed URLs are absolute URLs with domain.
 *
 * shimUrl() is to shim any given Confluence Page URL into the URL of the embedding parent product
 * e.g. Given shimUrl is "/jsm",
 * Given Confluence Page URL "http://abc.atlassian.net/wiki/spaces/X/123" would be converted to "http://abc.atlassian.net/jsm/spaces/X/123".
 * Given Confluence Page URL "/wiki/spaces/X/123" would be converted to "http://abc.atlassian.net/jsm/spaces/X/123".
 *
 * shimAdfUrls() is to shim all Confluence Page URLs inside the given atlassian document format (ADF), which is a JSON object, into the URLs of the embedding parent product
 * e.g. Given shimUrl is "/jsm",
 * Given ADF { "href": "http://abc.atlassian.net/wiki/spaces/X/123" } would be converted to { "href": "http://abc.atlassian.net/jsm/spaces/X/123" }
 * Given ADF { "href": "/wiki/spaces/X/123" } would be converted to { "href": "http://abc.atlassian.net/jsm/spaces/X/123" }
 *
 * shimHtmlUrls() is to shim all Confluence Page URLs inside the given HTML string into the URLs of the embedding parent product
 * e.g. Given shimUrl is "/jsm",
 * Given HTML string <a href="http://abc.atlassian.net/wiki/spaces/X/123"> would be converted to <a href="http://abc.atlassian.net/jsm/spaces/X/123">.
 * Given HTML string <a href="/wiki/spaces/X/123"> would be converted to <a href="http://abc.atlassian.net/jsm/spaces/X/123">.
 */
export function useShimUrl(navigationPolicy: NavigationPolicy | undefined): {
	shimUrl: (urlToShim: string) => string;
	shimAdfUrls: (adf: object) => object;
	shimHtmlUrls: (html: string | null) => string | null;
} {
	const shimTemplate = navigationPolicy?.shimUrl;
	const domainNamesToReplace = navigationPolicy?.domainNamesToReplace;

	const shimUrl = useCallback(
		(urlToShim: string): string => {
			return shimThisUrl(urlToShim, shimTemplate, domainNamesToReplace);
		},
		[shimTemplate, domainNamesToReplace],
	);

	const shimAdfUrls = useCallback(
		(adf: object) => {
			if (!shimTemplate || !adf || !Object.keys(adf).length) {
				return adf;
			}
			const adfClone = cloneDeep(adf);
			replaceCcPageUrlsInAdf(adfClone, shimTemplate, domainNamesToReplace);

			return adfClone;
		},
		[shimTemplate, domainNamesToReplace],
	);

	const shimHtmlUrls = useCallback(
		(html: string | null) => {
			if (!shimTemplate || !html) {
				return html;
			}

			try {
				return shimDomUrls(
					new DOMParser().parseFromString(html, 'text/html'),
					shimTemplate,
					domainNamesToReplace,
				).body.innerHTML;
			} catch {
				return html;
			}
		},
		[shimTemplate, domainNamesToReplace],
	);

	return useMemo(
		() => ({
			shimUrl,
			shimAdfUrls,
			shimHtmlUrls,
		}),
		[shimUrl, shimAdfUrls, shimHtmlUrls],
	);
}

function shimDomUrls(
	dom: Document,
	shimTemplate: string,
	domainNamesToReplace?: string[],
): Document {
	const anchors = dom.getElementsByTagName('a');
	Array.from(anchors).forEach((a) => {
		a.href = shimThisUrl(a.href, shimTemplate, domainNamesToReplace);
	});

	const imgs = dom.getElementsByTagName('img');
	Array.from(imgs).forEach((img) => {
		img.src = shimThisUrl(img.src, shimTemplate, domainNamesToReplace);
	});

	return dom;
}

function shimThisUrl(
	urlToShim: string,
	shimTemplate: string | undefined,
	domainNamesToReplace: string[] | undefined,
): string {
	if (!shimTemplate || !urlToShim || isHashedOnlyURL(urlToShim)) {
		return urlToShim;
	}

	try {
		const baseUrl = document.baseURI || window.location.toString();
		const absUrlWithDomainToShim = getAbsUrlWithDomain(urlToShim, baseUrl);
		const urlPrefixToShim = ccAbsUrlWithDomain(baseUrl);
		const urlShimTemplate = shimAbsUrlWithDomain(shimTemplate, baseUrl);
		return getShimmedUrl(
			absUrlWithDomainToShim,
			urlPrefixToShim,
			urlShimTemplate,
			baseUrl,
			domainNamesToReplace,
		);
	} catch {
		return urlToShim;
	}
}

const ccAbsUrlWithDomain = memoize((base: string) =>
	appendSlash(getAbsUrlWithDomain(CC_CONTEXT_PATH, base)),
);
const shimAbsUrlWithDomain = memoize((urlShimTemplate: string, base: string) =>
	appendSlash(getAbsUrlWithDomain(urlShimTemplate, base)),
);

function getAbsUrlWithDomain(url: string, base: string): string {
	return new URL(url, base).toString();
}

function getShimmedUrl(
	absUrlWithDomainToShim: string,
	urlPrefixToShim: string,
	urlShimTemplate: string,
	baseUrl: string,
	domainNamesToReplace?: string[],
) {
	if (absUrlWithDomainToShim.includes(CC_CONTEXT_PATH)) {
		//We can only rebase or shim /wiki URLs
		const absUrlObj = new URL(absUrlWithDomainToShim);
		const baseUrlObj = new URL(baseUrl);
		//Check if the URL should be rebased
		if (domainNamesToReplace?.includes(absUrlObj.hostname)) {
			//Rebase the URL by replacing the origin string of absUrlWithDomainToShim with that of baseUrl
			const rebasedAbsUrlToShim = absUrlWithDomainToShim.replace(
				absUrlObj.origin,
				baseUrlObj.origin,
			);
			//Shimming the rebased URL
			return rebasedAbsUrlToShim.replace(urlPrefixToShim, urlShimTemplate);
		}
	}
	return absUrlWithDomainToShim.replace(urlPrefixToShim, urlShimTemplate);
}

function appendSlash(url: string): string {
	if (!url.endsWith('/')) {
		return `${url}/`;
	}
	return url;
}

const URL_ADF_KEYS = ['url', 'href'];
function replaceCcPageUrlsInAdf(
	adfJson: { [key: string]: any },
	shimTemplate: string,
	domainNamesToReplace?: string[],
): void {
	Object.entries(adfJson).forEach(([key, value]) => {
		if (!value) {
			return;
		}
		if (URL_ADF_KEYS.includes(key) && typeof value === 'string') {
			adfJson[key] = shimThisUrl(value, shimTemplate, domainNamesToReplace);
		} else if (typeof value === 'object') {
			replaceCcPageUrlsInAdf(value, shimTemplate, domainNamesToReplace);
		}
	});
}
