import { cfetch } from '@confluence/network';

import { isNewUserQuery } from './isNewUser';

/**
 * Stores origin attributes into onboarding state for new users
 * @param attributes origin attributes
 */
export const storeOrigin = (attributes: any) => {
	const URI = window['GLOBAL_APOLLO_CLIENT_URI'] || '/cgraphql';
	void cfetch(`${URI}?q=isNewUserQuery`, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'X-APOLLO-OPERATION-NAME': 'isNewUserQuery',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			query: isNewUserQuery,
		}),
	}).then(async (res: any) => {
		if (res.ok) {
			const data = await res.json();
			const isNewUser = data?.data?.isNewUser || false;
			if (!!isNewUser) {
				void cfetch('/wiki/rest/onboarding/1/store', {
					method: 'PUT',
					body: JSON.stringify({
						key: 'onboarding-origin-state:attributes',
						value: JSON.stringify(attributes),
					}),
					headers: {
						'Content-Type': 'application/json',
					},
				});

				window.dispatchEvent(new Event('onboardingAttributesUpdated'));
				window.sessionStorage.setItem('NEW_XFLOW_USER', 'true');
			}
		}
	});
};
