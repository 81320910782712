/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { FC } from 'react';
import React from 'react';

import { themeSSRManager } from '@confluence/ssr-utilities';

export const SSRThemeInit: FC = () => {
	const nonceValue = window.__SSR_TRACE_ID__;
	return (
		<script
			{...(nonceValue ? { nonce: nonceValue } : {})}
			dangerouslySetInnerHTML={{
				__html: `if(window["__SSR_RENDERED__"]){${themeSSRManager.getThemeHtmlAttrsScript()}}`,
			}}
		/>
	);
};
