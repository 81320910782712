// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js!../../helpers/animation.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GwPZR26uyN {
	visibility: hidden;
	width: 0;
	height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/SpaceBase/SpaceBaseComponent.module.css"],"names":[],"mappings":"AAEA;CACC,kBAAkB;CAClB,QAAQ;CACR,SAAS;AACV","sourcesContent":["@import '../../helpers/animation.module.css';\n\n.hidden {\n\tvisibility: hidden;\n\twidth: 0;\n\theight: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": `GwPZR26uyN`
};
export default ___CSS_LOADER_EXPORT___;
