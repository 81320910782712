let localAnalyticsEnabled: boolean | undefined;
let localProduct: string | undefined;

interface BscGlobalState {
	analyticsEnabled?: boolean;
	product?: string;
}

export const getBscGlobalState: () => BscGlobalState = () => {
	if (localAnalyticsEnabled == null) {
		localAnalyticsEnabled = true;
	}

	return { analyticsEnabled: localAnalyticsEnabled, product: localProduct };
};

export const initializeGlobalState = ({
	analyticsEnabled = true,
	product = 'NONE',
}: BscGlobalState = {}) => {
	localAnalyticsEnabled = analyticsEnabled;
	localProduct = product;
};

export const clearGlobalState = () => {
	localAnalyticsEnabled = undefined;
	localProduct = undefined;
};
