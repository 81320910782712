import { fg } from '@atlaskit/platform-feature-flags';

import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';
import { isThirdPartyAllowed } from '../third-party-verification';

export const checkThirdParty = async (
	thirdParty: string,
	onAllowed: () => Promise<void>,
	onDenied?: () => Promise<void>,
): Promise<void> => {
	if (!fg('platform.moonjelly.browser-storage-controls')) {
		// If we have no flag, we should be operating as normal and allow all third parties
		return onAllowed();
	}

	try {
		const isAllowed = await isThirdPartyAllowed(thirdParty);
		sendPackageOperationalEvent({
			action: 'usedCheckThirdParty',
			attributes: {
				wasRejected: !isAllowed,
				thirdParty,
			},
		});

		return isAllowed ? onAllowed() : onDenied?.();
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'checkThirdPartyError',
			attributes: {
				wasRejected: true,
				thirdParty,
			},
			message: `Failed to use check third party. ${e.message || ''}`,
		});
	}
};
