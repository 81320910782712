import type { languageMessages } from './messages';

// For Localized Language Support, which we're aiming parity with, see: https://hello.atlassian.net/wiki/spaces/i18n/pages/647802766/Localized+Language+Support+Coverage+and+Strategy#What-languages-do-we-support/localize-at-Atlassian?
export enum SupportedTranslationLanguages {
	ChineseSimplified = 'chineseSimplified',
	ChineseTraditional = 'chineseTraditional',
	Czech = 'czech',
	Danish = 'danish',
	Dutch = 'dutch',
	English = 'english',
	Finnish = 'finnish',
	French = 'french',
	German = 'german',
	Hungarian = 'hungarian',
	Italian = 'italian',
	Japanese = 'japanese',
	Korean = 'korean',
	Norwegian = 'norwegian',
	Polish = 'polish',
	PortugueseBrazilian = 'portugueseBrazilian',
	Russian = 'russian',
	Spanish = 'spanish',
	Swedish = 'swedish',
	Turkish = 'turkish',
	Thai = 'thai',
	Ukrainian = 'ukrainian',
	Vietnamese = 'vietnamese',
}

export type TranslationLanguageLabels =
	(typeof languageMessages)[keyof typeof languageMessages]['defaultMessage'];

export const supportedTranslationLanguagesEnglishAlphabeticalOrder = Object.values(
	SupportedTranslationLanguages,
);

export const supportedTranslationLanguagesPopularOrder = [
	SupportedTranslationLanguages.English,
	SupportedTranslationLanguages.German,
	SupportedTranslationLanguages.Japanese,
	SupportedTranslationLanguages.French,
	SupportedTranslationLanguages.Korean,
	SupportedTranslationLanguages.Spanish,
	SupportedTranslationLanguages.PortugueseBrazilian,
	SupportedTranslationLanguages.Russian,
	SupportedTranslationLanguages.Dutch,
	SupportedTranslationLanguages.ChineseSimplified,
	SupportedTranslationLanguages.Polish,
	SupportedTranslationLanguages.Italian,
	SupportedTranslationLanguages.ChineseTraditional,
	SupportedTranslationLanguages.Norwegian,
	SupportedTranslationLanguages.Swedish,
	SupportedTranslationLanguages.Czech,
	SupportedTranslationLanguages.Finnish,
	SupportedTranslationLanguages.Danish,
	SupportedTranslationLanguages.Hungarian,
	SupportedTranslationLanguages.Turkish,
	SupportedTranslationLanguages.Thai,
	SupportedTranslationLanguages.Ukrainian,
	SupportedTranslationLanguages.Vietnamese,
];
