import type { FC } from 'react';
import React, { memo, useRef, useContext, useCallback, useEffect, useState } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import isEqual from 'lodash/isEqual';

import UFOLoadHold from '@atlassian/ufo-load-hold';

import {
	VIEW_PUBLIC_LINK_SHARED_PAGE_EXPERIENCE,
	VIEW_CONTENT_EXPERIENCE,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { isInitial, PageSegmentLoadEnd, PageSegmentLoadMark } from '@confluence/browser-metrics';
import { macrosPreloadPerformed, getPreloadFailedMacros } from '@confluence/fabric-extension-lib';
import { ProgressBarContainer } from '@confluence/global-progress-bar-container';
import { usePageState } from '@confluence/page-context';
import { getMark } from '@confluence/performance';
import { RoutesContext } from '@confluence/route-manager';

import { CONTENT_RENDERER_METRIC } from './perf.config';

const contentViews = new Set<string>();

type PerformanceMetricsComponentProps = {
	revision?: string;
	appearance: string;
	contentId: string;
	fabric: boolean;
	loadedFromCache: boolean;
	isAutoConverted?: boolean;
};
export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

const createRendererMeasure = () => {
	try {
		performance.mark('rendererRendered.end');
		performance.measure(
			'browser-metric:ContentRenderer',
			isInitial() ? undefined : 'browser-metric.start',
			'rendererRendered.end',
		);
	} catch (e) {
		// Suppress error when performance API failed.
		// Note: in MicrosoftEdge version 18 and prior, performance.measure(name, startMark, endMark) API will throw SyntaxError if startMark is undefined.
		// That's why wrap with try catch statement.
	}
};

const augmentWithNewTTI = (customData) => {
	if (process.env.REACT_SSR || !isInitial()) {
		return customData;
	}

	// Since TTI is not yet available at this point, use rendererRendered as the closest
	// approximation, and take actual measurement of FMP. Here FMP is defined and available
	// when page is successfully SSR'ed. Otherwise FMP *will be* evaluated as TTI later on.
	const tti = performance.getEntriesByName('rendererRendered.end').pop()?.startTime || 0;
	const fmp = performance.getEntriesByName('browser-metric:fmp').pop()?.duration || 0;

	const macroFailures = getPreloadFailedMacros();
	// nTTI is considered equal to FMP in the following cases:
	//  - page is successfully server-rendered;
	//  - fmp is already known at the given point;
	//  - all requested macros preloaded before preloading is cut off by timeout.
	// In all other cases nTTI = TTI.
	let ntti = tti;
	if (window.__SSR_RENDERED__ && !macroFailures && fmp > 0) {
		ntti = fmp;
	}

	return {
		...customData,
		macroFailures,
		// Both `fmp` and `tti` metrics are rounded to 1ms by BM library
		nTTI: Math.round(ntti),
	};
};

const PerformanceMetricsComponent: FC<PerformanceMetricsComponentProps> = ({
	revision,
	appearance,
	contentId,
	fabric,
	loadedFromCache,
	isAutoConverted,
}) => {
	const [{ isLite, routeName }] = usePageState();
	// Temp query param check if ccProxyTarget logic is still being used - CCP-2348
	const { getQueryParams } = useContext(RoutesContext);
	const [rendererMeasureCreated, setRendererMeasureCreated] = useState(false);
	const { ccProxyTarget, ccProxyStaticApp, homepageId } = getQueryParams();

	const hasHomepageId = Boolean(routeName === 'SPACE_OVERVIEW' && homepageId);

	const isPublicLink = routeName === 'EXTERNAL_SHARE';
	const experienceName = isPublicLink
		? VIEW_PUBLIC_LINK_SHARED_PAGE_EXPERIENCE
		: VIEW_CONTENT_EXPERIENCE;

	const macroFailures = getPreloadFailedMacros();
	const endMarkWhenMacrosSSRed =
		macrosPreloadPerformed() && !macroFailures ? getMark('CFP-63.ssr-ttr') : undefined;

	const createRendererMeasureOnComplete = useCallback(() => {
		createRendererMeasure();
		setRendererMeasureCreated(true);
	}, [setRendererMeasureCreated]);

	const customData = {
		appearance,
		contentId,
		fabric,
		isLite: Boolean(isLite),
		ccProxyTarget: Boolean(ccProxyTarget || ccProxyStaticApp),
		loadedFromCache,
		isAutoConverted,
		hasHomepageId,
	};

	return (
		<>
			<UFOLoadHold name="CreateRendererMeasure" hold={!rendererMeasureCreated} />
			<ExperienceSuccess
				key={`es${contentId}_${revision}`}
				name={experienceName}
				attributes={{ fabric }}
			/>
			<PageSegmentLoadEnd
				key={`psle${contentId}`}
				metric={CONTENT_RENDERER_METRIC}
				customData={customData}
				stopTime={endMarkWhenMacrosSSRed}
				onComplete={createRendererMeasureOnComplete}
				augmentCustomDataAfterComplete={augmentWithNewTTI}
			/>
			<PageSegmentLoadMark
				key={`pslm${contentId}`}
				metric={CONTENT_RENDERER_METRIC}
				markName="fy23-legacy-tti"
			/>
		</>
	);
};

type PerformanceMetricsProps = {
	appearance: string;
	contentId: string;
	fabric: boolean;
	revision?: string;
	isAutoConverted?: boolean;
};

function FinishProgressBar({ progressBar }) {
	useEffect(() => {
		/**
		 * Typically, the progress bar finishes at the conclusion of the browser-metric page load.
		 * On content pages, it is ended much earlier, right after the main content has rendered.
		 */
		if (progressBar.hasStarted()) {
			progressBar.finish();
		}
	});
	return null;
}

// The `PerformanceMetrics` component is memoized because performance and
// experience tracking components used below rely on their side effects to emit
// analytics and multiple re-rendering of these components may alter the timing
// of events being sent, and even data being sent.
// The memo ensures that these tracking components are rendered once per given
// set of props, which will make perf/experience tracking accurate.
export const PerformanceMetrics = memo(
	({ appearance, fabric, contentId, revision, isAutoConverted }: PerformanceMetricsProps) => {
		// This is a heuristic allowing to tell more or less accurately whether page has been loaded
		// from cache or from network. Heuristic is based on assumption that if any given contentId
		// was visited the corresponding content will be cached.
		// The only exception to that heuristic is transition from editor to renderer, which can be
		// identified and filtered out when querying data.
		// WARN: this is temp solution to figure out how much benefit our customers get from cached
		// pages content.
		const loadedFromCacheRef = useRef(contentViews.has(contentId));
		const loadedFromCache = loadedFromCacheRef.current;

		useEffect(() => {
			contentViews.add(contentId);
		}, [contentId]);

		// Only gather metrics if the content revision has changed since the last render.
		// This tracks transitions more accurately and also prevents metrics miss-firing
		// if the PageContentRenderer component re-renders for any reason.
		const prevRevision = usePrevious(revision) ?? null;
		const revisionChanged = revision !== prevRevision;

		if (revisionChanged) {
			return (
				<>
					<Subscribe to={[ProgressBarContainer]}>
						{(progressBar: ProgressBarContainer) => <FinishProgressBar progressBar={progressBar} />}
					</Subscribe>
					<PerformanceMetricsComponent
						revision={revision}
						appearance={appearance}
						contentId={contentId}
						isAutoConverted={isAutoConverted}
						fabric={fabric}
						loadedFromCache={loadedFromCache}
					/>
				</>
			);
		} else {
			return null;
		}
	},
	isEqual,
);
