import gql from 'graphql-tag';

export const PageUnifiedQueryV2 = gql`
	query PageUnifiedQueryV2(
		$contentId: ID!
		$isSSR: Boolean = false
		$includeAlias: Boolean = false
		$useNewContentTopper: Boolean = false
	) @SLA {
		pageV2(id: $contentId) {
			id
			status
			title
			version {
				createdAt
				number
			}
			internalProperties {
				confRev
				subType
			}
			body {
				atlas_doc_format {
					representation
					value
				}
			}
		}
		spaceV2ForPage(id: $contentId) {
			id
			key
			alias @include(if: $includeAlias)
			lookAndFeel {
				content {
					screen {
						background
						backgroundColor
						backgroundImage
						backgroundPosition
						backgroundSize
						backgroundRepeat
						backgroundOrigin
						backgroundClip
						backgroundAttachment
						backgroundBlendMode
						layer {
							background
							backgroundColor
							backgroundImage
							backgroundPosition
							backgroundSize
							backgroundRepeat
							backgroundOrigin
							backgroundClip
							backgroundAttachment
							backgroundBlendMode
							width
							height
						}
						gutterTop
						gutterRight
						gutterBottom
						gutterLeft
					}
					container {
						background
						backgroundColor
						backgroundImage
						backgroundPosition
						backgroundSize
						backgroundRepeat
						backgroundOrigin
						backgroundClip
						backgroundAttachment
						backgroundBlendMode
						padding
						borderRadius
					}
				}
			}
			settings {
				routeOverrideEnabled
			}
			theme {
				name
				themeKey
			}
		}
		contentPropertiesV2ForPage(
			pageId: $contentId
			keys: ["cover-picture-id-published", "editor", "content-appearance-published"]
		) {
			key
			value
			version {
				number
			}
		}

		# The following are still coming from the existing API
		content(id: $contentId) {
			nodes {
				id
				type
				subType
				macroRenderedOutput @include(if: $isSSR) {
					key
					value {
						value
						representation
						webresource {
							tags {
								js
								css
							}
						}
					}
				}
				metadata {
					lastModifiedDate
					frontend {
						fabricEditorSupported
						coverPictureWidth @include(if: $useNewContentTopper)
					}
				}
			}
		}
	}
`;
