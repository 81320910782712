import React from 'react';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import {
	SiteHomepageRedirectLoader,
	GoToHomeLoader,
} from '@confluence/home/entry-points/HomeRedirects';

export const WikiRoute = () => (
	<SiteHomepageRedirectLoader>
		<GoToHomeLoader />
	</SiteHomepageRedirectLoader>
);

WikiRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'wikiScreen' },
	},
	MainLayout: {
		navView: PRODUCT_HOME_ID,
	},
});
