import type { ParagraphChunk } from '../../utils/diff-match-patch/utils';

import { suggestionGenerator, type SuggestionGenerator } from './streaming-service';

// NOTE: It's important that we maintain a consistent API regardless of which endpoint we hit.
// This is a generic interface we should be able to use for any version of the provider.
export type ProactiveAIProvider = (
	paragraphs: Array<ParagraphChunk>,
) => SuggestionGenerator | undefined;

/**
 * This will filter out chunks from being streamed to the service which are not valid.
 */
export const isChunkExcluded = (chunk: ParagraphChunk) =>
	chunk.from === chunk.to || !chunk.text || !chunk.text.trim().length;

/* istanbul ignore next */
// This V1 endpoint is the final endpoint we're targetting for hello release.
export const fetchAIParagraphs =
	(proactiveAIApiUrl: string, locale: string): ProactiveAIProvider =>
	(paragraphs: Array<ParagraphChunk>) => {
		const included = paragraphs.filter((chunk) => !isChunkExcluded(chunk));
		if (!!included.length) {
			return suggestionGenerator({
				endpoint: proactiveAIApiUrl,
				paragraphs: included,
				abortController: new AbortController(),
			});
		}

		return;
	};
