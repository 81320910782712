import { preloadSpaceOverviewQuery } from '@confluence/space-overview/entry-points/preloadSpaceOverview';
import {
	preloadSpaceBlogs,
	preloadSpaceBlogsAppearance,
} from '@confluence/space-blogs/entry-points/preloadSpaceBlogs';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';

export const preloadSpaceBlogsRoute = async (match: RouteMatch) => {
	const tasks: Promise<any>[] = [];
	const spaceKey = match.params.spaceKey;

	const shouldPreloadUsingSpaceKey =
		getSSRFeatureFlag('confluence.ssr.remove.preload.space.overview.query') &&
		Boolean(process.env.REACT_SSR);

	tasks.push(
		preloadSpaceBlogs({
			spaceKey,
		}),
	);

	if (shouldPreloadUsingSpaceKey) {
		tasks.push(
			preloadPageTree({
				spaceKey,
				statuses: PAGE_TREE_STATUSES,
				isSuperAdmin: false,
			}),
		);
	} else {
		tasks.push(
			preloadSpaceOverviewQuery(spaceKey).then(({ data }) => {
				const homepageId = data?.space?.homepage?.id;
				if (homepageId) {
					return preloadPageTree({
						spaceKey,
						contentId: homepageId,
						statuses: PAGE_TREE_STATUSES,
						isSuperAdmin: false,
					});
				}
			}),
		);
	}

	tasks.push(
		getPreloaderFnContext().then(({ isLoggedIn }) => {
			return preloadSpaceBlogsAppearance({
				spaceKey,
				isLoggedIn,
			});
		}),
	);

	return Promise.all(tasks);
};
