/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { lazy, useContext, Fragment } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { RendererContext } from '../../context';
import { token } from '@atlaskit/tokens';
import MediaServicesNoImageIcon from '@atlaskit/icon/glyph/media-services/no-image';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';

const AKButton = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.button' */
			'@atlaskit/button'
		),
);

type EgressErrorMessageProps = {
	url: string;
};

type LinkWithIconProps = {
	href: string;
	text: string;
};

const LinkWithIcon = ({ href, text }: LinkWithIconProps) => {
	return (
		<AKButton
			iconAfter={<ShortcutIcon label="" />}
			appearance="link"
			href={href}
			target="_blank"
			spacing="none"
		>
			{text}
		</AKButton>
	);
};

export const EgressErrorMessage = ({ url }: EgressErrorMessageProps) => {
	const { forgeEnvironment } = useContext(RendererContext);
	const dataUriRegex = /^data/;
	const isDataUri = dataUriRegex.test(url);

	if (forgeEnvironment === 'DEVELOPMENT') {
		return (
			<div
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={css({
					display: 'flex',
					padding: token('space.150', '12px'),
				})}
				data-testid="egress-error-message"
			>
				<div
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					css={css({
						alignSelf: 'center',
					})}
				>
					<MediaServicesNoImageIcon label="" size="large" />
				</div>
				<div
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					css={css({
						display: 'flex',
						paddingLeft: token('space.150', '12px'),
						color: token('color.text.accent.red', '#AE2A19'),
						overflow: 'hidden',
					})}
				>
					<ErrorIcon label="" />
					<p
						// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						css={css({
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
							'&&': {
								paddingLeft: token('space.050', '4px'),
								margin: token('space.0', '0px'),
								alignSelf: 'start',
								overflow: 'hidden',
							},
						})}
					>
						{isDataUri ? (
							<Fragment>
								Please use the <b>data:image</b> scheme for an image source.
							</Fragment>
						) : (
							<Fragment>
								Egress permissions for <LinkWithIcon href={url} text={url} /> missing in the
								manifest. For more information{' '}
								<LinkWithIcon
									href="https://developer.atlassian.com/platform/forge/manifest-reference/permissions/#images"
									text="see reference docs"
								/>
								.
							</Fragment>
						)}
					</p>
				</div>
			</div>
		);
	}

	return (
		<div title={`Unable to display due to an image link issue.`}>
			<MediaServicesNoImageIcon label="" />
		</div>
	);
};
