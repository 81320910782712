import gql from 'graphql-tag';

/**
 * This is a query that fetches all the user preferences on the server-side.
 */
export const UserPreferencesForSSRQuery = gql`
	query UserPreferencesForSSRQuery($spaceKey: String!, $hasSpaceKey: Boolean!) {
		userPreferences {
			shouldShowCardOnPageTreeHover
			feedRecommendedUserSettingsDismissTimestamp
			spaceViewsPersistence(spaceKey: $spaceKey) @include(if: $hasSpaceKey)
			userSpacesNotifiedOfExternalCollab
			favouriteTemplateEntityIds
			homePagesDisplayView
			feedType
			feedTab
			recentFilter
			missionControlOverview
			globalPageCardAppearancePreference
			homeWidgets {
				id
				state
				__typename
			}
			recentFilter
			spacePagesSortView(spaceKey: $spaceKey) @include(if: $hasSpaceKey) {
				field
				order
				__typename
			}
			isHomeOnboardingDismissed
			theme
			watchMyOwnContent
			premiumToolsDropdownPersistence(spaceKey: $spaceKey) @include(if: $hasSpaceKey)
			__typename
		}
	}
`;
