import { getPreloaderFnContext } from '@confluence/query-preloader-tools';

export const getCompanyHubSpaceKeyForSSR = async () => {
	const { activationId, cloudId, featureFlags } = await getPreloaderFnContext();

	const isNewHubKeyEnabled =
		featureFlags['confluence.frontend.custom-sites.company-hub.new-hub-spacekey'];

	if (isNewHubKeyEnabled) {
		if (activationId && cloudId) {
			return `~HUB-${cloudId}-${activationId}~`;
		}
	} else {
		return '~SYSTEM~';
	}
};
