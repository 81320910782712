// "confluence.fe.page-segment-load.XXX"

export const APP_NAVIGATION_SEGMENT_KEY = 'app-navigation';
export const BLOG_TREE_SEGMENT_KEY = 'blog-tree';
export const BYLINE_SEGMENT_KEY = 'byline';
export const READ_TIME_SEGMENT_KEY = 'read-time';
export const CONTENT_ANALYTICS_VIEWERS_SEGMENT_KEY = 'content-analytics-viewers';
export const CONTENT_ANALYTICS_DIALOG_SEGMENT_KEY = 'content-analytics-dialog';
export const CONTENT_RENDERER_SEGMENT_KEY = 'content-renderer';
export const CONTENT_TITLE_SEGMENT_KEY = 'content-title';
export const FAVOURITE_BUTTON_SEGMENT_KEY = 'favourite-button';
export const FOCUSED_INLINE_COMMENT_SEGMENT_KEY = 'focused-inline-comment';
export const INLINE_COMMENTS_HIGHLIGHTS_SEGMENT_KEY = 'inline-comments-highlights';
export const RENDERER_INLINE_COMMENT_RENDER_SEGMENT_KEY = 'render-inline-comment';
export const EDITOR_INLINE_COMMENT_RENDER_SEGMENT_KEY = 'editor-render-inline-comment';
export const REACTIONS_SEGMENT_KEY = 'reactions';
export const MEDIA_SEGMENT_KEY = 'media';
export const PAGE_COMMENTS_SEGMENT_KEY = 'page-comments';
export const PAGE_REACTIONS_SEGMENT_KEY = 'page-reactions';
export const FEED_REACTIONS_SEGMENT_KEY = 'feed-reactions';
export const PAGE_TREE_SEGMENT_KEY = 'page-tree';
export const RESTRICTIONS_BUTTON_SEGMENT_KEY = 'restrictions-button';
export const GLOBAL_NAVIGATION_SEGMENT_KEY = 'global-navigation';
export const SPACE_NAVIGATION_SEGMENT_KEY = 'space-navigation';
export const SPACE_APPS_SEGMENT_KEY = 'space-apps';
export const WATCH_BUTTON_SEGMENT_KEY = 'watch-button';
export const HOME_OVERVIEW_RECENT_SEGMENT_KEY = 'home-overview-recent';
export const HOME_OVERVIEW_FEED_SEGMENT_KEY = 'home-overview-feed';
export const HOME_OVERVIEW_RIGHT_PANEL_SEGMENT_KEY = 'home-overview-right-panel';
export const HOME_OVERVIEW_SPACES_SECTION_SEGMENT_KEY = 'home-overview-spaces-section';
export const HOME_RECENTS_VIEW_SEGMENT_KEY = 'home-recents-view';
export const HOME_STARRED_VIEW_SEGMENT_KEY = 'home-starred-view';
export const HOME_DRAFTS_VIEW_SEGMENT_KEY = 'home-drafts-view';

export const END_OF_PAGE_RECOMMENDATIONS_SEGMENT_KEY = 'end-of-page-recommendations';
export const IN_EDITOR_TEMPLATES_SEGMENT_KEY = 'in-editor-templates';
export const NCS_CONNECTED_FMP_KEY = 'ncs-connected-fmp';
export const ADVANCED_SEARCH_COMPONENT_SEGMENT_KEY = 'advanced-search-component';
export const ADVANCED_SEARCH_FILTER_SECTION_SEGMENT_KEY = 'advanced-search-filter-section';
export const ADVANCED_SEARCH_RESULT_SECTION_SEGMENT_KEY = 'advanced-search-result-section';
export const DATABASE_HEADER_SEGMENT_METRIC_KEY = 'database-header';
export const SMART_LINK_EMBED_HEADER_SEGMENT_METRIC_KEY = 'smart-link-embed-header';
export const WHITEBOARD_HEADER_SEGMENT_METRIC_KEY = 'whiteboard-header';
export const CONTENT_TYPES_HEADER_TITLE_SEGMENT_KEY = 'content-types-header-title';
export const WHITEBOARD_VIEW_PAGE_CONTENT_SEGMENT_KEY = 'whiteboard-view-page-content';
export const DATABASE_VIEW_PAGE_CONTENT_SEGMENT_KEY = 'database-view-page-content';
export const SHARE_BUTTON_SEGMENT_KEY = 'share-button';
export const VIEW_PAGE_LABELS_METRIC_KEY = 'view-page-labels';
export const ADMIN_ANNOUNCEMENT_BANNER_SEGMENT_KEY = 'admin-announcement-banner';
export const CONNECT_APP_WEB_FRAGMENT_SEGMENT_KEY = 'cp-web-fragment';
export const SPACE_PERMISSIONS_SEGMENT_KEY = 'view-space-permissions';
export const VIEW_PAGE_CONTAINER_SEGMENT_KEY = 'view-page-container';
export const EMBED_VIEW_PAGE_CONTENT_SEGMENT_KEY = 'embed-view-page-content';
export const EMBED_VIEW_PAGE_BLANK_CONTENT_SEGMENT_KEY = 'embed-view-page-blank-content';
export const EDITOR_SEGMENT_KEY = 'editor';
