import Cookies, { type CookieAttributes } from 'js-cookie';

import { fg } from '@atlaskit/platform-feature-flags';

import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';

/**
 * Sets a cookie synchronously. Since this doesn't wait for user preferences,
 * it should only be used for strictly necessary cookies which are critical to
 * application functionality.
 */

export const setStrictlyNecessaryCookie = (
	key: string,
	value: string,
	attributes?: CookieAttributes,
) => {
	try {
		if (fg('platform.moonjelly.browser-storage-controls')) {
			sendPackageOperationalEvent({
				action: 'usedSetStrictlyNecessaryCookie',
				attributes: {
					cookieKey: key,
				},
			});
		}
		Cookies.set(key, value, { ...attributes, 'atl-set-cookie': true });
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'usedSetStrictlyNecessaryCookieError',
			attributes: { cookieKey: key },
			message: `Failed to use set strictly necessary cookie. ${e.message || ''}`,
		});
	}
};
