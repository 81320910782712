import { initializeStorageManager } from '@confluence/storage-manager';
import { applyPersistedOverrides, updateSessionData } from '@confluence/session-data';

export const postProcessSessionData = async (sessionData) => {
	const { userId, featureFlags, isLicensed } = sessionData;

	// Local and session storage
	initializeStorageManager({ userId, isLicensed }).then(() => {
		// Load and process FF overrides persisted by storage-manager
		updateSessionData(sessionData, applyPersistedOverrides(featureFlags));
	});

	return sessionData;
};
