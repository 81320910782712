import { query } from '@confluence/query-preloader-tools';

import { preloadOptions } from '../Options/preloadOptions';

import { getDraftsQueryOptions } from './draftsQueryHelpers';

export const preloadDrafts = ({ isLoggedIn, bestEffortTask, isSpaceAliasFFEnabled }) => {
	return Promise.all([
		bestEffortTask('HomeDraftsQuery', () =>
			query(getDraftsQueryOptions({ isSpaceAliasFFEnabled })),
		),
		preloadOptions({ isLoggedIn }),
	]);
};
