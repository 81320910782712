import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { Command } from '@atlaskit/editor-common/types';
import type { EditorState, Selection, Transaction } from '@atlaskit/editor-prosemirror/state';
import { AllSelection, TextSelection } from '@atlaskit/editor-prosemirror/state';
import { Decoration, DecorationSet } from '@atlaskit/editor-prosemirror/view';
import { isFedRamp } from '@atlassian/atl-context';

import type {
	InvokedFrom,
	InvokedFromTriggerMethod,
} from '../../analytics/analytics-flow/analyticsFlowTypes';
import { addAnalytics } from '../../analytics/utils';
import type { EditorPluginAIConfigItemMarkdown } from '../../config-items/config-items';
import type { AIGlobalOptIn } from '../../types';
import { isConfluenceTitleToolbarSuggestTitle } from '../../utils/confluence-suggest-title/is-title-toolbar-suggest-title';

import { createCommand } from './decoration-plugin-factory';
import type { EndExperienceAction, ShowModalAction } from './reducer';

export const createWidgetDecoration = (pos: number, type: 'start' | 'end', key?: string) => {
	const widgetSpanElement = document.createElement('span');
	// Firefox needs content inside the span in order to add height to the element.
	// Otherwise, the height is set to 0 which prevents the start and
	// end widgets from rendering correctly and produces incorrect measurements
	// when we need to determine where to place the modal under the content.
	// Zero width spaces have been known to introduce some strange selection
	// behaviour in the Editor, however this approach works for our use case
	// in the ai-plugin where we do not expect users to be making selections
	// over ai-highlighted content in the same way they select regular content
	// in the Editor, this is also not intended to be a permanent fix post EAP.
	widgetSpanElement.appendChild(document.createTextNode('\u200b'));
	widgetSpanElement.setAttribute('data-testid', `ai-modal-${type}-widget`);
	// TODO: https://product-fabric.atlassian.net/browse/ED-17641
	// Issue in safari when pressing cmd+shift+right at start widget highlights
	// all text
	widgetSpanElement.className = `ai-modal-${type}-widget`;

	widgetSpanElement.contentEditable = 'false';
	return Decoration.widget(pos, widgetSpanElement, {
		side: type === 'start' ? 0 : -1,
		key: key ?? `${type}WidgetDecoration`,
	});
};

export const createInlineDecoration = (from: number, to: number) => {
	return Decoration.inline(
		from,
		to,
		{
			class: 'ai-selection-highlight',
			'data-testid': 'ai-selection-highlight',
		},
		{ key: 'inlineDecoration' },
	);
};

export const createGeneratedContentDecorations = (from: number, to: number) => {
	const startWidgetDecoration = createWidgetDecoration(from, 'start', 'generatedStartWidget');

	const highlightInlineDecoration = Decoration.inline(
		from,
		to,
		{
			class: 'ai-generated-content-highlight',
			'data-testid': 'ai-generated-content-highlight',
		},
		{ key: 'generatedInlineDecoration' },
	);

	const endWidgetDecoration = createWidgetDecoration(to, 'end', 'generatedEndWidget');

	return [startWidgetDecoration, highlightInlineDecoration, endWidgetDecoration];
};

function getInitialPositions(selection: Selection) {
	switch (true) {
		case selection instanceof AllSelection:
			return { startPos: selection.from + 1, endPos: selection.to - 1 };
		default:
			return { startPos: selection.from, endPos: selection.to };
	}
}

export type OpenAIModalProps = {
	state: EditorState;
	configItem: EditorPluginAIConfigItemMarkdown;
	lastTriggeredFrom?: InvokedFrom;
	triggerMethod?: InvokedFromTriggerMethod;
	aiGlobalOptIn: AIGlobalOptIn;
	initialPrompt?: string;
	positions?: { startPos: number; endPos: number };
};

export const createOpenAIModalCommand = ({
	state,
	configItem,
	lastTriggeredFrom,
	triggerMethod,
	aiGlobalOptIn,
	initialPrompt,
	positions,
}: OpenAIModalProps): Command => {
	/**
	 * WARNING: Do not remove the isFedRamp check.
	 * This is to ensure that AI functionality is not enabled in FedRamp environments.
	 */
	if (aiGlobalOptIn.status === 'disabled' || isFedRamp()) {
		/**
		 * If the user has opted out of AI, or if user is in FedRamp environment,
		 * do not show the modal it's unclear how this function would be triggered as
		 * all entry points should be removed already
		 */
		return () => false;
	}

	if (aiGlobalOptIn.status === 'disabled-opt-in') {
		aiGlobalOptIn.triggerOptInFlow();

		return createCommand({}, (tr: Transaction) => {
			addAnalytics({
				editorState: state,
				tr,
				payload: {
					action: 'triggerOptInFlow',
					actionSubject: 'editorPluginAI',
					actionSubjectId: 'aiGlobalOptIn',
					attributes: {
						experienceType: configItem.key,
						lastTriggeredFrom,
						selectionType: configItem.selectionType,
					},
					eventType: EVENT_TYPE.UI,
				},
			});
			return tr;
		});
	}

	/**
	 * We add decoration based on the current selection
	 * state of the document and the config item in the command palette
	 * 2 scenarios
	 * 1. If the selection is empty and the document is not empty -> we decorate the entire document
	 * 2. If the selection is empty and the current config type is 'empty config' -> we decorate based on selection preview plugin
	 */
	let useCurrentSelection = true;

	if (state.selection.empty) {
		useCurrentSelection =
			state.doc.content.size !== 0 && configItem.selectionType !== 'empty' ? false : true;
	}

	/**
	 * EXPERIMENTAL: For experimental flow where 'Suggest title' in
	 * Confluence title toolbar will trigger the 'Suggest a title' flow in Editor AI.
	 * Forces whole document selection, even if an existing selection exists.
	 */
	if (isConfluenceTitleToolbarSuggestTitle({ configItem, lastTriggeredFrom })) {
		useCurrentSelection = false;
	}

	// First get the positions that the decorations will be added to the document at
	const { startPos, endPos } =
		positions ||
		getInitialPositions(useCurrentSelection ? state.selection : new AllSelection(state.doc));

	// Then create the decorations and add them to a decoration set
	const startWidgetDecoration = createWidgetDecoration(startPos, 'start');
	const endWidgetDecoration = createWidgetDecoration(endPos, 'end');
	const highlightInlineDecoration = createInlineDecoration(startPos, endPos);
	const widgetDecorations =
		startPos === endPos ? [endWidgetDecoration] : [startWidgetDecoration, endWidgetDecoration];

	const modalDecorationSet = DecorationSet.create(state.doc, [
		...widgetDecorations,
		highlightInlineDecoration,
	]);

	// This "command" is later picked up by a state reducer
	const action: ShowModalAction = {
		type: 'SHOW_MODAL',
		data: {
			lastTriggeredFrom,
			triggerMethod,
			modalDecorationSet,
			configItem,
			modalMountedTimeStamp: Date.now(),
			initialPrompt,
		},
	};

	// Clear the selection after opening the modal to hide annotation toolbar
	return createCommand(action, (tr: Transaction) => {
		const { to, from } = tr.selection;
		if (to !== from) {
			tr.setSelection(TextSelection.create(tr.doc, to));
		}
		return tr;
	});
};

export const createEndAIExperienceCommand = (
	modalDecorationSet?: DecorationSet,
	preserveEditorSelectionOnComplete?: boolean,
): Command => {
	const action: EndExperienceAction = {
		type: 'END_EXPERIENCE',
		data: modalDecorationSet && !preserveEditorSelectionOnComplete ? { modalDecorationSet } : {},
	};
	return createCommand(action, (tr: Transaction) => {
		if (preserveEditorSelectionOnComplete) {
			const inlineDeco = modalDecorationSet?.find(
				undefined,
				undefined,
				(spec) => spec.key === 'inlineDecoration',
			)?.[0];
			if (inlineDeco) {
				tr.setSelection(
					TextSelection.create(
						tr.doc,
						inlineDeco.from,
						Math.min(inlineDeco.to, tr.doc.content.size),
					),
				);
			}
		}
		return tr;
	});
};
