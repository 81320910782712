import { query } from '@confluence/query-preloader-tools';
import { preloadCreateSpace } from '@confluence/create-space/entry-points/preloadCreateSpace';

import {
	StarredSpacesQuery,
	RecentSpacesQuery,
	RecentSpacesWithCurrentSpaceQuery,
	MyVisitedSpacesQuery,
	MyVisitedSpacesWithCurrentSpaceQuery,
	AnonymousSpacesQuery,
} from './SpacesDropdownQueries.graphql';

type preloadSpacesDropdownOptions = {
	isLicensed: boolean;
	isMyVisitedSpacesEnabled?: boolean;
	isSpaceAliasFFEnabled?: boolean;
	selectedSpaceKey?: string;
};

export const preloadSpacesDropdown = ({
	isSpaceAliasFFEnabled = false,
	isLicensed,
	isMyVisitedSpacesEnabled,
	selectedSpaceKey,
}: preloadSpacesDropdownOptions): Promise<{ data: unknown }> => {
	const preloadQueries: Promise<{ data: unknown }>[] = [];

	const RecentSpacesQueryOption = isMyVisitedSpacesEnabled
		? MyVisitedSpacesQuery
		: RecentSpacesQuery;
	const RecentSpacesWithCurrentSpaceQueryOption = isMyVisitedSpacesEnabled
		? MyVisitedSpacesWithCurrentSpaceQuery
		: RecentSpacesWithCurrentSpaceQuery;

	if (isLicensed) {
		preloadQueries.push(
			query({
				query: StarredSpacesQuery,
				variables: { includeAlias: isSpaceAliasFFEnabled },
			}),
		);
		if (selectedSpaceKey) {
			preloadQueries.push(
				query({
					query: RecentSpacesWithCurrentSpaceQueryOption,
					variables: { selectedSpaceKey, includeAlias: isSpaceAliasFFEnabled },
				}),
			);
		} else {
			preloadQueries.push(
				query({
					query: RecentSpacesQueryOption,
					variables: { includeAlias: isSpaceAliasFFEnabled },
				}),
			);
		}
	} else {
		preloadQueries.push(
			query({
				query: AnonymousSpacesQuery,
				variables: { includeAlias: isSpaceAliasFFEnabled },
			}),
		);
	}

	preloadQueries.push(preloadCreateSpace({ isLicensed }));

	return Promise.all(preloadQueries).then(() => ({ data: {} }));
};
