import { getAnalyticsClient, isSSR, pollForBrowserControlsFlag } from '../../../common/utils';
import { initializeGlobalState } from '../../../common/utils/bsc-global-state';
import { Logger } from '../../../common/utils/logger';

import { getSetCookieOverride } from './set-cookie-override';

export interface initializeControlsArgs {
	analyticsEnabled?: boolean;
	product?: string;
	flagEnabled?: boolean;
}

// Hijack the document.cookie setter logic so we can track cookie and usage info if we've opted into cookies management
// This will generate a passthrough that will call the original document.cookie setter logic after we've logged analytics
export const createDocumentCookiePassthrough = async (args: initializeControlsArgs = {}) => {
	// exit silently if in SSR context
	if (isSSR()) {
		return;
	}

	const isFlagEnabled = args.flagEnabled || (await pollForBrowserControlsFlag());
	if (!isFlagEnabled) {
		return;
	}

	initializeGlobalState(args);

	const documentCookieProperty = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie');
	const originalSetter = documentCookieProperty?.set;
	const originalGetter = documentCookieProperty?.get;

	if (!originalSetter || !originalGetter) {
		Logger.warn('No document.cookie capabilities found');
		return;
	}

	// Ensures that the AWC is constructed before overwriting document.cookie. Not
	// doing so was allowing for cases of infinite recursion.
	const analyticsWebClientInstance = getAnalyticsClient();

	// If AWC is not initialized, it will be undefined
	const isAnalyticsInitialized: boolean = !!analyticsWebClientInstance;

	if (!isAnalyticsInitialized) {
		throw new Error(
			'Failed to initialize AnalyticsWebClient. Analytics logging will not be set for document.cookie.',
		);
	}

	// Override document.cookie setter
	Object.defineProperty(document, 'cookie', {
		get: originalGetter,
		set: getSetCookieOverride(originalSetter),
		configurable: true,
	});
};

export const initializeControls = async (args: initializeControlsArgs = {}) => {
	try {
		await createDocumentCookiePassthrough(args);
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'initializeControlsError',
			message: `Failed to initialize browser storage controls. ${e.message || ''}`,
		});
	}
};
