import type {
	MentionResource,
	MentionResourceConfig,
	TeamMentionResourceConfig,
} from '@atlaskit/mention';
import { PresenceResource, DefaultMentionNameResolver } from '@atlaskit/mention';
import type { WithAnalyticsEventsProps } from '@atlaskit/analytics-next/withAnalyticsEvents';

import { getExternalCollaboratorEntitlementFromQuery } from '@confluence/external-collab-ui';

import { MentionNameClient } from './MentionNameClient';
import type { MentionsProviderQuery as MentionsProviderQueryType } from './__types__/MentionsProviderQuery';
import { UserTeamMentionProvider } from './UserTeamMentionProvider';
import type { MentionContext, MentionsConfig } from './types';
import { fetchMentionsProviderData } from './graphql-helpers';
import FeatureGates from '@atlaskit/feature-gate-js-client';

export const USER_MENTION_SERVICE_URL = '/gateway/api/mentions';
export const TEAM_MENTION_SERVICE_URL = '/gateway/api/teams/mentions';

export async function createFabricMentionResource(
	cloudId: string,
	spaceId: string | undefined,
	accountId: string | null,
	withAnalyticsEventProps?: WithAnalyticsEventsProps,
	onInviteItemClick?: () => void,
	userRole?: 'admin' | 'trusted' | 'basic',
	focusedMentionId?: string,
): Promise<MentionResource> {
	const userMentionConfig: MentionResourceConfig = {
		url: `${USER_MENTION_SERVICE_URL}/${cloudId}`,
		productId: 'micros-group/confluence',
		containerId: spaceId || undefined,
		shouldHighlightMention: (mention) => mention.id === accountId,
		debounceTime: 150,
	};

	const shouldEnableInvite = !!onInviteItemClick;

	if (shouldEnableInvite) {
		userMentionConfig.productName = 'Confluence';
		userMentionConfig.shouldEnableInvite = shouldEnableInvite;
		userMentionConfig.onInviteItemClick = onInviteItemClick;
		userMentionConfig.userRole = userRole;
	}

	const teamMentionConfig: TeamMentionResourceConfig = {
		url: TEAM_MENTION_SERVICE_URL,
		productId: 'micros-group/confluence',
		shouldHighlightMention: (mention) => mention.id === accountId,
	};

	const isAnchoredMentionExperimentEnabled = () => {
		const isAnchoredMentionFeatureEnabled = FeatureGates.checkGate(
			'confluence_frontend_anchored_mentions',
		);
		const cohort = FeatureGates.getExperimentValue<'control' | 'experiment'>(
			'confluence_frontend_anchored_mentions_exp',
			'cohort',
			'control',
		);

		return isAnchoredMentionFeatureEnabled && cohort === 'experiment';
	};

	const scrollToMention = () => {
		if (focusedMentionId && isAnchoredMentionExperimentEnabled()) {
			const mentionElement = document.querySelector(`[data-local-id="${focusedMentionId}"]`);
			if (mentionElement) {
				mentionElement.scrollIntoView({
					behavior: 'instant',
					inline: 'center',
					block: 'center',
				});
			}
		}
	};

	userMentionConfig.mentionNameResolver = new DefaultMentionNameResolver(
		new MentionNameClient(),
		withAnalyticsEventProps,
		scrollToMention,
	);

	return userTeamMentionProvider(userMentionConfig, teamMentionConfig);
}

async function userTeamMentionProvider(
	userMentionConfig: MentionResourceConfig,
	teamMentionConfig: TeamMentionResourceConfig,
) {
	let includeExternalCollaborators = false;

	const data: MentionsProviderQueryType | undefined = await fetchMentionsProviderData();

	// Entitlement check to determine if tenant is premium/enabled for Guests
	includeExternalCollaborators = getExternalCollaboratorEntitlementFromQuery(data) === true;

	return new UserTeamMentionProvider(
		'/gateway/api/v1/recommendations',
		userMentionConfig,
		teamMentionConfig,
		includeExternalCollaborators,
	);
}

export async function getMentionsConfig(
	cloudId: string,
	accountId: string | null,
	mentionContext?: MentionContext,
	withAnalyticsEventProps?: WithAnalyticsEventsProps,
	onInviteItemClick?: () => void,
	userRole?: 'admin' | 'trusted' | 'basic',
): Promise<MentionsConfig> {
	if (!cloudId) {
		throw new Error('MentionResource cannot be configured without a cloud id');
	}

	const spaceId = mentionContext?.spaceId || undefined;

	const mentionResource: MentionResource = await createFabricMentionResource(
		cloudId,
		spaceId,
		accountId,
		withAnalyticsEventProps,
		onInviteItemClick,
		userRole,
	);

	return {
		mentionResource,
		presenceResource: new PresenceResource({
			url: '/gateway/api/directory/graphql',
			cloudId,
			productId: 'confluence',
		}),
		// Required for the TinyMCE mentions plugin, should be removed when TinyMCE is removed
		mentionServiceUrl: `${USER_MENTION_SERVICE_URL}/${cloudId}`,
		contextIdentifier: {
			containerId: spaceId || '',
			objectId: mentionContext?.contentId || '',
			childObjectId: mentionContext?.commentId || '',
		},
	};
}
