import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import { type EditorState, type Transaction } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import { type ProactiveAIConfig } from '../../types';
import { type ParagraphChunk } from '../../utils/diff-match-patch/utils';
import { createInlineDecoration } from '../decoration/actions';

import { fetchAIParagraphs } from './api';
import {
	disableCheckForFailedChunksWithAnalytics,
	disableCheckForPurgedChunksWithAnalytics,
	fireAPIReceivedAnalytics,
} from './commands-with-analytics';
import { regenerateAllDecorations } from './decorations';
import { createCommand, getPluginState } from './plugin-factory';
import type { ProactiveAIBlock, ProactiveAISentence } from './states';
import { ACTIONS } from './states';
import {
	getAllDiffObjects,
	getBlocksSentencesNeedingSGCheck,
	getDiffObjectsForSuggestions,
	getSelectedDiffObject,
	removeDiffObjectFromBlockMatchingText,
	removeSelectedDiffObjectFromBlock,
	setBlocksDiffObjects,
} from './utils';

export const insertSuggestion = (from: number, to: number, tr?: Transaction) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const {
				decorationSet,
				insertionCount: currentInsertionCount,
				proactiveAIBlocks,
			} = pluginState;
			if (!proactiveAIBlocks || proactiveAIBlocks.length === 0) {
				return false;
			}

			const updatedProactiveAIBlocks = removeSelectedDiffObjectFromBlock(pluginState, from, to);
			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					// Upon inserting a suggestion we need to ensure that that the insertion widget is removed.
					decorationSet: regenerateAllDecorations({
						decorationSet,
						tr: tr || state.tr,
						pluginState: {
							...pluginState,
							proactiveAIBlocks: updatedProactiveAIBlocks,
						},
					}),
					proactiveAIBlocks: updatedProactiveAIBlocks,
					insertionCount: currentInsertionCount + 1,
				},
			};
		},
		(originalTr: Transaction, state: EditorState) => {
			const { schema } = state;

			const pluginState = getPluginState(state);
			const transaction = tr || originalTr;
			const { selection } = transaction;

			if (selection.from === selection.to) {
				const selectedDiff = getSelectedDiffObject(pluginState, selection.to);

				if (selectedDiff) {
					/**
					 * mark transaction as suggestionAccepted, so that we can ignore update
					 * 	while updating proactiveAIBlocks.
					 */
					transaction.setMeta('suggestionAccepted', true);
					const { from, to, text } = selectedDiff;

					/**
					 * Take the marks from the 'from' position
					 * (beginning of the word to be replaced)
					 *
					 * This is to ensure that the new text has the same marks as the old text.
					 * To be more pragmatic, we only look at 'from' position in case the
					 * word has different mark combinations in each letter.
					 */
					const resolvedPos = state.doc.resolve(from);
					const marks = resolvedPos.nodeAfter?.marks || [];
					return transaction.replaceWith(from, to, schema.text(text, marks));
				}
			}
			return transaction;
		},
	);

export const removeSuggestion = (originalText: string, tr?: Transaction) =>
	createCommand((state) => {
		const pluginState = getPluginState(state);
		const { decorationSet, proactiveAIBlocks, dismissedWords } = pluginState;
		if (!proactiveAIBlocks || proactiveAIBlocks.length === 0) {
			return false;
		}

		const updatedProactiveAIBlocks = removeDiffObjectFromBlockMatchingText(
			pluginState,
			originalText,
		);

		return {
			type: ACTIONS.UPDATE_PLUGIN_STATE,
			data: {
				dismissedWords: dismissedWords.add(originalText),
				decorationSet: regenerateAllDecorations({
					decorationSet,
					tr: tr || state.tr,
					pluginState: {
						...pluginState,
						proactiveAIBlocks: updatedProactiveAIBlocks,
					},
				}),
				proactiveAIBlocks: updatedProactiveAIBlocks,
			},
		};
	});

export const toggleSpellingAndGrammar = (toggleCount: number) =>
	createCommand(
		(state) => {
			const pluginState = getPluginState(state);
			const { isSpellingAndGrammarEnabled, documentSGChecker } = pluginState;

			if (documentSGChecker) {
				// if currently isSpellingAndGrammarEnabled is false then action is to enable
				//	so start whole document S+G check.
				if (!isSpellingAndGrammarEnabled) {
					documentSGChecker.start();
				}
				// if currently isSpellingAndGrammarEnabled is true then action is to disable
				//	so stop whole document S+G check.
				else {
					documentSGChecker.stop();
				}
			}

			return {
				type: ACTIONS.TOGGLE_SPELLING_AND_GRAMMAR_ENABLED,
				data: {
					toggleCount,
				},
			};
		},
		(tr: Transaction) => tr.setMeta('addToHistory', false),
	);

export const disableNeedSpellingAndGrammar = (
	skippedChunkIds: Array<ParagraphChunk['id']>,
	transform?: (tr: Transaction, state: EditorState) => Transaction,
) =>
	createCommand(
		() => {
			return {
				type: ACTIONS.DISABLE_NEED_SPELLING_AND_GRAMMAR,
				data: {
					skippedChunkIds,
				},
			};
		},
		(tr: Transaction, state: EditorState) => {
			if (transform) {
				return transform(tr, state).setMeta('addToHistory', false);
			}
			return tr.setMeta('addToHistory', false);
		},
	);

export const highlightSentence = (sentence: ProactiveAISentence) =>
	createCommand(
		(state) => {
			const { decorationSet } = getPluginState(state);

			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					decorationSet: decorationSet.add(state.doc, [
						createInlineDecoration(sentence.from, sentence.to),
					]),
				},
			};
		},
		(tr) => tr.setMeta('addToHistory', false),
	);

export const unhighlightSentence = () =>
	createCommand(
		(state) => {
			const { decorationSet } = getPluginState(state);
			return {
				type: ACTIONS.UPDATE_PLUGIN_STATE,
				data: {
					decorationSet: decorationSet.remove(
						decorationSet.find(undefined, undefined, (spec) => spec.key === 'inlineDecoration'),
					),
				},
			};
		},
		(tr) => tr.setMeta('addToHistory', false),
	);

export const updateChunksWithDiffObjects = async (
	view: EditorView,
	chunks: ParagraphChunk[],
	locale: string,
) => {
	const pluginState = getPluginState(view.state);
	const { proactiveAIApiUrl } = pluginState;

	const suggestionGenerator = fetchAIParagraphs(proactiveAIApiUrl, locale)(chunks);

	if (!suggestionGenerator) {
		return;
	}

	for await (const suggestionState of suggestionGenerator) {
		switch (suggestionState.state) {
			case 'cached':
			case 'parsed': {
				const suggestions = suggestionState.suggestions;

				const chunksDiffObjectsById = getDiffObjectsForSuggestions(suggestions);
				const newPluginState = getPluginState(view.state);
				const { decorationSet } = newPluginState;
				const updatedProactiveAIBlocks = setBlocksDiffObjects(
					newPluginState,
					chunksDiffObjectsById,
				);

				createCommand(
					() => {
						return {
							type: ACTIONS.UPDATE_PLUGIN_STATE,
							data: {
								proactiveAIBlocks: updatedProactiveAIBlocks,
								decorationSet: regenerateAllDecorations({
									decorationSet,
									tr: view.state.tr,
									pluginState: {
										...newPluginState,
										proactiveAIBlocks: updatedProactiveAIBlocks,
									},
								}),
							},
						};
					},
					(tr: Transaction) => {
						return tr.setMeta('addToHistory', false);
					},
				)(view.state, view.dispatch);

				break;
			}
			case 'trackedDuration': {
				const newPluginState = getPluginState(view.state);
				const { insertionCount, dismissedWords } = newPluginState;
				const allDiffObjects = getAllDiffObjects(newPluginState);
				// Below is analytics for receiving step from S+G API.
				fireAPIReceivedAnalytics({
					view,
					duration: suggestionState.duration,
					totalSuggestions: allDiffObjects.length,
					totalAcceptedSuggestions: insertionCount,
					totalDismissedSuggestions: dismissedWords.size,
				});
				break;
			}
			case 'receivedChunkMetadata': {
				createCommand(
					() => {
						return {
							type: ACTIONS.UPDATE_CHUNK_METADATA,
							data: {
								chunkId: suggestionState.chunkId,
								metadata: suggestionState.metadata,
							},
						};
					},
					(tr: Transaction) => {
						return tr.setMeta('addToHistory', false);
					},
				)(view.state, view.dispatch);
				break;
			}
			case 'failed': {
				if (suggestionState.failedChunkIds.length > 0) {
					disableCheckForFailedChunksWithAnalytics({
						view,
						failedChunkIds: suggestionState.failedChunkIds,
						errors: suggestionState.errors,
						reason: suggestionState.reason,
						statusCode: suggestionState.statusCode,
					})(view.state, view.dispatch);
				}
				break;
			}
			case 'purged': {
				if (suggestionState.purgedChunkIds.length) {
					disableCheckForPurgedChunksWithAnalytics({
						purgedChunkIds: suggestionState.purgedChunkIds,
						totalParts: suggestionState.totalParts,
						totalPurgedParts: suggestionState.totalPurgedParts,
					})(view.state, view.dispatch);
				}
			}
		}
	}
};

const isSelectionInBlock = (from: number, to: number, block: ProactiveAIBlock) => {
	return (block.from <= from && block.to >= from) || (block.from <= to && block.to >= to);
};

const getBlocksNeedingSpellAndGrammarCheck = (state: EditorState) => {
	const { proactiveAIBlocks } = getPluginState(state);

	if (!proactiveAIBlocks || !proactiveAIBlocks.length) {
		return [];
	}

	return proactiveAIBlocks?.filter((block) => !!block.needSpellingAndGrammarCheck);
};

const getBlocksNeedingSGCheck = (state: EditorState, currentBlocks: boolean) => {
	const blocksNeedingSpellAndGrammarCheck = getBlocksNeedingSpellAndGrammarCheck(state);

	const { from, to } = state.selection;
	return blocksNeedingSpellAndGrammarCheck.filter((block) => {
		const selectionInBlock = isSelectionInBlock(from, to, block);
		return currentBlocks ? selectionInBlock : !selectionInBlock;
	});
};

const getChunksNeedingSGCheck = (state: EditorState, currentBlocks: boolean) => {
	const pluginState = getPluginState(state);
	const { splitParagraphIntoSentences } = pluginState;

	const blocksNeedingSpellAndGrammarCheck = getBlocksNeedingSGCheck(state, currentBlocks);

	return splitParagraphIntoSentences
		? getBlocksSentencesNeedingSGCheck(blocksNeedingSpellAndGrammarCheck)
		: blocksNeedingSpellAndGrammarCheck;
};
/**
 * Wait Y (Y > X) seconds before triggering S+G prompt for current blocks.
 * That means,
 *  Author starts updating block and keeps updating for sometime.
 *  Then Author pauses for Y (Y > X) seconds then trigger S+G prompt.
 */
export const triggerSpellingAndGrammarCheckForCurrentBlocks = async (
	view: EditorView,
	locale: string,
) => {
	/**
	 * If selection is overlap with block and it's been more than Y seconds
	 *  since it was last updated then send it for S+G prompt.
	 * Ignore non current blocks, as they will be handled separately.
	 */
	const chunks = getChunksNeedingSGCheck(view.state, true);

	if (chunks.length) {
		await updateChunksWithDiffObjects(view, chunks, locale);
	}
};

/**
 * Wait X seconds before triggering S+G prompt for non current blocks.
 * That means,
 *  Author starts updating block, till cursor (or selection) is in the block,
 *    and S+G prompt hasn't been run since last update then,
 *    trigger S+G prompts after X seconds.
 */
export const triggerSpellingAndGrammarCheckForNonCurrentBlocks = async (
	view: EditorView,
	locale: string,
) => {
	const chunks = getChunksNeedingSGCheck(view.state, false);

	if (chunks.length) {
		await updateChunksWithDiffObjects(view, chunks, locale);
	}
};

export const createTriggerSpellingAndGrammarCheck = (timings: ProactiveAIConfig['timings']) => {
	const triggerSpellingAndGrammarCheckForNonCurrentBlocksThrottled = throttle(
		triggerSpellingAndGrammarCheckForNonCurrentBlocks,
		timings.nonCurrentChunks,
	);

	const triggerSpellingAndGrammarCheckForCurrentBlocksDebounced = debounce(
		triggerSpellingAndGrammarCheckForCurrentBlocks,
		timings.currentChunks,
		{ maxWait: timings.currentChunksMaxWait },
	);

	const triggerSpellingAndGrammarCheck = (view: EditorView, locale: string) => {
		triggerSpellingAndGrammarCheckForNonCurrentBlocksThrottled(view, locale);
		triggerSpellingAndGrammarCheckForCurrentBlocksDebounced(view, locale);
	};

	const cancelDebouncedAndThrottledSpellingAndGrammarCheck = () => {
		triggerSpellingAndGrammarCheckForNonCurrentBlocksThrottled.cancel();
		triggerSpellingAndGrammarCheckForCurrentBlocksDebounced.cancel();
	};

	return {
		triggerSpellingAndGrammarCheck,
		cancelDebouncedAndThrottledSpellingAndGrammarCheck,
	};
};
