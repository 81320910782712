import type { IntlShape } from 'react-intl-next';
import type { PropsWithChildren } from 'react';

import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type {
	ExtensionManifest,
	ExtensionModuleNode,
	ExtensionProvider,
	ExtensionHandler,
	ExtensionHandlers,
	ExtensionComponentProps,
} from '@atlaskit/editor-common/extensions';
import type { ADNode } from '@atlaskit/editor-common/validator';
import type { EditorActions } from '@atlaskit/editor-core';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import type FeatureFlagClient from '@atlaskit/feature-flag-client';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import type { ExtensionHandlerWithReferenceFn } from '@atlassian/editor-referentiality';
import { getAIPanelsProvider } from '@atlassian/editor-plugin-ai/AIPanels';

import { createChartsExtension } from '@confluence/charts/entry-points/createChartsExtension';
import { getMacroId } from '@confluence/macro-tracker';
import { getSessionData } from '@confluence/session-data';
import { createDatabaseExtension } from '@confluence/confluence-databases-extension';
import { createWhiteboardExtension } from '@confluence/whiteboard-extension';
import type { FlagsStateContainer } from '@confluence/flags';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import {
	carouselExtensionProvider,
	linkCardsExtensionProvider,
} from '@confluence/custom-sites-extensions/entry-points/extensionProviders';

import { EXTENSION_TYPE } from '../../extensionConstants';
import { getAutoConverter } from '../../editor-extensions';
import { isAllowedWithFeatureFlags } from '../../editor-extensions/blocklist';
import { unwrapMacroParams, getDefaultParamValue } from '../../editor-extensions/transformers';
import { type MacroConfig, isBodiedExtension } from '../../extensions-common';
import { getRedactedMacroPlaceholderExtensionManifest } from '../../renderer-extensions/redacted-macro-placeholder/manifest';

import { transformFormDetailsIntoFields } from './field-helpers';
import { remapCustomFields, transformParams } from './field-mappings';
import {
	getMissingConfigPlaceholderComponent,
	getNonRenderablePlaceholderComponent,
} from './MacroComponents';
import {
	safeGetMacroName,
	getExtensionBodyType,
	getVisibleParameters,
	buildIconObject,
	prepareMacro,
	shouldUseMacroBrowser,
	canRenderInTheEditor,
	shouldShowPlaceholder,
} from './manifest-helpers';
import type {
	LegacyMacroManifest,
	ConfluencePageContext,
	RendererExtensionHandlers,
} from './extensionTypes';
import { getSelectedBodiedExtensionNode, createQuickInsertModule } from './utils';
// resolvers
import { getAttachmentResolverFor } from './data-resolvers/attachment-resolver';
import {
	spacekeyResolver,
	buildResolver as buildSpacekeyResolver,
} from './data-resolvers/spacekey-resolver';
import { usernameResolver } from './data-resolvers/username-resolver';
import { labelResolver } from './data-resolvers/label-resolver';
import { spaceAndPageResolver } from './data-resolvers/space-and-page-resolver';
import { confluenceContentResolver } from './data-resolvers/confluence-content-resolver';
import { getSpaceKeyAndPageTitleContentResolver } from './data-resolvers/space-key-page-title-content-resolver';
import { calendarResolver } from './data-resolvers/calendar-resolver';
import { getTemplateResolver } from './data-resolvers/template-resolver';
import { customValueResolver } from './data-resolvers/custom-value-resolver';
import { unsupportedValueResolver } from './data-resolvers/unsupported-value-resolver';
import { widthPercentageResolver } from './data-resolvers/width-percentage-resolver';
import { getExtensionManifest } from './data-resolvers/getExtensionManifest';
// transformers
import { cqlSerializer, cqlDeserializer } from './cql/transformers';

// We downloaded the extension manifest
// earlier within query-preloaders
// the resulting manifest is cached
const getMacroLegacyManifest = getExtensionManifest;

const mapLegacyManifestsToModernManifest = async (
	legacyMacros: LegacyMacroManifest[],
	pageContext: ConfluencePageContext,
	extensionHandlers: RendererExtensionHandlers,
	getMigrationExtensionManifest: Function,
	openMacroBrowserToEditExistingMacro: Function,
	openMacroBrowserForInitialConfiguration: Function,
	intl: IntlShape,
	featureFlagClient: FeatureFlagClient,
	macroBrowserConfig?: MacroConfig,
	editorActions?: EditorActions,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	onCompleteCallback?: (macro: string | undefined) => void,
	isLivePage?: boolean,
	flags?: FlagsStateContainer,
) => {
	const isAllowed = isAllowedWithFeatureFlags();
	const shouldHidePlaceholder = featureFlagClient[
		'confluence.frontend.hide.extension.placeholder'
	] as boolean;

	return legacyMacros
		.map((macro: LegacyMacroManifest) =>
			transformLegacyMacrosToExtensionManifest(
				EXTENSION_TYPE.MACRO_CORE,
				macro,
				pageContext,
				extensionHandlers,
				!isAllowed(macro),
				openMacroBrowserToEditExistingMacro,
				openMacroBrowserForInitialConfiguration,
				intl,
				macroBrowserConfig,
				editorActions,
				createAnalyticsEvent,
				shouldHidePlaceholder,
				onCompleteCallback,
				isLivePage,
			),
		)
		.concat(
			getMigrationExtensionManifest(
				pageContext.contentId,
				!!editorActions,
				flags,
				createAnalyticsEvent,
			),
		);
};

const transformLegacyMacrosToExtensionManifest = (
	extensionType: string,
	macro: LegacyMacroManifest,
	pageContext: ConfluencePageContext,
	extensionHandlers: RendererExtensionHandlers,
	readonly: boolean,
	openMacroBrowserToEditExistingMacro: any,
	openMacroBrowserForInitialConfiguration: any,
	intl: IntlShape,
	macroBrowserConfig?: MacroConfig,
	editorActions?: EditorActions,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	hidePlaceHolderInRendererFeatureFlag?: boolean,
	onCompleteCallback?: (macro: string | undefined) => void,
	isLivePage?: boolean,
): ExtensionManifest => {
	const extensionKey = macro.macroName;

	const isEditor = !!editorActions;
	const { enabled: isConfigPanelEnabled, optedOut: excludedMacros } =
		pageContext.features.configPanel || {};

	const adfNodeType = getExtensionBodyType(macro);

	const defaultNode: ExtensionModuleNode = {
		type: adfNodeType,
		render: () => {
			const extensionHandler = extensionHandlers[extensionType];

			return Promise.resolve((extension: PropsWithChildren<ExtensionComponentProps<any>>) => {
				// per backend restrictions, spaceKey and templateName are required in the extension's macroParams object in order for create-from-template macro to work for autogenerated blueprint index pages.
				if (extension.node.extensionKey === 'create-from-template') {
					const macroParams = extension.node.parameters?.['macroParams'];

					if (!macroParams?.spaceKey) {
						macroParams.spaceKey = { value: pageContext.spaceKey };
					}
					if (!macroParams?.templateName) {
						macroParams.templateName = macroParams.contentBlueprintId;
					}
				}

				const showPlaceholder = shouldShowPlaceholder(
					macro,
					excludedMacros,
					extension.node,
					isEditor,
					hidePlaceHolderInRendererFeatureFlag,
				);

				if ((isConfigPanelEnabled || !isEditor) && showPlaceholder) {
					return getMissingConfigPlaceholderComponent(macro, extension.node, isEditor);
				}

				if (isConfigPanelEnabled && isEditor && !canRenderInTheEditor(macro)) {
					return getNonRenderablePlaceholderComponent(macro, getExtensionBodyType(macro));
				}

				if (typeof extensionHandler === 'function') {
					return (extensionHandler as ExtensionHandler<any>)(extension.node, {
						references: extension.references,
						actions: extension.actions,
					});
				}
				return extensionHandler.render(extension.node, {
					references: extension.references,
					actions: extension.actions,
				});
			});
		},
	};

	if (
		!readonly &&
		(getVisibleParameters(macro).length > 0 || shouldUseMacroBrowser(macro, excludedMacros))
	) {
		const editInMacroBrowser = async (data, actions) => {
			let selectedNode: ADNode | undefined;
			/**
			 * In API v1, we used to pass the whole `node` as `parameters`.
			 * We fixed it in API v2 and now only passing `parameters`.
			 * But Bodied Extensions inside Macro Browser still need the body for preview.
			 * @see DEVHELP-6152
			 */
			if (isBodiedExtension(adfNodeType)) {
				// Currently, there's no other way to get selected node without using private API.
				selectedNode = getSelectedBodiedExtensionNode(editorActions);
			}

			const currentNode = await prepareMacro(
				{
					name: macro.macroName,
					params: unwrapMacroParams(data.macroParams),
				},
				pageContext.contentId,
				selectedNode,
			);

			const defaultParameterValue = getDefaultParamValue(data.macroParams || {});
			if (defaultParameterValue) {
				const macroParams = currentNode.attrs?.parameters?.macroParams;
				if (macroParams) {
					macroParams[''] = defaultParameterValue;
				}
			}

			const macroFilter = undefined;
			const extensionAnalyticsInfo = {
				createAnalyticsEvent,
				contentId: pageContext.contentId,
			};

			const updateMacro = (newNode) => {
				selectedNode = getSelectedBodiedExtensionNode(editorActions);
				actions.doc.update(selectedNode?.attrs?.localId, () => ({
					attrs: newNode.attrs,
					marks: newNode.marks,
					content: newNode.content,
				}));
			};

			const newNode = await openMacroBrowserToEditExistingMacro(
				currentNode,
				macroBrowserConfig,
				macroFilter,
				extensionAnalyticsInfo,
				updateMacro,
			);

			// For bodiedExtension we should update the content
			if (isBodiedExtension(adfNodeType) && actions?.doc?.update) {
				actions.doc.update(selectedNode?.attrs?.localId, () => ({
					attrs: newNode.attrs,
					marks: newNode.marks,
					content: newNode.content,
				}));
				return;
			}
			return newNode.attrs.parameters || {};
		};

		let params;

		defaultNode.update = (data, actions) => {
			// The adf parameters for macros look like:
			// {
			//    macroParams: {
			//        q: {value: 1},
			//        search: {value: 'my keyword'}
			//    },
			//    macroMetadata: {}
			// }

			// The only editable bit is what is inside the `macroParams`. This method unwraps the params and pass it to
			// the context panel for editing, and then wraps it back after saving.
			const transformBefore = (parameters) => {
				params = unwrapMacroParams(parameters.macroParams);
				return transformParams(
					'transformBefore',
					macro,
					params,
					pageContext,
					undefined,
					createAnalyticsEvent,
				);
			};

			const transformAfter = async (initialParameters) => {
				const parameters = transformParams(
					'transformAfter',
					macro,
					initialParameters,
					pageContext,
					undefined,
					createAnalyticsEvent,
				);

				// macros might have metadata changed based on macroParams so we need to fetch again. The rule changes on
				// each of them so we can't do it client side.
				const macroDefinition: any = {
					name: macro.macroName,
					params: parameters,
				};
				const defaultParameterValue = getDefaultParamValue(parameters);
				if (defaultParameterValue) {
					macroDefinition.defaultParameterValue = defaultParameterValue;
				}
				const node = await prepareMacro(macroDefinition, pageContext.contentId);

				// add a new macroId if we don't already have one
				if (node.attrs.parameters) {
					if (!node.attrs.parameters.macroMetadata) {
						node.attrs.parameters.macroMetadata = {};
					}

					if (!node.attrs.parameters.macroMetadata['macroId']) {
						node.attrs.parameters.macroMetadata['macroId'] = {
							value: getMacroId(node.attrs.parameters),
						};
					}
				}

				return node.attrs.parameters || {};
			};

			if (!isConfigPanelEnabled || shouldUseMacroBrowser(macro, excludedMacros)) {
				return editInMacroBrowser(data, actions);
			}

			return new Promise(() => {
				actions!.editInContextPanel(transformBefore, transformAfter);
			});
		};

		if (getVisibleParameters(macro).length > 0) {
			defaultNode.getFieldsDefinition = () => {
				const fields = macro.formDetails.parameters.map((params) =>
					transformFormDetailsIntoFields(params, {
						pluginKey: macro.pluginKey,
						macroName: safeGetMacroName(macro),
					}),
				);

				return remapCustomFields(
					macro,
					fields,
					pageContext,
					intl,
					params,
					undefined,
					createAnalyticsEvent,
				);
			};
		}
	}

	return {
		type: extensionType,
		key: extensionKey,
		title: macro.title,
		description: macro.description,
		icons: buildIconObject(macro),
		documentationUrl: macro.formDetails.documentationUrl || undefined,
		modules: {
			quickInsert:
				!readonly && editorActions
					? createQuickInsertModule(
							macro,
							pageContext,
							openMacroBrowserForInitialConfiguration,
							intl,
							macroBrowserConfig,
							editorActions,
							createAnalyticsEvent,
							onCompleteCallback,
							isLivePage,
						)
					: [],
			nodes: {
				default: defaultNode,
			},
			fields: {
				custom: {
					attachment: {
						resolver: getAttachmentResolverFor(macro, pageContext),
					},
					spacekey: {
						resolver: spacekeyResolver(macro),
					},
					username: {
						resolver: usernameResolver,
					},
					label: {
						resolver: labelResolver,
					},
					'confluence-content-by-id': {
						resolver: confluenceContentResolver,
					},
					'confluence-content': {
						resolver: unsupportedValueResolver(getSpaceKeyAndPageTitleContentResolver()),
					},
					spaceAndPage: {
						resolver: spaceAndPageResolver,
					},
					spaceKeyAndPageTitleContent: {
						resolver: getSpaceKeyAndPageTitleContentResolver(),
					},
					rootPage: {
						resolver: customValueResolver({
							resolver: getSpaceKeyAndPageTitleContentResolver(),
							values: ['@self', '@home', '@parent', '@none'],
						}),
					},
					calendar: {
						resolver: calendarResolver,
					},
					template: {
						resolver: getTemplateResolver(pageContext),
					},
					blogRecentlyUpdatedSpace: {
						resolver: customValueResolver({
							/* blog posts and recently updated macros don't support currentSpace() */
							resolver: buildSpacekeyResolver(false),
							values: ['@self', '@personal', '@global', '@favorite', '@favourite', '@all', '*'],
						}),
					},
					contributorsSpaceParamResolver: {
						resolver: customValueResolver({
							resolver: buildSpacekeyResolver(false),
							values: ['@personal', '@global', '@all'],
						}),
					},
					recentlyUpdatedDashboardSpaceKeyResolver: {
						resolver: customValueResolver({
							resolver: buildSpacekeyResolver(false),
							values: ['*'],
						}),
					},
					widthPercentage: {
						resolver: widthPercentageResolver(intl),
					},
				},
				fieldset: {
					cql: {
						serializer: cqlSerializer,
						deserializer: cqlDeserializer,
					},
				},
			},
		},
	};
};

export const getConfluenceMacrosExtensionProviderForEditor = async (
	pageContext: ConfluencePageContext,
	extensionHandlers: ExtensionHandlers,
	editorActions: EditorActions,
	macroBrowserConfig: MacroConfig,
	getMigrationExtensionManifest: Function,
	openMacroBrowserToEditExistingMacro: Function,
	openMacroBrowserForInitialConfiguration: Function,
	intl: IntlShape,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	onCompleteCallback?: (macro: string | undefined) => void,
	isLivePage?: boolean,
	flags?: FlagsStateContainer,
	experienceTracker?: ExperienceTrackerAPI,
	contentType?: string,
	allowCreateDatabaseExtension: boolean = false,
	allowDatabaseQuickInsert: boolean = false,
	allowCreateWhiteboardExtension: boolean = false,
): Promise<ExtensionProvider> => {
	const [legacyManifests, macroAutoConverters] = await Promise.all([
		getMacroLegacyManifest(pageContext),
		getAutoConverter(macroBrowserConfig),
	]);

	const { featureFlagClient } = await getSessionData();

	let modernManifests = await mapLegacyManifestsToModernManifest(
		legacyManifests.macros,
		pageContext,
		extensionHandlers,
		getMigrationExtensionManifest,
		openMacroBrowserToEditExistingMacro,
		openMacroBrowserForInitialConfiguration,
		intl,
		featureFlagClient,
		macroBrowserConfig,
		editorActions,
		createAnalyticsEvent,
		onCompleteCallback,
		isLivePage,
		flags,
	);

	modernManifests = modernManifests.filter((manifest) => manifest.key !== 'toc');

	const macro = legacyManifests.macros.find((m) => m.macroName === 'toc');
	const { tableOfContentsExtension } = await import(
		/* webpackChunkName: "loadable-TableOfContents" */ './custom-extensions/TableOfContents'
	);

	const createTableOfContentsExtension = tableOfContentsExtension({
		macro,
		pageContext,
		extensionHandlers,
		openMacroBrowserForInitialConfiguration,
		macroBrowserConfig,
		editorActions,
		createAnalyticsEvent,
		intl,
		onCompleteCallback,
		isLivePage,
	});

	modernManifests.push(createTableOfContentsExtension);

	const redactionExtensionEnabled = FeatureGates.checkGate('confluence_dlp_content_redaction');

	const { redactionExtension } = await import(
		/* webpackChunkName: "loadable-Redaction" */ './custom-extensions/Redaction'
	);

	if (redactionExtensionEnabled) {
		modernManifests.push(redactionExtension());
	}

	const createDatabaseExtensionEnabled = featureFlagClient.getBooleanValue(
		'confluence.frontend.databases.create-from-editor',
		{ default: false },
	);

	const referenceDatabaseEnabled = featureFlagClient.getBooleanValue(
		'confluence.frontend.databases.database-referencing',
		{ default: false },
	);

	const databasesOptOutEnabled = featureFlagClient.getBooleanValue(
		'confluence.frontend.databases.opt-out',
		{ default: false },
	);

	if (createDatabaseExtensionEnabled && allowCreateDatabaseExtension) {
		modernManifests.push(
			createDatabaseExtension({
				pageId: pageContext.contentId,
				spaceKey: pageContext.spaceKey!,
				spaceId: pageContext.spaceId!,
				intl,
				flags,
				createAnalyticsEvent,
				experienceTracker,
				referenceDatabaseEnabled,
				contentType,
				allowDatabaseQuickInsert: allowDatabaseQuickInsert && !databasesOptOutEnabled,
				isEdit: true,
			}),
		);
	}

	const createWhiteboardsExtensionEnabled = featureFlagClient.getBooleanValue(
		'confluence.frontend.whiteboard.create-from-editor',
		{ default: false },
	);

	if (createWhiteboardsExtensionEnabled && allowCreateWhiteboardExtension && pageContext.spaceKey) {
		modernManifests.push(
			createWhiteboardExtension({
				pageId: pageContext.contentId,
				spaceKey: pageContext.spaceKey,
				intl,
				flags,
				experienceTracker,
				createAnalyticsEvent,
				contentType,
				allowWhiteboardQuickInsert: allowCreateWhiteboardExtension,
			}),
		);
	}

	return new DefaultExtensionProvider(modernManifests, [
		(link: string) => {
			const result = macroAutoConverters(link);

			if (result) {
				return result;
			}
		},
	]);
};

export const getConfluenceMacrosExtensionProviderForRenderer = async (
	pageContext: ConfluencePageContext,
	extensionHandlers: RendererExtensionHandlers,
	getMigrationExtensionManifest: Function,
	openMacroBrowserToEditExistingMacro: Function,
	openMacroBrowserForInitialConfiguration: Function,
	intl: IntlShape,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	providerFactory?: ProviderFactory,
): Promise<ExtensionProvider> => {
	const chartsExtensionPromise = createChartsExtension({
		// TODO: use intl5 instead
		intl: intl as any,
		extensionHandlers: extensionHandlers[
			EXTENSION_TYPE.MACRO_WITH_REFERENCES
		] as ExtensionHandlerWithReferenceFn<any>,
		createAnalyticsEvent,
	});

	const { featureFlagClient } = await getSessionData();

	const legacyManifests = await getMacroLegacyManifest(pageContext);
	const modernManifests = await mapLegacyManifestsToModernManifest(
		legacyManifests.macros,
		pageContext,
		extensionHandlers,
		getMigrationExtensionManifest,
		openMacroBrowserToEditExistingMacro,
		openMacroBrowserForInitialConfiguration,
		intl,
		featureFlagClient,
	);

	modernManifests.push(getRedactedMacroPlaceholderExtensionManifest(extensionHandlers));

	if (chartsExtensionPromise) {
		modernManifests.push(await chartsExtensionPromise);
	}

	// We don't want to check for the aiOptInStatus as we want to show the rendered block
	// regardless of AI status so we don't lose content if people downgrade etc
	const aiBlockProvider = await getAIPanelsProvider({
		api: undefined,
		intl: intl as any,
		providerFactory,
	});
	modernManifests.push(...(await aiBlockProvider.getExtensions()));

	const isCustomSitesExtensionsEnabled = featureFlagClient.getBooleanValue(
		'confluence.frontend.custom-sites.cards-extension',
		{ default: false },
	);

	if (isCustomSitesExtensionsEnabled) {
		const customSitesCarouselProvider = await carouselExtensionProvider({
			contentId: pageContext.contentId,
			intl,
			createAnalyticsEvent,
		});
		modernManifests.push(...(await customSitesCarouselProvider.getExtensions()));

		const customSitesLinkCardsProvider = await linkCardsExtensionProvider({
			contentId: pageContext.contentId,
			intl,
			createAnalyticsEvent,
		});
		modernManifests.push(...(await customSitesLinkCardsProvider.getExtensions()));
	}

	const createDatabaseExtensionEnabled = featureFlagClient.getBooleanValue(
		'confluence.frontend.databases.create-from-editor',
		{ default: false },
	);

	const referenceDatabaseEnabled = featureFlagClient.getBooleanValue(
		'confluence.frontend.databases.database-referencing',
		{ default: false },
	);

	if (createDatabaseExtensionEnabled) {
		modernManifests.push(
			createDatabaseExtension({
				pageId: pageContext.contentId,
				spaceKey: pageContext.spaceKey!,
				spaceId: pageContext.spaceId!,
				intl,
				createAnalyticsEvent,
				referenceDatabaseEnabled,
				isEdit: false,
			}),
		);
	}

	const redactionExtensionEnabled = FeatureGates.checkGate('confluence_dlp_content_redaction');

	const { redactionExtension } = await import(
		/* webpackChunkName: "loadable-Redaction" */ './custom-extensions/Redaction'
	);

	if (redactionExtensionEnabled) {
		modernManifests.push(redactionExtension());
	}

	return new DefaultExtensionProvider(modernManifests);
};
