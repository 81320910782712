import type { CategorizedCookiesCache } from '../../../types';
import { Logger } from '../logger';

export const COOKIE_CACHE_URL_PROD =
	'https://atlassian-cookies--categories.us-east-1.prod.public.atl-paas.net/categories.json';

/**
 * Cache variable for storing categorized cookies cache
 */
let localResponseCache: CategorizedCookiesCache | undefined;

/**
 * Cache variable for storing a promise that's been fired by a different invocation of loadStorageControls
 *
 * Allows for many invocations to happen simultaneously, and for only one fetch to go out
 */
let localResponsePromiseCache: Promise<Response> | undefined;

/**
 * Cache variable for storing the promise returned from the response object's json method
 *
 * Ensures we don't invoke response.json multiple times
 */
let localResponseJsonPromiseCache: Promise<CategorizedCookiesCache | undefined> | undefined;

export const clearStorageControlsDataCache = () => {
	localResponseCache = undefined;
	localResponsePromiseCache = undefined;
	localResponseJsonPromiseCache = undefined;
};

/**
 * Unpacks the response promise from the categories CDN
 *
 * errors if that request was unsuccessful, returns the categories data otherwise
 *
 */
const handleCategoriesResponse = async (responsePromise: Promise<Response> | undefined) => {
	if (!responsePromise) {
		throw new Error(`Failed to fetch Cookies Cache! Cached promise was undefined`);
	}

	const response = await responsePromise;
	if (!response.ok) {
		throw new Error(`Failed to fetch Cookies Cache! status: ${response.status}`);
	}
	// Checks if we've already fired the response.json() promise
	localResponseJsonPromiseCache = localResponseJsonPromiseCache || response?.json();

	return localResponseJsonPromiseCache;
};

export const loadStorageControlsData = async (): Promise<CategorizedCookiesCache | undefined> => {
	if (localResponseCache) {
		return localResponseCache;
	} else {
		// Always use Prod CDN for prod/local/dev/staging environments, but stub the route for automation tests
		try {
			if (!localResponsePromiseCache) {
				// invoke CDN and cache the promise
				localResponsePromiseCache = fetch(COOKIE_CACHE_URL_PROD);
			}

			// unpack response
			localResponseCache = await handleCategoriesResponse(localResponsePromiseCache);

			return localResponseCache;
		} catch (e: any) {
			Logger.errorWithOperationalEvent({
				action: 'loadStorageControlsError',
				message: e.message || '',
			});
			clearStorageControlsDataCache();
		}
	}
};
