import { fetchDraft } from './fetchDraft';

declare global {
	interface Window {
		__PRELOAD_DRAFT__?: any;
	}
}

export async function preloadDraft(contentId: string, cloudId: string | null) {
	const documentAri = `ari:cloud:confluence:${cloudId}:page/${contentId}`;

	window.__PRELOAD_DRAFT__ = {
		contentId,
		draftFetchPromise: fetchDraft(documentAri),
		// eslint-disable-next-line no-restricted-properties
		startTime: window?.performance?.now?.(),
	};
}
