// eslint-disable-next-line no-restricted-imports
import window from 'window-or-global';

import type { MacroAttributes } from '@atlaskit/editor-core';

import type { LegacyMacro } from '../../extensions-common/types';
import { fetchMacroAttributes } from '../fetchMacroAttributes';
import { wrapMacroParams } from '../transformers';

/**
 * @see https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-jira-plugin/browse/confluence-jira-plugin/src/main/resources/editor-plugins/jira-paste-link.js#100
 */
type JiraMacroParams = {
	[otherParamName: string]: string;
	jqlQuery: string;
	server: string;
	serverId: string;
};

function convertLink(
	link: string,
	defaultJiraMacroAttributes: MacroAttributes,
): null | MacroAttributes {
	const noop = () => {};
	const legacyHandler = window.require('confluence/jim/editor-plugins/jira-paste-link');

	// jira paste handler needs object in this format
	const uri = {
		source: link,
	};
	const jimPasteHandlerResult: LegacyMacro & {
		params: JiraMacroParams;
	} = legacyHandler.pasteHandler(uri, null, noop);

	if (!jimPasteHandlerResult || !jimPasteHandlerResult.params) {
		return null;
	}

	/**
	 * use JSON.parse , JSON.stringify to deeply clone object, avoid any reference
	 */
	const macroAttributes: MacroAttributes = JSON.parse(JSON.stringify(defaultJiraMacroAttributes));

	if (!macroAttributes.attrs.parameters) {
		macroAttributes.attrs.parameters = {};
	}

	macroAttributes.attrs.parameters.macroParams = wrapMacroParams(jimPasteHandlerResult.params);

	return macroAttributes;
}

export async function createJiraConverter(
	contentId?: string,
): Promise<(link: string) => MacroAttributes | null> {
	const placeholderMacro = {
		macroDefinition: { name: 'jira' },
		contentId,
	};

	// fetch ADF structure of JIM, and modify it to provide to editor later
	const jiraADFNode = await fetchMacroAttributes(placeholderMacro);

	return (link: string) => convertLink(link, jiraADFNode);
}
