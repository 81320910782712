import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { default as PlatformPopup } from '@atlaskit/popup';
import type { TriggerProps } from '@atlaskit/popup/types';
import { Box, xcss } from '@atlaskit/primitives';

/**
 * Design decisions made:
 * `zIndex` and `offset` are excluded to constrain the appearance of the popup.
 *
 * `boundary` has been changed as the ADS type can also expect an HTMLElement to be passed in, which cannot work with Forge.
 *
 * `popupComponent` is excluded as the same customisation can be done with `Box` in content
 *
 */
// Public API
export type PopupProps = Omit<
	React.ComponentProps<typeof PlatformPopup>,
	'popupComponent' | 'zIndex' | 'offset' | 'boundary'
> & {
	boundary?: 'clippingParents';
	content: () => React.ReactNode;
	trigger: () => React.ReactNode;
};

/**
 * Internal API that aligns with the UI Kit wrapper in `@forge/react`
 * This is required since we can't pass functions outside of the runtime
 * The workaround is to invoke within the runtime and pass out the result back to the product
 * 
 * `content` and `trigger` props have been excluded as we are invoking the functions passed
into these props in @forge/react, and then passing the components as children of ContentWrapper.
 * 
 */
export type InternalPopupProps = Omit<PopupProps, 'content' | 'trigger'> & {
	children: React.ReactNode;
};

// This is required to keep the div width wrapped around the contents of trigger instead of the full width of the parent
const triggerContainerStyles = xcss({ display: 'inline-block', width: '100%' });

export const Popup = (props: Parameters<RenderFn>[0]) => {
	const {
		isOpen,
		id: providedId,
		testId,
		onClose,
		boundary,
		rootBoundary,
		shouldFlip,
		placement,
		fallbackPlacements,
		autoFocus,
		shouldRenderToParent,
		shouldUseCaptureOnOutsideClick,
		shouldFitContainer,
		shouldDisableFocusLock,
		strategy,
		role,
		label,
		titleId,
	} = props.forgeDoc.props as InternalPopupProps;

	const contentForgeDoc = props.forgeDoc.children.find((child) => {
		if (child.type === 'ContentWrapper' && child.props?.name === 'content') {
			return child;
		}
	});
	const triggerForgeDoc = props.forgeDoc.children.find((child) => {
		if (child.type === 'ContentWrapper' && child.props?.name === 'trigger') {
			return child;
		}
	});

	// Trigger props need to be passed internally here because it contains a ref object which can't be passed in the runtime
	// The double span is a workaround to ensure the span is wrapped around the popup trigger component without it extending the width of the container when used within `Inline`. Otherwise, the popup trigger component will extend the width of the container.
	const TriggerComponent = ({ triggerProps }: { triggerProps: TriggerProps }) => {
		return (
			<Box as="span">
				<Box as="span" xcss={triggerContainerStyles} {...triggerProps}>
					{triggerForgeDoc?.children.map(props.render)}
				</Box>
			</Box>
		);
	};

	/**
	 * This check is necessary because of the conditional typing for PopupProps in ADS.
	 * It has a union type `type PopupProps = StandardPopupProps | ShouldFitContainerPopupProps` where combined,
	 * the `shouldFitContainer` prop is actually typed `(false | undefined) OR true`.
	 * However, this resolves as `boolean | undefined`, which does not match the ADS typing. Therefore we need to declare two versions -
	 * where `shouldFitContainer` is explicitly true (to match `ShouldFitContainerPopupProps`), and false (to match StandardPopupProps).
	 */
	if (shouldFitContainer) {
		return (
			<PlatformPopup
				content={() => contentForgeDoc?.children.map(props.render)}
				isOpen={isOpen}
				placement={placement}
				fallbackPlacements={fallbackPlacements}
				boundary={boundary}
				rootBoundary={rootBoundary}
				shouldFlip={shouldFlip}
				id={providedId}
				testId={testId}
				onClose={onClose}
				autoFocus={autoFocus}
				shouldUseCaptureOnOutsideClick={shouldUseCaptureOnOutsideClick}
				shouldFitContainer={shouldFitContainer}
				shouldRenderToParent={shouldRenderToParent ? shouldRenderToParent : undefined}
				strategy={strategy ? 'absolute' : undefined}
				shouldDisableFocusLock={shouldDisableFocusLock}
				trigger={(triggerProps) => <TriggerComponent triggerProps={triggerProps} />}
				role={role}
				label={label}
				titleId={titleId}
			/>
		);
	}

	return (
		<PlatformPopup
			content={() => contentForgeDoc?.children.map(props.render)}
			isOpen={isOpen}
			placement={placement}
			fallbackPlacements={fallbackPlacements}
			boundary={boundary}
			rootBoundary={rootBoundary}
			shouldFlip={shouldFlip}
			id={providedId}
			testId={testId}
			onClose={onClose}
			autoFocus={autoFocus}
			shouldUseCaptureOnOutsideClick={shouldUseCaptureOnOutsideClick}
			shouldRenderToParent={shouldRenderToParent}
			shouldFitContainer={shouldFitContainer}
			shouldDisableFocusLock={shouldDisableFocusLock}
			trigger={(triggerProps) => <TriggerComponent triggerProps={triggerProps} />}
			strategy={strategy}
			role={role}
			label={label}
			titleId={titleId}
		/>
	);
};
