import type { MacroAttributes } from '@atlaskit/editor-core';

import type { MacroConfig, MacroMetadata } from '../../extensions-common/types';

import { createJiraConverter } from './jira-converter';
import { createConnectAddonConverter } from './connect-addon-converter';

const noop = () => null;

export const autoConvert = async (
	loadMacroMetadata: () => Promise<MacroMetadata[]>,
	browserConfig: MacroConfig,
): Promise<(link: string) => MacroAttributes | null> => {
	const { contentId } = browserConfig;

	if (!contentId) {
		return noop;
	}

	const [jiraAutoConvert, connectAddonAutoConvert] = await Promise.all([
		createJiraConverter(contentId),
		createConnectAddonConverter(loadMacroMetadata, contentId),
	]);

	return (link: string) => jiraAutoConvert(link) || connectAddonAutoConvert(link);
};
