import gql from 'graphql-tag';

export const LockScreenQuery = gql`
	query LockScreenQuery {
		siteConfiguration {
			ccpEntitlementId
		}
		license {
			trialEndDate
			billingSourceSystem
		}
	}
`;
