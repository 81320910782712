import { AnnotationUpdateEmitter } from '@atlaskit/editor-core';

type AnnotationEventEmitterFn = () => any;

let rendererEmitter: AnnotationUpdateEmitter | undefined = undefined;
export const getRendererAnnotationEventEmitter: AnnotationEventEmitterFn = () => {
	if (!rendererEmitter) {
		rendererEmitter = new AnnotationUpdateEmitter();
	}

	return rendererEmitter;
};
