/* eslint-disable check-file/filename-blocklist */
import type { IntlShape } from 'react-intl-next';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import type { ADNode } from '@atlaskit/editor-common/validator';
import type { EditorActions } from '@atlaskit/editor-core';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import type { ExtensionModule } from '@atlaskit/editor-common/extensions';

import { cfetch } from '@confluence/network';

import type { MacroConfig, ExtensionNodeType } from '../../extensions-common';
import { isBodiedExtension } from '../../extensions-common';
import { allowedMacrosForInsertMenu } from '../../extensionConstants';

import type { LegacyMacroManifest, ConfluencePageContext } from './extensionTypes';
import { safeGetMacroName } from './manifest-helpers';
import { getQuickInsertAction } from './quick-insert-action';
import { getIconComponent } from './MacroComponents';

const pageIdRegex = /^[0-9]+$/;
export const isPageId = (id: string) => pageIdRegex.test(id);

// Temporary solution until we have `extensionAPI.doc.getSelectedNode` @see ED-13045
export const getSelectedBodiedExtensionNode = (editorActions?: EditorActions) => {
	const editorView = editorActions?._privateGetEditorView();
	if (editorView) {
		let maybeNode = (editorView.state.selection as any).node;
		if (!maybeNode || !isBodiedExtension(maybeNode.type.name)) {
			const { $head } = editorView.state.selection;
			// If current selection isn't bodied extension traverse upward
			for (let i = $head.depth; i > 0; i--) {
				const node = $head.node(i);
				if (isBodiedExtension(node.type.name as ExtensionNodeType)) {
					maybeNode = node;
				}
			}
		}
		return maybeNode?.toJSON() as ADNode;
	}
};

export const isValueSet = (value: any) =>
	(value === 0 || value === false || !!value) && (!Array.isArray(value) || value.length > 0);

export const createQuickInsertModule = (
	macro: LegacyMacroManifest,
	pageContext: ConfluencePageContext,
	openMacroBrowserForInitialConfiguration: any,
	intl: IntlShape,
	macroBrowserConfig?: MacroConfig,
	editorActions?: EditorActions,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	onCompleteCallback?: (macro: string | undefined) => void,
	isLivePage?: boolean,
): ExtensionModule[] => {
	// Hidden macros don't appear in the slash menu
	// but still need to be defined in a manifest so old pages can show them
	if (macro.hidden) {
		return [];
	}

	const macroName = safeGetMacroName(macro);

	try {
		const action = getQuickInsertAction(
			macro,
			pageContext,
			openMacroBrowserForInitialConfiguration,
			intl,
			macroBrowserConfig,
			editorActions,
			createAnalyticsEvent,
			onCompleteCallback,
			isLivePage,
		);

		return [
			{
				key: macroName,
				title: macro.title,
				description: macro.description,
				keywords: [macro.macroName, ...macro.aliases],
				categories: macro.categories,
				icon: async () => () => getIconComponent(macro),
				featured:
					pageContext.features.elementBrowser && allowedMacrosForInsertMenu.includes(macroName),
				action,
			},
		];
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e);
		return [];
	}
};

// Extract App Key from moduleKey for connect app
// com.atlassian.plugins.atlassian-connect-plugin:xhtml__cc-eco-test-connect-multi-bodied-extension__cc-eco-test-multi-bodied-extension
// ====>
// cc-eco-test-connect-multi-bodied-extension
export const getConnectAppKey = (macroKey?: string): string => {
	if (!macroKey || !macroKey.startsWith('com.atlassian.plugins.atlassian-connect-plugin')) {
		return '';
	}

	const chunks = macroKey.split('__');
	if (chunks.length !== 3) {
		return '';
	}

	return chunks[1];
};

export const insertMacroPlaceholder = (node, placeholderText: string) => {
	node.content = [
		{
			type: 'paragraph',
			content: [
				{
					type: 'placeholder',
					attrs: {
						text: placeholderText,
					},
				},
			],
		},
	];
};

const getMatchingAppLinkForViewPage = async (selectedServerId: string) => {
	try {
		const res = await cfetch('/wiki/rest/jiraanywhere/1.0/servers');
		const appLinks: { url: string; rpcUrl: string; id: string }[] = await res.json();

		return appLinks.find((appLink) => appLink.id === selectedServerId);
	} catch {}
};

const isCloudAppLinkFromUrl = (url?: string) => {
	if (!url) {
		return;
	}

	return url.includes('atlassian.net') || url.includes('jira-dev.com');
};

export const getJiraHostingOption = async (
	macroName: string,
	selectedServerId: string = '',
	isViewPage: boolean = false,
) => {
	if (macroName !== 'jira') {
		return;
	}

	let isCloudAppLink: boolean | undefined;

	if (isViewPage) {
		const selectedServerObject = await getMatchingAppLinkForViewPage(selectedServerId);

		isCloudAppLink =
			isCloudAppLinkFromUrl(selectedServerObject?.url) ||
			isCloudAppLinkFromUrl(selectedServerObject?.rpcUrl);
	} else {
		try {
			// module is only available on edit page
			const ApplinksBridge = window.require('confluence/jim/jira/applinks-bridge-for-spa');

			isCloudAppLink = await ApplinksBridge.isCloudAppLinkFromServerId(selectedServerId);
		} catch {}
	}

	if (isCloudAppLink === undefined) {
		return 'unknown';
	}

	return isCloudAppLink ? 'cloud' : 'server';
};

// Adding this here and not exporting from @confluence/profile-macros due to circular dependency issue
export const isProfileMacrosExperimentEnabled = () => {
	return FeatureGates.getExperimentValue<boolean>(
		'confluence_profile_macros_updates',
		'isEnabled',
		false,
	);
};
