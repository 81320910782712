import { preloadUserPreferencesForSSR } from '@confluence/storage-manager/entry-points/preloadUserPreferencesForSSR';
import { preloadPersonalSpaceDetail } from '@confluence/space-utils/entry-points';
import {
	preloadIntentBasedOnboarding,
	INTENT_BASED_ONBOARDING_QUERY,
} from '@confluence/home/entry-points/preloadIntentBasedOnboarding';
import { preloadHome } from '@confluence/home/entry-points/preloadHome';
import {
	preloadCreateFromTemplate,
	CREATE_FROM_TEMPLATE_QUERY,
} from '@confluence/home/entry-points/preloadCreateFromTemplate';
import { bestEffortTask, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';

export const preloadHomeRoute = async (match: RouteMatch) => {
	const { featureFlags, userId, cloudId, isLicensed, accessStatus } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];

	const canAccessHome =
		accessStatus !== 'ANONYMOUS_ACCESS' && accessStatus !== 'UNLICENSED_AUTHENTICATED_ACCESS';

	if (isLicensed && canAccessHome) {
		tasks.push(
			preloadHome({
				cloudId,
				isLicensed,
				userId,
				matchParams: match.params,
				bestEffortTask,
				isSpaceAliasFFEnabled: Boolean(featureFlags['confluence.frontend.space.alias']),
				isMyVisitedSpacesEnabled: Boolean(
					featureFlags['confluence.frontend.recently-viewed-plugin.spaces'],
				),
				isWhiteboardViewedEnabled: Boolean(featureFlags['confluence.frontend.whiteboard.enable']),
				isDatabaseEnabled: Boolean(featureFlags['confluence.frontend.databases.enabled']),
				isSmartLinkEmbedEnabled: Boolean(featureFlags['confluence.frontend.embeds.enabled']),
			}),
		);
	}

	const shouldPreloadSpaceDetails = featureFlags['confluence.frontend.ecosystem.access.narrrowing'];
	if (shouldPreloadSpaceDetails) {
		tasks.push(preloadPersonalSpaceDetail());
	}

	if (process.env.REACT_SSR) {
		tasks.push(preloadUserPreferencesForSSR({ isLicensed }));
	}

	tasks.push(
		bestEffortTask(INTENT_BASED_ONBOARDING_QUERY, () => preloadIntentBasedOnboarding({ userId })),
		bestEffortTask(CREATE_FROM_TEMPLATE_QUERY, () => preloadCreateFromTemplate({ userId })),
	);

	return Promise.all(tasks);
};
