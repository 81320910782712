import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Query, ApolloProvider } from 'react-apollo';
import isEqual from 'lodash/isEqual';
import { FormattedMessage } from 'react-intl-next';
import { ApolloError } from 'apollo-client';

import type { ADNode } from '@atlaskit/editor-common/validator';
import type { ExtensionParams } from '@atlaskit/editor-common/extensions';

import { getApolloClient } from '@confluence/graphql';
import { getLogger } from '@confluence/logger';
import { useSessionData } from '@confluence/session-data';
import { LegacyMacroRendererShadow } from '@confluence/content-renderer-legacy-macros';
import {
	getMacroAttributesFromADFNode,
	getExperienceName,
	stopJIRAMacro,
} from '@confluence/macro-tracker';
import type { Extension } from '@confluence/macro-tracker';
import {
	ExperienceTrackerContext,
	getExperienceTracker, // eslint-disable-line no-restricted-imports
} from '@confluence/experience-tracker';
import {
	extensionToADF,
	getParametersString,
} from '@confluence/fabric-extension-lib/entry-points/editor-extensions';
import {
	BODIED_EXTENSION_TYPE,
	REGULAR_EXTENSION_TYPE,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import type { WebResources } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { EDITOR_MACRO_ADF_RENDERING } from '@confluence/editor-features/entry-points/featureFlags';
import {
	serializeExtensionADF,
	MacroContentRendererQueryWithTags,
	MacroBodyRendererQueryWithTags,
	getContentConverterVariables,
	ContentConverterQuery,
	MultiMacroQuerySubscriber,
	canUseMultiMacroQuery,
} from '@confluence/fabric-extension-queries';
import type {
	MacroContentRendererQueryWithTagsTypes,
	MacroBodyRendererQueryWithTagsTypes,
} from '@confluence/fabric-extension-queries';

import { LegacyMacroRendererComponent } from './LegacyMacroRendererComponent';
import { LegacyMacroRendererError } from './LegacyMacroRendererError';
import type { QueryNode, QueryProps } from './macroRendererTypes';
import { i18n } from './i18n';
import { LegacyMacroRendererContext } from './LegacyMacroRendererContext';
import type { ExtensionWithParentContentId } from './PageIncludeContext';
import { PageIncludeContext } from './PageIncludeContext';

/**
 * Checks whether an extension node is a plain text macro or not.
 *
 * The node at this point may not indicate what it's content type is, so a
 * reliable way is to check for the `__bodyContent` property, which exists
 * on plain text macros.
 *
 * @param node Extension node to check
 */
const isPlainTextMacro = (node: Extension): boolean =>
	node.type === REGULAR_EXTENSION_TYPE && Boolean(node?.parameters?.macroParams?.__bodyContent);

/**
 * All bodied extensions are rich text.
 *
 * @param node Extension node to check
 */
const isRichTextMacro = (node: Extension): boolean => node.type === BODIED_EXTENSION_TYPE;

type ADFContent = (ADNode | QueryNode) | (ADNode | QueryNode)[];

const convertADFContentToStorageQuery = async (
	contentId: string,
	content: ADFContent,
): Promise<string> => {
	const result = await getApolloClient().query({
		query: ContentConverterQuery,
		variables: getContentConverterVariables({
			content,
			contentId,
		}),
	});

	return result.data?.contentConverter?.value;
};

type State = {
	body: string;
	hasBodyBeenFetched: boolean;
};

export class LegacyMacroRendererQueryComponent extends Component<QueryProps, State> {
	static childContextTypes = {
		/**
		 * Handler for css injection in components.
		 */
		insertCss: PropTypes.func.isRequired,
	};

	state = {
		body: '',
		hasBodyBeenFetched: false,
	};

	getChildContext() {
		const { context } = this.props;
		const noop = () => {};
		return {
			insertCss: context.insertCss || noop,
		};
	}

	componentDidMount() {
		this.setMacroData();
	}

	shouldComponentUpdate(nextProps: QueryProps, nextState: State) {
		return (
			this.props.contentId !== nextProps.contentId ||
			!isEqual(this.props?.node?.parameters, nextProps?.node?.parameters) ||
			((nextProps.references || this.props.references) &&
				this.props.references !== nextProps.references) ||
			this.state.hasBodyBeenFetched !== nextState.hasBodyBeenFetched ||
			this.state.body !== nextState.body
		);
	}

	componentDidUpdate() {
		this.setMacroData();
	}

	componentWillUnmount(): void {
		const { node, mode, contentId } = this.props;

		const attributes = getMacroAttributesFromADFNode(node);

		stopJIRAMacro(mode, contentId, name, attributes);
	}

	logger = getLogger('LegacyMacroRendererQuery');

	/**
	 * Parses the rich text macro's body and sets the state with it.
	 */
	async setRichTextMacroBody() {
		const { contentId, node, featureFlags, extensionKey } = this.props;

		// Get the ADF content of the node
		const content = (node?.content || []) as ADFContent;

		try {
			// Unfortunately, vendor add ons expect storage format. We need to convert
			// the ADF to the appropriate format.

			let body;
			// only use conversion query if not using macros query where
			// body storage format is already provided
			if (!canUseMultiMacroQuery(extensionKey, featureFlags)) {
				body = await convertADFContentToStorageQuery(contentId, content);
			}

			this.setState({
				body,
				hasBodyBeenFetched: true,
			});
		} catch (error) {
			this.logger.error`${error}`; //tslint:disable-line
		}
	}

	/**
	 * Parses the plain text macro's body and sets the state with it.
	 *
	 * Even though it's plain text, we don't actually need to set state. This
	 * could be done in the renderer, but because we're converting rich text
	 * macro's body to storage format, the mechanism for passing in the body is
	 * `this.setState`. This will change in the future if we start passing
	 * adf in our `getMacroBody` calls (and then this can be cleaned up).
	 */
	setPlainTextMacroBody() {
		const { node } = this.props;

		const body = node?.parameters?.macroParams?.__bodyContent?.value || '';

		this.setState({
			body,
			hasBodyBeenFetched: true,
		});
	}

	setMacroData() {
		const { node } = this.props;
		// Node is null, which means we won't render anything.
		if (!node || (isRichTextMacro(node) && this.state.hasBodyBeenFetched)) {
			return;
		}

		if (isPlainTextMacro(node)) {
			this.setPlainTextMacroBody();
		} else if (isRichTextMacro(node)) {
			void this.setRichTextMacroBody();
		} else {
			// It's neither, so just render the component
			this.setState({
				hasBodyBeenFetched: true,
			});
		}
	}

	// Use parent id if the macro source is different than the current page.
	// Critical for having the include-page macro load properly
	getContentId(adf, contentId) {
		const parentId = adf?.attrs?.parameters?.macroParams?._parentId?.value;
		return parentId || contentId;
	}

	/**
	 * Return a query for both contentRenderer and macroBodyRenderer.
	 */
	getQueryForMacroContentAndBodyForPossibleFrontendRendering = ({
		adf,
		contentId,
		extensionKey,
		mode,
		node,
		references,
		multiBodiedExtensionActions,
		featureFlags,
		macroRenderedOutputFromSSR,
		macroOutput,
		spaceKey,
		context,
		shouldRefetchMacroQuery,
		noOverlay,
		isPreviewMode,
	}) => {
		const renderPageIncludeError = (pageTitle) => {
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className="error">
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className="error">
						<FormattedMessage {...i18n.highlightedPageIncludeRenderedError} />
					</span>
					<FormattedMessage
						{...i18n.nonhighlightedPageIncludeRenderedError}
						values={{ pageTitle }}
					/>
				</div>
			);
		};

		return (
			<Query<MacroBodyRendererQueryWithTagsTypes>
				query={MacroBodyRendererQueryWithTags}
				variables={{
					adf: serializeExtensionADF(adf),
					// The template editor passes a "0" as contentId by default,
					// in this scenario we do NOT want to pass a contentId at all into the query.
					// Not passing the correct contentId can make some macros fail to load
					...(contentId !== '0' ? { contentId: this.getContentId(adf, contentId) } : {}),
					mode: mode && mode.toUpperCase(),
				}}
				// Used when multiple instances of PageContentRenderer cause conflicting IDs in macroRenderedOutput. This forces
				// a refetch when rendering subsequent PageContentRenderer instances so that a new ID is present in
				// macroRenderedOutput. 'cache-first' is Apollo's default fetchPolicy.
				fetchPolicy={shouldRefetchMacroQuery ? 'no-cache' : 'cache-first'}
				// Some macros take a long time to render, they should not hold up the rendering of other things
				context={{
					throttle: true,
					allowOnExternalPage: true,
				}}
				skip={Boolean(macroRenderedOutputFromSSR) || Boolean(macroOutput)}
			>
				{({ data, loading, error }) => {
					// render LegacyMacroRendererComponent without query data if macro has been
					// SSR'd and macro output already exists or when macroOutput
					// exists which contains the entire html string needed to render the macro
					if (macroRenderedOutputFromSSR || macroOutput) {
						return (
							<LegacyMacroRendererComponent
								contentId={contentId}
								experienceName={getExperienceName(mode, node)}
								adf={adf}
								attributes={getMacroAttributesFromADFNode(node)}
								references={references}
								multiBodiedExtensionActions={multiBodiedExtensionActions}
								macroRenderedOutput={data?.macroBodyRenderer?.value}
								macroRepresentation={data?.macroBodyRenderer?.representation || 'view'}
								mediaToken={data?.macroBodyRenderer?.mediaToken?.token}
								node={node}
								webresource={data?.macroBodyRenderer?.webResourceDependencies as WebResources}
								mode={mode}
								body={this.state.body}
								parameters={getParametersString(node)}
								featureFlags={featureFlags}
								macroRenderedOutputFromSSR={macroRenderedOutputFromSSR}
								macroOutput={macroOutput}
								spaceKey={spaceKey}
								context={context}
								noOverlay={noOverlay}
								isPreviewMode={isPreviewMode}
							/>
						);
					}

					if (loading) {
						return <LegacyMacroRendererShadow showSpinner={!!data} node={node} />;
					}

					if (error || !data || !data.macroBodyRenderer) {
						return (
							<LegacyMacroRendererError
								error={error}
								node={node}
								mode={mode}
								extensionKey={extensionKey}
								contentId={contentId}
							/>
						);
					}

					// Make it possible to execute AC JS functions locally.
					// The hostOrigin should match browser host
					if (process.env.NODE_ENV === 'development' && data.macroBodyRenderer.value) {
						data.macroBodyRenderer.value = data.macroBodyRenderer.value.replace(
							/\"hostOrigin\":"([^"]+)"/,
							`"hostOrigin":"http://localhost:8081/"`,
						);
					}
					return (
						<PageIncludeContext.Consumer>
							{({ listOfRenderedPageIncludeNodes }) => {
								// Keep reference so that we can render that previous page title in error message
								let prevRenderedNode;
								const pageIncludeNodeAlreadyRendered = listOfRenderedPageIncludeNodes.some(
									(node: ExtensionWithParentContentId) => {
										const newNode = node as ExtensionParams<any>;
										prevRenderedNode =
											listOfRenderedPageIncludeNodes[listOfRenderedPageIncludeNodes.length - 1];
										return (
											newNode.extensionKey === 'include' &&
											this.getReferencedContentId(node) === node?.parentContentId
										);
									},
								);

								// indicate that this page has already been included, to avoid infinite loop
								if (pageIncludeNodeAlreadyRendered) {
									const prevReferencedNodePageTitle =
										prevRenderedNode?.parameters?.macroParams?.['']?.value;
									return renderPageIncludeError(prevReferencedNodePageTitle);
								}

								return (
									<PageIncludeContextProvider
										contentId={contentId}
										node={this.props.node}
										listOfRenderedPageIncludeNodes={listOfRenderedPageIncludeNodes}
									>
										<LegacyMacroRendererComponent
											contentId={contentId}
											experienceName={getExperienceName(mode, node)}
											adf={adf}
											attributes={getMacroAttributesFromADFNode(node)}
											references={references}
											multiBodiedExtensionActions={multiBodiedExtensionActions}
											macroRenderedOutput={data.macroBodyRenderer?.value}
											macroRepresentation={data.macroBodyRenderer?.representation || 'view'}
											mediaToken={data?.macroBodyRenderer?.mediaToken?.token}
											node={node}
											webresource={data.macroBodyRenderer?.webResourceDependencies as WebResources}
											mode={mode}
											body={this.state.body}
											parameters={getParametersString(node)}
											featureFlags={featureFlags}
											spaceKey={spaceKey}
											context={context}
											noOverlay={noOverlay}
											isPreviewMode={isPreviewMode}
										/>
									</PageIncludeContextProvider>
								);
							}}
						</PageIncludeContext.Consumer>
					);
				}}
			</Query>
		);
	};

	getQueryForMacroContentFromBackend = ({
		adf,
		contentId,
		extensionKey,
		mode,
		node,
		references,
		multiBodiedExtensionActions,
		featureFlags,
		macroRenderedOutputFromSSR,
		macroOutput,
		spaceKey,
		context,
		shouldRefetchMacroQuery,
		noOverlay,
		isPreviewMode,
	}) => {
		return (
			<Query<MacroContentRendererQueryWithTagsTypes>
				query={MacroContentRendererQueryWithTags}
				variables={{
					adf: serializeExtensionADF(adf),
					contentId,
					mode: mode && mode.toUpperCase(),
				}}
				// Used when multiple instances of PageContentRenderer cause conflicting IDs in macroRenderedOutput. This forces
				// a refetch when rendering subsequent PageContentRenderer instances so that a new ID is present in
				// macroRenderedOutput. 'cache-first' is Apollo's default fetchPolicy.
				fetchPolicy={shouldRefetchMacroQuery ? 'no-cache' : 'cache-first'}
				// Some macros take a long time to render, they should not hold up the rendering of other things
				context={{
					throttle: true,
					allowOnExternalPage: true,
				}}
				skip={Boolean(macroRenderedOutputFromSSR) || Boolean(macroOutput)}
			>
				{({ data, loading, error }) => {
					// render LegacyMacroRendererComponent without query data if macro has been
					// SSR'd and macro output already exists or when macroOutput
					// exists which contains the entire html string needed to render the macro
					if (macroRenderedOutputFromSSR || macroOutput) {
						return (
							<LegacyMacroRendererComponent
								contentId={contentId}
								experienceName={getExperienceName(mode, node)}
								adf={adf}
								node={node}
								attributes={getMacroAttributesFromADFNode(node)}
								macroRenderedOutput={data?.contentRenderer?.html}
								references={references}
								multiBodiedExtensionActions={multiBodiedExtensionActions}
								macroRepresentation="view"
								webresource={data?.contentRenderer?.webResourceDependencies as WebResources}
								mode={mode}
								body={this.state.body}
								parameters={getParametersString(node)}
								featureFlags={featureFlags}
								macroRenderedOutputFromSSR={macroRenderedOutputFromSSR}
								macroOutput={macroOutput}
								spaceKey={spaceKey}
								context={context}
								noOverlay={noOverlay}
								isPreviewMode={isPreviewMode}
							/>
						);
					}

					if (loading) {
						return <LegacyMacroRendererShadow showSpinner={!!data} node={node} />;
					}
					if (error || !data || !data.contentRenderer) {
						return (
							<LegacyMacroRendererError
								error={error}
								node={node}
								mode={mode}
								extensionKey={extensionKey}
								contentId={contentId}
							/>
						);
					}

					// Make it possible to execute AC JS functions locally.
					// The hostOrigin should match browser host
					if (process.env.NODE_ENV === 'development' && data.contentRenderer.html) {
						data.contentRenderer.html = data.contentRenderer.html.replace(
							/\"hostOrigin\":"([^"]+)"/,
							`"hostOrigin":"http://localhost:8081/"`,
						);
					}
					// assume we have data here
					return (
						<LegacyMacroRendererComponent
							contentId={contentId}
							experienceName={getExperienceName(mode, node)}
							adf={adf}
							node={node}
							attributes={getMacroAttributesFromADFNode(node)}
							macroRenderedOutput={data.contentRenderer.html}
							references={references}
							multiBodiedExtensionActions={multiBodiedExtensionActions}
							macroRepresentation="view"
							webresource={data.contentRenderer.webResourceDependencies as WebResources}
							mode={mode}
							body={this.state.body}
							parameters={getParametersString(node)}
							featureFlags={featureFlags}
							context={context}
							noOverlay={noOverlay}
							isPreviewMode={isPreviewMode}
						/>
					);
				}}
			</Query>
		);
	};

	getQueryForMacros = (args) => {
		const {
			adf,
			contentId,
			extensionKey,
			mode,
			node,
			references,
			multiBodiedExtensionActions,
			featureFlags,
			macroRenderedOutputFromSSR,
			macroOutput,
			spaceKey,
			context,
			noOverlay,
		} = args;

		const macroId = node.parameters?.macroMetadata?.macroId?.value;
		return (
			<MultiMacroQuerySubscriber contentId={contentId} macroId={macroId}>
				{({ renderedMacro, loading, error, complete }) => {
					// if the multi macro query is complete and there were no errors
					// that means we are inserting a new macro in the editor or have
					// just published the page in which case the new macro data
					// will not be available on the backend to render yet so use
					// the old queries to render instead
					if (complete && !renderedMacro && !error) {
						return this.determineQuery(args, true);
					}

					// render LegacyMacroRendererComponent without query data if macro has been
					// SSR'd and macro output already exists or when macroOutput
					// exists which contains the entire html string needed to render the macro
					if (macroRenderedOutputFromSSR || macroOutput) {
						return (
							<LegacyMacroRendererComponent
								contentId={contentId}
								experienceName={getExperienceName(mode, node)}
								adf={adf}
								node={node}
								attributes={getMacroAttributesFromADFNode(node)}
								macroRenderedOutput={renderedMacro?.value}
								references={references}
								multiBodiedExtensionActions={multiBodiedExtensionActions}
								macroRepresentation="view"
								webresource={renderedMacro?.webResource as WebResources}
								mode={mode}
								body={renderedMacro?.macroBodyStorage || this.state.body}
								parameters={getParametersString(node)}
								featureFlags={featureFlags}
								macroRenderedOutputFromSSR={macroRenderedOutputFromSSR}
								macroOutput={macroOutput}
								spaceKey={spaceKey}
								context={context}
								noOverlay={noOverlay}
							/>
						);
					}

					if (loading) {
						return <LegacyMacroRendererShadow node={node} />;
					}

					if (error || !renderedMacro) {
						return (
							<LegacyMacroRendererError
								error={
									new ApolloError({ errorMessage: `error rendering legacy macro: {$extensionKey}` })
								}
								node={node}
								mode={mode}
								extensionKey={extensionKey}
								contentId={contentId}
							/>
						);
					}

					// Make it possible to execute AC JS functions locally.
					// The hostOrigin should match browser host
					// DO WE NEED THIS ↓?
					if (process.env.NODE_ENV === 'development' && renderedMacro?.value) {
						renderedMacro.value = renderedMacro.value.replace(
							/\"hostOrigin\":"([^"]+)"/,
							`"hostOrigin":"http://localhost:8081/"`,
						);
					}

					// assume we have data here
					return (
						<LegacyMacroRendererComponent
							contentId={contentId}
							experienceName={getExperienceName(mode, node)}
							adf={adf}
							node={node}
							attributes={getMacroAttributesFromADFNode(node)}
							macroRenderedOutput={renderedMacro?.value}
							references={references}
							multiBodiedExtensionActions={multiBodiedExtensionActions}
							macroRepresentation="view"
							webresource={renderedMacro?.webResource as WebResources}
							mode={mode}
							body={renderedMacro?.macroBodyStorage || this.state.body}
							parameters={getParametersString(node)}
							featureFlags={featureFlags}
							context={context}
							noOverlay={noOverlay}
						/>
					);
				}}
			</MultiMacroQuerySubscriber>
		);
	};

	getReferencedContentId = (node) => {
		return node?.parameters?.macroParams?._referencedContentId?.value || undefined;
	};

	determineQuery = (props, doNotUseMultiMacro = false) => {
		const { extensionKey, node } = props;

		if (
			!doNotUseMultiMacro &&
			!node.parameters.macroParams._parentId &&
			canUseMultiMacroQuery(extensionKey, this.props.featureFlags)
		) {
			return this.getQueryForMacros(props);
		}

		return Boolean(this.props.featureFlags[EDITOR_MACRO_ADF_RENDERING])
			? this.getQueryForMacroContentAndBodyForPossibleFrontendRendering(props)
			: this.getQueryForMacroContentFromBackend(props);
	};

	render() {
		const mode = window.location.pathname.startsWith('/wiki/pdf/') ? 'pdf' : this.props.mode;

		const { hasBodyBeenFetched } = this.state;
		const node = this.props.node;

		if (!node) {
			return null;
		}

		if (!hasBodyBeenFetched) {
			return <LegacyMacroRendererShadow node={node} />;
		}

		const adf = extensionToADF(node);

		return (
			<LegacyMacroRendererContext.Consumer>
				{({ shouldRefetchMacroQuery }) => (
					// In edit mode the editor renders this component outside of the main app, therefore
					// the Apollo client has to be provided here too

					// only use the multi macro query if this macro is not inside another, such as a page include
					// since that means it will not have been fetched by the multi macro query
					<ApolloProvider client={getApolloClient()}>
						<ExperienceTrackerContext.Provider value={getExperienceTracker()}>
							{this.determineQuery({ ...this.props, mode, adf, shouldRefetchMacroQuery })}
						</ExperienceTrackerContext.Provider>
					</ApolloProvider>
				)}
			</LegacyMacroRendererContext.Consumer>
		);
	}
}

type PageIncludeContextProviderProps = {
	contentId: any;
	node: any;
	listOfRenderedPageIncludeNodes: any;
	children: React.ReactNode;
};
const PageIncludeContextProvider = ({
	contentId,
	node,
	listOfRenderedPageIncludeNodes,
	children,
}: PageIncludeContextProviderProps) => {
	const contextValue = React.useMemo(() => {
		const renderedPageIncludeNodes = [
			...listOfRenderedPageIncludeNodes,
			{ ...node, parentContentId: contentId },
		];

		return { listOfRenderedPageIncludeNodes: renderedPageIncludeNodes };
	}, [listOfRenderedPageIncludeNodes, node, contentId]);

	return <PageIncludeContext.Provider value={contextValue}>{children}</PageIncludeContext.Provider>;
};

export const LegacyMacroRendererQuery = React.memo(
	(props: any) => {
		const { featureFlags } = useSessionData();

		return <LegacyMacroRendererQueryComponent featureFlags={featureFlags} {...props} />;
	},
	(prevProps, nextProps) => isEqual(prevProps, nextProps),
);
