const issueKeyPattern = '[a-zA-Z0-9]+-\\d+';
const jiraIssueContentIdParseStr = `\\/browse\\/(?<resourceId>${issueKeyPattern})#?.*?\\/?$`;
const jiraIssueContentIdParse = new RegExp(jiraIssueContentIdParseStr);

// For Jira when url has query parameters
// Examples:
// * https://my-instance.atlassian.net/jira/software/projects/TEST/boards/1/backlog?selectedIssue=TEST-100
// * https://my-instance.atlassian.net/jira/software/projects/TEST/boards/1?selectedIssue=TEST-100
const jiraIssueQueryParametersParseStr = `\\/jira\\/software\\/projects\\/\\w+\\/boards\\/\\d+[\\/a-zA-Z]*\\?selectedIssue=(?<resourceId>${issueKeyPattern})`;
const jiraIssueQueryParametersParse = new RegExp(jiraIssueQueryParametersParseStr);

// For Jira Service Desk work portals.
// Examples:
// * https://jdog.jira-dev.com/servicedesk/customer/portal/223/NICK-7?created=true
// * https://jdog.jira-dev.com/servicedesk/projects/NICK/queues/custom/1698/NICK-7
// * https://jdog.jira-dev.com/servicedesk/projects/NICK/queues/custom/1698
// * https://jdog.jira-dev.com/jira/servicedesk/projects/NICK/queues/custom/1698/NICK-7
// * https://jdog.jira-dev.com/jira/servicedesk/projects/NICK/queues/custom/1698
// * https://jdog.jira-dev.com/jira/servicedesk/projects/NICK/queues/issue/NICK-7
// * https://jdog.jira-dev.com/jira/servicedesk/projects/WOOH/section/incidents/custom/272/WOOH-15
// * https://spartans.jira-dev.com/helpcenter/2/portal/23/BIREN-30?created=true
// * https://spartans.jira-dev.com/helpcenter/slug/portal/23/BIREN-30
const jiraPortalIdStr = `(\\/jira)?\\/servicedesk\\/customer\\/portal\\/\\d+\\/(?<resourceId>${issueKeyPattern})\\/?.*$`;
const jiraPortalIdParse = new RegExp(jiraPortalIdStr);
const jiraPortalQueueIdStr = `(\\/jira)?\\/servicedesk\\/projects\\/[\\w]*\\/queues\\/(custom|issue)\\/(\\d+)(\\/(?<resourceId>${issueKeyPattern}))?\\/?$`;
const jiraPortalQueueIdParse = new RegExp(jiraPortalQueueIdStr);
const jiraPortalSectionIdStr = `(\\/jira)?\\/servicedesk\\/projects\\/[\\w]*\\/section\\/(incidents|changes|service-requests|problems)\\/custom\\/\\d+\\/(?<resourceId>${issueKeyPattern})\\/?.*$`;
const jiraPortalSectionIdParse = new RegExp(jiraPortalSectionIdStr);
const jiraAdvancedHelpCenterPortalIdStr = `(\\/jira)?\\/helpcenter\\/[\\w-]+\\/portal\\/\\d+\\/(?<resourceId>${issueKeyPattern})\\/?.*$`;
const jiraAdvancedHelpCenterPortalIdParse = new RegExp(jiraAdvancedHelpCenterPortalIdStr);

// For Jira Work Management views
// Examples:
// * https://jdog.jira-dev.com/jira/core/projects/TH2/timeline
const jwmTimelineViewPattern = `\\/jira\\/core\\/projects\\/(?<resourceId>\\w+)\\/timeline\\/?`;
const jwmTimelineViewParse = new RegExp(jwmTimelineViewPattern);

// * https://jdog.jira-dev.com/jira/core/projects/TH2/calendar
const jwmCalendarViewPattern = `\\/jira\\/core\\/projects\\/(?<resourceId>\\w+)\\/calendar\\/?`;
const jwmCalendarViewParse = new RegExp(jwmCalendarViewPattern);

// * https://jdog.jira-dev.com/jira/core/projects/TH2/list
const jwmListViewPattern = `\\/jira\\/core\\/projects\\/(?<resourceId>\\w+)\\/list\\/?`;
const jwmListViewParse = new RegExp(jwmListViewPattern);

// * https://jdog.jira-dev.com/jira/core/projects/TH2/board
const jwmBoardViewPattern = `\\/jira\\/core\\/projects\\/(?<resourceId>\\w+)\\/board\\/?`;
const jwmBoardViewParse = new RegExp(jwmBoardViewPattern);

// https://jdog.jira-dev.com/jira/software/projects/DL39857/boards/3186/
// https://jdog.jira-dev.com/jira/software/c/projects/DL39857/boards/3186/
const jiraProjectBoardIdPattern = `\\/jira\\/software\\/c?\\/?projects\\/(?<resourceId>[^\\/]+?)\\/boards\\/(?<resourceContext>\\d+)\\/?`;
const jiraProjectBoardViewParse = new RegExp(jiraProjectBoardIdPattern);

// https://softwareteams.atlassian.net/projects/SAI/versions/12505/tab/release-report-all-issues
// https://hello.atlassian.net/projects/BUILDENG/versions/44334/tab/release-report-all-issues
const jiraVersionIdPattern = `\\/projects\\/(?<resourceContext>[^\\/]+?)\\/versions\\/(?<resourceId>\\d+)\\/?`;
const jiraVersionParse = new RegExp(jiraVersionIdPattern);

// * https://jdog.jira-dev.com/jira/core/projects/TH2/form/1?
const jwmFormViewPattern =
	'\\/jira\\/core\\/projects\\/(?<resourceId>\\w+)\\/form\\/(?<resourceContext>\\d+)(?!\\/\\w+)\\b';
const jwmFormViewParse = new RegExp(jwmFormViewPattern);

// https://jdog.jira-dev.com/jira/core/projects/TH2/summary
const jwmSummaryViewPattern = `\\/jira\\/core\\/projects\\/(?<resourceId>\\w+)\\/summary\\/?`;
const jwmSummaryViewParse = new RegExp(jwmSummaryViewPattern);

// https://jdog.jira-dev.com/jira/software/projects/DL39857/boards/3186/timeline
// https://jdog.jira-dev.com/jira/software/c/projects/DL39857/boards/3186/timeline
const jiraTimelineIdPattern = `\\/jira\\/software\\/c?\\/?projects\\/(?<resourceId>[^\\/]+?)\\/boards\\/(?<resourceContext>\\d+)\\/(timeline|roadmap)\\/?`;
const jiraTimelineIdParse = new RegExp(jiraTimelineIdPattern);

// https://efoo.jira-dev.com/jira/software/projects/KAN/code
const jiraCodeViewPattern = `\\/jira\\/software\\/projects\\/(?<resourceId>[^\\/]+?)\\/code\\/?`;
const jiraCodeViewParse = new RegExp(jiraCodeViewPattern);

// https://hello.jira.atlassian.cloud/jira/software/c/projects/PC/security
const jiraSecurityViewPattern = `\\/jira\\/software\\/projects\\/(?<resourceId>[^\\/]+?)\\/security\\/?`;
const jiraSecurityViewParse = new RegExp(jiraSecurityViewPattern);

// https://hello.jira.atlassian.cloud/projects/PC?selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page
const jiraReleaseViewPattern = `\\/projects\\/(?<resourceId>[^\\/]+?)\\?selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page`;
const jiraReleaseViewParse = new RegExp(jiraReleaseViewPattern);

// https://softwareteams.atlassian.net/jira/software/projects/INT/deployments
const jiraDeploymentsViewPattern = `\\/jira\\/software\\/projects\\/(?<resourceId>[^\\/]+?)\\/deployments\\/?`;
const jiraDeploymentsViewParse = new RegExp(jiraDeploymentsViewPattern);

// https://efoo.jira-dev.com/jira/servicedesk/projects/SUP
const jiraServiceDeskViewPattern = `\\/jira\\/servicedesk\\/projects\\/(?<resourceId>[^\\/]+?)\\/?$`;
const jiraServiceDesksViewParse = new RegExp(jiraServiceDeskViewPattern);

// https://jdog.jira-dev.com/jira/software/projects/DL39857/list
// https://jdog.jira-dev.com/jira/software/c/projects/DL39857/list
const jiraListIdPattern = `\\/jira\\/software\\/c?\\/?projects\\/(?<resourceId>[^\\/]+?)\\/list\\/?`;
const jiraListIdParse = new RegExp(jiraListIdPattern);

// For ProForma Issue Forms Direct. Available for all project types.
// Examples:
// * https://jdog.jira-dev.com/jira/servicedesk/projects/RN2/forms/form/direct/1/23930?requestTypeId=3564
// * https://jdog.jira-dev.com/jira/software/c/projects/PFORM/forms/form/direct/1/3
const proformaPattern =
	'\\/jira\\/(core|software(\\/c)?|servicedesk)\\/projects\\/(?<resourceId>\\w+)\\/forms\\/form\\/direct\\/\\d+\\/\\d+.*$';
const proformaParse = new RegExp(proformaPattern);

// https://abocan-test-1.jira-dev.com/browse/CHEES
const jiraProjectIdPattern = `\\/browse\\/(?<resourceId>[^#\\/\?]+)(\\#[^\\/]*?)?(\\?[^\\/]*?)?$`;
const jiraProjectIdParse = new RegExp(jiraProjectIdPattern);

// The pattern below is fixing an issue for issues opened from a JQL search. The change is behind a FF which is why we kept the old version of a pattern
// Examples:
// https://jdog.jira-dev.com/issues/?jql=project%20%3D%20%22ADIL%22%20ORDER%20BY%20created%20DESC
// https://product-fabric.atlassian.net/jira/software/c/projects/EDM/issues/?jql=project%20%3D%20%22PCT%22%20and%20type%20%3D%20Epic%20ORDER%20BY%20created%20DESC
// https://product-fabric.atlassian.net/jira/software/c/projects/EDM/issues/?filter=allissues&jql=project%20%3D%20%22EDM%22%20AND%20%22Epic%20Link%22%20%3D%20EDM-7965%20ORDER%20BY%20created%20DESC
const jiraIssueJqlPattern = `(\\/jira\\/software(\\/c)?\\/projects\\/.*?)?\\/issues\\/\\?.*?(\\&)*(?!.*filter=)jql=(?<resourceId>[^&]*)`;
const jiraIssueJqlParse = new RegExp(jiraIssueJqlPattern);

// https://jdog.jira-dev.com/issues/?jql=project%20%3D%20%22ADIL%22%20ORDER%20BY%20created%20DESC
// https://product-fabric.atlassian.net/jira/software/c/projects/EDM/issues/?jql=project%20%3D%20%22PCT%22%20and%20type%20%3D%20Epic%20ORDER%20BY%20created%20DESC
// https://product-fabric.atlassian.net/jira/software/c/projects/EDM/issues/?filter=allissues&jql=project%20%3D%20%22EDM%22%20AND%20%22Epic%20Link%22%20%3D%20EDM-7965%20ORDER%20BY%20created%20DESC
const jiraIssueJqlPatternOld = `(\\/jira\\/software(\\/c)?\\/projects\\/.*?)?\\/issues\\/.*?(\\&|\\?)(?!.*filter=)jql=(?<resourceId>[^&]*)`;
const jiraIssueJqlParseOld = new RegExp(jiraIssueJqlPatternOld);

// https://linchen.jira-dev.com/issues/LINS-664?jql=created%20%3E%3D%20-30d%20order%20by%20created%20DESC
const jiraIssueJqlSearchPattern = `(\\/jira\\/software(\\/c)?\\/projects\\/.*?)?\\/issues\\/(?<resourceId>${issueKeyPattern})\\?.*?(\\&)*(?!.*filter=)jql=([^&]*)`;
const jiraIssueJqlSearchParse = new RegExp(jiraIssueJqlSearchPattern);

// https://product-fabric.atlassian.net/jira/software/c/projects/EDM/issues/
const jiraProjectIssuesPattern = `\\/jira\\/software(\\/c)?\\/projects\\/(?<resourceId>[^#\\/\\?]+)\\/issues(\\/?)$`;
const jiraProjectIssuesParse = new RegExp(jiraProjectIssuesPattern);

// https://product-fabric.atlassian.net/issues/?filter=17795
const jiraFilteredIssuesPattern = `(\\/jira\\/software(\\/c)?\\/projects\\/.*?)?\\/issues\\/.*?(\\&|\\?)filter=(?<resourceId>\\d+)(?!.*jql=)`;
const jiraFilteredIssuesParse = new RegExp(jiraFilteredIssuesPattern);

// https://product-fabric.atlassian.net/jira/dashboards/15429
// https://product-fabric.atlassian.net/jira/dashboards/15429?maximized=26562
const jiraDashboardPattern = `\\/jira\\/dashboards\\/(?<resourceId>\\d+)\\/?$`;
const jiraDashboardParse = new RegExp(jiraDashboardPattern);

// https://product-fabric.atlassian.net/jira/dashboards/15429?maximized=26562
const jiraDashboardChartPattern = `\\/jira\\/dashboards\\/(?<resourceId>\\d+)\\/?\\?maximized=(?<resourceContext>\\d+)$`;
const jiraDashboardChartParse = new RegExp(jiraDashboardChartPattern);

// ContentId paths for Jira. *MUST* contain a regex group for resourceId.
// Supports:
// - issue
// - roadmap
// - project
// - timeline
// - calendar
// - list
// - board
// - form
// - summary
// - proformaForm
// - jql
export const jiraPatterns: RegExp[] = [
	jiraIssueContentIdParse,
	jiraPortalIdParse,
	jiraPortalQueueIdParse,
	jiraPortalSectionIdParse,
	jiraIssueQueryParametersParse,
	jiraAdvancedHelpCenterPortalIdParse,
	jiraIssueJqlSearchParse,
	jiraTimelineIdParse,
	jiraProjectIdParse,
	jiraProjectBoardViewParse,
	jiraServiceDesksViewParse,
	jiraCodeViewParse,
	jiraSecurityViewParse,
	jiraReleaseViewParse,
	jiraDeploymentsViewParse,
	jiraVersionParse,
	jwmTimelineViewParse,
	jwmCalendarViewParse,
	jwmListViewParse,
	jiraListIdParse,
	jwmBoardViewParse,
	jwmFormViewParse,
	jwmSummaryViewParse,
	proformaParse,
	jiraProjectIssuesParse,
	jiraFilteredIssuesParse,
	jiraIssueJqlParse,
	jiraDashboardParse,
	jiraDashboardChartParse,
	jiraIssueJqlParseOld,
];
