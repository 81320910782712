import React from 'react';

import type { ExtensionHandlers, ExtensionParams } from '@atlaskit/editor-common/extensions';

import { ConfluencePageAri, ConfluenceSiteAri } from '@atlassian/ari';

import { ExperienceTimeout } from '@confluence/experience-tracker';
import {
	MacroExperienceStart,
	getMacroAttributesFromADFNode,
	RENDERER,
	getExperienceName,
} from '@confluence/macro-tracker';

import { smartButtonExtensionType } from '../smartButtonConstants';
import type { SmartButtonParameters } from '../../__types__/SmartButtonConfigTypes';

import { SmartButtonLoader } from './SmartButtonLoader';

type SmartButtonExtensionHandlerProps = {
	contentId?: string;
	contentType?: string;
	cloudId: string;
};

export const getRendererSmartButtonExtensionHandlers = ({
	contentId,
	contentType,
	cloudId,
}: SmartButtonExtensionHandlerProps): ExtensionHandlers => {
	const extensionHandlers: ExtensionHandlers = {};

	extensionHandlers[smartButtonExtensionType] = (
		extension: ExtensionParams<SmartButtonParameters>,
	) => {
		const pageAri = contentId
			? ConfluencePageAri.create({
					siteId: cloudId,
					pageId: contentId,
				}).toString()
			: undefined;

		const site = ConfluenceSiteAri.create({ siteId: cloudId }).toString();
		const experienceName = getExperienceName(RENDERER, extension);

		return (
			<>
				<MacroExperienceStart
					name={experienceName}
					contentId={contentId ?? ''}
					mode={RENDERER}
					extensionKey={extension.extensionKey}
					attributes={getMacroAttributesFromADFNode(extension)}
					timeout={ExperienceTimeout.MACRO_LOAD}
				/>
				<SmartButtonLoader
					ruleId={Number(extension.parameters?.ruleId)}
					scopeId={extension.parameters?.scopeId}
					pageAri={pageAri}
					site={site}
					smartButtonName={extension.parameters?.smartButtonName}
					experienceTrackerName={experienceName}
					contentId={contentId ?? ''}
					contentTypeType={contentType ?? ''}
					isViewMode
				/>
			</>
		);
	};

	return extensionHandlers;
};
