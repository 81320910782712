import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import { SHORTCUTS_SMART_LINKS_BEST_EFFORT_TASK_NAME } from '@confluence/space-shortcuts/entry-points/ShortcutsSmartLinksBestEffortTaskName';
import { preloadUserPreferencesForSSR } from '@confluence/storage-manager/entry-points/preloadUserPreferencesForSSR';
import { preloadSideNavigation } from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadEmbedData } from '@confluence/confluence-embeds/entry-points/preloadEmbedData';
import { bestEffortTask, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';

export const preloadEmbedRoute = async (
	match: RouteMatch,
	_url: string,
	isTransition: boolean = false,
) => {
	const { isLicensed, featureFlags } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey, contentId } = match.params;

	if (process.env.REACT_SSR) {
		tasks.push(
			preloadUserPreferencesForSSR({
				isLicensed,
				spaceKey,
			}),
			preloadSideNavigation(spaceKey, isLicensed),
			preloadSpaceGuestList(spaceKey),
			bestEffortTask(SHORTCUTS_SMART_LINKS_BEST_EFFORT_TASK_NAME, () =>
				preloadShortcutsSmartLinks(spaceKey),
			),
		);
	} else if (!isTransition) {
		tasks.push(preloadSpaceViews(spaceKey, isLicensed));
	}

	tasks.push(
		preloadEmbedData(spaceKey, contentId, Boolean(featureFlags['confluence.frontend.space.alias'])),
	);

	return Promise.all(tasks);
};
