import gql from 'graphql-tag';

export const ChildrenMacroQuery = gql`
	query ChildrenMacroQuery($contentId: ID!) {
		ptpage(id: $contentId) {
			id
			children(first: 100) {
				nodes {
					id
					title
					links {
						webui
					}
				}
				pageInfo {
					hasNextPage
					__typename
				}
				__typename
			}
			__typename
		}
	}
`;
