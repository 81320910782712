export const ONBOARDING_STATE_PREFIX = 'onboarding-state:';
export const PEOPLE = 'peopleNudge';
export const LEGACY = 'legacy';
export const INTRO_WORKFLOW = 'introWorkflow';
export const FIRST_SPACE_SELECTED = 'firstSpaceSelected';
export const CONFLUENCE_GROUP_ID = 'confluenceGroupId';
export const HELP = 'help';
export const ONBOARDING_INTENTS = 'onboardingIntents';
export const IS_FOUNDING_ADMIN = 'isFoundingAdmin';
export const ONBOARDING_INTENT_EXPERIENCES = {
	ONBOARDING_INTENT_EXPERIENCES_CLOSED:
		'onboarding-intent-experiences:onboardingIntentExperienceClosed',
	ONBOARDING_EXPERIENCE_ELIGIBLE: 'onboarding-intent-experience:isOnboardingExperienceEligibleUser',
	ONBOARDING_INTENT_EXPERIENCES_LAND_NEW_HOME_VIEWED_AT:
		'onboarding-intent-experiences:onboardingIntentExperienceLandViewedAt',
	ONBOARDING_INTENT_EXPERIENCES_LAND_NEW_HOME_CLOSED:
		'onboarding-intent-experiences:onboardingIntentExperienceLandClosed',
};

export const ONBOARDING_REVERSE_TRIAL = {
	MODAL_SEEN: 'onboarding-reverse-trial:isModalSeen',
};

export const TEMPLATES_COLLECTION = {
	EXPERIMENT_ELIGIBLE: 'templates-collection:isTaggedForTemplatesCollectionExperiment',
	EXPERIMENT_DISMISSED: 'templates-collection:isTemplatesSectionDismissed',
};

export const PERSISTENT_INVITE = {
	HAS_INVITE_CAPABILITIES: 'persistent-invite:hasInviteCapabilities',
};

// existing onboarding experiences for making query calls
export const ONBOARDING_EXPERIENCES = {
	INTRO_WORKFLOW: `${ONBOARDING_STATE_PREFIX}${INTRO_WORKFLOW}`,
	LEGACY_ONBOARDING_STATE: `${ONBOARDING_STATE_PREFIX}${LEGACY}`,
	IS_FOUNDING_ADMIN: `${ONBOARDING_STATE_PREFIX}${IS_FOUNDING_ADMIN}`,
};

// existing experience status
export const COMPLETE = '__complete__';

export const { LEGACY_ONBOARDING_STATE } = ONBOARDING_EXPERIENCES;
