/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import type { ADFEntity } from '@atlaskit/adf-utils/types';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import { Text } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';
import Loading from '@atlassian/generative-ai-modal/assets/Loading';

import type { ExtensionKeys } from '../../../actions/types';
import {
	AnalyticsFlowContextProvider,
	useCreateAnalyticsFlow,
} from '../../../analytics/analytics-flow/analyticsFlowUtils';
import type { EditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { StreamedContentPreview } from '../streamed-content-preview/StreamedContentPreview';
import type { AIManifest } from '../types';
import {
	getAupViolationMessage,
	getErrorMessage,
	getInternalServerErrorMessage,
	getRateLimitErrorMessage,
} from '../utils';

import { messages } from './messages';

const extensionStyles = css({
	background: token('elevation.surface', '#FFFFFF'),
	padding: token('space.200', '16px'),
	borderRadius: token('border.radius.100', '3px'),
});

const loadingStyles = css({
	display: 'flex',
});

const textStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	wordBreak: 'break-word',
	padding: '2px 0 0 8px',
});

export type AIPanelProps = {
	api: AIManifest['api'];
	localId: string;
	action: (callback: (markdown: string, status: string) => void) => void;
	schema: EditorState['schema'];
	providerFactory: ProviderFactory;
	updateParams?: (
		callback: (currentValue: Pick<ADFEntity, 'attrs'>) => Pick<ADFEntity, 'attrs'>,
	) => void;
	configItem: EditorPluginAIConfigItemMarkdown<'empty'>;
	extensionKey: ExtensionKeys;
	convertExtension: (
		api: AIManifest['api'],
		localId: string,
		extensionType: ExtensionKeys,
		addToHistory?: boolean,
	) => void;
	idMap?: IDMap;
};

export const AIPanel = ({
	api,
	localId,
	action,
	schema,
	providerFactory,
	updateParams,
	extensionKey,
	configItem,
	convertExtension,
	idMap,
}: AIPanelProps) => {
	const { formatMessage } = useIntl();
	const [content, setContent] = React.useState<string>('');
	const [status, setStatus] = React.useState<string>('loading');
	const [isFailed, setIsFailed] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] = React.useState<string | React.ReactNode>('');
	const isRequestSent = React.useRef(false);
	const loadingAltText = formatMessage(messages.iconAltText);

	const startUpdateParams = React.useCallback(
		(status: string, content: string) => {
			updateParams?.((currentValue) => {
				return {
					...currentValue,
					attrs: {
						...currentValue.attrs,
						parameters: {
							...currentValue?.attrs?.parameters,
							macroMetadata: {
								...currentValue?.attrs?.parameters?.macroMetadata,
								status: status,
							},
							content,
						},
					},
				};
			});
		},
		[updateParams],
	);

	const streamHandler = React.useCallback(
		(content: string, status: string) => {
			// TODO: EDF-659 This is temporary, once the ai service is updated to remove the hardcoded # Actions
			// We can remove this
			if (content && extensionKey.includes('ai-action-items')) {
				content = content.replace('# Action Items', '');
			}
			setContent(content);
			setStatus(status);
			if (status === 'loaded') {
				// When response is loaded, we update the content in the node then convert it to a bodiedExtension
				startUpdateParams('success', content);
				const addToHistory = false;
				convertExtension(api, localId, extensionKey, addToHistory);
			}
			if (status === 'failed' || status === 'aup-violation') {
				switch (content) {
					case 'ACCEPTABLE_USE_VIOLATIONS':
						const aupViolationMessage = getAupViolationMessage(formatMessage);
						setErrorMessage(aupViolationMessage);
						setIsFailed(true);
						startUpdateParams('aup-violation', content);
						break;
					case 'NO_AGENT':
					case 'FEATURE_DISABLED_ON_SITE':
					case 'FEATURE_DISABLED':
					case 'INTERNAL_SERVER_ERROR':
					case 'PLUGIN_ERRORED':
						const internalServerError = getInternalServerErrorMessage(formatMessage);
						setErrorMessage(internalServerError);
						setIsFailed(true);
						startUpdateParams('failed', content);
						break;
					case 'RATE_LIMIT':
					case 'OPENAI_RATE_LIMIT_USER_ABUSE':
						const rateLimitErrorMessage = getRateLimitErrorMessage(formatMessage);
						setErrorMessage(rateLimitErrorMessage);
						setIsFailed(true);
						startUpdateParams('failed', content);
						break;
					default:
						const errorMessageDescriptor = getErrorMessage(content);
						const errorMessage = formatMessage(errorMessageDescriptor);
						setErrorMessage(errorMessage);
						setIsFailed(true);
						startUpdateParams('failed', content);
						break;
				}
			}
		},
		[
			api,
			setContent,
			setStatus,
			convertExtension,
			localId,
			extensionKey,
			formatMessage,
			startUpdateParams,
		],
	);

	const analyticsFlow = useCreateAnalyticsFlow({
		invokeAttributes: {
			experienceName: configItem.key,
		},
	});

	React.useEffect(() => {
		// If we have already made a request we don't want to send it again
		if (!isRequestSent.current) {
			action(streamHandler);
			isRequestSent.current = true;
		}
	}, [streamHandler, action]);

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			css={extensionStyles}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={status === 'loading' ? 'streaming' : undefined}
		>
			<AnalyticsFlowContextProvider value={analyticsFlow}>
				{!content && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					<div css={loadingStyles}>
						<Loading alt={loadingAltText} />
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
						<div css={textStyles}>Generating</div>
					</div>
				)}

				{content && !isFailed && (
					<StreamedContentPreview
						content={content}
						schema={schema}
						idMap={idMap}
						status={status}
						providerFactory={providerFactory}
					/>
				)}
				{isFailed && (
					<SectionMessage appearance="error" title={''}>
						<Text as="p">{errorMessage}</Text>
					</SectionMessage>
				)}
			</AnalyticsFlowContextProvider>
		</div>
	);
};
