import { createActionsHook, createContainer, createHook, createStore } from 'react-sweet-state';

import type { ThemeColorModes, ThemeState } from '@atlaskit/tokens';

import {
	addIframeThemingSupport,
	applyTheme,
	buildThemeFromState,
	getDefaultTheme,
	getSystemPreferredColorScheme,
} from '@confluence/theming-utils';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

import { ErrorSubjects, ErrorSubjectIds, reportThemingError } from './errorHandling';
import { ThemeAPI } from './themeAPI';

export interface ConfluenceThemeState {
	isInitialized: boolean;
	theme: Partial<ThemeState>;
}

const initialState: ConfluenceThemeState = {
	isInitialized: false,
	theme: getDefaultTheme(),
};

type ThemingStateContainerProps = {
	initialTheme?: Partial<ThemeState>;
};

export const themingActions = {
	applyTheme: (theme: Partial<ThemeState>) => async () => {
		await applyTheme(theme);
		const client = await getAnalyticsWebClient();
		client.setUIViewedAttributes({
			theme,
			// OStheme may become stale until the session is restarted since the user can
			//      change their system/browser settings at any time without manually changing their theme in the app.
			OStheme: getSystemPreferredColorScheme(),
		});
	},
	initializeTheme:
		(initialTheme?: Partial<ThemeState>) =>
		async ({ setState, dispatch }) => {
			if (!initialTheme) {
				// Get local theme first to help render theme faster than waiting to fetch latest preference from remote
				const persistedLocalTheme = ThemeAPI.getSavedThemeLocal();
				if (persistedLocalTheme) {
					dispatch(themingActions.applyTheme(buildThemeFromState(persistedLocalTheme)));
				}
			}

			addIframeThemingSupport();

			const theme = initialTheme || (await ThemeAPI.getSavedTheme());
			const effectiveTheme = buildThemeFromState(theme);
			setState({
				isInitialized: true,
				theme: effectiveTheme,
			});
			await dispatch(themingActions.applyTheme(effectiveTheme));
		},
	setColorModePreference:
		(themeColorMode: ThemeColorModes) =>
		async ({ getState, setState, dispatch }) => {
			const theme = {
				...getState().theme,
				colorMode: themeColorMode,
			};
			try {
				setState({
					...getState(),
					theme,
				});
				await Promise.all([dispatch(themingActions.applyTheme(theme)), ThemeAPI.saveTheme(theme)]);
			} catch (e) {
				const attributes = {
					message: e.message,
					stack: e.stack,
				};
				await reportThemingError(
					ErrorSubjects.useThemingState,
					ErrorSubjectIds.setColorMode,
					attributes,
				);
			}
		},
	restoreThemePreference:
		() =>
		async ({ dispatch, getState }) => {
			const { theme } = getState();
			await dispatch(themingActions.applyTheme(theme));
		},
};

export type ThemingActions = typeof themingActions;

const Store = createStore<ConfluenceThemeState, ThemingActions>({
	initialState,
	actions: themingActions,
	name: 'themingState',
});

// Used for testing
export const ThemingStateContainer = createContainer<
	ConfluenceThemeState,
	ThemingActions,
	ThemingStateContainerProps
>(Store, {
	onInit:
		() =>
		async ({ dispatch }, { initialTheme }) => {
			void dispatch(themingActions.initializeTheme(initialTheme));
		},
});
export const useThemingState = createHook<ConfluenceThemeState, ThemingActions>(Store);

// This hook is used just for consumers that only need to dispatch actions but don't
//    rely on the state and prevents re-renders in those consumers
export const useThemingActions = createActionsHook<ConfluenceThemeState, ThemingActions>(Store);
