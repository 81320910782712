export const LEARN_MORE_URL = 'https://www.atlassian.com/software/confluence/pricing';

export const LEARN_SWITCH_URL =
	'https://support.atlassian.com/subscriptions-and-billing/docs/manage-your-bill-for-standard-and-premium-plans/#Moving-from-a-paid-plan-to-a-Free-plan';

export const USER_MANAGEMENT_URL = '/admin/users';

export const ADMIN_HUB_PROD_URL = 'https://admin.atlassian.com';

export const ADMIN_HUB_NON_PROD_URL = 'https://admin.stg.atlassian.com';

export const REFERRER = 'confluence';

export const NOT_ENROLLED = 'not-enrolled';

export const VARIATION = 'variation';

export const TRIAL_END_LOCK_SCREEN_STATSIG_FLAG = 'confluence_trial_end_lock_screen_experiment';

export const TRIAL_END_LOCK_SCREEN_FF =
	'confluence.frontend.trial-end-lock-screen-experiment_5fqe8';

export const ATLASSIAN_START_PROD_URL = 'https://start.atlassian.com';

export const ATLASSIAN_START_NON_PROD_URL = 'https://start.stg.atlassian.com';
