import { CardSizes, CardType, CardAlignment } from '../linkCardsTypes';
import type { LinkCardsParameters, Card } from '../linkCardsTypes';
import { DEFAULT_CARDS_SIZE } from '../LinkCardsConfigPanel/components/CardConfigPanelSizeField';
import { DEFAULT_CARDS_TYPE } from '../LinkCardsConfigPanel/components/CardConfigPanelToggleField';
import { DEFAULT_CARDS_ALIGNMENT } from '../LinkCardsConfigPanel/components/CardConfigPanelAlignmentField';

const checkIsParameterValueValid = (parameter: any, expectedValues: any[]) => {
	return expectedValues.includes(parameter) ? parameter : null;
};

const checkSize = (size: CardSizes) =>
	checkIsParameterValueValid(size, [
		CardSizes.EXTRA_SMALL,
		CardSizes.SMALL,
		CardSizes.MEDIUM,
		CardSizes.LARGE,
		CardSizes.HERO,
	]);

export const checkAlignment = (alignment: CardAlignment) =>
	checkIsParameterValueValid(alignment, [CardAlignment.LEFT, CardAlignment.CENTER]);

export const checkType = (type: CardType) => {
	const expectedTypes = [CardType.TEXT];

	return checkIsParameterValueValid(type, expectedTypes);
};

const validateCardsUrls = (cards: Card[]) => {
	cards.forEach((card) => {
		if (!!card?.link && !card?.link.startsWith('http')) {
			card.link = '';
		}
	});
};

export const checkCards = (cards: Card[]) => {
	if (!cards) {
		return null;
	} else if (Array.isArray(cards) && cards.length > 0) {
		validateCardsUrls(cards);
		return cards;
	}
};

export const useValidateRequiredParameters = (currentParameters: any): LinkCardsParameters => {
	const validatedSize = checkSize(currentParameters?.size);
	const validatedAlignment = checkAlignment(currentParameters?.alignment);
	const validatedType = checkType(currentParameters?.type);
	const validatedCards = checkCards(currentParameters?.cards);

	if (!!validatedSize && !!validatedAlignment && !!validatedType && !!validatedCards)
		return currentParameters;

	const validatedRequiredParameters = {
		size: validatedSize || DEFAULT_CARDS_SIZE,
		alignment: validatedAlignment || DEFAULT_CARDS_ALIGNMENT,
		type: validatedType || DEFAULT_CARDS_TYPE,
		cards: validatedCards || [],
	} as LinkCardsParameters;

	return {
		...currentParameters,
		...validatedRequiredParameters,
	} as LinkCardsParameters;
};
