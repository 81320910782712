import React from 'react';

import { LegacyMacroRenderer } from '@confluence/fabric-extension-legacy-macro-renderer';
import {
	CreateFromTemplateLoader,
	LazyCreateFromTemplateLoader,
} from '@confluence/create-from-template';

export const CreateFromTemplateHandler = ({
	hasADF,
	macroDefaultProps,
	cloudId,
	userId,
	isPreviewMode,
	contentType,
	shouldLoadAfterPaint,
}) => {
	if (!hasADF) {
		return <LegacyMacroRenderer {...macroDefaultProps} />;
	}

	return shouldLoadAfterPaint || isPreviewMode ? (
		<LazyCreateFromTemplateLoader
			userId={userId}
			cloudId={cloudId}
			macroDefaultProps={macroDefaultProps}
			isPreviewMode={isPreviewMode}
			contentType={contentType}
		/>
	) : (
		<CreateFromTemplateLoader
			userId={userId}
			cloudId={cloudId}
			macroDefaultProps={macroDefaultProps}
			isPreviewMode={isPreviewMode}
			contentType={contentType}
		/>
	);
};
