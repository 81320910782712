import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { EditorActions } from '@atlaskit/editor-core';

import { getForgeExtensionProviderNext } from '@atlassian/xen-editor-provider';

import {
	FlagProvider,
	getWorkspaceARI,
	GQLExtensionContextsFilterType,
} from '@confluence/forge-ui';
import { getSessionData } from '@confluence/session-data';
import {
	getForgeClient,
	environmentToForgeProductEnvironment,
	createForgeUIAnalyticsContext,
	createForgeMacroExtensionData,
} from '@confluence/fabric-providers';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { preloadSpaceDetail as fetchSpaceDetail } from '@confluence/space-utils';

type ForgeExtensionProviderParams = Parameters<typeof getForgeExtensionProviderNext>[0];
export const getForgeExtensionHandler = async ({
	spaceKey,
	spaceId,
	contentId,
	contentType,
	mentionProvider,
	isEditing,
	connectExtensionProvider,
	editorActions,
	hideGlassPaneForForge,
}: {
	spaceKey: string;
	spaceId?: string;
	contentId: string;
	contentType: any;
	mentionProvider: any;
	isEditing: boolean;
	connectExtensionProvider?: ForgeExtensionProviderParams['connectExtensionProvider'];
	editorActions?: EditorActions;
	hideGlassPaneForForge?: boolean;
}) => {
	const { cloudId, userId, environment, featureFlags, activationId } = await getSessionData();
	const accountId = userId ?? '';

	const apolloClient = getForgeClient();
	const contextIds = [getWorkspaceARI({ cloudId, activationId })];
	const providerFactory = ProviderFactory.create(mentionProvider);

	const getProviderOptions = ({ spaceKey: spaceKeyArg }: { spaceKey: string }) => ({
		accountId,
		cloudId,
		apolloClient,
		contextIds,
		dataProviders: providerFactory,
		environment: environmentToForgeProductEnvironment(environment),
		product: 'confluence',
		page: 'confluence:macroEditor',
		isEditing,
		analyticsWebClient: getAnalyticsWebClient(),
		forgeUIAnalyticsContext: createForgeUIAnalyticsContext({
			objectId: contentId,
			objectType: contentType,
			containerId: spaceKeyArg, // TODO replace with spaceId
			containerType: 'space',
		}),
		extensionData: createForgeMacroExtensionData({
			spaceKey: spaceKeyArg,
			spaceId,
			contentId,
		}),
		connectExtensionProvider,
		editorActions,
		FlagProvider,
		extensionsFilter: undefined,
		hideGlassPane: hideGlassPaneForForge,
	});

	const forgeExtensionProvider = fetchForgeExtensionProvider({
		getProviderOptions,
		featureFlags,
		spaceKey,
	});

	return forgeExtensionProvider;
};

export const fetchForgeExtensionProvider = async ({
	getProviderOptions,
	featureFlags,
	spaceKey,
}) => {
	const shouldLoadSpaceDependencies =
		!!featureFlags?.['confluence.frontend.ecosystem.access.narrrowing'];
	const isSpaceAliasFFEnabled = !!featureFlags?.['confluence.frontend.space.alias'];

	if (!shouldLoadSpaceDependencies) {
		return getForgeExtensionProviderNext(getProviderOptions({ spaceKey }));
	}

	return fetchSpaceDetail(spaceKey, isSpaceAliasFFEnabled)
		.then(({ data, loading, error }) => {
			if (!loading) {
				const dataClassificationTags = data?.space?.dataClassificationTags;
				const spaceKeyFromQuery = data?.space?.key;

				return { dataClassificationTags, spaceKeyFromQuery, error };
			}
		})
		.then((data) => {
			if (data?.error) {
				if (isSpaceAliasFFEnabled) {
					return Promise.reject(data.error);
				} else {
					return getForgeExtensionProviderNext(getProviderOptions({ spaceKey }));
				}
			}

			const providerOptions = getProviderOptions({
				spaceKey: isSpaceAliasFFEnabled ? data?.spaceKeyFromQuery : spaceKey,
			});
			const shouldUseNewExtensionsQuery = !!(
				data?.dataClassificationTags && data?.dataClassificationTags.length > 0
			);

			if (shouldUseNewExtensionsQuery) {
				providerOptions.extensionsFilter = [
					{
						type: GQLExtensionContextsFilterType.ExtensionType,
						value: ['xen:macro'],
					},
				];

				providerOptions.dataClassificationTagIds = data?.dataClassificationTags;
			}

			return getForgeExtensionProviderNext(providerOptions);
		});
};
