import React, { useContext } from 'react';

import type { RouteMatch } from '@confluence/route';
import { LoadablePaint } from '@confluence/loadable';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { useCompanyHubPremiumGate } from '@confluence/company-hub/entry-points/useCompanyHubPremiumGate';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { SPAViewContext } from '@confluence/spa-view-context';

const SpacePermissionsSettingsRouteComponent = LoadablePaint({
	loader: async () =>
		import(
			/* webpackChunkName: "loadable-SpacePermissionSettingsRouteComponent" */ './SpacePermissionsSettingsRouteComponent'
		),
});

export const SpacePermissionsSettingsRoute = ({ params: { spaceKey, tab } }: RouteMatch) => {
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip: !isCompanyHub,
	});
	const { isSiteAdmin } = useContext(SPAViewContext);

	return isCompanyHub && isSiteAdmin && shouldShowPremiumUpsell ? (
		<CompanyHomeBuilderUpsellPage featureName="companyHubPermissions" />
	) : (
		<SpacePermissionsSettingsRouteComponent spaceKey={spaceKey} tab={tab} />
	);
};

SpacePermissionsSettingsRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey, tab } }) => ({
	Screen: {
		screenEvent: {
			name:
				tab === 'public-links'
					? 'spacePermissionsPublicLinksScreen'
					: `${tab}SpacePermissionsScreen`,
			id: spaceKey,
		},
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
	},
});
