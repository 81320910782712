import type { StringField } from '@atlaskit/editor-common/extensions';

import { MAX_ATLASSIAN_ALLOWED_FRAGMENT_LENGTH } from '../../../extensionConstants';

import type { FieldTransformer } from './types';

export const anchor: FieldTransformer<any> = {
	transformFields: async (fields) => {
		return fields.map((field) => {
			if (field.name !== 'defaultParameterValue') {
				return field;
			}

			return {
				...field,
				type: 'string',
				isRequired: true,
			} as StringField;
		});
	},
	// Transforming name that user entered into a valid URI. Not a great experience, need to find a way to inform the user and allow them to pick again.
	transformAfter(params) {
		if (params.defaultParameterValue) {
			// Strip spaces off the beginning and end of the name
			params.defaultParameterValue = params.defaultParameterValue.replace(/^\s+/g, '');
			params.defaultParameterValue = params.defaultParameterValue.replace(/\s+$/g, '');

			// Changing spaces to '-' to match header logic
			params.defaultParameterValue = params.defaultParameterValue.replace(/\s+/g, '-');

			// Stripping out quotes for security (VULN-539679)
			params.defaultParameterValue = params.defaultParameterValue.replace(/["']+/g, '');

			params.defaultParameterValue = params.defaultParameterValue.substr(
				0,
				MAX_ATLASSIAN_ALLOWED_FRAGMENT_LENGTH,
			);
		}

		return params;
	},
};
