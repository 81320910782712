import React from 'react';
import type { ApolloClient } from '@apollo/client';

import { AnalyticsListener, type UIAnalyticsEvent } from '@atlaskit/analytics-next';

import { getAnalyticsWebClient } from '@confluence/analytics-web-client';

import { EmbeddedConfluenceSource } from './EmbeddedConfluenceSource';
import { extractContentAttributes } from './extractContentAttributes';

const eventTypes = [
	'sendOperationalEvent',
	'sendPageEvent',
	'sendTrackEvent',
	'sendUIEvent',
	'sendScreenEvent',
];

type ConfluenceAnalyticsListenerProps = {
	children: React.ReactNode;
	apolloClient: ApolloClient<any>;
};

// Checks if content attributes should be added to this analytics events
// If so, also retrieves the contentId used to fetch those attributes
// out of the GraphQL cache.
// Currently, the only attributes added are `type` and `subType`
//
// Returns [shouldAddContentAttributes: boolean, contentId: string];
const getContentAttributesInfo = (event: UIAnalyticsEvent) => {
	if (event?.payload?.type === 'sendOperationalEvent') {
		// Exclude operational events
		return [false, ''];
	} else if (event?.payload?.data?.attributes?.contentId) {
		// Use the contentId attribute if it exists
		return [true, event?.payload?.data?.attributes?.contentId];
	} else if (event?.payload?.data?.objectType === 'page') {
		// Otherwise, use the objectId if the object is a page
		return [true, event?.payload?.data?.objectId];
	} else {
		// Otherwise, exclude this event because we don't have a contentId for it
		return [false, ''];
	}
};

export const ConfluenceAnalyticsListener = ({
	children,
	apolloClient,
}: ConfluenceAnalyticsListenerProps) => {
	const sendAnalyticEvents = (apolloClient: ApolloClient<any>) => (event: UIAnalyticsEvent) => {
		if (!event || !event.payload) {
			throw new Error('Analytics Web Client Payload is required');
		} else if (!eventTypes.includes(event.payload.type)) {
			throw new Error(`Recieved Unknown Type for Analytics Web Client: ${event.payload.type}`);
		}

		let contentAttributes = {};
		const [shouldAddContentAttributes, contentId] = getContentAttributesInfo(event);
		if (apolloClient && shouldAddContentAttributes) {
			contentAttributes = extractContentAttributes(apolloClient, contentId);
		}
		void getAnalyticsWebClient().then((analyticWebClientResponse) => {
			const newData = {
				...event.payload.data,
				attributes: {
					...contentAttributes,
					...event.payload.data.attributes,
				},
			};
			analyticWebClientResponse[event.payload.type](newData);
		});
	};

	return (
		<AnalyticsListener onEvent={sendAnalyticEvents(apolloClient)}>
			<EmbeddedConfluenceSource>{children}</EmbeddedConfluenceSource>
		</AnalyticsListener>
	);
};
