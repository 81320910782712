import React from 'react';
import {
	UI_EVENT_TYPE,
	OPERATIONAL_EVENT_TYPE,
	TRACK_EVENT_TYPE,
} from '@atlaskit/analytics-gas-types';
import {
	type ExtensionManifest,
	type ExtensionAPI,
	type FieldDefinition,
	type ExtensionProvider,
} from '@atlaskit/editor-common/extensions';
import type { EditorActions } from '@atlaskit/editor-core';
import { ForgeExtensionProvider, type SendAnalyticsFunc } from './forgeExtensionProvider';
import { shouldBlockExtensionDueToAppAccessPolicy } from '../render';
import { parse } from '@atlassian/cs-ari';
import { getForgeUIExtensionsAsync } from '@atlassian/forge-ui/provider';
import type { GQLExtensionContextsFilter } from '@atlassian/forge-ui/provider';
import { emit } from '@atlassian/forge-ui/events';

import {
	type AnalyticsWebClient,
	type ForgeUIAnalyticsContext,
	sendEvent,
	extensionIdToAnalyticsAttributes,
} from '@atlassian/forge-ui/analytics';
import { type ForgeDoc, type ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import type ApolloClient from 'apollo-client';
import {
	CONNECT_ADDON_ACTION_SUBJECT,
	CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE,
	EXTENSION_NAMESPACE,
	EXTENSION_TYPE_BODIED,
	MODULE_NAME,
} from '../utils/constants';
import { CONFIG_USER_PICKER_PROVIDER, createSchemaFromAux } from '../config/createConfigSchema';
import {
	type ConnectToForgeParameters,
	type ForgeCreateExtensionProps,
	type ForgeExtension,
	type ForgeExtensionManifest,
	type ForgeExtensionParameters,
} from '../render';
import { createTitleWithEnvironmentInfo } from './getForgeExtensionProvider';
import { createLocalId } from '../utils/createLocalId';
import { extractKeyFromId } from '../utils/extractKeyFromId';
import Icon from './icon';

const manifestIcons = {
	'16': () => import('./icon'),
	'24': () => import('./icon'),
	'48': () => import('./icon'),
};

// We keep track of the reason that an extension failed to upgrade, for analytics purposes
const connectToForgeBodiedExtensions = new Set();

const transformBefore = (parameters: ForgeExtensionParameters) => getConfig(parameters);
const transformAfter = async (parameters: any) => ({ guestParams: parameters });

const getConfig = ({ config, guestParams }: ForgeExtensionParameters) => guestParams || config;

type UpdateADFEntity = Parameters<Parameters<ExtensionAPI['doc']['update']>[1]>[0];

export function upgradeConnectNodeToForge(
	node: UpdateADFEntity,
	extensionManifest: ForgeExtensionManifest,
): UpdateADFEntity {
	const { attrs } = node;
	// Ensure extension hasn't been upgraded since the last check
	if (attrs?.extensionType === CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE) {
		const extensionProps = extensionManifest?.createExtensionProps();
		return {
			...node,
			attrs: {
				...attrs,
				...extensionProps.attrs,
				parameters: {
					...convertConnectParametersToForge(attrs.parameters),
					...extensionProps.attrs.parameters,
				},
			},
		};
	}
	return node;
}

function convertConnectParametersToForge({
	guestParams = {},
	macroParams = {},
	...restParams
}: ConnectToForgeParameters = {}): ConnectToForgeParameters {
	const migratedGuestParams = { ...guestParams };
	Object.entries(macroParams).forEach(([key, obj]) => {
		// Don't override existing entries
		if (typeof migratedGuestParams[key] === 'undefined' && obj && 'value' in obj) {
			migratedGuestParams[key] = obj.value;
		}
	});
	return {
		macroParams,
		guestParams: migratedGuestParams,
		...restParams,
	};
}

interface ForgeExtensionProviderSharedParams {
	accountId?: string;
	apolloClient: ApolloClient<object>;
	cloudId: string;
	contextIds: string[];
	forgeUIAnalyticsContext: ForgeUIAnalyticsContext;
	page: string;
	product: string;
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient> | undefined;
	createRenderFunction: (
		extension?: ForgeUIExtensionType,
	) => Promise<({ node }: { node: ForgeExtension }) => JSX.Element>;
	connectExtensionProvider?: Promise<ExtensionProvider>;
	editorActions?: EditorActions;
	getFieldsDefinitionFunction: (data: ForgeExtensionParameters) => Promise<FieldDefinition[]>;
	extensionsFilter?: GQLExtensionContextsFilter[];
	dataClassificationTagIds?: string[];
}

export async function getForgeExtensionProviderShared({
	accountId,
	apolloClient,
	cloudId,
	contextIds,
	forgeUIAnalyticsContext,
	page,
	product,
	analyticsWebClient,
	createRenderFunction,
	getFieldsDefinitionFunction,
	connectExtensionProvider,
	editorActions,
	extensionsFilter,
	dataClassificationTagIds,
}: ForgeExtensionProviderSharedParams) {
	let extensionProvider: ForgeExtensionProvider;
	const { extensions } = await getForgeUIExtensionsAsync({
		client: apolloClient,
		contextIds,
		moduleType: MODULE_NAME,
		expandAppOwner: true,
		extensionsFilter,
		dataClassificationTagIds,
	});

	const eventSource = editorActions ? 'editPageScreen' : 'viewPageScreen';

	async function upgradeConnectExtensionToForge(
		attrs: Record<string, string>,
		actions: ExtensionAPI<ForgeExtensionParameters>,
	): Promise<UpdateADFEntity> {
		const { localId, extensionKey } = attrs;
		const extensionManifest = await extensionProvider.getConnectToForgeExtension(extensionKey);
		if (!extensionManifest || !extensionManifest.createExtensionProps) {
			throw new Error(
				`Connect to Forge macro upgrade: No ${
					!extensionManifest ? 'extension manifest' : 'createExtensionProps function'
				} found`,
			);
		}

		return new Promise<UpdateADFEntity>((resolve) => {
			actions.doc.update(localId, (currentValue) => {
				const updatedNode = upgradeConnectNodeToForge(currentValue, extensionManifest);
				resolve(updatedNode);
				return updatedNode;
			});
		});
	}

	async function maybeUpgradeConnectExtensionToForge(
		actions?: ExtensionAPI<ForgeExtensionParameters>,
	) {
		// Only passed through when we are in the Editor view
		if (editorActions) {
			const node = editorActions.getSelectedNode();
			const attrs = node?.attrs;
			if (attrs?.extensionType === CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE) {
				const { extensionKey, localId: nodeLocalId } = attrs;
				const macroId = attrs.parameters?.macroMetadata?.macroId?.value;

				if (node?.type.name === EXTENSION_TYPE_BODIED) {
					// Forge doesn't support rich text yet, so we can't upgrade this node to Forge
					connectToForgeBodiedExtensions.add(macroId);
					if (analyticsWebClient) {
						sendEvent(analyticsWebClient)({
							source: eventSource,
							...forgeUIAnalyticsContext,
							eventType: OPERATIONAL_EVENT_TYPE,
							action: 'h11n_macro_upgrade_rich_text_skipped',
							actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
							actionSubjectId: extensionKey,
							attributes: {
								extensionKey,
								nodeLocalId,
							},
						});
					}
					return;
				}

				/* At this point, we know the extension should be upgraded from Connect to Forge
				 * If anything goes wrong after this point, it should be logged as an upgrade failure */
				try {
					if (!actions?.doc?.update) {
						throw new Error('Connect to Forge macro upgrade: Missing ExtensionAPI update action');
					}

					const updatedNode = await upgradeConnectExtensionToForge(attrs, actions);

					if (analyticsWebClient) {
						const { localId, extensionId } = (
							updatedNode.attrs as ForgeCreateExtensionProps['attrs']
						).parameters;
						sendEvent(analyticsWebClient)({
							source: eventSource,
							...forgeUIAnalyticsContext,
							eventType: TRACK_EVENT_TYPE,
							action: 'h11n_macro_upgrade_success',
							actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
							actionSubjectId: extensionKey,
							attributes: {
								extensionKey,
								...extensionIdToAnalyticsAttributes(extensionId),
								localId,
								nodeLocalId,
							},
						});
					}
				} catch (e) {
					if (analyticsWebClient) {
						sendEvent(analyticsWebClient)({
							source: eventSource,
							...forgeUIAnalyticsContext,
							eventType: OPERATIONAL_EVENT_TYPE,
							action: 'h11n_macro_upgrade_failed',
							actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
							actionSubjectId: extensionKey,
							attributes: {
								errorMessage: e instanceof Error ? e.message : String(e),
								extensionKey,
								nodeLocalId,
							},
						});
					}
					throw e;
				}
			}
		}
	}

	const updateFunction = async (
		_: ForgeExtensionParameters,
		actions?: ExtensionAPI<ForgeExtensionParameters>,
	): Promise<ForgeExtensionParameters | void> => {
		maybeUpgradeConnectExtensionToForge(actions);
		return actions!.editInContextPanel(transformBefore, transformAfter);
	};

	const sendConnectToForgeResolveAnalytics: SendAnalyticsFunc = (extensionKey, errorMessage) => {
		if (analyticsWebClient) {
			sendEvent(analyticsWebClient)({
				source: eventSource,
				...forgeUIAnalyticsContext,
				eventType: OPERATIONAL_EVENT_TYPE,
				action: 'h11n_macro_resolve_failed',
				actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
				actionSubjectId: extensionKey,
				attributes: {
					errorMessage,
					extensionKey,
				},
			});
		}
	};

	const fieldsModules: ExtensionManifest<ForgeExtensionParameters>['modules']['fields'] = {
		user: {
			[CONFIG_USER_PICKER_PROVIDER]: {
				provider: async () => ({
					siteId: cloudId,
					// If principalId === 'Context', upstream attempts to use other context to discover the principal ID
					principalId: accountId || 'Context',
					fieldId: 'forgeConfigUserPicker',
					productKey: product as 'confluence',
				}),
			},
		},
	};

	const extensionManifests: ForgeExtensionManifest[] = (extensions || [])
		.filter(({ id }) => parse(id).resourceId !== undefined)
		.map((extension) => {
			const { id, properties, environmentType, migrationKey, environmentKey } = extension;
			const title = createTitleWithEnvironmentInfo(
				properties.title || 'Forge UI Macro',
				environmentType,
				environmentKey,
			);

			const extensionIdWithoutPrefix = parse(id).resourceId!;
			const isCustomUI = !!properties.resource && properties.render !== 'native';
			const isUiKitTwo = extension.properties.render === 'native';

			const hasUiKitOneConfig = !!properties.config && !!properties.config.function;
			const hasConfig = isUiKitTwo || isCustomUI ? !!properties.config : hasUiKitOneConfig;

			const connectModuleKey = extractKeyFromId(extensionIdWithoutPrefix);
			const createExtensionProps = () => {
				const localId = createLocalId();
				const extensionId = id;
				const type =
					properties.layout === 'inline' && !isCustomUI ? 'inlineExtension' : 'extension';
				return {
					type: type,
					attrs: {
						extensionKey: extensionIdWithoutPrefix,
						extensionType: EXTENSION_NAMESPACE,
						parameters: {
							localId,
							extensionId,
							extensionTitle: title,
						},
						text: title,
					},
				};
			};

			const uiKitTwoGetFieldsDefinitionFunction = async (parameters: ForgeExtensionParameters) => {
				const { extensionId, localId } = parameters;
				const config = getConfig(parameters);

				const id = `${extensionId}-${localId}`;

				const getForgeDoc = new Promise<ForgeDoc>((resolve, reject) => {
					emit(`GET_CONFIG_FORGE_DOC_${id}`, { resolve, reject, config });
				});

				try {
					const forgeDoc = await getForgeDoc;
					return createSchemaFromAux(forgeDoc);
				} catch (error: unknown) {
					const { name, message } = error as Error;

					analyticsWebClient &&
						sendEvent(analyticsWebClient)({
							eventType: UI_EVENT_TYPE,
							action: 'errored',
							actionSubject: 'forgeUIExtension',
							actionSubjectId: 'editorMacro',
							source: page,
							attributes: {
								errorName: name,
								errorMessage: message,
							},
						});
					throw new Error(message || '');
				}
			};

			const connectToForgeGetFieldsDefinitionFunction = async (
				parameters: ForgeExtensionParameters,
			) => {
				if (!parameters.extensionId) {
					/* If extensionId is missing, then we are dealing with a Connect macro instead of Forge
					 * This can happen when reverting a page with an upgraded Connect -> Forge addon back to Connect,
					 * or if the Connect macro wasn't upgraded for some reason (eg. bodiedExtension)
					 * We can't edit/store the config in this state, so safer to just prevent panel loading
					 */
					const macroId = parameters.macroMetadata?.macroId?.value;
					const isBodied = connectToForgeBodiedExtensions.has(macroId);
					let userMessage = 'This macro has been reverted. Please close this panel and try again.';
					let errorMessage = 'Macro reverted';

					if (isBodied) {
						userMessage = 'Editing rich text macros is not currently supported.';
						errorMessage = 'Editing rich text macro blocked';
					}

					if (analyticsWebClient) {
						sendEvent(analyticsWebClient)({
							source: eventSource,
							...forgeUIAnalyticsContext,
							eventType: OPERATIONAL_EVENT_TYPE,
							action: 'h11n_macro_config_error',
							actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
							actionSubjectId: parameters.macroMetadata?.title,
							attributes: {
								errorMessage,
								macroId,
							},
						});
					}
					throw new Error(userMessage);
				}
				return (isCustomUI || isUiKitTwo) && !hasUiKitOneConfig
					? uiKitTwoGetFieldsDefinitionFunction(parameters)
					: getFieldsDefinitionFunction(parameters);
			};

			const icon = <Icon label={properties?.title ?? ''} imgUrl={properties.icon} />;
			const macroIcons =
				properties.icon && properties.icon.length > 0
					? {
							'16': () => Promise.resolve(() => icon),
							'24': () => Promise.resolve(() => icon),
							'48': () => Promise.resolve(() => icon),
						}
					: manifestIcons;

			return {
				title,
				description: properties.description,
				type: EXTENSION_NAMESPACE,
				key: extensionIdWithoutPrefix,
				icons: macroIcons,
				categories: properties.categories,
				connectModuleKey,
				migrationKey,
				createExtensionProps,
				modules: {
					...(shouldBlockExtensionDueToAppAccessPolicy(extension)
						? {}
						: {
								quickInsert: [
									{
										key: 'forge-macro',
										action: async () => {
											const extensionProps = createExtensionProps();
											const { localId, extensionId } = extensionProps.attrs.parameters;

											if (analyticsWebClient) {
												sendEvent(analyticsWebClient)({
													eventType: UI_EVENT_TYPE,
													action: 'inserted',
													actionSubject: 'forgeUIExtension',
													actionSubjectId: 'editorMacro',
													source: 'editPageScreen',
													...forgeUIAnalyticsContext,
													attributes: {
														localId,
														...extensionIdToAnalyticsAttributes(extensionId),
													},
												});
											}

											return extensionProps;
										},
									},
								],
							}),
					nodes: {
						default: {
							type: 'extension',
							render: () => createRenderFunction(extension),
							update: hasConfig ? updateFunction : undefined,
							getFieldsDefinition: connectToForgeGetFieldsDefinitionFunction,
						},
					},
					fields: fieldsModules,
				},
			};
		});

	const legacyMacroManifest: ExtensionManifest<ForgeExtensionParameters> = {
		/**
		 * title, description and icons are required but will not be used anywhere,
		 * since this manifest is used solely for rendering legacy nodes
		 **/
		title: 'Forge UI Macro',
		description: 'Supports existing Forge UI Macro nodes',
		type: EXTENSION_NAMESPACE,
		key: 'xen:macro',
		icons: manifestIcons,
		modules: {
			nodes: {
				default: {
					type: 'extension',
					render: createRenderFunction,
					update: updateFunction,
					getFieldsDefinition: getFieldsDefinitionFunction,
				},
			},
			fields: fieldsModules,
		},
	};

	const manifests = [legacyMacroManifest, ...extensionManifests];
	extensionProvider = new ForgeExtensionProvider(
		manifests,
		connectExtensionProvider,
		sendConnectToForgeResolveAnalytics,
	);
	return extensionProvider;
}
