import { useQuery } from '@apollo/react-hooks';

import type { Extension } from '@confluence/macro-tracker';
import {
	canUseMultiMacroQuery,
	getMacroQueryVariables,
	MacroBodyRendererQueryWithTags,
	MacroContentRendererQueryWithTags,
	useMultiMacroQuery,
} from '@confluence/fabric-extension-queries';

export const FF_EDITOR_MACRO_ADF_RENDERING =
	'confluence.frontend.fabric.editor.macro.adf.frontend.render';

export const useIndividualMacroContentData = (
	contentId: string,
	node: Extension,
	featureFlags: any,
) => {
	const { isMacroContentAndBody, ...rest } = getMacroQueryVariables({
		node,
		contentId,
		featureFlags,
	});

	const MacroQuery = isMacroContentAndBody
		? MacroBodyRendererQueryWithTags
		: MacroContentRendererQueryWithTags;

	const { data } = useQuery(MacroQuery, {
		fetchPolicy: 'cache-only',
		variables: rest,
	});

	return data;
};

export const useMultiMacroContentData = (contentId: string, node: Extension) => {
	const macroId = node.parameters?.macroMetadata?.macroId?.value;
	const { renderedMacro } = useMultiMacroQuery({ contentId, macroId });

	return {
		macroBodyRenderer: renderedMacro,
		representation: 'view',
		mediaToken: renderedMacro?.mediaToken,
		webResourceDependencies: renderedMacro?.webResource,
		macroBodyStorageFormat: { value: renderedMacro?.macroBodyStorage },
	};
};

export const useMacroContentData = (contentId: string, node: any, featureFlags: any) => {
	return canUseMultiMacroQuery(node.extensionKey, featureFlags)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useMultiMacroContentData(contentId, node)
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useIndividualMacroContentData(contentId, node, featureFlags);
};
