import { fg } from '@atlaskit/platform-feature-flags';

import { getBitValueFromBool } from '../../../common/utils/helpers';
import { V001_SCHEMA_KEY, V002_SCHEMA_KEY, V003_SCHEMA_KEY } from '../../../constants';
import type {
	ConsentHubResponse,
	ConsentToken,
	PrefetchedConsentCookieData,
	PrefetchedConsentPreferences,
} from '../../../services/consent-hub-service/types';
import {
	checkIfTokenIsDefault,
	serializeConsents,
} from '../../../services/consent-hub-service/utils';
import {
	type ConsentPreference,
	type ConsentPreferenceV001,
	type ConsentPreferenceV002,
	type ConsentPreferenceV003,
	ConsentPreferenceV2,
	ContextualConsentProperty,
	PreferenceCategory,
	PrefetchedPreferenceState,
} from '../../../types';

export const checkTokenVersionValidity = (consentToken: string) => {
	// A token can technically be bad data (alphanumeric instead of binary) but we should fail gracefully if so
	if (consentToken.length < 3) {
		// Wrong format, no version
		return { isValid: false, isV001: false, isV002: false, isV003: false };
	}

	const version = consentToken[0] + consentToken[1] + consentToken[2];

	const isV001 = version === V001_SCHEMA_KEY && consentToken.length === 7;
	const isV002 = version === V002_SCHEMA_KEY && consentToken.length === 12;
	const isV003 = version === V003_SCHEMA_KEY && consentToken.length === 10;

	// We support version 001, 002, 003 only
	const isSupportedVersion = [V001_SCHEMA_KEY, V002_SCHEMA_KEY, V003_SCHEMA_KEY].includes(version);
	const isValid = isSupportedVersion && (isV001 || isV002 || isV003);

	return { isValid, isV001, isV002, isV003 };
};

/* Schema Version 001:
---------------------------------------------------
  - Format 0000000
  - 001 (schema version 3 digits)
  - 0/1 (1 digit, FUNCTIONAL cookies disabled/enabled)
  - 0/1 (1 digit, ANALYTICS cookies disabled/enabled)
  - 0/1 (1 digit, MARKETING cookies disabled/enabled)
  - 0/1 (1 digit, UNKNOWN cookies disabled/enabled)
*/

const packPrefsVersion001 = (userPreferences: ConsentPreferenceV001): ConsentToken => {
	const version = V001_SCHEMA_KEY;
	return [
		version,
		getBitValueFromBool(userPreferences.FUNCTIONAL),
		getBitValueFromBool(userPreferences.ANALYTICS),
		getBitValueFromBool(userPreferences.MARKETING),
		getBitValueFromBool(userPreferences.UNKNOWN),
	].join('');
};

const unpackPrefsVersion001 = (
	userPreferencesCookie: string,
): ConsentPreferenceV001 | undefined => {
	if (userPreferencesCookie.length !== 7) {
		// Wrong length for version 001
		return;
	}

	return {
		[PreferenceCategory.StrictlyNecessary]: true, // always allowed
		[PreferenceCategory.Functional]: userPreferencesCookie[3] === '1',
		[PreferenceCategory.Analytics]: userPreferencesCookie[4] === '1',
		[PreferenceCategory.Marketing]: userPreferencesCookie[5] === '1',
		[PreferenceCategory.Unknown]: userPreferencesCookie[6] === '1',
	};
};

/* Schema Version 002 ***** DEPRECATED *****
---------------------------------------------------
  - Format 000000000000
  - 002 (schema version, 3 digits)
  - 0/1 (1 digit, FUNCTIONAL cookies - disabled/enabled)
  - 0/1 (1 digit, ANALYTICS cookies - disabled/enabled)
  - 0/1 (1 digit, MARKETING cookies - disabled/enabled)
  - 0/1 (1 digit, UNKNOWN cookies - disabled/enabled)
  - 0/1 (1 digit, consent records are unavailable from ConsentHub (logged out) - yes/no)
  - 0/1 (1 digit, consents saved while in GDPR locale (Europe) - yes/no)
  - 0/1 (1 digit, consents saved while in CPRA locale (CPRA will act as 'all USA' bucket) - yes/no)
  - 0/1 (1 digit, consents saved while in LGPD locale (Brazil) - yes/no)
  - 0/1 (1 digit, consents saved while in FADP locale (Switzerland) - yes/no)
*/
const packPrefsVersion002 = (userPreferences: ConsentPreferenceV002): ConsentToken => {
	// As we want to slowly kill off any V002 preferences, if we're provided one for some reason
	// internally convert it to a V003 preference to drop contextual GeoIP details
	const version = V003_SCHEMA_KEY;

	const hasConsentedPreviously =
		userPreferences[ConsentPreferenceV2.ConsentedUnderGDPR] ||
		userPreferences[ConsentPreferenceV2.ConsentedUnderCPRA] ||
		userPreferences[ConsentPreferenceV2.ConsentedUnderLGPD] ||
		userPreferences[ConsentPreferenceV2.ConsentedUnderFADP];

	const isDefaultConsentBit = !hasConsentedPreviously;
	// As we've broken out authentication insights/context from "ConsentDataUnavailable" bit in V3,
	// and it does not exist, we will backfill the value based on the previous bit state
	const existingConsentDataUnavailableBit =
		userPreferences[ContextualConsentProperty.ConsentDataUnavailable];

	const userIsAuthenticatedBit = !existingConsentDataUnavailableBit;

	return [
		version,
		getBitValueFromBool(userPreferences[PreferenceCategory.Functional]),
		getBitValueFromBool(userPreferences[PreferenceCategory.Analytics]),
		getBitValueFromBool(userPreferences[PreferenceCategory.Marketing]),
		getBitValueFromBool(userPreferences[PreferenceCategory.Unknown]),
		getBitValueFromBool(isDefaultConsentBit),
		getBitValueFromBool(userIsAuthenticatedBit),
		getBitValueFromBool(existingConsentDataUnavailableBit),
	].join('');
};

const unpackPrefsVersion002 = (userPreferencesCookie: string): ConsentPreferenceV003 => {
	// If the provided V002 token has consented anywhere, we'll drop the contextual GeoIP bits and
	// convert it to a V003 schema
	const isADefaultConsentToken = checkIfTokenIsDefault(userPreferencesCookie);

	// Backfill the value based on the previous "ConsentDataUnavailable" bit state as we've broken this contextual info out
	const consentDataUnavailable = userPreferencesCookie[7] === '1';
	const userIsDeemedAuthenticated = !consentDataUnavailable;

	return {
		...unpackPrefsVersion003(userPreferencesCookie),
		[ContextualConsentProperty.ConsentsAreDefault]: !!isADefaultConsentToken,
		[ContextualConsentProperty.UserIsAuthenticated]: userIsDeemedAuthenticated,
		[ContextualConsentProperty.ConsentDataUnavailable]: consentDataUnavailable,
	};
};

/* Schema Version 003
---------------------------------------------------
  - Format 000000000
  - 003 (schema version, 3 digits)
  - 0/1 (1 digit, FUNCTIONAL cookies - disabled/enabled)
  - 0/1 (1 digit, ANALYTICS cookies - disabled/enabled)
  - 0/1 (1 digit, MARKETING cookies - disabled/enabled)
  - 0/1 (1 digit, UNKNOWN cookies - disabled/enabled)
  - 0/1 (1 digit, Consent token in a default state, user has yet to consent) - no/yes)
  - 0/1 (1 digit, User is authenticated, did not 401 during save) - no/yes)
  - 0/1 (1 digit, ConsentHub records are unavailable (CH is down, errored while saving, etc) - no/yes)
*/
const packPrefsVersion003 = (userPreferences: ConsentPreferenceV003): string => {
	const version = V003_SCHEMA_KEY;
	return [
		version,
		getBitValueFromBool(userPreferences[PreferenceCategory.Functional]),
		getBitValueFromBool(userPreferences[PreferenceCategory.Analytics]),
		getBitValueFromBool(userPreferences[PreferenceCategory.Marketing]),
		getBitValueFromBool(userPreferences[PreferenceCategory.Unknown]),
		getBitValueFromBool(userPreferences[ContextualConsentProperty.ConsentsAreDefault]),
		getBitValueFromBool(userPreferences[ContextualConsentProperty.UserIsAuthenticated]),
		getBitValueFromBool(userPreferences[ContextualConsentProperty.ConsentDataUnavailable]),
	].join('');
};

const unpackPrefsVersion003 = (userPreferencesCookie: string): ConsentPreferenceV003 => {
	return {
		[PreferenceCategory.StrictlyNecessary]: true, // always allowed
		[PreferenceCategory.Functional]: userPreferencesCookie[3] === '1',
		[PreferenceCategory.Analytics]: userPreferencesCookie[4] === '1',
		[PreferenceCategory.Marketing]: userPreferencesCookie[5] === '1',
		[PreferenceCategory.Unknown]: userPreferencesCookie[6] === '1',
		[ContextualConsentProperty.ConsentsAreDefault]: userPreferencesCookie[7] === '1',
		[ContextualConsentProperty.UserIsAuthenticated]: userPreferencesCookie[8] === '1',
		[ContextualConsentProperty.ConsentDataUnavailable]: userPreferencesCookie[9] === '1',
	};
};

const V002SpecificBitKeys = [
	ContextualConsentProperty.ConsentDataUnavailable,
	ConsentPreferenceV2.ConsentedUnderGDPR,
	ConsentPreferenceV2.ConsentedUnderCPRA,
	ConsentPreferenceV2.ConsentedUnderLGPD,
	ConsentPreferenceV2.ConsentedUnderFADP,
];

const V003SpecificBitKeys = [
	ContextualConsentProperty.ConsentsAreDefault,
	ContextualConsentProperty.UserIsAuthenticated,
	ContextualConsentProperty.ConsentDataUnavailable,
];

export function packUserPreferences(userPreferences: ConsentPreference): string {
	if (!fg('platform.moonjelly.browser-storage-controls-v2')) {
		return packPrefsVersion001(userPreferences as ConsentPreferenceV001);
	}

	const isV002 = V002SpecificBitKeys.every((key) => key in userPreferences);
	if (isV002) {
		// As V002 is deprecated, if we've been provided V002 set of "legacy" prefs, internally
		// convert them to V003 to drop the unnecessary contextual details
		return packPrefsVersion002(userPreferences as ConsentPreferenceV002);
	}

	const isV003 = V003SpecificBitKeys.every((key) => key in userPreferences);
	if (isV003) {
		return packPrefsVersion003(userPreferences as ConsentPreferenceV003);
	}
	// Fallback to V001
	return packPrefsVersion001(userPreferences as ConsentPreferenceV001);
}

export function unpackUserPreferencesCookie(
	userPreferencesCookie: string,
): ConsentPreference | undefined {
	if (!fg('platform.moonjelly.browser-storage-controls-v2')) {
		if (userPreferencesCookie.length < 3) {
			// Wrong format, no version
			return;
		}

		const version = userPreferencesCookie[0] + userPreferencesCookie[1] + userPreferencesCookie[2];

		if (version === V001_SCHEMA_KEY) {
			return unpackPrefsVersion001(userPreferencesCookie);
		}
		return;
	}

	const { isValid, isV001, isV002, isV003 } = checkTokenVersionValidity(userPreferencesCookie);

	if (!isValid) {
		// We support version 001, 002, 003 only
		return;
	}

	if (isV001) {
		return unpackPrefsVersion001(userPreferencesCookie);
	}

	if (isV002) {
		// Deprecated, so this will unpack to 003 to drop the contextual GeoIP bits
		return unpackPrefsVersion002(userPreferencesCookie);
	}

	if (isV003) {
		return unpackPrefsVersion003(userPreferencesCookie);
	}
}

export const initialPreferencesAreMissing = (initialPreferences?: PrefetchedConsentPreferences) => {
	if (!initialPreferences) {
		return true;
	}
	const prefExistButAreEmpty = Object.keys(initialPreferences).length === 0;
	return prefExistButAreEmpty;
};

// The goal here is to transform the initialPreferences into a format we can consume
// and store values in the correct cookies if necessary
// If at anytime, something fails during the process, set to INTERNAL so we can refetch
export const handleInitialPreferencesData = (
	initialPreferences?: PrefetchedConsentPreferences,
): ConsentPreference | PrefetchedPreferenceState => {
	if (initialPreferences !== PrefetchedPreferenceState.INTERNAL) {
		if (initialPreferencesAreMissing(initialPreferences)) {
			// Failsafe handling: Consumer failed to provide data even though they have prefetched, so we need to fetch again
			// This may occur if a consumer's prefetch requests fails, or if they fail to implement the system correctly
			return PrefetchedPreferenceState.INTERNAL;
		}
		// If initialPreferences is not INTERNAL on first render, we have been provided a pre-fetched
		// value, which means we don't need to make a fetch to storage or ConsentHub to get it.
		// If that preference is defined, we persist it to our local cookie set

		// If the data is a ConsentHubResponse/PrefetchedConsentCookieData, we'll need to transform it properly before consumption
		const consentToken = (initialPreferences as PrefetchedConsentCookieData)?.consentToken;

		if (consentToken) {
			// We already have a consent cookie that was passed in, let's not override it by setting it again
			const unpackedExistingPrefs = unpackUserPreferencesCookie(consentToken);
			return unpackedExistingPrefs ?? PrefetchedPreferenceState.INTERNAL;
		}
		const serializedPrefetchedConsentToken = serializeConsents(
			initialPreferences as ConsentHubResponse,
		);
		const unpackedPrefetchedPrefs = unpackUserPreferencesCookie(serializedPrefetchedConsentToken);

		return unpackedPrefetchedPrefs || PrefetchedPreferenceState.INTERNAL;
	}
	// If we've fallen into this, EMPTY implies we have no data at all
	// INTERNAL implies we still need to fetch new data, so trigger a refetch
	return initialPreferences;
};
