import React from 'react';

import type { ExtensionParams } from '@atlaskit/editor-common/extensions';

import { ExtensionKeys } from '../../../actions/types';
import { findActionItemsConfigItem } from '../../../prebuilt/config-items-empty/find-action-items/find-action-items';
import { AIPanelRenderer } from '../ai-panel-renderer/AIPanelRenderer';
import type { AIPanelProps } from '../ai-panel/AIPanel';
import { AIPanel } from '../ai-panel/AIPanel';
import type { AIManifest, AIPanelParameters } from '../types';

type Props = {
	node: ExtensionParams<AIPanelParameters>;
	api: AIManifest['api'];
	intl: AIManifest['intl'];
};

export const AIActionItemsExtension = ({ node, api, intl }: Props) => {
	// If there is no API and there is a status, we should render the AIPanelRenderer
	// As we are in the confluence renderer
	if (!api && node.parameters?.macroMetadata?.status) {
		return <AIPanelRenderer node={node} />;
	}

	if (!node.localId || !api || !api.aiExperience) {
		return null;
	}

	const context = api.aiExperience.actions.getInitialAIPanelContext({
		intl,
		configItem: findActionItemsConfigItem,
		intentSchemaId: 'action_items_intent_schema.json',
	});

	if (!context || !context.schema || !context.providerFactory) {
		return null;
	}

	const updateContent: AIPanelProps['updateParams'] = (callback) => {
		if (!node.localId || !api.extension) {
			return;
		}
		const extensionAPI = api.extension.actions.api();
		extensionAPI.doc.update(node.localId, callback, { addToHistory: false });
	};

	const findActionItems = (callback: (markdown: string, status: string) => void) => {
		if (!api.aiExperience) {
			return;
		}

		api.aiExperience.actions.findActionItems(callback, intl, context.initialContext);
	};

	return (
		<AIPanel
			api={api}
			localId={node.localId}
			action={findActionItems}
			schema={context.schema}
			providerFactory={context.providerFactory}
			updateParams={updateContent}
			configItem={findActionItemsConfigItem}
			extensionKey={ExtensionKeys.AIActionItemsBodiedExtension}
			convertExtension={api.aiExperience.actions.convertExtension}
			idMap={context.idMap}
		/>
	);
};
