import { query } from '@confluence/query-preloader-tools';
import { themeSSRManager } from '@confluence/ssr-utilities';
import { getEffectiveThemeState } from '@confluence/theming-utils';

import { UserPreferencesForSSRQuery } from './UserPreferencesForSSRQuery.graphql';
import type {
	UserPreferencesForSSRQuery as UserPreferencesForSSRQueryType,
	UserPreferencesForSSRQueryVariables as UserPreferencesForSSRQueryVariablesType,
} from './__types__/UserPreferencesForSSRQuery';

export const API_DEFAULT_THEME_STATE = 'default';

export const preloadUserPreferencesForSSR = async ({
	spaceKey = '',
	isLicensed,
	themeState,
}: {
	spaceKey?: string;
	isLicensed: boolean;
	themeState?: string;
}) => {
	if (themeState) {
		themeSSRManager.setThemeStateBasedOnSavedThemeState(
			getEffectiveThemeState(decodeURIComponent(themeState)),
		);
	}

	if (!isLicensed) {
		return Promise.resolve(null);
	}

	const response = await query<
		UserPreferencesForSSRQueryType,
		UserPreferencesForSSRQueryVariablesType
	>({
		query: UserPreferencesForSSRQuery,
		variables: { spaceKey, hasSpaceKey: Boolean(spaceKey) },
	});

	const theme = response?.data?.userPreferences?.theme;
	// Setting colorMode for user theme
	if (theme) {
		themeSSRManager.setThemeStateBasedOnSavedThemeState(getEffectiveThemeState(theme));
	}

	return response;
};
