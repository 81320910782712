import { constructCompatibleRequestInit } from '@confluence/network/entry-points/constructCompatibleRequestInit';
import { SSRMeasures } from '@confluence/action-measures';
import { getMonitoringClient } from '@confluence/monitoring';
import { cfetch } from '@confluence/network';

import { SessionDataQueryRaw } from './SessionDataQueryRaw';
import type { SessionDataType } from './SessionDataTypes';
import type { SessionDataQuery as SessionDataQueryType } from './__types__/SessionDataQuery';

declare global {
	interface Window {
		USE_MOCKED_SESSION_DATA?: boolean;
		MOCK_SESSION_DATA_RESOLVE: (data: any) => void;
		__SESSION_DATA_PROMISE__: Promise<SessionDataType> | null;
		__SESSION_DATA__: Promise<SessionDataQueryType> | SessionDataQueryType | Promise<null> | null;
	}
}

/**
 * Apollo is too slow normalizing all the feature flags.
 * Getting session data using pure fetch
 */
export async function loadSessionData(): Promise<SessionDataQueryType | null> {
	if (process.env.NODE_ENV === 'testing' || window.USE_MOCKED_SESSION_DATA) {
		return new Promise((resolve) => {
			window['MOCK_SESSION_DATA_RESOLVE'] = resolve;
		});
	}

	// __SESSION_DATA__ is either actual session data
	// or the promise that is resolving the session data
	// In the case when page is rendered by SSR, the __SESSION_DATA__ will be populated with JSON data.
	if (window['__SESSION_DATA__']) {
		return window['__SESSION_DATA__'];
	}

	const URI = window['GLOBAL_APOLLO_CLIENT_URI'] || '/cgraphql';
	return (window['__SESSION_DATA__'] = SSRMeasures.run(
		'ssr-app/prepare/preloadQuery/fetch:SessionDataQuery',
		async () => {
			let result = null;
			try {
				const res = await cfetch(
					`${URI}?q=SessionDataQuery`,
					constructCompatibleRequestInit({
						method: 'POST',
						credentials: 'include',
						referrerPolicy: 'same-origin',
						headers: {
							'X-APOLLO-OPERATION-NAME': 'SessionDataQuery',
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							query: SessionDataQueryRaw,
						}),
					}),
				);
				if (!res.ok) {
					throw new Error(`Cannot load session data: ${res.statusText}`);
				}
				result = (await res.json()).data;
			} catch (e) {
				getMonitoringClient().submitError(e, { attribution: 'FATAL' });
			}
			return (window['__SESSION_DATA__'] = result);
		},
	));
}
