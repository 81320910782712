import { fg } from '@atlaskit/platform-feature-flags';

import type { ContextList } from '../types';

/**
 * When;
 *
 * - the full content feature flag is not turned on
 *   - the relationship is stripped
 * - the markdown plus feature flag is not turned on
 *   - the type is overridden to text
 *
 * While this code is not able to be automatically cleaned up by codemods (the codemods will leave an empty map)
 * it does get us most of the way, and makes consumers of this code not have to worry about the feature flags.
 */
export function runContextListAgainstFFs(contextList: ContextList) {
	const transformedContextList = contextList.map((contextEntry) => {
		if (!fg('platform.editor.ai-send-full-content_r1qpp')) {
			// This is sent as json -- so will be stripped away by setting to undefined
			if ('relationship' in contextEntry) {
				contextEntry.relationship = undefined;
			}
		}

		return contextEntry;
	});

	return transformedContextList;
}
