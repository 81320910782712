import { type ForgeDoc } from '@atlassian/forge-ui-types';
import React from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { fg } from '@atlaskit/platform-feature-flags';

import { FORGE_UI_ANALYTICS_CHANNEL } from '../analytics';
import { type Extension } from '../web-client';
import { trackComponentUsageRendered } from '../analytics/useForgeUiAnalyticsEvent';
import { useMetricsContext } from '../context/metrics';

type ComponentName = string;
type PropName = string;
type Counts = {
	componentCount: number;
	propCount: Record<PropName, number>;
};
type CountAnalytics = Record<ComponentName, Counts>;

// Gets the count of props on a component
export const getPropCount = (
	componentCountMap: CountAnalytics,
	componentName: string,
	props: Record<string, any>,
) => {
	const propsArray = Object.keys(props);
	propsArray.map((propName) => {
		if (propName === 'children') {
			return;
		}
		if (componentCountMap[componentName].propCount[propName] !== undefined) {
			componentCountMap[componentName].propCount[propName] += 1;
		} else {
			componentCountMap[componentName].propCount[propName] = 1;
		}
	});
};

// Recursive function to get the total number of components and total number of props on a component
const getCountAnalytics = (forgeDoc: ForgeDoc, componentCountMap: CountAnalytics = {}) => {
	const componentName = forgeDoc.type;
	// Type String is not a component
	if (componentName === 'String') {
		return componentCountMap;
	}
	// Don't send Root count as it's always one
	if (componentName !== 'Root') {
		const hasComponentEncountered = componentCountMap[componentName] !== undefined;
		if (hasComponentEncountered) {
			componentCountMap[componentName].componentCount += 1;
		} else {
			componentCountMap[componentName] = { componentCount: 1, propCount: {} };
		}
		if (forgeDoc.props !== undefined) {
			getPropCount(componentCountMap, componentName, forgeDoc.props);
		}
	}

	forgeDoc.children.forEach((forgeDocChild) => {
		getCountAnalytics(forgeDocChild, componentCountMap);
	});

	return componentCountMap;
};

const createKey = (countMap: CountAnalytics) => {
	let key = '';
	Object.keys(countMap)
		.sort()
		.forEach((componentName) => {
			key += `${componentName}${countMap[componentName].componentCount}`;
		});
	return key;
};

interface Args {
	forgeDoc: ForgeDoc | undefined;
	forgeEnvironment: Extension['environmentType'];
	UIKitVersion: '1' | '2';
}

// Gets the total number of components and props to send for analytics
// Only sends when there is a new count of components that has not been sent before
export const useSendNodeCountAnalytics = ({ forgeDoc, forgeEnvironment, UIKitVersion }: Args) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { page: source } = useMetricsContext();

	const prevCountMap = React.useRef<Record<string, true>>({});

	if (forgeDoc === undefined) {
		return;
	}

	const componentCount = getCountAnalytics(forgeDoc);
	const componentCountAsKey = createKey(componentCount);

	// converting to a string here as a safety measure against mutations to the original forgeDoc object
	const forgeReactMajorVersion = forgeDoc.forgeReactMajorVersion?.toString() ?? null;

	// compare if a previous count has already been sent for analytics
	if (!prevCountMap.current.hasOwnProperty(componentCountAsKey)) {
		prevCountMap.current = {
			...prevCountMap.current,
			[componentCountAsKey]: true,
		};

		// NOTE: createAnaltyicsEvent has a side effect of potentially mutating objects that are passed into it
		// Any objects passed in should not be reused or must be deep cloned first

		if (fg('platform.forge-ui.use-new-event-schema')) {
			// Rename field "componentCount" to "count". Once we've switched over to the new schema,
			// I'll then update getCountAnalytics() to use "count" instead of "componentCount".
			const components: Record<ComponentName, { count: number; propCount: Counts['propCount'] }> =
				{};
			for (let componentName in componentCount) {
				components[componentName] = {
					count: componentCount[componentName].componentCount,
					propCount: componentCount[componentName].propCount,
				};
			}

			trackComponentUsageRendered(createAnalyticsEvent, {
				source,
				isLegacyUiKit: UIKitVersion === '1',
				forgeEnvironment,
				components,
				forgeReactMajorVersion,
			});
		}

		// TODO: remove this event once we have switched over to the new event schema
		// https://ecosystem-platform.atlassian.net/browse/EXT-2196
		createAnalyticsEvent({
			eventType: UI_EVENT_TYPE,
			data: {
				action: 'rendered',
				actionSubject: `uiKit${UIKitVersion}App`,
				attributes: {
					forgeEnvironment,
					componentCount,
					forgeReactMajorVersion,
				},
			},
		}).fire(FORGE_UI_ANALYTICS_CHANNEL);
	}
};
