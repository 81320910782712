import React, { Fragment, useCallback } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import PersonIcon from '@atlaskit/icon/glyph/person';

import { ToggleField } from '../../../shared-components';
import { CardType } from '../../linkCardsTypes';
import { CONDITIONAL_FIELDS } from '../linkCardsConfigPanelTypes';
import type { UpdateMainFormFieldType } from '../linkCardsConfigPanelTypes';

export const DEFAULT_CARDS_TYPE = CardType.TEXT;

const { IS_AVATAR_SHOWN, IS_PUBLISH_DATE_SHOWN } = CONDITIONAL_FIELDS;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ToggleFieldFlexContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ToggleWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.200', '8px'),
});

const i18n = defineMessages({
	publishDateToggleLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.type-field.toggle.publish-date.label',
		defaultMessage: 'Show last update',
		description:
			'A visible label for the toggle to display/hide publish date for type text based link card',
	},
	avatarToggleLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.type-field.toggle.avatar.label',
		defaultMessage: 'Show owner',
		description:
			'A visible label for the toggle to display/hide avatar for type text based link card',
	},
});

type CardConfigPanelToggleProps = {
	typeParameter: CardType;
	updateMainFormField: UpdateMainFormFieldType;
	isAvatarShownParameter: boolean;
	isPublishDateShownParameter: boolean;
};

export const CardConfigPanelToggleField: React.FC<CardConfigPanelToggleProps> = ({
	updateMainFormField,
	typeParameter,
	isAvatarShownParameter,
	isPublishDateShownParameter,
}) => {
	const intl = useIntl();

	const handleAvatarToggleChange = useCallback(() => {
		updateMainFormField({
			[IS_AVATAR_SHOWN]: !isAvatarShownParameter,
		});
	}, [isAvatarShownParameter, updateMainFormField]);

	const handlePublishDateToggleChange = useCallback(() => {
		updateMainFormField({
			[IS_PUBLISH_DATE_SHOWN]: !isPublishDateShownParameter,
		});
	}, [isPublishDateShownParameter, updateMainFormField]);

	return (
		<ToggleFieldFlexContainer>
			<ToggleWrapper>
				{typeParameter === CardType.TEXT ? (
					<Fragment>
						<ToggleField
							label={intl.formatMessage(i18n.publishDateToggleLabel)}
							Icon={CalendarIcon}
							isChecked={isPublishDateShownParameter}
							onChange={handlePublishDateToggleChange}
						/>
						<ToggleField
							label={intl.formatMessage(i18n.avatarToggleLabel)}
							Icon={PersonIcon}
							isChecked={isAvatarShownParameter}
							onChange={handleAvatarToggleChange}
						/>
					</Fragment>
				) : null}
			</ToggleWrapper>
		</ToggleFieldFlexContainer>
	);
};
