import type { FC, ReactElement } from 'react';
import React from 'react';

import type FeatureFlagClient from '@atlaskit/feature-flag-client';

import { useSessionData } from '@confluence/session-data';
import { LoadablePaint } from '@confluence/loadable';

import type { ChildrenMacroSupportedParams } from './childrenMacroParamsSupported';
import { childrenMacroParamsSupported } from './childrenMacroParamsSupported';

const ChildrenMacroLoader = LoadablePaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ChildrenMacro" */ './ChildrenMacro'))
			.ChildrenMacro,
});

type ChildrenMacroExtensionHandlerProps = {
	contentId: string;
	params: unknown; // should be of type ChildrenMacroParams, but consumers are often untyped, so we make this unknown out of caution
	legacyMacroRenderer: () => ReactElement;
	isEditor?: boolean;
};

export const ChildrenMacroExtensionHandler: FC<ChildrenMacroExtensionHandlerProps> = ({
	contentId,
	params,
	legacyMacroRenderer,
	isEditor,
}) => {
	const { featureFlagClient } = useSessionData();

	if (isSpaChildrenMacroEnabled(featureFlagClient, params)) {
		return <ChildrenMacroLoader contentId={contentId} isEditor={isEditor} />;
	}

	return legacyMacroRenderer();
};

const isSpaChildrenMacroEnabled = (
	featureFlagClient: FeatureFlagClient,
	params: unknown,
): params is ChildrenMacroSupportedParams => {
	const isFeatureEnabled = featureFlagClient.getBooleanValue(
		'confluence.frontend.children-macro.spa',
		{ default: false },
	);

	return isFeatureEnabled && childrenMacroParamsSupported(params);
};
