/* eslint-disable check-credentials-option/has-credentials-option */
/* eslint-disable no-restricted-syntax */
import { query } from '@confluence/query-preloader-tools';
import {
	ssrPrepareErrorsClient,
	getSSRFeatureFlag,
	getSSRRenderInputs,
} from '@confluence/ssr-utilities';
import { fetchORS, getInitialState, getSmartShortcuts } from '@confluence/content-smartlinks';

import type {
	SpaceShortcutsSpaceNavigationQuery_spaceSidebarLinks_quick as SpaceNavigationQueryShortcutsType,
	SpaceShortcutsSpaceNavigationQueryVariables as SpaceNavigationQueryVariablesType,
} from './__types__/SpaceShortcutsSpaceNavigationQuery';
import { SpaceShortcutsSpaceNavigationQuery } from './SpaceShortcutsSpaceNavigationQuery.graphql';

declare global {
	interface Window {
		__SSR_SHORTCUTS__?: { [key: string]: any } | {};
	}
}

export const preloadShortcutsSmartLinks = async (spaceKey: string) => {
	const { customURLs, userContext } = getSSRRenderInputs();
	// If we don't receive a URL - no reason to try and run queries
	let objectResolverURL = customURLs?.['object-resolver-service'];
	if (!objectResolverURL) {
		return;
	}
	const shortcutsSmartLinksQueryStartTime = performance.now();

	// Call cc-graphql and get SmartLink URLs for the current space's shortcuts
	const res = await query<SpaceNavigationQueryShortcutsType, SpaceNavigationQueryVariablesType>({
		query: SpaceShortcutsSpaceNavigationQuery,
		variables: {
			spaceKey,
		},
	});
	const shortcutsSmartLinksQueryDuration = performance.now() - shortcutsSmartLinksQueryStartTime;

	// Loop through query data and pull out smart links
	const smartLinks = getSmartShortcuts(res);

	// If there are no valid SmartLinks shortcuts - exit
	if (!smartLinks?.length) {
		return;
	}

	const timeout = getSSRFeatureFlag('confluence.ssr.preload.query.timeout');
	if (timeout) {
		const errorBudgetForORSResolver = Math.round(timeout - shortcutsSmartLinksQueryDuration);
		if (errorBudgetForORSResolver > 0) {
			objectResolverURL += `?timeout=${errorBudgetForORSResolver}`;
		}
	}

	// Send smart links to linking platform
	try {
		const res = await fetchORS({
			url: objectResolverURL,
			userContext,
			body: JSON.stringify(smartLinks),
		});

		if (res.ok) {
			const __SSR_SHORTCUTS__ = await res.json();
			// Format response to fit SmartCard Provider storeOptions initial state
			// Save correctly formatted response to window obj so it can be used on SSR and SPA (no duplicate calls)
			window['__SSR_SHORTCUTS__'] = getInitialState(__SSR_SHORTCUTS__);
		}
	} catch (e) {
		ssrPrepareErrorsClient.submitError(
			new Error(`Smart link shortcuts ORS call failed: ${e.message}`),
		);
	}
};
