import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';

import type { ExtensionSource } from '@confluence/change-edition/entry-points/CustomSitesUpsell';

import type { LinkCardsParameters } from '../link-cards/linkCardsTypes';

type CustomSitesManifestArguments = {
	key: string;
	icons: any;
	parameters: any;
	update: any;
	render: any;
	title: any;
	type: any;
	description: any;
	source: ExtensionSource;
	setUpsellModalSource?: (source: ExtensionSource) => void;
};

// carousel uses Small sized cards so the parameters are effectively the same
export const getExtensionManifest = ({
	key,
	icons,
	parameters,
	update,
	render,
	title,
	type,
	description,
	setUpsellModalSource,
	source,
}: CustomSitesManifestArguments): ExtensionManifest<LinkCardsParameters> => ({
	title,
	type,
	key,
	description,
	icons,
	modules: {
		quickInsert: setUpsellModalSource
			? [
					{
						key: 'quick-insert',
						action: async () => setUpsellModalSource(source),
					},
				]
			: [
					{
						key: 'quick-insert',
						action: {
							type: 'node',
							key: 'default',
							parameters,
						},
					},
				],
		nodes: {
			default: {
				type: 'extension',
				render,
				update,
			},
		},
	},
});
