import React from 'react';
import AddonIcon from '@atlaskit/icon/glyph/addon';
import { token } from '@atlaskit/tokens';
import { N60 } from '@atlaskit/theme/colors';

export default function Icon({ label = '', imgUrl }: { label: string; imgUrl?: string }) {
	return imgUrl ? (
		<img data-testid="macro-custom-icon-img" alt={label} src={imgUrl} width="100%" />
	) : (
		<AddonIcon label={label} primaryColor={token('color.icon.accent.gray', N60)} size="large" />
	);
}
