import gql from 'graphql-tag';

export const CollabDraftQuery = gql`
	query CollabDraftQuery($id: ID!, $token: String) {
		collabDraft(id: $id, token: $token) {
			document
			version
			metadata {
				title
			}
		}
	}
`;
