import type { Hub, Scope } from '@sentry/browser';

import { getLogger } from '@confluence/logger';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { getBuildInfo } from '@confluence/build-info';
import { isResolved, getValue } from '@confluence/lifted-promise';

import { getSentryHubForSLAErrors } from './sentry';
import { Batcher } from './Batcher';
import type { MonitoringClient, ErrorData, MonitoringContext } from './MonitoringClient';
import { getLogSafeErrorAttributes } from './error-attributes';

type MetricEvent = {
	// one of https://bitbucket.org/observability/metal-client/src/master/src/metric/types.js
	type: 'timing' | 'gauge' | 'increment' | 'decrement';
	name: string;
	tags: string[];
	value: number;
	/**
	 * ISO string
	 *
	 * @type {string}
	 */
	timestamp: string;
	/**
	 * Just for consistency with metal-client
	 *
	 * @type {number}
	 */
	sampleRate: number;
};
type ErrorEvent = {
	event: ErrorAttributes;
	tags?: string[];
};
type ErrorAttributes = { error: string; [key: string]: any };

type TelemetryPayload = {
	// one of https://bitbucket.org/observability/metal-client/src/10948bd43c827deae8cb6177011319c84aa59a17/src/client/types.js#lines-20:26
	type: 'metric' | 'log' | 'trace' | 'analytics';
	data: MetricEvent[];
	meta: {
		globalTags: string[];
	};
};
type TelemetryErrorPayload = {
	type: 'error';
	data: ErrorEvent[];
	meta: {
		globalTags: string[];
	};
};

const logger = getLogger('CustomMonitoringClient');

export type CustomMonitoringClientOptions = {
	/**
	 * Maximum number of errors to submit per transition.
	 *
	 * This is mainly to counter infinite error submission loops, where a single user may be submitting thousands (or even millions) of errors due to some code being stuck in an infinite retry loop.
	 */
	errorsPerTransitionLimit?: number;

	/**
	 * Specifies the maximum number of metrics to be sent out at once. If there are more metrics then this number, they will be split into separate batches.
	 */
	metricBatchMaxSize?: number;

	/**
	 * Specifies how often will the metrics queue be flushed.
	 *
	 * Generally, the metrics are sent out once the `metricBatchMaxSize` has been reached. This interval value will allow for the sending of non-full metric batches.
	 */
	metricBatchFlushIntervalMs?: number;

	/**
	 * Specifies the maximum number of errors to be sent to the telemetry service at once. If there are more errors then this number, they will be split into separate batches.
	 */
	errorBatchMaxSize?: number;

	/**
	 * Specifies how often will the errors queue be flushed.
	 *
	 * Generally, the errors are sent out once the `errorBatchMaxSize` has been reached. This interval value will allow for the sending of non-full error batches.
	 */
	errorBatchFlushIntervalMs?: number;

	/**
	 * Specifies what each error event's 1) error attribute, 2) stack attribute, and 3) componentStack attribute will be trimmed to before being batched & sent to telemetry service.
	 *
	 * It's recommended to trim the error events prior to sending the batch to telemetry service, as there is a 255KB request size limit. If set to 0, errors won't have their attributes trimmed. Trimmed strings end in "..."
	 */
	errorBatchMsgTrimLength?: number;
};

export class CustomMonitoringClient implements MonitoringClient {
	private batcher: Batcher<MetricEvent>;
	private errorBatcher: Batcher<ErrorEvent>;
	private isWindowUnloading = false;
	private context: MonitoringContext = {};
	// First error occurred in initial load or transition
	private firstErrorInSession = true;

	private newSentryEnabled = false;
	private sendErrorsToTelemetryService = false;

	private errorsPerTransitionLimit: number;
	private errorsPerCurrentTransition: number = 0;
	private errorBatchMsgTrimLength: number;

	constructor({
		errorsPerTransitionLimit = 1000,
		metricBatchMaxSize = 50,
		metricBatchFlushIntervalMs = 10000,
		errorBatchFlushIntervalMs = 10000,
		errorBatchMaxSize = 10,
		errorBatchMsgTrimLength = 7168,
	}: CustomMonitoringClientOptions = {}) {
		this.errorsPerTransitionLimit = errorsPerTransitionLimit;
		this.errorBatchMsgTrimLength = errorBatchMsgTrimLength;

		this.batcher = new Batcher(metricBatchFlushIntervalMs, metricBatchMaxSize);
		this.errorBatcher = new Batcher(errorBatchFlushIntervalMs, errorBatchMaxSize);

		this.batcher.onFlush((batch) => {
			this.onBatch(batch);
		});
		this.errorBatcher.onFlush((batch) => {
			this.onErrorBatch(batch);
		});

		const unloadListener = () => {
			this.isWindowUnloading = true;
			this.batcher.forceFlush();
			this.errorBatcher.forceFlush();
		};
		window.addEventListener('pagehide', unloadListener);
	}

	public enableNewSentry() {
		this.newSentryEnabled = true;
	}

	public enableSendErrorsToTelemetryService() {
		this.sendErrorsToTelemetryService = true;
	}

	public submitError(error: any, errorData: ErrorData): void {
		if (this.errorsPerCurrentTransition === this.errorsPerTransitionLimit) {
			// limit reached; do not submit the errors
			return;
		}
		this.errorsPerCurrentTransition++;

		const logSafeErrorAttributes = getLogSafeErrorAttributes(error);
		const { attribution } = errorData;
		const isFirstInSession = this.firstErrorInSession;

		this.incrementCounter('jsErrors', [
			`attribution:${attribution}`,
			`firstInSession:${isFirstInSession}`,
			...(logSafeErrorAttributes.operationName
				? [`queryName:${logSafeErrorAttributes.operationName}`]
				: []),
		]);
		this.firstErrorInSession = false;

		const errorWithStackTrace =
			error && error.stack && error.message
				? error
				: new Error((error && error.message) || String(error));

		const sentryTags: { [key: string]: string } = {
			attribution,
		};

		if (this.context.slaExperience) {
			sentryTags.slaExperience = this.context.slaExperience;
		}
		if (this.context.pageName) {
			sentryTags.pageName = this.context.pageName;
		}
		if (logSafeErrorAttributes.failedSlaExperience) {
			sentryTags.failedSlaExperience = logSafeErrorAttributes.failedSlaExperience;
		}
		if (logSafeErrorAttributes.operationName) {
			sentryTags.operationName = logSafeErrorAttributes.operationName;
		}

		sentryTags.ssrRendered = Boolean((window as any).__SSR_RENDERED__).toString();
		sentryTags.react18Enabled = Boolean(
			(window as any)?.__SSR_FEATURE_FLAGS__?.['confluence.ssr.use-react18'],
		).toString();

		if (logSafeErrorAttributes.failedSlaExperience) {
			this.logSlaErrorToSentry(errorWithStackTrace, sentryTags).catch((sentryError) => {
				void this.logError(sentryError, { attribution: 'backbone' });
			});
		} else if (this.newSentryEnabled) {
			this.logErrorToSentry(errorWithStackTrace, sentryTags);
		}

		void this.logError(errorWithStackTrace, {
			...errorData,
			...logSafeErrorAttributes,
			firstInSession: isFirstInSession,
		});
	}

	public updateContext(update: Partial<MonitoringContext>): void {
		if (
			update.transitionId !== undefined &&
			(update.transitionId === 0 || this.context.transitionId !== update.transitionId)
		) {
			this.incrementCounter('session', [
				`type:${update.transitionId === 0 ? 'initial' : 'transition'}`,
			]);

			// Next error will be first in the session when there is a initial load (transition id = 0) or a SPA transition.
			this.firstErrorInSession = true;
			this.errorsPerCurrentTransition = 0;
		}

		if (update.pageName && this.context.pageName !== update.pageName) {
			// since the page name changed, all the events in the current batch were done
			// under an old page name. Hence we force-flush the batch, and only then set the new context.
			if (this.context.pageName !== undefined) {
				// skip force-flush if that's the first time we set pageName (i.e. initial navigation)
				this.batcher.forceFlush();
			}
		}
		Object.assign(this.context, update);
	}

	public incrementCounter(counterName: string, tags?: string[]): void {
		this.batcher.add(
			this.createMetric({
				type: 'increment',
				name: counterName,
				value: 1,
				tags,
			}),
		);
	}

	private logErrorToSentry(error: Error, tags: { [id: string]: string }) {
		import(/* webpackChunkName: "loadable-sentrybrowser" */ '@sentry/browser')
			.then(({ withScope, captureException }) => {
				withScope((scope: Scope) => {
					scope.setTags(tags);
					captureException(error);
				});
			})
			.catch((sentryError) => {
				void this.logError(sentryError, { attribution: 'backbone' });
			});
	}

	private async logSlaErrorToSentry(error: Error, tags: { [id: string]: string }) {
		let sentryHub: Hub | null | undefined = null;

		const standaloneSentryPromise = getSentryHubForSLAErrors();
		if (isResolved(standaloneSentryPromise)) {
			sentryHub = getValue(standaloneSentryPromise);
		} else {
			sentryHub = await standaloneSentryPromise;
		}

		if (sentryHub) {
			sentryHub.withScope((scope: Scope) => {
				scope.setTags(tags);
				sentryHub!.captureException(error);
			});
		}
	}

	private async logError(
		error: Error,
		additionalData: Record<string, string | number | boolean | null | undefined>,
	): Promise<void> {
		try {
			const analyticsWebClientPromise = getAnalyticsWebClient();
			let client;
			if (isResolved(analyticsWebClientPromise)) {
				client = getValue(analyticsWebClientPromise);
			} else {
				client = await analyticsWebClientPromise;
			}

			const errorAttributes: ErrorAttributes = {
				error: error.message || String(error),
				stack: error.stack,
				browserInfo: window.navigator.userAgent,
				ssrRendered: Boolean((window as any).__SSR_RENDERED__),
				releaseId: getBuildInfo().FRONTEND_VERSION,
				track: getBuildInfo().RELEASE_TRACK,
				pageName: this.context.pageName,
				shard: this.context.shard,
				edition: this.context.edition,
				jqVer: (window as any).jQuery?.fn?.jquery,
				activeExperiences: this.context.activeExperiences
					? Array.from(this.context.activeExperiences)
					: undefined,
				slaExperience: this.context.slaExperience,
				react18Enabled: Boolean(
					(window as any)?.__SSR_FEATURE_FLAGS__?.['confluence.ssr.use-react18'],
				),
				...additionalData,
			};

			client.sendOperationalEvent({
				source: 'ui',
				action: 'unhandledError',
				actionSubject: 'ui',
				attributes: errorAttributes,
			});

			if (this.sendErrorsToTelemetryService) {
				const attributesToTrim = ['error', 'stack', 'componentStack'];
				attributesToTrim.forEach((attribute) => {
					if (errorAttributes[attribute]) {
						errorAttributes[attribute] = this.trimTextLength(
							errorAttributes[attribute],
							this.errorBatchMsgTrimLength,
						);
					}
				});

				this.errorBatcher.add({
					event: errorAttributes,
				});
			}
		} catch {
			// no-op, if an error occurs here, there's really nowhere to submit it
		}
	}

	/**
	 * Defines an allow-list of context attributes that will be sent as "global" tags to FE Telemetry and SignalFX with every event.
	 * Tags additionally have to be allow-listed in the FE Telemetry repository. We PURPOSELY exclude certain tags (that are allowed by FE Telemetry)
	 * such as "edition", as we do not want to be sending that tag for every experience event (thereby increasing metric cardinality). Instead, those tags
	 * are included with specific experience events.
	 * @private
	 */
	private formatContextAsTags(): string[] {
		const tags: string[] = [];

		if (this.context.pageName) {
			tags.push(`page:${this.context.pageName}`);
		}

		if (this.context.tenantId) {
			tags.push(`cloudId:${this.context.tenantId}`);
		}

		if (this.context.shard) {
			tags.push(`shard:${this.context.shard}`);
		}

		if (this.context.product) {
			tags.push(`product:${this.context.product}`);
		}

		const { FRONTEND_VERSION: frontendVersion, RELEASE_TRACK: releaseTrack } = getBuildInfo();

		if (frontendVersion) {
			tags.push(`version:${frontendVersion}`);
		}

		if (releaseTrack) {
			tags.push(`track:${releaseTrack}`);
		}

		return tags;
	}

	private onBatch(batch: MetricEvent[]): void {
		const payload: TelemetryPayload = {
			type: 'metric',
			meta: {
				globalTags: this.formatContextAsTags(),
			},
			data: batch,
		};

		this.send(payload);
	}

	private onErrorBatch(batch: ErrorEvent[]): void {
		const payload: TelemetryErrorPayload = {
			type: 'error',
			meta: {
				globalTags: this.formatContextAsTags(),
			},
			data: batch,
		};

		this.send(payload);
	}

	private send(telemetryPayload: TelemetryPayload | TelemetryErrorPayload): void {
		try {
			logger.info`Sending monitoring events: ${telemetryPayload}`;

			const reportingUrl = '/gateway/api/telemetry';
			const data = JSON.stringify(telemetryPayload);
			let isSentViaBeacon: boolean = false;
			if (navigator.sendBeacon) {
				isSentViaBeacon = navigator.sendBeacon(reportingUrl, data);
			}

			if (!isSentViaBeacon) {
				// resorting to XMLHttpRequest instead of fetch here so that we can submit these events *synchronously* when
				// the page unloads (`fetch` can't do that)
				const client = new XMLHttpRequest();
				const shouldBeSync = this.isWindowUnloading;
				client.open('POST', reportingUrl, !shouldBeSync);
				// we're setting the "Content-Type" header to "text/plain" to behave as closely to `sendBeacon` as possible
				client.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
				client.send(data);
			}
		} catch (e) {
			// ignore - there's nothing we can do when monitoring is down
			logger.error`Sending monitoring data failed: ${e}`;
		}
	}

	private createMetric({
		type,
		name,
		value,
		tags = [],
	}: Pick<MetricEvent, 'type' | 'name' | 'value'> &
		Partial<Pick<MetricEvent, 'tags'>>): MetricEvent {
		return {
			type,
			name,
			value,
			sampleRate: 1,
			tags,
			timestamp: new Date().toISOString(),
		};
	}

	private trimTextLength(text: string, trimmedLength: number): string {
		if (!text || !trimmedLength) return text;
		if (text.length > trimmedLength) {
			return `${text.substring(0, trimmedLength)}...`;
		}

		return text;
	}
}
