import { DiffMatchPatch } from '../../utils/diff-match-patch/diff-match-patch';

export type IgnoredRange = { pos: number; size: number; type: 'mark' | 'inlineNode' };
export type DiffObject = {
	id: string;
	from: number;
	to: number;
	type: 'REPLACEMENT' | 'ADDITION' | 'DELETION';
	text: string;
	originalText: string;
};

export type ParagraphChunk = {
	id: string;
	text: string;
	from: number;
	to: number;
	ignoredRanges: Array<IgnoredRange>;
};

export type Diff = {
	type: -1 | 0 | 1;
	text: string;
	length: number;
};

export const createDiffs = (a: string, b: string): Diff[] => {
	const diff = DiffMatchPatch.diff_linesToWords_(a, b);
	const diffs = DiffMatchPatch.diff_main(diff.chars1, diff.chars2, false);
	DiffMatchPatch.diff_charsToLines_(diffs, diff.lineArray);

	return diffs.map((diff: { [0]: -1 | 0 | 1; [1]: string }) => ({
		type: diff[0],
		text: diff[1],
		length: diff[1].length,
	}));
};

const normaliseType = (diff: Diff, nextDiff?: Diff) => {
	if (diff.type === -1 && nextDiff?.type === 1) {
		// Lookahead 1 diff to see if the next diff is an Addition
		return 'REPLACEMENT';
	}
	// delete
	else if (diff.type === 1) {
		return 'DELETION';
	}
	// add
	else if (diff.type === -1 && (!nextDiff || (nextDiff && nextDiff.type !== 1))) {
		return 'ADDITION';
	}
};

export const getDiffObjectsBetweenParagraphs = (
	paragraphsA: ParagraphChunk[],
	paragraphsB: ParagraphChunk[],
) =>
	paragraphsA.reduce<Array<DiffObject>>(
		(acc, paragraph, index) =>
			acc.concat(
				getDiffObjectsForParagraph({
					originalParagraph: paragraph,
					correctedParagraphText: paragraphsB[index].text,
					isDiffHiddenInIgnoredRanges: true,
				}),
			),
		[],
	);

function isIntersectingWithIgnoredRanges(
	diffObject: DiffObject,
	ignoredRanges: Array<IgnoredRange>,
	originalFrom: number,
) {
	return ignoredRanges.some(({ pos, size }) => {
		if (diffObject.to < originalFrom + pos || diffObject.from > originalFrom + pos + size) {
			return false;
		}
		return true;
	});
}

export const getDiffObjectsForParagraph = ({
	originalParagraph,
	correctedParagraphText,
	isDiffHiddenInIgnoredRanges,
}: {
	originalParagraph: ParagraphChunk;
	correctedParagraphText: string;
	isDiffHiddenInIgnoredRanges: boolean;
}) => {
	const diffObjects: Array<DiffObject> = [];

	const originalText = originalParagraph.text;
	const diffs = createDiffs(originalText.trim(), correctedParagraphText.trim());

	const trimmingStartOffset = originalText.length - originalText.trimStart().length;
	let offset = originalParagraph.from + trimmingStartOffset;

	const n = diffs.length;
	for (let i = 0; i < n; i++) {
		const { type, text, length } = diffs[i];
		const finalType = normaliseType(diffs[i], diffs?.[i + 1]);

		if (finalType === 'DELETION') {
			diffObjects.push({
				id: `${originalParagraph.id}_${i}`,
				from: offset,
				to: offset,
				type: finalType,
				text,
				originalText: text,
			});
		} else if (finalType === 'REPLACEMENT' || finalType === 'ADDITION') {
			// Count how many inline nodes are overlapping with the word correction, and cater for them

			let inlineNodeOffsetSize = 0,
				inlineNodeOverlapSize = 0;
			originalParagraph.ignoredRanges.forEach(({ pos, size, type }) => {
				if (type === 'inlineNode') {
					/**
					 * Calculate offsets from inlineNodes that are before the current paragraph,
					 * and inline nodes within the diff.
					 * Only inline nodes should be considered, as marks don't contribute to node size.
					 */
					if (originalParagraph.from + pos < offset) {
						inlineNodeOffsetSize += size;
					}
					if (
						originalParagraph.from + pos < offset + length &&
						originalParagraph.from + pos > offset
					) {
						inlineNodeOverlapSize += size;
					}
				}
			});

			const from = offset + inlineNodeOffsetSize;

			const obj: DiffObject = {
				id: `${originalParagraph.id}_${i}`,
				from,
				to: offset + length + inlineNodeOffsetSize + inlineNodeOverlapSize,
				type: finalType,
				text,
				originalText: text,
			};

			if (finalType === 'REPLACEMENT') {
				const nextText = diffs?.[i + 1]?.text ?? text;
				const shouldTrimEndingSpace = text.endsWith(' ') && nextText.endsWith(' ');

				obj.to += shouldTrimEndingSpace ? -1 : 0;
				obj.text = shouldTrimEndingSpace ? nextText.slice(0, -1) : nextText;
				obj.originalText = shouldTrimEndingSpace ? text.slice(0, -1) : text;

				i++;
			}

			if (isDiffHiddenInIgnoredRanges) {
				if (
					!isIntersectingWithIgnoredRanges(
						obj,
						originalParagraph.ignoredRanges,
						originalParagraph.from,
					)
				) {
					diffObjects.push(obj);
				}
			} else {
				diffObjects.push(obj);
			}
		}

		// unchanged and removed words are part of original text
		if (type === 0 || type === -1) {
			offset += length;
		}
	}

	return diffObjects;
};
