/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { FC } from 'react';
import React from 'react';

import { script as initSSRMouseEventScript } from '../inline-scripts-dist/initSSRMouseEvent';

export const SSRScriptsInit: FC = () => {
	const nonceValue = window.__SSR_TRACE_ID__;
	return (
		<script
			{...(nonceValue ? { nonce: nonceValue } : {})}
			dangerouslySetInnerHTML={{
				__html: `if(window["__SSR_RENDERED__"]){${initSSRMouseEventScript}}`,
			}}
		/>
	);
};
