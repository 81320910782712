import React, { useState, useCallback, useMemo, useEffect } from 'react';
import type ApolloClient from 'apollo-client';

import { type MentionProvider } from '@atlaskit/mention';
import { type ForgeDoc, type ExtensionData } from '@atlassian/forge-ui-types';

import { type ComponentMap, type ModalExtension, RendererNext } from '..';
import { ForgeUIExtensionAnalyticsContext } from '../../analytics';
import {
	makeAvatar,
	makeAvatarStack,
	makeModalThreeLOPromptForCustomUI,
	makeNativeUserPicker,
	makeThreeLOPromptForCustomUI,
} from '../../components';

import { type ProductKey } from '../../components/UIKit1/userPicker';
import { type BridgeConfig } from '../../custom-ui/bridge/types';
import { type Extension } from '../../web-client';
import { WebRuntime } from '../../web-runtime';

interface CommonProps {
	accountId: string | undefined;
	client: ApolloClient<any>;
	contextIds: Array<string>;
	extension: Extension;
	extensionData: ExtensionData;
	product: ProductKey | 'sandbox';
	bridge?: BridgeConfig;
	components?: ComponentMap;
	consentMessage?: string;
	entryPoint?: string;
	getContextToken?: () => Promise<string>;
	locale?: string;
	localId?: string;
	mentionProvider?: Promise<MentionProvider>;
	modalExtension?: ModalExtension;
	onConsentSuccess?: () => void;
	onForgeDocUpdated?: (forgeDoc: ForgeDoc) => void;
	onInitialRender?: () => void;
	onConsentModalClose?: () => void;
}

type Props = CommonProps & ({ cloudId: string } | { workspaceId: string });

export const ForgeUIRenderer = (props: Props) => {
	const {
		accountId,
		bridge,
		client,
		components,
		consentMessage,
		contextIds,
		entryPoint,
		extension,
		extensionData,
		getContextToken,
		localId,
		mentionProvider,
		product,
		locale,
		modalExtension,
		onConsentSuccess,
		onForgeDocUpdated,
		onInitialRender,
		onConsentModalClose,
	} = props;

	let cloudId: string | undefined;
	let workspaceId: string | undefined;

	if ('cloudId' in props) {
		cloudId = props.cloudId;
	} else if ('workspaceId' in props) {
		workspaceId = props.workspaceId;
	}

	const [forgeDoc, setForgeDoc] = useState<ForgeDoc | undefined>(undefined);
	const [error, setError] = useState<Error | undefined>(undefined);

	useEffect(() => {
		onInitialRender?.();
	}, [onInitialRender]);

	const wiredComponents = useMemo(
		() => ({
			Avatar: makeAvatar({ client }),
			AvatarStack: makeAvatarStack({ client }),
			UserPicker: makeNativeUserPicker({
				client,
				mentionProvider,
				accountId,
				cloudId,
				productAttributes: workspaceId ? { workspaceIds: [workspaceId] } : undefined,
				productKey: product,
			}),
		}),
		[accountId, client, cloudId, mentionProvider, product, workspaceId],
	);

	const getComponents = useCallback(
		(defaults: ComponentMap) => ({
			...defaults,
			...wiredComponents,
			...components,
		}),
		[components, wiredComponents],
	);

	const getThreeLOPrompt = useCallback(
		() => ({
			ThreeLOPrompt: modalExtension
				? makeModalThreeLOPromptForCustomUI({
						appName: extension.properties.title,
						isBlanketHidden: true,
						onClose: onConsentModalClose,
					})
				: makeThreeLOPromptForCustomUI({
						appName: extension.properties.title,
						onSuccess: onConsentSuccess,
						message: consentMessage,
					}),
		}),
		[
			extension.properties.title,
			consentMessage,
			modalExtension,
			onConsentSuccess,
			onConsentModalClose,
		],
	);

	const { id: extensionId, environmentId, environmentType } = extension;
	const coreData = useMemo(
		() => ({
			workspaceId,
			cloudId,
			localId: localId || extensionId,
			environmentId,
			environmentType,
		}),
		[cloudId, environmentId, environmentType, extensionId, localId, workspaceId],
	);

	return (
		<ForgeUIExtensionAnalyticsContext extensionId={extensionId} localId={localId || extensionId}>
			{/* hidden span used for testing purposes and to identify that this renderer is being used when toggling the feature flag in products*/}
			<span hidden id="ui-kit-renderer" />
			<RendererNext
				isNative
				forgeDoc={forgeDoc}
				extension={extension}
				error={error?.message}
				components={getComponents}
				modalExtension={modalExtension}
				extensionType={extensionData.type}
				loading
			/>
			<WebRuntime
				accountId={accountId}
				setForgeDoc={(forgeDoc: ForgeDoc) => {
					if (forgeDoc.type !== 'MacroConfig') {
						setForgeDoc(forgeDoc);
					}
					onForgeDocUpdated?.(forgeDoc);
				}}
				setError={setError}
				apolloClient={client}
				components={getThreeLOPrompt}
				contextIds={contextIds}
				extension={extension}
				coreData={coreData}
				extensionData={extensionData}
				locale={locale}
				bridge={bridge}
				getContextToken={getContextToken}
				entryPoint={entryPoint}
			/>
		</ForgeUIExtensionAnalyticsContext>
	);
};
