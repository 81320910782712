/* SSR is also using the same elements to prevent duplication and risk for getting out of sync. Moved to fabric extension to
 prevent circular dependency with fabric-extension-handler and content-renderer */
import React, { memo, useLayoutEffect, useRef } from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import type { ADNode } from '@atlaskit/editor-common/validator';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import { token } from '@atlaskit/tokens';

import type { Mode } from '@confluence/macro-tracker';
import { RENDERER, EDITOR } from '@confluence/macro-tracker';
import { ADFRenderer } from '@confluence/adf-renderer';
import type { FeatureFlagsType } from '@confluence/session-data';
import {
	delayIframeLoading,
	useDelayedIframe,
} from '@confluence/fabric-extension-lib/entry-points/renderer-extensions';

const LegacyMacroElement = memo(
	({
		contentId,
		className,
		isInline,
		nodeProps,
		macroDataProps,
		macroRepresentation,
		macroRenderedOutput,
		style,
		children,
		featureFlags,
		extensionHandlers,
		mediaToken,
		ssredElement,
		delayIframe,
	}: {
		contentId: string;
		adf: ADNode;
		isInline: boolean;
		nodeProps: any;
		macroDataProps: any;
		mode: Mode;
		macroRepresentation: string;
		macroRenderedOutput: string | null;
		style?: any;
		children?: any;
		className?: string;
		featureFlags?: FeatureFlagsType;
		extensionHandlers?: ExtensionHandlers;
		mediaToken?: string;
		ssredElement?: Element;
		delayIframe: boolean;
	}) => {
		const ElementTag = isInline ? 'span' : 'div';

		const containerRef = nodeProps?.ref;
		const placeholderRef = useRef<(HTMLInputElement & HTMLSpanElement) | null>(null);

		useLayoutEffect(() => {
			const markerElement = placeholderRef.current;
			if (markerElement && ssredElement) {
				markerElement.insertAdjacentElement('afterend', ssredElement);
				if (containerRef && typeof containerRef === 'function') {
					containerRef(ssredElement);
				}
				return () => {
					// We inserted ssredElement above which is outside the React lifecycle
					// When the actual macro is rendered, this component will unmount.
					// At that point, we need to remove the inserted element to avoid duplication
					try {
						ssredElement.parentNode?.removeChild(ssredElement);
					} catch {}
				};
			}
		}, [containerRef, ssredElement]);

		useDelayedIframe(delayIframe, ssredElement);

		if (children) {
			return (
				<ElementTag
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={className}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={style}
					{...nodeProps}
					{...macroDataProps}
				>
					{children}
				</ElementTag>
			);
		}
		if (macroRepresentation === 'atlas_doc_format') {
			return macroRenderedOutput ? (
				<ElementTag
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={className}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={style}
					{...nodeProps}
					{...macroDataProps}
					data-testid="legacy-macro-adf"
				>
					<ADFRenderer
						document={JSON.parse(macroRenderedOutput)}
						contentId={contentId}
						featureFlags={featureFlags ?? {}}
						extensionHandlers={extensionHandlers}
						mediaToken={mediaToken}
						appearance="full-width"
					/>
				</ElementTag>
			) : null;
		} else {
			if (ssredElement) {
				return <input type="hidden" ref={placeholderRef} data-legacy-ssred-element-ref />;
			}

			const macroHtml = delayIframe ? delayIframeLoading(macroRenderedOutput) : macroRenderedOutput;

			return (
				<ElementTag
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={className}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={style}
					dangerouslySetInnerHTML={{ __html: `${macroHtml}` }}
					{...nodeProps}
					{...macroDataProps}
					{...(delayIframe ? { 'data-delay-iframe': '1' } : {})}
					data-testid="legacy-macro-element"
				/>
			);
		}
	},
);

const createClassnameForExtension = (adf: ADNode) => {
	const macroParams = adf?.attrs?.parameters?.macroParams || {};
	const extensionKey = adf?.attrs?.extensionKey || '';
	let key = `macro${extensionKey}`;
	if (extensionKey === 'jira' && !macroParams.key && !macroParams.count) {
		key += 'Table';
	}
	return key;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LegacyMacroStyledElement = styled(LegacyMacroElement)`
	/** Fabric Editor sets "white-space: pre-wrap" which breaks layout for nested content not prepared for it, like macros */
	white-space: normal;
	p {
		white-space: normal;
	}
	// Styled overrides for expand macro nested in adf-rendered macros to prevent horizontal scrollbar
	[data-node-type='expand'] {
		margin-left: ${token('space.0', '0')};
		margin-right: ${token('space.0', '0')};
	}

	/**
    * Jira Macros within page include are not displaying correctly when ADF macro renderer frontend flag is on.
    * The jira macros need to be stretched from left to right. This manual css override will adjust it accordingly
    * See ticket CERN-1743 for more info.
    * */
	${(props) => {
		const adfJiraMacroPageIncludeCssOverride =
			createClassnameForExtension(props.adf) === 'macrojiraTable' ||
			createClassnameForExtension(props.adf) === 'macroinclude';
		return (
			adfJiraMacroPageIncludeCssOverride &&
			`.ak-renderer-document > div.ak-renderer-extension {
       width: 100% !important;
     }`
		);
	}}

	/**
    * Style override for nested page includes with recursion error message (ADF Macro Rendering/CERN-1805)
    * */
   ${(props) => {
		return (
			createClassnameForExtension(props.adf) === 'macroinclude' &&
			`.ak-renderer-document .ak-renderer-extension-overflow-container .error {
         padding: ${token('space.075', '6px')};
       }`
		);
	}}

   ${(props) => {
		return (
			createClassnameForExtension(props.adf) === 'macrojiraTable' &&
			`display: block;
         max-width: 100%;
         overflow-x: auto;
         th {
           word-break: normal;
         }`
		);
	}}
   ${(props) => props.mode === EDITOR && `position: relative; user-select: none;`}
   ${(props) => (props.isInline ? `clear: none;` : `clear: both;`)}
   ${(props) =>
		props.mode === RENDERER &&
		props.isInline &&
		`display: inline-block; padding: ${token('space.025', '2px')};`}
   ${(props) => {
		return (
			createClassnameForExtension(props.adf) === 'macroinclude' &&
			`.ak-renderer-document > .pm-table-container > .pm-table-wrapper {
         padding: ${token('space.0', '0')};
         margin: ${token('space.0', '0')};
       }
       .ak-renderer-document [data-testid="sticky-table-fixed"] > .pm-table-container {
         margin-bottom: ${token('space.0', '0')};
       }
       .ak-renderer-document [data-testid="sticky-table-fixed"] > .pm-table-container table {
         margin-top: ${token('space.0', '0')};
       }`
		);
	}}

   table.confluenceTable {
		tr {
			th.confluenceTh:nth-child(1),
			td.confluenceTd:nth-child(1) {
				padding-left: ${token('space.150', '12px')};
			}
		}
	}
`;
