import { getSSRAutoScript, getThemeStyles, getThemeHtmlAttrs } from '@atlaskit/tokens';
import type { ThemeState } from '@atlaskit/tokens';

import { getDefaultTheme } from '@confluence/theming-utils';

export class ThemeSSRManager {
	private themeState: Partial<ThemeState>;

	constructor() {
		// If colorMode doesn't get set, you don't want to return anything
		this.themeState = getDefaultTheme();
	}

	setThemeStateBasedOnSavedThemeState(theme?: Partial<ThemeState>): void {
		// If theme is undefined, no reason to set that in themeState as it's not actually a theme (just Original)
		if (theme) {
			this.themeState = {
				...this.getThemeState(),
				...theme,
			};
		}
	}

	getThemeState(): Partial<ThemeState> {
		return this.themeState;
	}

	async getThemeStylesScript(): Promise<string> {
		// Generates an array of styles
		if (this.getThemeState().colorMode) {
			const themeStyles = await getThemeStyles(this.getThemeState());
			// Add a stylesheet for each theme being used
			return themeStyles
				.map((theme) => {
					const attributes = Object.entries(theme.attrs).map(([key, value]) => `${key}="${value}"`);
					return `<style ${attributes}>${theme.css}</style>`;
				})
				.join('');
		} else {
			return '';
		}
	}

	getSSRAutoColorModeScript(): string {
		// Creates and returns the script used to set the html attributes if the user choose 'auto'
		const currentColorMode = this.getThemeState().colorMode;

		if (currentColorMode === 'auto') {
			const nonceValue = window.__SSR_TRACE_ID__;
			const scriptContent = getSSRAutoScript(currentColorMode);
			return nonceValue
				? `<script nonce="${nonceValue}">${scriptContent}</script>`
				: `<script>${scriptContent}</script>`;
		} else {
			return '';
		}
	}

	getThemeHtmlAttrsScript(): string {
		// Simple script to add theme attributes to HTML tag based on users codeMode selection
		const currentThemeState = this.getThemeState();

		if (currentThemeState.colorMode) {
			const themeAttrs = getThemeHtmlAttrs(currentThemeState);

			if (currentThemeState.colorMode === 'auto') {
				/*
           If the colorMode is auto, we don't want to just default to "light" because if the user's
           browser setting is "dark", this would set the "data-color-mode" to "light" and then set
           again with "dark" with the script from getSSRAutoColorModeScript(). Removing the "data-color-mode"
           setAttribute for "auto" makes sure that it is only set once by the getSSRAutoColorModeScript()
         */
				delete themeAttrs['data-color-mode'];
			}

			const attributeSetters = Object.entries(themeAttrs)
				.map(([attr, value]) => `document?.documentElement?.setAttribute("${attr}", "${value}");`)
				.join('');

			return `try { ${attributeSetters} } catch (e) {}`;
		} else {
			return '';
		}
	}
}
