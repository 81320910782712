import { getSSRFeatureFlag } from '@confluence/ssr-utilities';
const SINGLE_ELEMENT = 'SingleElement';
const loadableCountByName: Map<string, number> = new Map<string, number>();
const loadableCountByNameAndId: Map<string, number> = new Map<string, number>();
const viewportLoadableCountByName: Map<string, number> = new Map<string, number>();

export function setServerSideLoadableCount(name: string) {
	if (loadableCountByName.has(name)) {
		loadableCountByName.set(name, loadableCountByName.get(name)! + 1);
	} else {
		loadableCountByName.set(name, 1);
	}
}

export function getServerSideLoadableCount() {
	return getCurrentLoadableCount(loadableCountByName);
}

export function getViewportGetServerSideLoadableCount() {
	return getCurrentLoadableCount(viewportLoadableCountByName);
}

export function setViewportServerSideLoadableCount(name: string) {
	if (viewportLoadableCountByName.has(name)) {
		viewportLoadableCountByName.set(name, viewportLoadableCountByName.get(name)! + 1);
	} else {
		viewportLoadableCountByName.set(name, 1);
	}
}

export function setServerSideLoadableIdCount(name: string) {
	if (loadableCountByNameAndId.has(name)) {
		loadableCountByNameAndId.set(name, loadableCountByNameAndId.get(name)! + 1);
	} else {
		loadableCountByNameAndId.set(name, 1);
	}
}

export function getServerSideLoadableIdCount(name: string) {
	return loadableCountByNameAndId.get(name);
}

function getCurrentLoadableCount(loadableMap: Map<string, number>) {
	const currentTaskCount: Record<string, number> = { [SINGLE_ELEMENT]: 0 };
	for (const [name, count] of loadableMap) {
		// If there is only one element, we don't need to include the name in the map
		if (count === 1) {
			currentTaskCount[SINGLE_ELEMENT] = currentTaskCount[SINGLE_ELEMENT] + 1;
		} else {
			currentTaskCount[name] = count;
		}
	}
	return currentTaskCount;
}

export const isElementCountGreaterThanRenderThreshold = (name: string) => {
	const renderThresholdConfig = getSSRFeatureFlag('confluence.ssr.render.above.threshold') || {};
	if (!renderThresholdConfig.enabled || !renderThresholdConfig[name.toLowerCase()]) {
		return false;
	}
	const count = loadableCountByName.get(name);
	if (count && count > renderThresholdConfig[name.toLowerCase()]) {
		return true;
	}
	return false;
};
