import React, { Fragment, type PropsWithChildren, lazy, useState } from 'react';
import type { FieldProps } from '@atlaskit/form/Field';
import {
	type NativeSelectProps,
	type AKOption,
	type OptionProps,
	type RenderFn,
} from '@atlassian/forge-ui-types';
import { PortalConsumer } from '../../../context';
import { FormStateChangeNotifier } from '../form';
import { useIsInForm } from '../utils/useIsInForm';
import { Label } from '../label';
import { FieldContainer } from '../utils/FieldContainer';

const AKFormField = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.form' */
			'@atlaskit/form/Field'
		),
);

const AKSelect = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.select' */
			'@atlaskit/select'
		),
);

const AKErrorMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.ErrorMessage })),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

interface AKSelectProps {
	options: AKOption[];
	defaultValue?: AKOption | AKOption[];
}

const validate = (value: any) => (!value ? 'EMPTY' : undefined);

const FormSelect: React.FunctionComponent<Omit<NativeSelectProps, 'children'> & AKSelectProps> = ({
	options,
	isMulti,
	defaultValue,
	label,
	name,
	description,
	isRequired,
	placeholder,
	onChange,
}) => {
	return (
		<PortalConsumer>
			{(portal) => (
				<FieldContainer>
					<AKFormField
						defaultValue={defaultValue}
						label={label}
						name={name}
						isRequired={isRequired}
						validate={isRequired ? validate : undefined}
					>
						{({ fieldProps, error }: { fieldProps: FieldProps<unknown>; error?: string }) => {
							const {
								id,
								isDisabled,
								isRequired,
								isInvalid,
								onBlur,
								onFocus,
								value,
								'aria-invalid': ariaInvalid,
								'aria-labelledby': ariaLabelledBy,
							} = fieldProps as FieldProps<AKOption>;

							return (
								<Fragment>
									<FormStateChangeNotifier name={name} value={fieldProps.value} />
									<AKSelect
										id={id}
										isDisabled={isDisabled}
										isRequired={isRequired}
										isInvalid={isInvalid}
										onBlur={onBlur}
										onFocus={onFocus}
										value={value}
										name={fieldProps.name}
										onChange={(option: unknown) => {
											fieldProps.onChange(option);
											onChange?.(option as AKOption);
										}}
										isMulti={isMulti}
										menuPortalTarget={portal}
										options={options}
										placeholder={placeholder}
										validationState={error ? 'error' : 'default'}
										aria-invalid={ariaInvalid}
										aria-labelledby={ariaLabelledBy}
									/>
									{error === 'EMPTY' && <AKErrorMessage>This field is required.</AKErrorMessage>}
									{description && <AKHelperMessage>{description}</AKHelperMessage>}
								</Fragment>
							);
						}}
					</AKFormField>
				</FieldContainer>
			)}
		</PortalConsumer>
	);
};

const SelectInner = ({
	options,
	isMulti,
	label,
	placeholder,
	onChange,
	defaultValue,
	description,
	name,
	isRequired,
	children,
}: PropsWithChildren<Omit<NativeSelectProps, 'children'> & AKSelectProps>) => {
	const [value, setValue] = useState(defaultValue || null);

	return (
		<FieldContainer>
			<Label name={name} isRequired={isRequired} label={label} />
			<AKSelect
				isMulti={isMulti}
				value={value}
				onChange={(option) => {
					setValue(option as AKOption | AKOption[]);
					onChange?.(option as AKOption | AKOption[]);
				}}
				options={options}
				placeholder={placeholder}
				description={description}
				name={name}
				isRequired={isRequired}
			>
				{children}
			</AKSelect>
			{description && <AKHelperMessage>{description}</AKHelperMessage>}
		</FieldContainer>
	);
};

export const Select = (props: Parameters<RenderFn>[0]) => {
	const { description, label, name, isMulti, placeholder, isRequired, onChange } = props.forgeDoc
		.props as NativeSelectProps;

	let defaultValue: AKOption[] = [];
	const options: AKOption[] = props.forgeDoc.children
		.map((child) => {
			const { props, type } = child;

			if (type === 'Option') {
				const { defaultSelected, label, value } = props as OptionProps;
				const option = { label, value };

				if (defaultSelected) {
					if (isMulti) {
						defaultValue.push(option);
					} else {
						defaultValue = [option];
					}
				}
				return option;
			}
		})
		.filter((option): option is AKOption => typeof option !== 'undefined');

	const formattedDefaultValue = isMulti ? defaultValue : defaultValue[0];

	const isInForm = useIsInForm();
	if (!isInForm) {
		return (
			<SelectInner
				label={label}
				name={name}
				options={options}
				isMulti={isMulti}
				isRequired={isRequired}
				defaultValue={formattedDefaultValue}
				description={description}
				placeholder={placeholder}
				onChange={onChange}
			/>
		);
	}

	return (
		<FormSelect
			label={label}
			name={name}
			options={options}
			isMulti={isMulti}
			isRequired={isRequired}
			defaultValue={formattedDefaultValue}
			description={description}
			placeholder={placeholder}
			onChange={onChange}
		/>
	);
};
