import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useBooleanFeatureFlag } from '@confluence/session-data';

import {
	TRIAL_END_LOCK_SCREEN_STATSIG_FLAG,
	TRIAL_END_LOCK_SCREEN_FF,
	NOT_ENROLLED,
	VARIATION,
} from './constants';

const getTrialEndLockScreenCohort = (fireExperimentExposure = true) => {
	return FeatureGates.getExperimentValue(
		TRIAL_END_LOCK_SCREEN_STATSIG_FLAG,
		'cohort',
		NOT_ENROLLED,
		{
			fireExperimentExposure,
		},
	);
};

export const isVariationForTrialEndLockScreen = () => {
	return getTrialEndLockScreenCohort(false) === VARIATION;
};

export const useIsLockScreenExperimentEnrolled = () => {
	const isLockScreenSwitchEnabled = useBooleanFeatureFlag(TRIAL_END_LOCK_SCREEN_FF);

	return isLockScreenSwitchEnabled && getTrialEndLockScreenCohort(false) !== NOT_ENROLLED;
};

export const useFeatureExposedEvent = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		(ineligibilityReason: string | null, attributes: Record<string, unknown> = {}) => {
			const isEligible = ineligibilityReason === null;
			const cohort = getTrialEndLockScreenCohort(isEligible);

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'trialEndLockScreen',
					action: 'exposed',
					actionSubject: 'feature',
					actionSubjectId: 'trialEndLockScreen',
					attributes: {
						productKey: 'confluence.ondemand',
						growthInitiative: 'transmuters',
						...(isEligible
							? { cohort }
							: {
									ineligibilityReason,
									originalCohort: cohort,
									cohort: 'not-enrolled',
								}),
						...attributes,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);
};
