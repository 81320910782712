import { query } from '@confluence/query-preloader-tools';
import { getLogger } from '@confluence/logger';
import { preloadEditorPageCoverPicture } from '@confluence/page-cover-picture/entry-points/preloadEditorPageCoverPicture';
import { preloadEmojiTitle } from '@confluence/emoji-title/entry-points/preloadEmojiTitle';
import { preloadEditorContentState } from '@confluence/content-state/entry-points/preloadEditorContentState';
import { getDraftShareIdFromQueryParam } from '@confluence/route-manager/entry-points/getDraftShareIdFromQueryParam';
import { preloadDraftContentAppearance } from '@confluence/content-appearance/entry-points/preloadDraftContentAppearance';
import { preloadEditorPageTitleContentProperties } from '@confluence/custom-sites-extensions/entry-points/preloadEditorPageTitleContentProperties';
import {
	fetchCollabPermissionToken,
	nativeCollabPermissionsTokenState,
} from '@confluence/native-collab/entry-points/CollabPermissionsToken';

import { featureDiscovery } from './queries/featureDiscovery.graphql';
import {
	preloadNativeEditorContent,
	preloadCollabDraftContent,
	preloadCollabServiceProperty,
	preloadNativeCollab,
	preloadNativeCollabProvider,
} from './preloadNativeEditor';
import type { IPreloadEditorData } from './types';

const logger = getLogger('load-edit-page');

function preloadFeatureDiscovery() {
	return query({
		query: featureDiscovery,
	});
}

export async function preloadEditorData({
	userId,
	contentId,
	cloudId,
	preloadNativeCollabData = false,
	shouldPreloadNativeEditorData = false, // use cache for native editor data
	catchFn,
	isCustomSitesPageTitleFFOn = false,
	shouldLoadDraftFromGql = false,
	isBlog = false,
	isTransition = false,
	isLivePage = false,
}: IPreloadEditorData): Promise<void> {
	// note: explicitly casting to Promise<any> to avoid typescript incorrectly inferring tuple types; although we don't use the return value of this function regardless
	logger.debug`Calling preloadEditorData ${performance.now() / 1000} for ${{
		preloadNativeCollabData,
	}}`;
	const draftShareId = getDraftShareIdFromQueryParam();

	// preload queries shared by Synchrony and Native Collab editors
	const immediateResultsPromises: Array<Promise<any> | undefined> = [
		preloadFeatureDiscovery(),
		preloadDraftContentAppearance(contentId, shouldPreloadNativeEditorData),
	];

	if (preloadNativeCollabData) {
		// specific native collab editor queries
		if (!!contentId) {
			if (shouldLoadDraftFromGql) {
				// get permission token for CollabDraftQuery
				let permissionToken: string | null = null;
				if (!!contentId && isTransition && !isLivePage) {
					permissionToken = await fetchCollabPermissionToken(contentId, draftShareId)();

					if (permissionToken) {
						nativeCollabPermissionsTokenState.setToken(contentId, Promise.resolve(permissionToken));
						nativeCollabPermissionsTokenState.setTimestamp(contentId);
					}
				}

				const contentType = isBlog ? 'blogpost' : 'page';
				const documentAri = `ari:cloud:confluence:${cloudId}:${contentType}/${contentId}`;
				immediateResultsPromises.push(
					preloadCollabDraftContent(documentAri, permissionToken, shouldPreloadNativeEditorData),
				);
			} else {
				immediateResultsPromises.push(
					preloadNativeEditorContent(contentId, shouldPreloadNativeEditorData),
				);
			}
			immediateResultsPromises.push(
				preloadCollabServiceProperty(contentId, draftShareId, shouldPreloadNativeEditorData),
				preloadNativeCollab(contentId, draftShareId, shouldPreloadNativeEditorData),
				preloadNativeCollabProvider(userId, shouldPreloadNativeEditorData),
			);
		}
	}

	immediateResultsPromises.push(
		preloadEmojiTitle(contentId, draftShareId, shouldPreloadNativeEditorData),
		preloadEditorPageCoverPicture(contentId, draftShareId, shouldPreloadNativeEditorData),
		preloadEditorContentState(contentId, shouldPreloadNativeEditorData),
	);

	if (isCustomSitesPageTitleFFOn) {
		immediateResultsPromises.push(
			preloadEditorPageTitleContentProperties({
				contentId,
				draftShareId,
				useCache: shouldPreloadNativeEditorData,
			}),
		);
	}
	await Promise.all(immediateResultsPromises.map((p) => (!!catchFn ? p?.catch(catchFn) : p)));

	logger.debug`preloadEditorData complete ${performance.now() / 1000}`;
}
