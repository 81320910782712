import React from 'react';

import { LoadableLazy } from '@confluence/loadable';
import { withTransparentErrorBoundary, Attribution } from '@confluence/error-boundary';

import { useIsLockScreenExperimentEnrolled } from './utils';

const LockScreen = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-OverviewOverview" */ './LockScreen')).LockScreen,
});

export const LazyLockScreen = withTransparentErrorBoundary({
	attribution: Attribution.GROWTH_TRANSMUTERS,
})(() => {
	const isLockScreenExperimentEnrolled = useIsLockScreenExperimentEnrolled();

	if (isLockScreenExperimentEnrolled) {
		return <LockScreen />;
	}

	return null;
});
