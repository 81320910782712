/* eslint react/prop-types:0 */
import React from 'react';

import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const SpaceSettingsContentStateRouteComponent = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-SpaceSettingsContentStateRouteComponent" */ './SpaceSettingsContentStateRouteComponent'
		),
});

export const SpaceSettingsContentStateRoute = ({ params: { spaceKey } }) => (
	<SpaceSettingsContentStateRouteComponent spaceKey={spaceKey} />
);

SpaceSettingsContentStateRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }) => ({
	Screen: {
		screenEvent: { name: 'spaceSettingsContentState', id: spaceKey },
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		disableMinWidth: true,
	},
});
