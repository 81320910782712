import type { ExtensionProvider } from '@atlaskit/editor-common/extensions';
import type { EditorActions } from '@atlaskit/editor-core';
import type { FlagFunctions, FlagProviderHOC } from '../utils/getFlagProvider';
import {
	type AnalyticsWebClient,
	type ForgeUIAnalyticsContext,
	sendEvent,
} from '@atlassian/forge-ui/analytics';

import {
	createLegacyInvokeAuxEffectsInput,
	handleInvokeAuxEffectsResponse,
	invokeAuxEffectsMutation,
	APIError,
} from '@atlassian/forge-ui/internal';
import type { GQLExtensionContextsFilter } from '@atlassian/forge-ui/provider';

import {
	type ContextId,
	type ProductEnvironment,
	type ForgeUIExtensionType,
	type EnvironmentType,
	ExtensionEnvironment,
} from '@atlassian/forge-ui-types';
import { type ExtensionHandlerWithReferenceFn } from '@atlassian/editor-referentiality';
import { type ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import type ApolloClient from 'apollo-client';
import { createSchemaFromAux, ValidationError } from '../config/createConfigSchema';
import {
	type ForgeExtensionParameters,
	renderExtension,
	renderBlockedMacroExtension,
	shouldBlockExtensionDueToAppAccessPolicy,
} from '../render';
import { getForgeExtensionProviderShared } from './getForgeExtensionProviderShared';

interface ForgeExtensionProviderParams {
	accountId?: string;
	cloudId: string;
	apolloClient: ApolloClient<object>;
	contextIds: ContextId[];
	dataProviders: ProviderFactory;
	environment: ProductEnvironment;
	product: string;
	page: string;
	isEditing?: boolean;
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>;
	forgeUIAnalyticsContext: ForgeUIAnalyticsContext;
	extensionData: Record<string, any>;
	FlagProvider?: FlagProviderHOC;
	extensionHandlerWithReference?: ExtensionHandlerWithReferenceFn<ForgeExtensionParameters>;
	connectExtensionProvider?: Promise<ExtensionProvider>;
	editorActions?: EditorActions;
	extensionsFilter?: GQLExtensionContextsFilter[];
	dataClassificationTagIds?: string[];
	hideGlassPane?: boolean;
}

const humanReadableEnvironmentType: Record<EnvironmentType, string> = {
	DEVELOPMENT: 'Development',
	STAGING: 'Staging',
	PRODUCTION: 'Production',
};

export const createTitleWithEnvironmentInfo = (
	title: string,
	environmentType: EnvironmentType = ExtensionEnvironment.PRODUCTION,
	environmentKey?: string,
): string => {
	if (environmentType === ExtensionEnvironment.PRODUCTION) {
		return title;
	}

	if (environmentKey === undefined) {
		return `${title} (${humanReadableEnvironmentType[environmentType]})`;
	}

	if (environmentType === ExtensionEnvironment.DEVELOPMENT && environmentKey !== 'default') {
		return `${title} - ${environmentKey} (Dev)`;
	}

	return `${title} (${humanReadableEnvironmentType[environmentType]})`;
};

export const getConfig = ({ config, guestParams }: ForgeExtensionParameters) =>
	guestParams || config;

/*
	This exists to provide a default value for the flag functions that Confluence
	is meant to inject with the FlagProvider param. The param must be optional in
	getForgeExtensionProvider because breaking changes are disallowed without a
	deprecation period (which we want to avoid) - see go/releasing-changes-safely.
	The renderExtension function expects FlagProvider to be defined, so this default is provided.
*/
const noopFlags: FlagFunctions = {
	showFlag: async () => {
		return {};
	},
	hideFlag: async () => {},
};
const noopFlagProvider: FlagProviderHOC = ({
	children,
}: {
	children: (flags: FlagFunctions) => JSX.Element;
}) => children(noopFlags);

export async function getForgeExtensionProvider({
	accountId,
	cloudId,
	apolloClient,
	contextIds,
	dataProviders,
	environment,
	product,
	page,
	isEditing,
	analyticsWebClient,
	forgeUIAnalyticsContext = {},
	extensionData,
	extensionHandlerWithReference,
	connectExtensionProvider,
	editorActions,
	FlagProvider = noopFlagProvider,
	extensionsFilter,
	dataClassificationTagIds,
	hideGlassPane,
}: ForgeExtensionProviderParams): Promise<ExtensionProvider> {
	const createRenderFunction = async (extension?: ForgeUIExtensionType) =>
		extension && shouldBlockExtensionDueToAppAccessPolicy(extension)
			? renderBlockedMacroExtension({ extension })
			: renderExtension({
					accountId,
					analyticsWebClient,
					cloudId,
					extension,
					extensionData,
					extensionHandlerWithReference,
					forgeUIAnalyticsContext,
					apolloClient,
					contextIds,
					isEditing: Boolean(isEditing),
					dataProviders,
					environment,
					product,
					page,
					FlagProvider,
					hideGlassPane,
				});

	const getFieldsDefinitionFunction = async (data: ForgeExtensionParameters) => {
		const { extensionId, localId } = data;
		const config = getConfig(data);
		try {
			const input = createLegacyInvokeAuxEffectsInput(
				{
					contextIds,
					extensionId,
					localId,
					functionId: 'config',
				},
				{
					effects: [
						{
							type: 'initialize',
						},
					],
					state: {},
					config,
					context: {
						isConfig: true,
						cloudId,
					},
				},
				{
					...extensionData,
					config,
					isConfig: true,
				},
			);
			const mutationResult = await apolloClient.mutate({
				mutation: invokeAuxEffectsMutation,
				variables: { input },
			});
			const effect = handleInvokeAuxEffectsResponse(mutationResult, (message) => {
				throw new APIError(message);
			})[0];
			return createSchemaFromAux(effect.type === 'result' ? effect.forgeDoc : effect.aux);
		} catch (error) {
			const { name, message } = error as Error;
			if (!(error instanceof ValidationError)) {
				analyticsWebClient &&
					sendEvent(analyticsWebClient)({
						eventType: UI_EVENT_TYPE,
						action: 'errored',
						actionSubject: 'forgeUIExtension',
						actionSubjectId: 'editorMacro',
						source: 'editPageScreen',
						attributes: {
							errorName: name,
							errorMessage: message,
						},
					});
			}
			throw new Error(message || '');
		}
	};
	return await getForgeExtensionProviderShared({
		accountId,
		apolloClient,
		cloudId,
		contextIds,
		forgeUIAnalyticsContext,
		page,
		product,
		analyticsWebClient,
		createRenderFunction,
		getFieldsDefinitionFunction,
		connectExtensionProvider,
		editorActions,
		extensionsFilter,
		dataClassificationTagIds,
	});
}
