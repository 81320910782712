type ModalWidths = 'small' | 'medium' | 'large' | 'xlarge' | 'max';

export const getAtlasKitModalWidth = (modalSize: ModalWidths) => {
	if (modalSize === 'xlarge') {
		return 'x-large';
	}
	if (modalSize === 'max') {
		return '100%';
	}
	return modalSize;
};
