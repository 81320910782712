// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Container } from 'unstated';

const SERVER_RENDER_BANNER_NAME = 'ServerRenderedBanners';

export type BannerStateInfo = {
	height: number;
	name: string;
};

type BannerStateContainerState = {
	banners: BannerStateInfo[];
};

type BannerStateContainerProps = {
	hasTopNav?: boolean;
	initialState?: BannerStateContainerState;
};

export const findBanner = (name: string, inArray: BannerStateInfo[]) =>
	inArray.indexOf(inArray.filter((banner: BannerStateInfo) => banner.name === name)[0]);

export class BannerStateContainer extends Container<BannerStateContainerState> {
	topNavHeight: number;

	constructor(props: BannerStateContainerProps = {}) {
		super();
		this.topNavHeight = props.hasTopNav === false ? 0 : 56;
		this.state.banners = props.initialState?.banners || [];
	}
	state: BannerStateContainerState = {
		banners: [],
	};

	_updateServerRenderedBanners = (spaBannerHeight: number) => {
		if (spaBannerHeight === 0) {
			this.hide(SERVER_RENDER_BANNER_NAME);
		} else {
			this.show(SERVER_RENDER_BANNER_NAME, spaBannerHeight);
		}
	};

	/**
	 * Adds or updates the specified banner in the list of visible banners.
	 * Add the banner is not already in the list, otherwise update the banner's height
	 *
	 * @param {string} name Unique name of the banner
	 * @param {number} height Height of the banner, in pixels
	 */
	show = (name: string, height: number) => {
		void this.setState((state: any) => {
			const index = findBanner(name, state.banners);

			if (index === -1) {
				return {
					...state,
					banners: [...state.banners, { name, height }],
				};
			} else if (state.banners[index].height !== height) {
				const newArray = [...state.banners];
				newArray[index].height = height;
				return { ...state, banners: newArray };
			}
		});
	};

	/**
	 * Removes the specified banner from the list of visible banners
	 * Remove the banner if it is in the list, do nothing it is not
	 *
	 * @param {string} name Unique name of the banner
	 */
	hide = (name: string) => {
		void this.setState((state: any) => {
			const index = findBanner(name, state.banners);

			if (index > -1) {
				const newArray = [...state.banners];
				newArray.splice(index, 1);
				return { ...state, banners: newArray };
			}
		});
	};

	/**
	 * Returns the combined height of all banners in the list.
	 * @param {boolean} includeTopNav Include `TOP_NAV_HEIGHT` in the calculation
	 * if not `false`
	 * @returns {number} Combined height of all visible banners
	 */
	getTotalHeight = (includeTopNav?: boolean) =>
		this.state.banners.reduce(
			(totalOffset: number, banner: BannerStateInfo) => totalOffset + banner.height,
			includeTopNav !== false ? this.topNavHeight : 0,
		);
}
