/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';
import type ApolloClient from 'apollo-client';
import {
	EnvironmentContext,
	type Extension,
	makeAvatar,
	makeAvatarStack,
	makeNativeUserPicker,
	makeThreeLOPromptForCustomUI,
	RendererNext,
	WebRuntime,
	type RenderFn,
	AkButton,
	useNativeButton,
	makeFrame,
} from '@atlassian/forge-ui/ui';
import { ErrorWithCustomContent } from '@atlassian/forge-ui';
import { emit, on } from '@atlassian/forge-ui/events';
import {
	type DispatchEffect,
	type ProductEnvironment,
	type CoreData,
	type ExtensionData,
	type ForgeDoc,
	type FrameProps,
} from '@atlassian/forge-ui-types';

import { token } from '@atlaskit/tokens';
import { type FlagFunctions, getFlagProvider } from '../../utils/getFlagProvider';
import { areCommonPropertiesEqual } from '../../utils/areCommonPropertiesEqual';
import { IframeRenderer } from './IframeRenderer';

interface LegacyUIKitTwoRendererProps {
	accountId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	coreData: CoreData;
	extensionData: ExtensionData;
	extension: Extension;
	environment: ProductEnvironment;
	locale: string;
	extensionTitle?: string;
	flags: FlagFunctions;
	isInline?: boolean;
}

export function NativeButtonWithBackground(props: Parameters<RenderFn>[0]) {
	const { akButtonProps } = useNativeButton(props);
	return (
		<div
			// The button background is needed to prevent any coloured
			// background behind an app from showing through the button.
			// TODO Delete this comment after verifying space token -> previous value `border-radius: 3px`
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			css={css({
				backgroundColor: token('color.background.neutral', 'white'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				width: akButtonProps.shouldFitContainer ? '100%' : 'max-content',
				borderRadius: token('border.radius', '3px'),
			})}
		>
			<AkButton {...akButtonProps} />
		</div>
	);
}

export const LegacyUIKitTwoRenderer = ({
	accountId,
	apolloClient,
	coreData,
	extensionData,
	environment,
	extension,
	locale,
	extensionTitle,
	flags,
	isInline = false,
}: LegacyUIKitTwoRendererProps) => {
	const { cloudId } = coreData;
	const [forgeDoc, setForgeDoc] = useState<ForgeDoc | undefined>(undefined);
	const dispatch = useCallback(async (_: DispatchEffect) => {}, []);

	const [macroConfigForgeDocSubject$] = useState(() => new Subject());
	const configValueRef = useRef(undefined);
	// Stores the forgeDoc for the macro config schema. The ref is often a render behind
	// the actual schema, so should only be used on the initial load.
	const macroConfigForgeDocRef = useRef<ForgeDoc | undefined>(undefined);

	// This sets up an event listener to respond to Editor's requests for the macro config schema.
	// When the GET_CONFIG_FORGE_DOC_${id} event is emitted, the payload includes the current
	// config value, and the resolve and reject functions of a Promise. The listener should be
	// resolving the promise with the value of the macro config forgeDoc.
	const id = `${extension.id}-${coreData.localId}`;
	useEffect(() => {
		const macroConfigForgeDocSubscription = on(
			`GET_CONFIG_FORGE_DOC_${id}`,
			async ({ resolve, reject, config }) => {
				// If the config value received in the event is different from the existing
				// stored value in configValueRef, then the forgeDoc will be updated.
				// We need to set a debounce time to wait for the new forgeDoc to be returned
				// from the app before we resolve the promise.
				if (configValueRef.current && !areCommonPropertiesEqual(configValueRef.current, config)) {
					const subscription = macroConfigForgeDocSubject$.pipe(debounceTime(200)).subscribe({
						next: (forgeDoc) => {
							resolve({
								type: 'Root',
								props: {},
								children: [forgeDoc],
							});
							subscription.unsubscribe();
						},
					});
				} else {
					if (!macroConfigForgeDocRef.current) {
						reject('MacroConfig forgeDoc is not defined.');
					}
					resolve({
						type: 'Root',
						props: {},
						children: [macroConfigForgeDocRef.current],
					});
				}
				configValueRef.current = config;
			},
		);

		return () => {
			macroConfigForgeDocSubscription.unsubscribe();
		};
	}, [id, macroConfigForgeDocSubject$]);

	const getComponents = useCallback(
		(defaults: any) => {
			const forgeReactMajorVersion = forgeDoc?.forgeReactMajorVersion;

			const componentOverrides =
				!!forgeReactMajorVersion && forgeReactMajorVersion < 10
					? {
							Button: (args: Parameters<RenderFn>[0]) => <NativeButtonWithBackground {...args} />,
						}
					: {};

			return {
				...defaults,
				...componentOverrides,
				Avatar: makeAvatar({ client: apolloClient }),
				AvatarStack: makeAvatarStack({ client: apolloClient }),
				ThreeLOPrompt: makeThreeLOPromptForCustomUI({
					appName: extensionTitle,
				}),
				UserPicker: makeNativeUserPicker({
					client: apolloClient,
					accountId,
					cloudId,
					productKey: 'confluence',
				}),
				Frame: makeFrame((inputProps: FrameProps) => {
					// Frame is not supported in inline layout for performance reasons
					if (isInline) {
						throw new ErrorWithCustomContent({
							logMessage: 'Frame rendered in macro inline layout',
							errorTitle: 'Error rendering component',
							errorBody: (
								<div>
									The Frame component does not support the inline layout for the macro module.
									Please use another component from the UI Kit{' '}
									<a
										href="https://developer.atlassian.com/platform/forge/ui-kit/components/"
										target="_blank"
									>
										components list.
									</a>
								</div>
							),
						});
					}
					return (
						<IframeRenderer
							accountId={accountId}
							apolloClient={apolloClient}
							contextIds={[`ari:cloud:confluence::site/${cloudId}`]}
							environment={environment}
							extension={{
								...extension,
								properties: {
									...extension.properties,
									...inputProps,
								},
							}}
							coreData={coreData}
							extensionData={extensionData}
							locale={locale}
							flags={flags}
						/>
					);
				}),
			};
		},
		[
			forgeDoc?.forgeReactMajorVersion,
			apolloClient,
			extensionTitle,
			accountId,
			cloudId,
			isInline,
			environment,
			extension,
			coreData,
			extensionData,
			locale,
			flags,
		],
	);

	const { showFlag, closeFlag } = useMemo(() => getFlagProvider(flags), [flags]);

	return (
		<EnvironmentContext.Provider value={environment}>
			<RendererNext
				forgeDoc={forgeDoc}
				dispatch={dispatch}
				components={getComponents}
				extension={extension}
				isNative
			/>
			<WebRuntime
				setForgeDoc={(forgeDoc) => {
					if (forgeDoc.type === 'MacroConfig') {
						macroConfigForgeDocRef.current = forgeDoc;
						macroConfigForgeDocSubject$.next(forgeDoc);
						emit(`CONFIG_FORGE_DOC_UPDATED_${id}`);
						return;
					}
					setForgeDoc(forgeDoc);
				}}
				accountId={accountId}
				apolloClient={apolloClient}
				components={getComponents}
				contextIds={[`ari:cloud:confluence::site/${cloudId}`]}
				extension={extension}
				coreData={coreData}
				extensionData={extensionData}
				locale={locale}
				bridge={{
					showFlag,
					closeFlag,
				}}
			/>
		</EnvironmentContext.Provider>
	);
};
