import gql from 'graphql-tag';

export const LegacyBridgeQueryV2 = gql`
	query LegacyBridgeQueryV2($contentId: ID!) {
		pageV2(id: $contentId) {
			id
			title
			status
			parentType
			parentPage {
				id
				title
			}
			version {
				number
			}
			operations {
				results {
					operation
					targetType
				}
			}
		}
		singleContent(id: $contentId) {
			type
		}
		space(pageId: $contentId) {
			id
			key
			name
			type
		}
	}
`;
