import { Slice } from '@atlaskit/editor-prosemirror/model';
import { safeInsert } from '@atlaskit/editor-prosemirror/utils';

import type {
	ActionAppearance,
	EditorPluginAIConfigItemMarkdownAction,
} from '../../config-items/config-items';
import { SuggestTitleEvents } from '../../utils/confluence-suggest-title/event-enums';

import {
	beforeDispatchAIRovoAgentTransaction,
	beforeDispatchAITransaction,
	insertAIContentAtCurrentPosition,
	replaceWithAIContent,
} from './action-utils';
import { messages } from './messages';

export type CreateInsertProps = { appearance: ActionAppearance; isRovoAgentAction?: boolean };

export const createInsertAtTop = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insertAtTop,
	appearance,
	run({ editorView, pmFragment }) {
		const { state } = editorView;
		const transaction = state.tr;

		const slice = new Slice(pmFragment, 0, 0);
		transaction.replace(0, 0, slice);

		beforeDispatchAITransaction(transaction, state);
		editorView.dispatch(transaction);
	},
});

export const createInsertBelow = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insertBelow,
	appearance,
	run({ editorView, positions, pmFragment }) {
		const { state } = editorView;
		const transaction = state.tr;
		const insertPos = state.doc.resolve(positions[1]).after();
		safeInsert(pmFragment, insertPos)(transaction);

		beforeDispatchAITransaction(transaction, state);
		editorView.dispatch(transaction);
	},
});

export const createInsertAtCurrentPosition = ({
	appearance,
	isRovoAgentAction,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insert,
	appearance,
	isRovoAgentAction,
	run({ editorView, positions, pmFragment }) {
		const tr = insertAIContentAtCurrentPosition({ editorView, positions, pmFragment });
		if (isRovoAgentAction) {
			beforeDispatchAIRovoAgentTransaction(tr);
		}
		editorView.dispatch(tr);
	},
});

export const createReplace = ({
	appearance,
	isRovoAgentAction,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.replace,
	appearance,
	isRovoAgentAction,
	run({ editorView, positions, pmFragment }) {
		const tr = replaceWithAIContent({ editorView, positions, pmFragment });
		if (isRovoAgentAction) {
			beforeDispatchAIRovoAgentTransaction(tr);
		}
		editorView.dispatch(tr);
	},
});

/**
 * EXPERIMENTAL: Util for experimental flow where 'Suggest title' in
 * Confluence title toolbar will trigger the 'Suggest a title' flow in Editor AI.
 * This util allows replacing the title in Confluence.
 */
export const createReplaceExistingConfluenceTitle = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.replaceTitle,
	appearance,
	run({ pmFragment }) {
		let fragmentToInsert = pmFragment;
		let textContent = '';
		fragmentToInsert.forEach((node) => {
			textContent += node.textContent;
		});
		const confluenceTitle = document.querySelector(
			'textarea[data-ai-suggest-title-target="true"]',
		) as HTMLTextAreaElement;
		if (confluenceTitle) {
			const event = new CustomEvent(SuggestTitleEvents.ReplaceConfluenceTitle, {
				detail: { title: textContent },
			});
			document.dispatchEvent(event);
			confluenceTitle.scrollIntoView();
		}
	},
});
