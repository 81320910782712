import type { ApolloQueryResult } from 'apollo-client';

import type { Metadata } from '@atlaskit/collab-provider/types';

import { getApolloClient } from '@confluence/graphql';
import { isResolved, getValue } from '@confluence/lifted-promise';
import { cfetch } from '@confluence/network';
import { getSessionData } from '@confluence/session-data';

import { CollabDraftQuery } from './CollabDraftQuery.graphql';
import type {
	CollabDraftQuery as CollabDraftQueryRes,
	CollabDraftQueryVariables,
} from './__types__/CollabDraftQuery';
import { getCollabProviderUrl } from './collabProviderUrl';

export type InitialDraft = {
	document: any;
	version: number;
	metadata?: Metadata;
};

export type FetchDraft = {
	draft: InitialDraft | null;
	response?: Response | ApolloQueryResult<any>;
	error?: Error;
};

export async function fetchDraft(documentAri: string, token?: string | null): Promise<FetchDraft> {
	const sessionDataPromise = getSessionData();
	const sessionData = isResolved(sessionDataPromise)
		? getValue(sessionDataPromise)
		: await sessionDataPromise;
	const shouldLoadDocumentFromGql = sessionData?.featureFlagClient.getBooleanValue(
		'confluence.frontend.load-document-draft-from-graphql',
		{
			default: false,
		},
	);

	let draftPromise;
	if (shouldLoadDocumentFromGql) {
		draftPromise = fetchDraftGQL(documentAri, token);
	} else {
		draftPromise = fetchDraftREST(documentAri, token);
	}

	return draftPromise;
}

async function fetchDraftREST(documentAri: string, token?: string | null): Promise<FetchDraft> {
	const url = getCollabProviderUrl();
	const encodeARI = encodeURIComponent(documentAri);

	const headers: HeadersInit = {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
	};

	if (token) {
		headers['X-Token'] = token;
	}

	const response = await cfetch(`${url}/document/${encodeARI}/draft?format=pm`, {
		method: 'GET',
		headers: { ...headers },
	});

	if (response.ok) {
		// draftResponse from endpoint will contain a document (ProseMirrorNode JSON string),
		// metadata, and version number

		// On the client side, document will then be converted into a JSON object
		const responseJson = await response.json();
		const draft = {
			...responseJson,
			document: JSON.parse(responseJson.document),
		};

		return { draft, response };
	}

	return { draft: null, response };
}

const fetchDraftGQL = async (documentAri: string, token?: string | null) => {
	const response = await getApolloClient().query<CollabDraftQueryRes, CollabDraftQueryVariables>({
		query: CollabDraftQuery,
		variables: {
			id: documentAri,
			token: token || null,
		},
		fetchPolicy: window.__SSR_RENDERED__ ? 'cache-first' : 'network-only',
	});
	const { data, errors } = response;

	if (errors) {
		return { draft: null, response };
	}

	const responseJson = data.collabDraft;
	const draft = {
		...responseJson,
		document: JSON.parse(responseJson?.document || ''),
	} as InitialDraft;

	return { draft, response };
};
