/* eslint react/prop-types:0 */
import React from 'react';

import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const SpaceSettingsAutomationRouteComponent = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-SpaceSettingsAutomationRouteComponent" */ './SpaceSettingsAutomationRouteComponent'
		),
});

export const SpaceSettingsAutomationRoute = ({ params: { spaceKey } }) => (
	<SpaceSettingsAutomationRouteComponent spaceKey={spaceKey} />
);

SpaceSettingsAutomationRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }) => ({
	Screen: {
		screenEvent: { name: 'spaceSettingAutomationRoute', id: spaceKey },
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		disableMinWidth: true,
	},
});
