import type { FileState } from '@atlaskit/media-client';
import { globalMediaEventEmitter } from '@atlaskit/media-client';
import type { MediaProvider } from '@atlaskit/editor-core';
import type { MediaClientConfig } from '@atlaskit/media-core';
import type { UploadRejectionData } from '@atlaskit/media-picker';

import { cacheWithExp } from '../cacheWithExp';
import { getTemplateMediaContextValues, getTemplateMediaAuth } from '../TemplateMediaProvider';

import type {
	MediaDownloadTokenQuery_content_nodes,
	MediaDownloadTokenQuery_mediaConfiguration,
} from './__types__/MediaDownloadTokenQuery';
import { fetchMediaToken, fetchExternalShareMediaToken, getUploadToken } from './graphql-helpers';
import { attachUploadedMedia } from './media-helpers';

type ConfigOptions = {
	contentId: string;
	isExternalShare: boolean;
	mediaToken?: string;
};

type EditorMediaProviderOptions = {
	contentType: string;
	onUploadRejection?: (data: UploadRejectionData) => boolean;
} & ConfigOptions;

export type TokenData = {
	config: ConfigData;
	token: string;
	collectionId?: string;
};

export type ConfigData = MediaDownloadTokenQuery_mediaConfiguration;

const DEFAULT_CONFIG: ConfigData = { clientId: '', fileStoreUrl: '' };

export type AuthProvider = {
	clientId: string;
	baseUrl: string;
	token: string;
};

const cachedGetDownloadData = cacheWithExp<TokenData>(
	async ({ contentId, isExternalShare }: ConfigOptions) => {
		const response = await (isExternalShare ? fetchExternalShareMediaToken : fetchMediaToken)(
			contentId,
		);
		const data = response?.data;
		const content: MediaDownloadTokenQuery_content_nodes = isExternalShare
			? data?.['singleContent']
			: data?.['content']?.nodes?.[0];

		return {
			token: content?.body?.atlas_doc_format?.mediaToken?.token || '',
			config: data?.mediaConfiguration || DEFAULT_CONFIG,
		};
	},
	(json) => json.token,
);

export const createAuthProvider =
	(dataGetter: () => Promise<TokenData>): (() => Promise<AuthProvider>) =>
	async () => {
		const data = await dataGetter();
		return {
			token: data.token || '',
			clientId: data.config.clientId || '',
			baseUrl: data.config.fileStoreUrl || '',
		};
	};

export const getAuthFromContext = async (contextId: string): Promise<AuthProvider> => {
	const { collectionId, templateId, spaceKey } = getTemplateMediaContextValues(contextId);

	// template media informtion is stored slightly different in the context
	// to be able to discern which call to make
	if (collectionId || templateId) {
		return getTemplateMediaAuth({ collectionId, spaceKey, templateId });
	}

	const response = await fetchMediaToken(contextId);
	const { clientId = '', fileStoreUrl = '' } = response?.data?.mediaConfiguration || {};

	return {
		clientId,
		token: response?.data?.content?.nodes?.[0]?.body?.atlas_doc_format?.mediaToken?.token || '',
		baseUrl: fileStoreUrl || '',
	};
};

const cachedGetUploadData = cacheWithExp<TokenData>(
	async (options: ConfigOptions) => {
		const response = await getUploadToken(options.contentId);
		const session = response?.data?.contentMediaSession;
		return {
			token: session?.token?.value || '',
			config: session?.configuration || DEFAULT_CONFIG,
			collectionId: session?.collection || '',
		};
	},
	(json) => json.token,
);

const getUploadCollectionId = async (options: ConfigOptions) => {
	const uploadData = await cachedGetUploadData.getValue(options);
	return uploadData.collectionId;
};

const getUploadMediaClientConfig = async (options: ConfigOptions): Promise<MediaClientConfig> => {
	const config = {
		authProvider: createAuthProvider(() => cachedGetUploadData.getValue(options)),
		getAuthFromContext,
		useSha256ForUploads: true,
	};

	return config;
};

export const getViewMediaClientConfig = async (
	options: ConfigOptions,
): Promise<MediaClientConfig> => ({
	authProvider: createAuthProvider(async () => {
		let data: TokenData = await cachedGetDownloadData.getValue(options);

		if (options.mediaToken) {
			data.token = options.mediaToken;
		}

		// For drafts mediaToken by default is null and created once any media added to them
		// So, once publishing draft that just got media but token was cached before as `null`
		// we need to re-fetch the token without the cache
		if (!data.token) {
			data = await cachedGetDownloadData.forceGetValue(options);
		}

		return data;
	}),
	getAuthFromContext,
});

export const createRendererMediaProvider = async (
	options: ConfigOptions,
): Promise<MediaProvider> => ({
	viewMediaClientConfig: await getViewMediaClientConfig(options),
});

export const createEditorMediaProvider = async (
	options: EditorMediaProviderOptions,
): Promise<MediaProvider> => {
	registerGlobalFileAddedListener(options);

	const [viewMediaClientConfig, uploadMediaClientConfig, collection] = await Promise.all([
		getViewMediaClientConfig(options),
		getUploadMediaClientConfig(options),
		getUploadCollectionId(options),
	]);

	return {
		viewMediaClientConfig,
		uploadParams: {
			collection,
			onUploadRejection: options.onUploadRejection,
		},
		uploadMediaClientConfig,
	};
};

let previousAttachUploadedMediaListener: (fileState: FileState) => void;

const registerGlobalFileAddedListener = (options: EditorMediaProviderOptions) => {
	if (previousAttachUploadedMediaListener) {
		globalMediaEventEmitter.off('file-added', previousAttachUploadedMediaListener);
	}
	previousAttachUploadedMediaListener = attachUploadedMedia(options);
	globalMediaEventEmitter.on('file-added', previousAttachUploadedMediaListener);
};
