import gql from 'graphql-tag';

export const SPAViewContextQuery = gql`
	query SPAViewContextQuery {
		spaViewContext {
			userCanCreateContent
			isAnonymous
			homepageUri
			homepageTitle
		}
		siteConfiguration {
			tenantId
			siteLogoUrl
			siteTitle
			showSiteTitle
			showFrontCover
			frontCoverState
			companyHubName
		}
		isNewUser
		isSiteAdmin
		abTestCohorts
		experimentFeatures
	}
`;
