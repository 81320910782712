import { query } from '@confluence/query-preloader-tools';

import { preloadOptions } from '../Options/preloadOptions';

import { getStarredQueryOptions } from './starredQueryHelpers';

export const preloadStarred = ({ isLoggedIn, bestEffortTask, isSpaceAliasFFEnabled = false }) => {
	return Promise.all([
		bestEffortTask('HomeStarredPagesQuery', () =>
			query(getStarredQueryOptions({ isSpaceAliasFFEnabled })),
		),
		preloadOptions({ isLoggedIn }),
	]);
};
