import mean from 'lodash/mean';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { EditorState, Transaction } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { getExperimentCohort } from '@atlassian/generative-ai-modal/utils/experiments';

import { addAnalytics, withAnalytics } from '../../analytics/utils';
import { type DiffObject, type ParagraphChunk } from '../../utils/diff-match-patch/utils';
import { median } from '../../utils/median';

import { aiSpellingGrammarPluginKey } from './ai-spelling-grammar-plugin-key';
import {
	disableNeedSpellingAndGrammar,
	insertSuggestion,
	removeSuggestion,
	toggleSpellingAndGrammar,
} from './commands';
import type { ProactiveAIBlock, ProactiveAISentence } from './states';

const getCommonAnalyticsAttributes = (): Record<string, string> => {
	const variation = getExperimentCohort('editor_ai_-_proactive_ai_model_variations');
	if (variation !== 'control' && variation !== 'not-enrolled') {
		return {
			variation,
		};
	}
	return {
		variation: 'default',
	};
};

const getSuggestionMetadata = ({
	blockOrSentenceFromDiffObject,
	editorState,
}: {
	blockOrSentenceFromDiffObject?: ProactiveAIBlock | ProactiveAISentence;
	editorState: EditorState;
}) => {
	if (!blockOrSentenceFromDiffObject) {
		return;
	}

	const { doc, schema } = editorState;
	const paragraphNodeType = schema.nodes.paragraph;

	/**
	 * Contextual node types that contain text nodes
	 */
	const contextualParentNodeTypes = [
		schema.nodes.heading,
		schema.nodes.taskItem,
		schema.nodes.decisionItem,
	];

	/**
	 * Contextual node types that contain a paragraph
	 */
	const contextualGrandparentNodeTypes = [schema.nodes.listItem, schema.nodes.tableHeader];

	let contextualFormatting = false;
	const resolvedSelectedDiffPos = doc.resolve(blockOrSentenceFromDiffObject.from);
	const parent = resolvedSelectedDiffPos.parent;
	if (contextualParentNodeTypes.includes(parent.type)) {
		contextualFormatting = true;
	} else if (parent.type === paragraphNodeType && resolvedSelectedDiffPos.depth >= 1) {
		const grandparent = resolvedSelectedDiffPos.node(resolvedSelectedDiffPos.depth - 1);
		if (contextualGrandparentNodeTypes.includes(grandparent.type)) {
			contextualFormatting = true;
		}
	}

	const metadata = {
		...blockOrSentenceFromDiffObject?.metadata,
		inlineNodeCount: blockOrSentenceFromDiffObject?.ignoredRanges.filter(
			({ type }) => type === 'inlineNode',
		).length,
		contextualFormatting,
	};

	return metadata;
};

export const insertSuggestionWithAnalytics = ({
	aiInteractionID,
	selectedDiff,
	editorState,
	blockOrSentenceFromDiffObject,
}: {
	aiInteractionID: string;
	editorState: EditorState;
	selectedDiff: DiffObject;
	blockOrSentenceFromDiffObject?: ProactiveAIBlock | ProactiveAISentence;
}) =>
	withAnalytics({
		payload: {
			action: 'inserted',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				...getCommonAnalyticsAttributes(),
				...getSuggestionMetadata({
					blockOrSentenceFromDiffObject,
					editorState,
				}),
				aiInteractionID,
			},
			eventType: EVENT_TYPE.TRACK,
		},
	})(insertSuggestion(selectedDiff.from, selectedDiff.to));

export const removeSuggestionWithAnalytics = ({
	aiInteractionID,
	editorState,
	selectedDiff,
	blockOrSentenceFromDiffObject,
}: {
	aiInteractionID: string;
	editorState: EditorState;
	selectedDiff: DiffObject;
	blockOrSentenceFromDiffObject?: ProactiveAIBlock | ProactiveAISentence;
}) =>
	withAnalytics({
		payload: {
			action: 'dismissed',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				...getCommonAnalyticsAttributes(),
				aiInteractionID,
				...getSuggestionMetadata({
					blockOrSentenceFromDiffObject,
					editorState,
				}),
			},
			eventType: EVENT_TYPE.TRACK,
		},
	})(removeSuggestion(selectedDiff.originalText));

export const toggleSpellingAndGrammarWithAnalytics = ({
	proactiveToggleCount,
	totalSuggestionsOnPage,
	dismissedWords,
	insertionCount,
	initialToggleState,
}: {
	proactiveToggleCount: number;
	totalSuggestionsOnPage: number;
	dismissedWords: number;
	insertionCount: number;
	initialToggleState: boolean;
}) =>
	withAnalytics({
		payload: {
			action: 'toggled',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				...getCommonAnalyticsAttributes(),
				toggledCount: proactiveToggleCount,
				initialToggleState,
				totalSuggestions: totalSuggestionsOnPage,
				totalAcceptedSuggestions: insertionCount,
				totalDismissedSuggestions: dismissedWords,
			},
			eventType: EVENT_TYPE.TRACK,
		},
	})(toggleSpellingAndGrammar(proactiveToggleCount));

const getFireAPIReceivedAnalytics = () => {
	let apiReceivedCount = 0;
	const apiReceivedSamplingRate = 10;
	let durationArray: number[] = [];
	return ({
		view,
		duration,
		totalSuggestions,
		totalAcceptedSuggestions,
		totalDismissedSuggestions,
	}: {
		view: EditorView;
		duration: number;
		totalSuggestions: number;
		totalAcceptedSuggestions: number;
		totalDismissedSuggestions: number;
	}) => {
		apiReceivedCount++;
		durationArray.push(duration);
		if (apiReceivedCount >= apiReceivedSamplingRate) {
			/**
			 * min / max will not be undefined as this function samples
			 * durations after X amount of times, and this is only calculated
			 * at the end of the sampling
			 */
			const minDuration = Math.min(...durationArray)!;
			const maxDuration = Math.max(...durationArray)!;

			const meanDuration = mean(durationArray);
			const medianDuration = median(durationArray);

			const tr = addAnalytics({
				editorState: view.state,
				tr: view.state.tr,
				payload: {
					action: 'apiReceived',
					actionSubject: 'editorPluginAI',
					actionSubjectId: 'spellingSuggestion',
					attributes: {
						...getCommonAnalyticsAttributes(),
						meanDuration,
						medianDuration,
						minDuration,
						maxDuration,
						totalSuggestions,
						totalAcceptedSuggestions,
						totalDismissedSuggestions,
					},
					eventType: EVENT_TYPE.OPERATIONAL,
				},
			});
			view.dispatch(tr);

			// reset sampling
			apiReceivedCount = 0;
			durationArray = [];
		}
	};
};

export const fireAPIReceivedAnalytics = getFireAPIReceivedAnalytics();

export const disableCheckForFailedChunksWithAnalytics = ({
	view,
	failedChunkIds,
	errors,
	reason,
	statusCode,
}: {
	view: EditorView;
	failedChunkIds: Array<ParagraphChunk['id']>;
	errors: string[];
	reason: string;
	statusCode: number;
}) =>
	disableNeedSpellingAndGrammar(failedChunkIds, (tr) => {
		const uniqueInteractionID = tr.getMeta(aiSpellingGrammarPluginKey);
		let trWithAnalytics = tr;
		errors.forEach((error) => {
			trWithAnalytics = addAnalytics({
				editorState: view.state,
				tr: trWithAnalytics,
				payload: {
					action: 'apiError',
					actionSubject: 'editorPluginAI',
					actionSubjectId: 'spellingSuggestion',
					attributes: {
						...getCommonAnalyticsAttributes(),
						error,
						reason,
						statusCode,
					},
					eventType: EVENT_TYPE.OPERATIONAL,
				},
			});
		});

		trWithAnalytics = addAnalytics({
			editorState: view.state,
			tr: trWithAnalytics,
			payload: {
				action: 'error',
				actionSubject: 'aiResult',
				actionSubjectId: 'editorPluginAI',
				eventType: EVENT_TYPE.TRACK,
				attributes: {
					singleInstrumentationID: uniqueInteractionID || '',
					aiInteractionID: uniqueInteractionID || '',
					aiFeatureName: 'Editor AI',
					aiExperienceName: 'Proactive Spelling and Grammar',
					proactiveAIGenerated: 1,
					userGeneratedAI: 0,
					isAIFeature: 1,
					aiErrorMessage: reason,
					aiErrorCode: statusCode,
				},
			},
		});

		return trWithAnalytics;
	});

export const disableCheckForPurgedChunksWithAnalytics = ({
	purgedChunkIds,
	totalParts,
	totalPurgedParts,
}: {
	purgedChunkIds: Array<ParagraphChunk['id']>;
	totalParts: number;
	totalPurgedParts: number;
}) => {
	return withAnalytics({
		payload: {
			action: 'apiPurged',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				...getCommonAnalyticsAttributes(),
				totalParts,
				totalPurgedParts,
			},
			eventType: EVENT_TYPE.OPERATIONAL,
		},
	})(disableNeedSpellingAndGrammar(purgedChunkIds));
};

export const selectSuggestionWithAnalytics = (
	aiInteractionID: string,
	editorState: EditorState,
) => {
	const transaction = addAnalytics({
		editorState,
		tr: editorState.tr,
		payload: {
			action: 'initiated',
			actionSubject: 'aiInteraction',
			actionSubjectId: 'editorPluginAI',
			attributes: {
				...getCommonAnalyticsAttributes(),
				singleInstrumentationID: aiInteractionID,
				aiInteractionID: aiInteractionID,
				aiFeatureName: 'Editor AI',
				aiExperienceName: 'Proactive Spelling and Grammar',
				proactiveAIGenerated: 1,
				userGeneratedAI: 0,
				isAIFeature: 1,
			},
			eventType: EVENT_TYPE.TRACK,
		},
	});

	return trackSuggestionsPresentationSuccessWithAnalytics(
		aiInteractionID,
		editorState,
		transaction,
	);
};

export const trackSuggestionsPresentationSuccessWithAnalytics = (
	aiInteractionId: string,
	editorState: EditorState,
	transaction?: Transaction,
) =>
	addAnalytics({
		editorState,
		tr: transaction ?? editorState.tr,
		payload: {
			action: 'viewed',
			actionSubject: 'aiResult',
			actionSubjectId: 'editorPluginAI',
			attributes: {
				aiFeatureName: 'Editor AI',
				aiInteractionID: aiInteractionId ?? '',
				singleInstrumentationID: aiInteractionId ?? '',
				proactiveAIGenerated: 1,
				userGeneratedAI: 0,
				isAIFeature: 1,
				aiExperienceName: 'Proactive Spelling and Grammar',
			},
			eventType: EVENT_TYPE.TRACK,
		},
	});

export const ignoreSuggestionWithAnalytics = ({
	aiInteractionID,
	editorState,
	blockOrSentenceFromDiffObject,
	transaction,
}: {
	aiInteractionID: string;
	editorState: EditorState;
	blockOrSentenceFromDiffObject?: ProactiveAIBlock | ProactiveAISentence;
	transaction?: Transaction;
}) =>
	addAnalytics({
		editorState,
		tr: transaction ?? editorState.tr,
		payload: {
			action: 'ignored',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'spellingSuggestion',
			attributes: {
				...getCommonAnalyticsAttributes(),
				...getSuggestionMetadata({
					editorState,
					blockOrSentenceFromDiffObject,
				}),
				aiInteractionID,
			},
			eventType: EVENT_TYPE.TRACK,
		},
	});
// export const ignoreSuggestionWithAnalytics = (
// 	aiInteractionID: string,
// 	editorState: EditorState,
// 	transaction?: Transaction,
// ) =>
// 	addAnalytics({
// 		editorState,
// 		tr: transaction ?? editorState.tr,
// 		payload: {
// 			action: 'ignored',
// 			actionSubject: 'editorPluginAI',
// 			actionSubjectId: 'spellingSuggestion',
// 			attributes: {
// 				...getCommonAnalyticsAttributes(),
// 				aiInteractionID,
// 			},
// 			eventType: EVENT_TYPE.TRACK,
// 		},
// 	});
