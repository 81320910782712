import React, {
	createElement,
	useCallback,
	useLayoutEffect,
	useRef,
	useState,
	useEffect,
} from 'react';

import UFOLoadHold from '@atlassian/ufo-load-hold';

import { useSessionData } from '@confluence/session-data';
import { isElementInViewport } from '@confluence/dom-helpers';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';

import { LoadingPriority, NOOP } from './constants';
import { LoadablePlaceholderClient } from './placeholders/LoadablePlaceholder';
import type {
	DefinitionType,
	LoadableType,
	ModuleType,
	ReactLoadableCompatibleLoadingProps,
	createLoadableComponentProps,
} from './types';
import { LoadableAncestorsProvider, useAutoCorrectPriorityWithAncestor } from './LoadableAncestors';
import { usePlaceholderId } from './placeholders/usePlaceholderId';
import { getServerMarkup } from './placeholders/placeholders';
import { setViewportServerSideLoadableCount } from './serverSideLoadableCount';
import { defer } from './defer';

const DELAY_HYRATION_ON_HOVER_FF = 'confluence.ssr.delay.hydration.on.hover';
const loadableCountByNameAndId: Map<string, number> = new Map<string, number>();

export function createLoadableClientComponent<P extends {}>(
	{
		__loadable_id__,
		isReactLoadable,
		render,
		loading = NOOP,
		// Following props are internal APIs.
		// Do NOT use unless consulted with the performance team.
		_reactTreeRootForServerPlaceholderId,
		_forceServerPlaceholderId,
		hydrateImmediately,
	}: DefinitionType<P>,
	{
		displayName,
		loadableName,
		priority,
		load,
		// Using ref here because we want to get the latest value not a snapshot of the reference
		loadedModuleRef,
	}: createLoadableComponentProps<P>,
) {
	const LoadableClientComponent = (props: P) => {
		const [error, setError] = useState<Error | null>(null);
		const active = useRef(true);
		const elementRef = useRef<HTMLElement>(null);
		const [module, setModule] = useState<ModuleType<P>>(() => loadedModuleRef.ref);
		const currentOccurrenceIndex = useRef(0);
		const placeholderId = usePlaceholderId(__loadable_id__, _forceServerPlaceholderId);
		const loadableNameWithId = `${loadableName}:${placeholderId}`;

		// similar to LoadableServerComponent, LoadableClientComponent needs a unique placeholderReplaceId.
		// Each client component uses a combination of its loadable's placeholderId and the nth occurrence, which
		// matches the id attached to the corresponding element coming from the server-side
		useEffect(() => {
			const currentIndex = loadableCountByNameAndId.get(loadableNameWithId) || 0;
			loadableCountByNameAndId.set(loadableNameWithId, currentIndex + 1);
			currentOccurrenceIndex.current = currentIndex;
			return () => {
				const currentIndex = loadableCountByNameAndId.get(loadableNameWithId);
				if (currentIndex) {
					loadableCountByNameAndId.set(loadableNameWithId, currentIndex - 1);
				}
			};
		}, [placeholderId, loadableNameWithId]);

		const placeholderReplaceId = `${placeholderId}-${currentOccurrenceIndex.current}`;

		const isHydrating = getServerMarkup().has(placeholderId);

		const updateModule = useCallback((m: ModuleType<P> | Error) => {
			if (m instanceof Error) {
				setError(() => m);
			} else {
				setModule(() => m);
			}
		}, []);

		(LoadableClientComponent as LoadableType<P>).setLoadedModule = updateModule;

		const sessionData = useSessionData();
		const enableHolds = sessionData
			? sessionData.featureFlagClient?.getBooleanValue?.('confluence.frontend.ufo.loadable.holds', {
					default: false,
				})
			: false;

		// Correct the priority with nearest React Loadable ancestor
		// Because we force all React Loadable to be AFTER_PAINT. We can't render any PAINT under AFTER_PAINT.
		// Don't change priority directly as this is scope variable that gonna change all the instances of the component.
		const newPriority = useAutoCorrectPriorityWithAncestor(priority);
		const runLoadFn = useCallback(
			(retry: boolean) => {
				if (module || (error && !retry)) return;
				void load(newPriority, isHydrating).then((m) => {
					if (active.current) {
						updateModule(m);
					}
				});
			},
			[module, error, isHydrating, newPriority, updateModule],
		);

		useLayoutEffect(() => {
			active.current = true;
			const delayingHydrationOnHoverConfig = getSSRFeatureFlag(DELAY_HYRATION_ON_HOVER_FF) || {};
			if (
				delayingHydrationOnHoverConfig.enabled &&
				priority === LoadingPriority.HYDRATE_ON_HOVER &&
				!hydrateImmediately?.() &&
				window?.__SSR_RENDERED__
			) {
				defer(delayingHydrationOnHoverConfig.timeout ?? 0, () => runLoadFn(false));
			} else {
				runLoadFn(false);
			}

			return () => {
				active.current = false;
			};
		}, [runLoadFn]);

		useEffect(() => {
			if (elementRef.current) {
				const isElementVisible = isElementInViewport(elementRef.current);
				if (isElementVisible) {
					setViewportServerSideLoadableCount(loadableName);
				}
			}
		}, []);

		let element;
		if (module) {
			// Render is backward compatible with React Loadable
			// note render is a function not a functional component so you can't use hooks in it
			element = render ? render(module, props) : createElement(module, props);
		} else {
			let mergedProps;
			if (isReactLoadable) {
				// Compatible with React Loadable
				// https://github.com/jamiebuilds/react-loadable#propserror
				const reactLoadableLoadingProps: ReactLoadableCompatibleLoadingProps = {};
				if (error) {
					reactLoadableLoadingProps.error = error;
					reactLoadableLoadingProps.retry = runLoadFn.bind(null, true);
				}
				mergedProps = {
					// Do not override user's error or retry props
					...reactLoadableLoadingProps,
					...props,
				};
			} else {
				mergedProps = props;
			}

			// Render SSR markup-based placeholder when available, with fallback to
			// client-side placeholder in case server markup could not be retrieved
			element =
				window['__SSR_RENDERED__'] &&
				(newPriority === LoadingPriority.AFTER_PAINT ||
					newPriority === LoadingPriority.HYDRATE_ON_HOVER)
					? React.createElement(LoadablePlaceholderClient, {
							placeholderId,
							clientPlaceholder: loading,
							elementRef,
							...mergedProps,
						})
					: React.createElement(loading, mergedProps);

			if (priority !== LoadingPriority.BACKGROUND && enableHolds) {
				element = <UFOLoadHold name={`${loadableName}Placeholder`}>{element}</UFOLoadHold>;
			}
		}

		return (
			<LoadableAncestorsProvider
				id={__loadable_id__}
				// placeholderReplaceId is passed to the context so that on hydration,
				// each element can access it and add a new attribute data-ssr-placeholder-replace, whose value is the placeholderId.
				// This data-attribute's value will be checked against the value of the data-ssr-placeholder (added to the initial SSR response).
				// If the placeholderIds match, as well as the size and position of the element, the element is excluded from the TTVC calculation.
				placeholderReplaceId={placeholderReplaceId}
				name={loadableName}
				priority={newPriority}
				rootForServerPlaceholderId={Boolean(_reactTreeRootForServerPlaceholderId)}
				isReactLoadable={isReactLoadable}
			>
				{element}
			</LoadableAncestorsProvider>
		);
	};

	LoadableClientComponent.displayName = displayName;

	// Default priority is designed for predictive preloading.
	// E.g. preload the editor when on view page.
	LoadableClientComponent.preload = () => {
		if (loadedModuleRef.ref) {
			return Promise.resolve(loadedModuleRef.ref);
		}
		return load(LoadingPriority.PAINT);
	};

	LoadableClientComponent.hydrateOnHover = () => {
		const delayingHydrationOnHoverConfig = getSSRFeatureFlag(DELAY_HYRATION_ON_HOVER_FF) || {};
		if (
			!delayingHydrationOnHoverConfig.enabled ||
			loadedModuleRef.ref ||
			hydrateImmediately?.() ||
			!window?.__SSR_RENDERED__
		) {
			return;
		}

		return load(LoadingPriority.HYDRATE_ON_HOVER).then((m) => {
			(LoadableClientComponent as LoadableType<P>).setLoadedModule?.(m);
		});
	};

	return LoadableClientComponent as LoadableType<P>;
}
