import React from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { WidthObserver } from '@atlaskit/width-detector';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const Container = styled.div`
	position: relative;
	overflow: visible;
	z-index: 13;
`;

const popupPortalContainerId = 'highlight-actions-portal-container';
export { popupPortalContainerId };

let popupPortalContainerWidth;

function onResize(width) {
	// Hide popup when the container is resized.
	if (width !== popupPortalContainerWidth) {
		popupPortalContainerWidth = width;
		window.dispatchEvent(new CustomEvent('highlight-actions.container.resized'));
	}
}

export const HighlightActionsProvider = () => {
	return (
		<Container id={popupPortalContainerId}>
			<WidthObserver setWidth={onResize} />
		</Container>
	);
};
