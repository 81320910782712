import { getSessionData } from '@confluence/session-data';
import { getValue } from '@confluence/lifted-promise';
import type { SessionDataType } from '@confluence/session-data';

export const CUSTOM_SITES_COMPANY_HUB_FF = 'confluence.frontend.custom-sites.company-home-builder';
export const NEW_COMPANY_HUB_SPACE_KEY_FF =
	'confluence.frontend.custom-sites.company-hub.new-hub-spacekey';
export const SYSTEM_SPACE_KEY = '~SYSTEM~';
const COMPANY_HUB_SPACE_KEY_PREFIX = '~HUB';

export const isCompanyHubSpaceKey = (spaceKey?: string | null): boolean =>
	!!(
		spaceKey === SYSTEM_SPACE_KEY ||
		(spaceKey?.startsWith(COMPANY_HUB_SPACE_KEY_PREFIX) && spaceKey?.endsWith('~'))
	);

export const getCompanyHubSpaceKey = (
	sessionData?: Pick<SessionDataType, 'activationId' | 'cloudId' | 'featureFlagClient'>,
) => {
	if (!sessionData?.activationId || !sessionData?.cloudId || !sessionData?.featureFlagClient) {
		sessionData = getValue(getSessionData());
	}
	if (sessionData) {
		const { activationId, cloudId, featureFlagClient } = sessionData;
		const isNewHubKeyEnabled = featureFlagClient.getBooleanValue(NEW_COMPANY_HUB_SPACE_KEY_FF, {
			default: false,
		});

		if (isNewHubKeyEnabled) {
			if (activationId && cloudId) {
				return `${COMPANY_HUB_SPACE_KEY_PREFIX}-${cloudId}-${activationId}~`;
			}
		} else {
			return SYSTEM_SPACE_KEY;
		}
	}
};

export enum FrontCoverStateEnum {
	UNSET = 'unset',
	TRANSITION = 'transition',
	HIDDEN = 'hidden',
	EDITORS_ONLY = 'editors_only',
	SHOWN = 'shown',
}

export enum FrontCoverStateInputEnum {
	UNSET = 'UNSET',
	TRANSITION = 'TRANSITION',
	HIDDEN = 'HIDDEN',
	SHOWN = 'SHOWN',
}
