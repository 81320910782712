import { query } from '@confluence/query-preloader-tools';

import { ChildrenMacroQuery } from './ChildrenMacroQuery.graphql';

export function preloadChildrenMacro(contentId: string) {
	return query({
		query: ChildrenMacroQuery,
		variables: {
			contentId,
		},
	});
}
