import { query } from '@confluence/query-preloader-tools';

import { UserPersonalizationQuery } from './UserPersonalizationQuery.experimentalgraphql';

export const preloadUserPersonalizationQuery = (userId: string | null) => {
	return query({
		query: UserPersonalizationQuery,
		variables: {
			userId,
		},
	});
};
