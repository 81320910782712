import type { FC } from 'react';
import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import Select from '@atlaskit/select';
import { Label } from '@atlaskit/form';

import { ConfigPanelSubheading } from './ConfigPanelSubheading';
import { useIsDisabledContext } from './IsDisabledContext';

export enum Alignment {
	LEFT = 'left',
	CENTER = 'center',
}

type AlignmentSelectOption = {
	label: React.ReactNode;
	value: Alignment;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AlignmentFieldFlexContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

export const i18n = defineMessages({
	alignmentTextLabel: {
		id: 'custom-sites-extensions.config-panel.alignment-text-label',
		defaultMessage: 'Alignment',
		description: 'A visible text label for the alignment section of the config panel',
	},
	leftAlignmentDropDownItemLabel: {
		id: 'custom-sites-extensions.alignment-field.drop-down.item.left-alignment.label',
		defaultMessage: 'Left',
		description: 'A visible label for the select option to change alignment to left',
	},
	centerAlignmentDropDownItemLabel: {
		id: 'custom-sites-extensions.alignment-field.drop-down.item.center-alignment.label',
		defaultMessage: 'Center',
		description: 'A visible label for the select option to change alignment to center',
	},
});

const alignmentSelectOptions: AlignmentSelectOption[] = [
	{
		label: <FormattedMessage {...i18n.leftAlignmentDropDownItemLabel} />,
		value: Alignment.LEFT,
	},
	{
		label: <FormattedMessage {...i18n.centerAlignmentDropDownItemLabel} />,
		value: Alignment.CENTER,
	},
];

type AlignmentFieldProps = {
	alignmentParameter: Alignment;
	onAlignmentChange: (selectedAlignment: Alignment) => void;
};

export const AlignmentField: FC<AlignmentFieldProps> = ({
	alignmentParameter,
	onAlignmentChange,
}) => {
	const { formatMessage } = useIntl();
	const selectedOption = alignmentSelectOptions.find(({ value }) => value === alignmentParameter);
	const isDisabled = useIsDisabledContext();

	const handleSelectOnChange = useCallback(
		(selectedLabel: any) => {
			onAlignmentChange(selectedLabel.value);
		},
		[onAlignmentChange],
	);

	return (
		<AlignmentFieldFlexContainer>
			<Label htmlFor="alignment-selector">
				<ConfigPanelSubheading>
					<FormattedMessage {...i18n.alignmentTextLabel} />
				</ConfigPanelSubheading>
			</Label>
			<Select
				id="alignment-selector"
				inputId="card-alignment-select-input"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="alignment-single-select"
				classNamePrefix="alignment-select"
				isSearchable={false}
				value={selectedOption}
				onChange={handleSelectOnChange}
				options={alignmentSelectOptions}
				isDisabled={isDisabled}
				aria-label={formatMessage(i18n.alignmentTextLabel)}
			/>
		</AlignmentFieldFlexContainer>
	);
};
