import type { Action } from 'react-sweet-state';
import { createStore } from 'react-sweet-state';

import { LIVE_PAGES_STORE_ID } from './constants';

type Mode = 'view' | 'edit';

type State = {
	isLive: boolean;
	mode: Mode;
};

const initialState: State = {
	isLive: false,
	mode: 'edit',
};

/**
 * Switches the live status of the page to control whether the page is currently live or not.
 * @param isLive - A boolean indicating the desired live status of the page. Accepts:
 *                 - `true`: The page is live.
 *                 - `false`: The page is not live.
 */
const setIsLive =
	(isLive: boolean): Action<State> =>
	({ setState }) => {
		setState({ isLive });
	};

/**
 * Switches the page mode to control how users see a live page: 'view' mode for read-only access,
 * and 'edit' mode to allow users to make changes to the page.
 *
 * @param mode - The desired mode for the page. Accepts:
 *               - 'view': Users can only view content.
 *               - 'edit': Users can modify the content.
 */
const setMode =
	(mode: Mode): Action<State> =>
	({ setState }) => {
		setState({ mode });
	};

const actions = {
	setIsLive,
	setMode,
};

export const LivePagesStore = createStore({
	initialState,
	actions,
	name: LIVE_PAGES_STORE_ID,
});
