import type { SelectProps as PlatformSelectProps } from '@atlaskit/select';

export type SelectProps = Pick<
PlatformSelectProps<any, true | false>,
  'spacing' | 'appearance' | 'testId' | 'autoFocus' | 'defaultValue' | 'closeMenuOnSelect' | 'inputValue' | 'inputId' | 'isClearable' | 'isLoading' | 'isMulti' | 'isSearchable' | 'menuIsOpen' | 'onInputChange' | 'options' | 'placeholder' | 'onChange'
 | 'id' | 'isDisabled' | 'isInvalid' | 'onBlur' | 'onFocus' | 'value' | 'aria-invalid' | 'aria-labelledby' | 'name' | 'openMenuOnFocus'
> & { isRequired?: boolean };


/**
 * ADS Select has a highly complex API surface area with some props not being compatiable with Forge. 
 * To simplify usage and ensure compatibility, the following props have been excluded:
 * - backspaceRemovesValue
 * - blurInputOnSelect
 * - captureMenuScroll
 * - closeMenuOnScroll
 * - components
 * - controlShouldRenderValue
 * - escapeClearsValue
 * - formatGroupLabel
 * - formatOptionLabel
 * - getOptionLabel
 * - getOptionValue
 * - hideSelectedOptions
 * - isOptionDisabled
 * - isOptionSelected
 * - noOptionsMessage
 * - filterOption - not working correctly
 *
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::15e8dd3c3af9237dd247890c305203b2>>
 * @codegenId #select
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen select
 * @codegenParams {"defineOwnType": true, "hasFieldProps": {"onChange": false, "isRequired": false}, "props": {"spacing": true, "appearance": true, "testId": true, "autoFocus": true, "defaultValue": true, "closeMenuOnSelect": true, "inputValue": true, "inputId" : true, "isClearable": true, "isLoading": true, "isMulti": true, "isSearchable": true, "menuIsOpen": true, "openMenuOnFocus": true, "noOptionsMessage": false, "onInputChange": {"value": "(value, metaData) => { onInputChange?.(value, metaData); return; }", "comment": "onInputChange must return a void type for this to work"}, "options": true, "placeholder": true, "onChange": true }}
 * @codegenDependency ../../../../../../../design-system/select/src/types.ts <<SignedSource::be8cf2db6cce20db25fbea826b24772b>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::776e3f3df8ff5c06e378bdcf4d407167>>
 * @codegenDependency ../../../../../../../design-system/select/src/createSelect.tsx <<SignedSource::5620ac3a9b351e3ab8cccea6b53802dc>>
 * @codegenDependency ../../../../../../../design-system/select/src/Select.ts <<SignedSource::2e3fb9b4f610256b52b96b9e17db5eb5>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { SelectWithoutAnalytics as PlatformSelectWithoutAnalytics } from '@atlaskit/select';
import { adaptEventHandler } from "../../../utils";

// Define the type for SelectWithoutAnalytics as SelectWithoutAnalyticsProps at the top of this file

export const Select = (props: Parameters<RenderFn>[0]) => {
  const {
    spacing,
    appearance,
    testId,
    autoFocus,
    defaultValue,
    closeMenuOnSelect,
    inputValue,
    inputId,
    isClearable,
    isLoading,
    isMulti,
    isSearchable,
    menuIsOpen,
    openMenuOnFocus,
    onInputChange,
    options,
    placeholder,
    onChange,
    // Field props
    id,
    isDisabled,
    isInvalid,
    onBlur,
    onFocus,
    value,
    "aria-invalid": ariaInvalid,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as SelectProps;
  return (
    <PlatformSelectWithoutAnalytics
      spacing={spacing}
      appearance={appearance}
      testId={testId}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      closeMenuOnSelect={closeMenuOnSelect}
      inputValue={inputValue}
      inputId={inputId}
      isClearable={isClearable}
      isLoading={isLoading}
      isMulti={isMulti}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen}
      openMenuOnFocus={openMenuOnFocus}
      // onInputChange must return a void type for this to work
      onInputChange={(value, metaData) => { onInputChange?.(value, metaData); return; }}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      // Field props
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      value={value}
      aria-invalid={ariaInvalid}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */