import type { FC } from 'react';
import React, { memo, useContext } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { FlagsStateContainer } from '@confluence/flags';
import {
	BannerContext,
	BannerStateContainer,
	FloatingBannerStateContainer,
} from '@confluence/banners';
import { useATLGeneralWebPanelListener } from '@confluence/web-panel-location';

import type { ADFRendererProps } from './ADFRendererComponent';
import { ADFRendererComponent } from './ADFRendererComponent';

export const ADFRenderer: FC<ADFRendererProps> = memo((props: ADFRendererProps) => {
	const { includeTopNav } = useContext(BannerContext);
	const { atlGeneralWebPanelHeight } = useATLGeneralWebPanelListener();

	return (
		<Subscribe to={[FlagsStateContainer, BannerStateContainer, FloatingBannerStateContainer]}>
			{(
				flags: FlagsStateContainer,
				bannerState: BannerStateContainer,
				floatingBanners: FloatingBannerStateContainer,
			) => {
				// if page header is always fixed at the top, so it adds extra 55px offset if the FF is enabled
				// However, when there is a theme applied to a space, page headers are not fixed at the top and will not require additional offset.
				const offsetTop =
					bannerState.getTotalHeight(includeTopNav) +
					floatingBanners.getFloatingHeight() +
					(props.isContentHeaderFixedAtTop ? 55 + atlGeneralWebPanelHeight : 0);

				// WARNING: PERFORMANCE: <ADFRendererComponent> has been split out from <ADFRenderer>
				// because the child function of <Subscribe> component is called 4-5 times per every
				// transition or initial load. This caused AkRenderer component to be re-rendered the
				// same amount of times, which is quite bad for performance. Split allows to memoize
				// the <ADFRendererComponent> and render it only once.
				//
				// The fact that <ADFRenderer> is used for comments as well makes it even more
				// important to *not* re-render AkRenderer more than once for a page or comment.
				return <ADFRendererComponent flags={flags} offsetTop={offsetTop} {...props} />;
			}}
		</Subscribe>
	);
});

ADFRenderer.displayName = 'ADFRenderer';
