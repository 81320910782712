import React from 'react';
import type { FC, PropsWithChildren } from 'react';

import { useBooleanFeatureFlag } from '@confluence/session-data';
import { LoadablePaint } from '@confluence/loadable';

export const PassThroughComponent: FC<PropsWithChildren<{}>> = ({ children }) => <>{children}</>;

export const TreeRootForServerPlaceholder = LoadablePaint({
	loader: async () => PassThroughComponent,
	_reactTreeRootForServerPlaceholderId: true,
});

export const TreeRootForServerPlaceholderWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
	const editorSSRFeatureFlag = useBooleanFeatureFlag('confluence.frontend.editor.ssr');
	return editorSSRFeatureFlag ? (
		<TreeRootForServerPlaceholder>{children}</TreeRootForServerPlaceholder>
	) : (
		<>{children}</>
	);
};
