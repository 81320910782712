export const FETCH_MORE_PAGE_SIZE = 5;
export const COMMENT_COLLAPSE_THRESHOLD = 85;

export const feedbackUrl = 'https://atlassian.slack.com/archives/C01AXNT2PH8';
export const learnMoreUrl = 'https://hello.atlassian.net/l/c/1kWRHALN';
export const FEED_BANNER_EP_ID = 'confluence-feed-banner-nav-items';
export const onboardingFeedHeaderCard = 'home.feed.header.onboarding.followed-for-you';

export const FEED_CONTENT_QUERY_TASK_NAME = 'FeedContentQuery';
export const FEED_POPULAR_FEED_QUERY_TASK_NAME = 'FeedPopularFeedQuery';
